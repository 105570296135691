<template>
  <v-field-template
    name="submit"
    :field="field"
    :hidden="hidden"
    :disabled="disabled"
    :errors="errors"
    class="submit__v-field-template"
  >
    <template #field>
      <NolteFormWrapper :errors="errors">
        <NolteButton :type="buttonType" :disabled="disabled" form-submit>
          {{ field.model.title }}
        </NolteButton>
      </NolteFormWrapper>
    </template>
  </v-field-template>
</template>

<script>
import FieldMixin from '../structure/FieldMixin';
import FieldConditionMixin from '../structure/conditions/FieldConditionMixin';
import FieldValidationMixin from '../structure/validation/FieldValidationMixin';

import NolteButton from '../../nolte-ui/NolteButton';
import NolteFormWrapper from '../../nolte-ui/NolteForm/NolteFormWrapper';

export default {
  name: 'SitecoreFormsFieldsSubmit',

  components: {
    NolteButton,
    NolteFormWrapper,
  },

  computed: {
    // set a different button type (e.g. "yellow--plain") if the class attribute of the field
    // contains a css class in the format "button-type__yellow-plain"
    buttonType() {
      const matches = this.field.model.cssClass.match(/button-type__([a-z-]+)/);
      if (matches) {
        return matches[1];
      }
      return 'yellow--shadow';
    },
  },

  mixins: [FieldMixin, FieldConditionMixin, FieldValidationMixin],
};
</script>

<style lang="scss" scoped>
.submit__v-field-template {
  text-align: center;
  min-height: 140px;
}
.submit__v-field-template.button-type__yellow--plain {
  min-height: 0;
}
.form-field--button-left.submit__v-field-template {
  text-align: left;
}
:deep(.nolte-form-wrapper__error-msg) {
  margin-top: 8px;
}
</style>
