<template>
  <div class="nolte-dealer-map" :class="{ open: overlayIsOpen }">
    <div id="map" />
  </div>
</template>

<script>
/*global google*/
import gmapsInit from '../../lib/gmaps';
import { mapState } from 'vuex';
import { MarkerClusterer, SuperClusterAlgorithm } from '@googlemaps/markerclusterer';
import { createPhoneLink, createRouteplannerLink, nl2br } from './utils';
import { bp320, bp425, bp1024 } from '../../styles/variables.scss';

const debug = false;

const icons = {
  showroom: {
    icon: `${process.env.VUE_APP_ASSETS_PATH}/assets/markers/nolte-label.svg`,
  },
  retailer: {
    icon: `${process.env.VUE_APP_ASSETS_PATH}/assets/markers/map-pin-black.svg`,
  },
  retailer_active: {
    icon: `${process.env.VUE_APP_ASSETS_PATH}/assets/markers/map-pin-yellow.svg`,
  },
};

const clusterRenderer = {
  render({ count, position }) {
    return new google.maps.Marker({
      position,
      icon: {
        url: `${process.env.VUE_APP_ASSETS_PATH}/assets/markers/cluster.svg`,
        scaledSize: new google.maps.Size(46, 32),
      },
      label: {
        text: String(count),
        className: 'clusterLabel',
      },
      // adjust zIndex to be above other markers
      zIndex: Number(google.maps.Marker.MAX_ZINDEX) + count,
    });
  },
};

let showroomMarkers = [];
let retailerMarkers = [];
let markerClusterer = null;

export default {
  name: 'GoogleMap',

  props: {
    currentRetailers: {
      type: Array,
      default: undefined,
    },
    currentShowrooms: {
      type: Array,
      default: undefined,
    },
    googleMapsApiKey: {
      type: String,
      default: undefined,
    },
    currentLocation: {
      type: Object,
      default: null,
    },
    currentMarkerId: {
      type: String,
      default: undefined,
    },
    overlayIsOpen: {
      type: Boolean,
      default: false,
    },
    isMobile: {
      type: Boolean,
      default: false,
    },
  },

  data() {
    return {
      google: null,
      map: null,
      infoWindow: null,

      bounds: [],
      mapStyle: [
        {
          featureType: 'road.highway',
          elementType: 'labels.icon',
          stylers: [{ visibility: 'on' }],
        },
        {
          featureType: 'road',
          elementType: 'labels.icon',
          stylers: [{ visibility: 'on' }],
        },
        {
          featureType: 'poi',
          elementType: 'labels.icon',
          stylers: [{ visibility: 'on' }],
        },
        {
          featureType: 'transit',
          elementType: 'labels.icon',
          stylers: [{ visibility: 'on' }],
        },
        {
          featureType: 'poi',
          elementType: 'geometry',
          stylers: [{ color: 'lime' }],
        },
      ],
    };
  },
  computed: {
    ...mapState(['windowWidth']),
    viewportWidth() {
      return this.windowWidth || screen?.width;
    },
    showrooms() {
      return this.currentShowrooms;
    },
    retailers() {
      return this.currentRetailers;
    },
    location() {
      return this.currentLocation;
    },
    retailersLength() {
      return this.retailers.length;
    },
    tillBp320() {
      return this.viewportWidth <= parseInt(bp320, 10) ? true : false;
    },
    tillBp425() {
      return this.viewportWidth <= parseInt(bp425, 10) ? true : false;
    },
    tillBp1024() {
      return this.viewportWidth <= parseInt(bp1024, 10) ? true : false;
    },
  },

  methods: {
    createMarker(id, latLng, html, icon) {
      const marker = new google.maps.Marker({
        id: id,
        position: latLng,
        map: this.map,
        icon: icon ?? icons['retailer'].icon,
      });
      const data = { marker: marker, this: this, html };

      (function (marker, data) {
        google.maps.event.addListener(marker, 'click', function () {
          // unselect all markers
          data.this.unselectMarkers();
          data.this.infoWindow.close();
          data.this.$emit('markerClicked', marker.id);
          marker.setIcon(icons['retailer_active'].icon);

          if (!data.this.isMobile) {
            data.this.infoWindow.setContent(html);
            data.this.infoWindow.open(data.this.map, marker);
          }
        });
      })(marker, data);

      return marker;
    },
    initialize() {
      const myLatLng = { lat: 52.520008, lng: 13.404954 };

      const mapProp = {
        center: myLatLng,
        zoom: 7,
        styles: this.mapStyle,
        disableDefaultUI: true,
        panControl: false,
        zoomControl: true,
        mapTypeControl: false,
        scaleControl: true,
        fullscreenControl: false,
        streetViewControl: false,
        overviewMapControl: false,
        gestureHandling: 'cooperative',
      };

      this.infoWindow = new google.maps.InfoWindow();

      return new google.maps.Map(document.getElementById('map'), mapProp);
    },

    unselectMarkers() {
      // unselect all markers
      for (let i = 0; i < retailerMarkers.length; i++) {
        retailerMarkers[i].setIcon(icons['retailer'].icon);
      }
    },

    setMarkers() {
      this.bounds = new google.maps.LatLngBounds();

      for (let i = 0; i < this.retailers.length; i++) {
        const latLng = new google.maps.LatLng(this.retailers[i].lat, this.retailers[i].lng);

        // add latLng to bounds
        this.bounds.extend(latLng);

        // Using Array-joins over string-interpolation or -concatination might be slower but uses less
        // memory which reduces the probability of 'out of memory' errors, see NOEP-473 memory leak issue.
        const html = ["<div class='address-wrapper'><h4 class='headline'>"];
        if (this.retailers[i].name1) {
          html.push(`${this.retailers[i].name1} `);
        }
        if (this.retailers[i].name2) {
          html.push(this.retailers[i].name2);
        }
        html.push('</h4>');
        html.push(`<span>${this.retailers[i].street}</span>`);
        html.push(`<span>${this.retailers[i].zipcode} ${this.retailers[i].city}</span>`);

        if (this.retailers[i].phone) {
          html.push(
            `<span><a href="
              ${createPhoneLink(this.retailers[i].phone)}
              " target="_blank">
              ${this.retailers[i].phone}
              </a></span>`
          );
        }

        if (this.retailers[i].email) {
          html.push(
            `<span><a href='mailto:
              ${this.retailers[i].email}
              ' class='email'>
              ${this.retailers[i].email}
              </a></span>`
          );
        }

        html.push('</div>');
        html.push("<div class='label-wrapper'>");
        html.push("<span class='label nolte'></span>");
        if (this.retailers[i].isNeo) {
          html.push("<span class='label nolte-neo'></span>");
        }

        html.push('</div>');
        html.push(`<div class='routeplanner-wrapper'>
          <a href='
          ${createRouteplannerLink(this.retailers[i].lat, this.retailers[i].lng)}
          ' target='_blank' class='button--yellow'>
          ${this.$t('editorial-map-overlay-route-planner')}
          </a>
        </div>`);

        const marker = this.createMarker(this.retailers[i].identifier, latLng, html.join(''));

        retailerMarkers.push(marker);
      }

      if (this.bounds) {
        this.map.fitBounds(this.bounds);
      }
    },

    clusterMarkers(map, markers) {
      let radius = 400;
      let extent = 1000;
      let minPoints = 2;

      if (this.tillBp1024) {
        // Portrait view
        if (
          typeof window !== 'undefined' &&
          window.matchMedia('(orientation: portrait )').matches
        ) {
          radius = 200;
          extent = 1250;
        } else {
          radius = 300;
          extent = 1000;
        }
      }
      if (this.tillBp425) {
        radius = 300;
        extent = 1250;
      }
      if (this.tillBp320) {
        radius = 200;
        extent = 1000;
      }

      let algorithm = new SuperClusterAlgorithm({
        radius: radius,
        extent: extent,
        minPoints: minPoints,
      });

      markerClusterer = new MarkerClusterer({
        algorithm: algorithm,
        map: map,
        markers: markers,
        renderer: clusterRenderer,
      });
    },
  },
  async mounted() {
    try {
      this.google = await gmapsInit(this.googleMapsApiKey, 'places,geometry').then(() => {
        this.map = this.initialize();
      });
      this.$watch(
        'location',
        location => {
          if (location?.id) {
            if (retailerMarkers?.length > 0) {
              this.unselectMarkers();
              this.infoWindow.close();

              let selectedMarker = retailerMarkers.find(({ id }) => id === location.id);
              if (selectedMarker) {
                selectedMarker.setIcon(icons['retailer_active'].icon);
                if (debug) {
                  console.log('location.zoom: ', location.zoom);
                  console.log('this.map.getZoom(): ', this.map.getZoom());
                }
                if (location.zoom) {
                  if (this.map.getZoom() < 12) {
                    this.map?.setZoom(12);
                  }
                }
                this.map?.setCenter(new google.maps.LatLng(location.latitude, location.longitude));
                if (!this.isMobile) {
                  this.map.panBy(-250, 0);
                }
                google.maps.event.trigger(selectedMarker, 'click');
              }
            }
          } else {
            if (location?.longitude && location?.latitude) {
              this.map?.setCenter(new google.maps.LatLng(location.latitude, location.longitude));
              if (!this.isMobile) {
                this.map.panBy(-250, 0);
              }
            }
          }
        },
        { immediate: true }
      );

      this.$watch(
        'retailers',
        retailers => {
          if (debug) {
            console.log('showroomMarkers: ', showroomMarkers);
            console.log('retailers changed. amount: ' + retailers?.length);
          }

          retailerMarkers = [];
          markerClusterer?.clearMarkers();

          if (retailers?.length > 0) {
            this.setMarkers();
            this.clusterMarkers(this.map, retailerMarkers);
          }
        },
        { immediate: true }
      );

      this.$watch(
        'showrooms',
        showrooms => {
          for (let i = 0; i < showroomMarkers?.length; i++) {
            showroomMarkers[i].setMap(null);
          }

          showroomMarkers = [];

          if (showrooms?.length > 0) {
            if (debug) {
              console.log('showrooms changed!');
            }
            // add showroomsOfCountry
            for (let i = 0; i < showrooms.length; i++) {
              // Create the markers.
              let latLng = new google.maps.LatLng(
                showrooms[i].addressLat.value,
                showrooms[i].addressLng.value
              );

              let marker = new google.maps.Marker({
                id: 'showroom' + i,
                position: latLng,
                map: this.map,
                icon: icons['showroom'].icon,
                zIndex: 99999,
              });

              showroomMarkers.push(marker);

              const html = [''];
              html.push("<div class='address-wrapper'>");
              html.push("<h4 class='headline'>" + this.showrooms[i].addressName.value + '</h4>');
              html.push('<span>' + this.showrooms[i].addressStreet.value + '</span>');
              html.push(
                '<span>' +
                  this.showrooms[i].addressZip.value +
                  ' ' +
                  this.showrooms[i].addressLocality.value +
                  '</span>'
              );
              html.push(
                '<span><a href="' +
                  createPhoneLink(this.showrooms[i].addressPhone.value) +
                  '" target="_blank">' +
                  this.showrooms[i].addressPhone.value +
                  '</a></span>'
              );

              html.push(
                "<span><a href='mailto:" +
                  this.showrooms[i].addressEmail.value +
                  "' class='email'>" +
                  this.showrooms[i].addressEmail.value +
                  '</a></span>'
              );
              html.push('</div>');
              html.push("<div class='address-wrapper openinghours-wrapper'>");
              html.push(
                "<h4 class='headline'>" + this.$t('editorial-map-overlay-opening-hours') + '</h4>'
              );
              html.push('<div>' + nl2br(this.showrooms[i].openinghours.value) + '</div>');

              html.push('</div>');
              html.push("<div class='label-wrapper'>");
              html.push("<span class='label nolte'></span>");
              html.push("<span class='label nolte-neo'></span>");

              html.push('</div>');
              html.push("<div class='routeplanner-wrapper'>");
              html.push(
                "<a href='" +
                  createRouteplannerLink(
                    this.showrooms[i].addressLat.value,
                    this.showrooms[i].addressLng.value
                  ) +
                  "' target='_blank' class='button--yellow'>" +
                  this.$t('editorial-map-overlay-route-planner') +
                  '</a>'
              );
              html.push('</div>');

              let data = {
                marker: marker,
                this: this,
                html: html,
              };

              (function (marker, data) {
                google.maps.event.addListener(marker, 'click', function () {
                  // unselect all markers
                  data.this.unselectMarkers();
                  data.this.infoWindow.close();
                  data.this.map?.setCenter(
                    new google.maps.LatLng(
                      data.this.showrooms[i].addressLat.value,
                      data.this.showrooms[i].addressLng.value
                    )
                  );
                  data.this.map?.setZoom(12);
                  data.this.$emit('showroomClicked');

                  if (!data.this.isMobile) {
                    data.this.infoWindow.setContent(data.html);
                    data.this.infoWindow.open(data.map, marker);
                  }
                });
              })(marker, data);
            }
          }

          if (!this.retailerMarkers || this.retailerMarkers.length === 0) {
            if (this.tillBp320) {
              this.map?.setZoom(5);
            } else {
              this.map?.setZoom(6);
            }
          }
        },
        { immediate: true }
      );
    } catch (error) {
      console.error(error);
    }
  },
};
</script>

<style lang="scss" scoped>
#debug {
  position: absolute;
  top: 200;
  left: 0;
  z-index: 1000;
}

:deep() {
  .clusterLabel {
    font-family: $font-futura-pt-book;
    font-size: 16px !important;
    line-height: (24 / 18);
    letter-spacing: 0px;
    font-weight: bold;
    color: $color-concret !important;
    position: relative;
    top: 1px;
  }
}

@include helper__until($bp-1024) {
  :deep() {
    .gm-style .gm-style-iw-t {
      display: none !important;
    }
  }
}

:deep() {
  .address-wrapper {
    display: flex;
    flex-direction: column;

    @include text-base;
    color: $color-ironside-gray;
    width: 248px;
    .headline {
      @include text-h4;
      margin: 0 $unit-double $unit-half 0;
    }
    a.email {
      text-decoration: underline;
    }
  }
  .openinghours-wrapper {
    margin-top: $unit-base;
  }
  .button--yellow {
    @include text-h5;
    @include helper__transition((color, border-color, background-color));
    border-radius: $radius-form;
    padding: $unit-half $unit-double;

    border: 2px solid $color-aureolin;
    background-color: $color-aureolin;
    color: #686663;
    text-transform: uppercase;
    text-align: center;
    letter-spacing: 1px;
    margin: 0 auto;
    &:hover {
      border: 2px solid $color-citrine;
    }
  }
  .label-wrapper {
    margin: $unit-half 0;
    @include helper__greater($bp-1024) {
      margin: $unit-base 0;
    }
    display: flex;
    align-items: center;
    align-content: flex-start;
  }

  .label {
    width: auto;
    height: 32px;
    margin-right: $unit-half;

    &.not-active {
      filter: opacity(0.2);
    }

    &.nolte {
      width: 62px;
      background-image: url('../../assets/images/nolte-label.svg');
    }
    &.nolte-neo {
      width: 80px;
      margin-top: -5px;
      background-image: url('../../assets/images/nolte-neo-label.svg');
    }
  }

  .routeplanner-wrapper {
    margin-top: $unit-half;
    margin-bottom: $unit-half;
    height: 24px;
  }

  .gm-style .gm-style-iw-t::after {
    display: none;
  }

  .gm-ui-hover-effect span {
    opacity: 1;
    width: 30px !important;
    height: 30px !important;

    content: url('../../assets/icons/Cross.svg') !important;
  }

  .gm-ui-hover-effect {
    top: 4px !important;
    right: 20px !important;
    width: 26px !important;
    height: 26px !important;
  }
  .gm-style-iw-c {
    box-shadow: 0px 30px 50px #68666366 !important;
    min-width: 279px !important;
    max-width: 285x !important;
    height: auto;
    padding: 16px;
  }

  .gm-style .gm-style-iw-d::-webkit-scrollbar-track,
  .gm-style .gm-style-iw-d::-webkit-scrollbar-track-piece,
  .gm-style .gm-style-iw-c,
  .gm-style .gm-style-iw-t::after {
    background: $color-concret;
  }
}
</style>
<style lang="scss">
img[src*='assets/markers/map-pin'] {
  filter: drop-shadow(0px 3px 4px #0000009f) !important;
}
</style>
