export default function Mixin() {
  return {
    created() {
      // the following lines are a bugfix
      // if you have a shared field of type "CommonFieldTypes.ItemLink" then you will get the data back differently:
      // * Disconnected mode: In an array with only one item that holds the data
      // * Connected mode: Directly
      //
      // We assume that it is wrong to return an array (because of the item type that usually returns only one item) and rewrite this globally.
      // This bugfix assumes that shared data is accessed by a field named "shared".
      //
      // Also keep in mind that this bugfix will change the default behaviour if the field is not of type "ItemLink" but "ContentList".
      // If the ContentList would contain only one item the access syntax would change.
      // I did not find a way to check the type of the field here so I could't implement a workaround for this behaviour.
      if (
        typeof this.fields === 'object' &&
        typeof this.fields?.shared === 'object' &&
        typeof this.fields?.shared[0] === 'object' &&
        typeof this.fields?.shared[0].fields === 'object'
      ) {
        this.fields.shared = this.fields.shared[0];
      }
    },

    mounted() {
      if (process.env.VUE_APP_ENABLE_COMPONENT_ATTRIBUTES) {
        this.markComponentsWithAttribute();
      }
    },

    // As "fields" is the default name for the sitecore parameters and almost every component needs to define this block
    // we define it in the global mixin so we don't have to write this in every component
    props: {
      fields: {
        type: Object,
        default: () => ({}),
      },
    },

    methods: {
      // add a data attribute to the root element of each component
      // so we are able to see where a component begins in the web inspector
      markComponentsWithAttribute() {
        // node is a comment
        if (this.$el.nodeType === 8) return;

        const componentName = `data-component-${this.$options.name || 'root'}`;
        if (this.$el.setAttribute) {
          this.$el.setAttribute(componentName, '');
        }
      },
    },
  };
}
