<template>
  <nav class="nolte-nav-image-pill">
    <NolteScroller class="nolte-nav-image-pill__scroller" scroller-el="ul" shadow-only="">
      <li
        class="nolte-nav-image-pill__item"
        v-for="({ label, image, href }, index) in links"
        :key="index"
        ref="items"
      >
        <NolteButtonImagePill :label="label" :image="image" :href="href" />
      </li>
    </NolteScroller>
  </nav>
</template>

<script>
import NolteButtonImagePill from './NolteButtonImagePill';
import NolteScroller from './NolteScroller';

export default {
  name: 'NolteNavImagePill',

  components: {
    NolteButtonImagePill,
    NolteScroller,
  },

  props: {
    links: {
      type: Array,
      default: undefined,
    },
  },
};
</script>

<style lang="scss" scoped>
.nolte-nav-image-pill__scroller :deep(.viewport) {
  padding-left: $unit-base;
  padding-right: $unit-base;

  @include helper__greater($bp-1024) {
    // we align the start of the viewport vertically with the main content
    padding-left: calc((100% - #{$bp-1024 - $unit-base * 2}) / 2);
    padding-right: calc((100% - #{$bp-1024 - $unit-base * 2}) / 2);
  }
}

.nolte-nav-image-pill__item {
  // size is defined via image size inside button-image-pill

  & + & {
    margin-left: $unit-base;
  }

  :deep(img) {
    height: 100%;
  }
}
</style>
