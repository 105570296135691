export default function (id) {
  (function (d, s, i) {
    const j = d.createElement(s);
    j.type = 'text/javascript';
    j.src = `https://load.nootiz.com/${i}`;
    j.defer = true;

    const sc = d.getElementsByTagName(s)[0];
    sc.parentNode.insertBefore(j, sc);
  })(document, 'script', id);
}
