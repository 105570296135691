<template>
  <div class="nolte-highlight" :class="`nolte-highlight--design-${design}`">
    <ContentLightbox :images="[image]">
      <div class="nolte-highlight__image-wrapper">
        <EditorialImage
          v-if="image.value.src"
          class="nolte-highlight__image"
          :media="image"
          default-aspect-ratio="3:2"
          default-sizes="500px"
          fit="cover"
          :editable="false"
        />
      </div>
    </ContentLightbox>
    <div class="nolte-highlight__content-wrapper">
      <h2 class="nolte-highlight__headline">{{ headline }}</h2>
      <p>{{ copy }}</p>
    </div>
  </div>
</template>

<script>
import EditorialImage from '@/components/EditorialImage';
import ContentLightbox from '@/components/ContentLightbox';

export default {
  name: 'NolteHighlight',

  components: {
    EditorialImage,
    ContentLightbox,
  },

  props: {
    image: {
      type: Object,
      required: true,
    },
    headline: {
      type: String,
      required: true,
    },
    copy: {
      type: String,
      required: true,
    },
    design: {
      type: String,
      default: 'card',
      validator(design) {
        return ['card', 'plain'].includes(design);
      },
    },
  },
};
</script>

<style lang="scss" scoped>
.nolte-highlight {
  width: 300px;
  max-width: 80vw;
}

.nolte-highlight__image-wrapper {
  @include helper__aspect-ratio(4/3);
}

.nolte-highlight__image {
  height: 100%;
}

.nolte-highlight__headline {
  margin-top: $unit-base * 1.5;
  margin-bottom: $unit-half;

  .nolte-highlight--design-card & {
    @include text-h4;
  }

  .nolte-highlight--design-plain & {
    @include text-h2;
    font-size: 32px !important;
  }
}

.nolte-highlight--design-plain {
  position: relative;
  padding-left: $unit-base;

  &::after {
    content: '';
    position: absolute;
    left: -2px;
    top: 0;
    height: 100%;
    width: 4px;
    background-color: $color-athens-gray;
    border-radius: 2px;
  }

  .nolte-highlight__image-wrapper {
    border-radius: $radius-default;
    overflow: hidden;
  }
}

$border-radius-card: $radius-default;

.nolte-highlight--design-card {
  background: $color-white;
  border-radius: $border-radius-card;
  position: relative;

  &::before {
    content: '';
    position: absolute;
    top: $unit-base;
    right: $unit-base;
    bottom: 0;
    left: $unit-base;
    border-radius: $border-radius-card;
    box-shadow: 0 0 50px rgba($color-black, 0.15);
    z-index: -1;
  }

  .nolte-highlight__image-wrapper {
    border-top-left-radius: $radius-default;
    border-top-right-radius: $radius-default;
    overflow: hidden;
  }

  .nolte-highlight__content-wrapper {
    padding-right: $unit-base;
    padding-bottom: $unit-base;
    padding-left: $unit-base;
  }
}
</style>
