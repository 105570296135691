<template>
  <div class="editorial-button-outer-wrapper" :class="`editorial-component--${design}`">
    <div class="editorial-button-wrapper">
      <NolteButton
        v-if="!isEditing && linkHasValue(fields.link)"
        class="editorial-button"
        :link-url="linkUrl"
        :target="target"
        :external="isExternal"
        :type="buttonType"
        >{{ fields.link.value.text }}</NolteButton
      >
      <div
        v-else-if="isEditing"
        class="editorial-button editorial-button--yellow"
        :class="`editorial-button--${buttonType}`"
      >
        <sc-link :field="fields.link" />
      </div>
    </div>
  </div>
</template>

<script>
import { mapState, mapGetters } from 'vuex';
import { linkHasValue } from './helper';
import NolteButton from '../nolte-ui/NolteButton';
import { Link } from '@sitecore-jss/sitecore-jss-vue';

export default {
  name: 'EditorialButton',

  components: {
    ScLink: Link,
    NolteButton,
  },

  computed: {
    ...mapState('jss', ['sitecoreContext', 'routeData']),
    ...mapGetters('jss', ['isNeoPage', 'isEditing']),

    isExternal() {
      return this.fields?.link?.value?.linktype === 'external';
    },
    buttonType() {
      return (
        this.fields?._extendedParams?.ButtonType?.fields?.Type?.value ||
        (this.isNeoPage ? 'white--outline' : 'yellow')
      );
    },
    linkUrl() {
      let linkUrl = this.fields?.link?.value?.href;
      if (this.fields?.link?.value?.querystring !== undefined) {
        linkUrl = linkUrl + '?' + this.fields?.link?.value?.querystring;
      }
      return this.isEditing ? '' : linkUrl;
    },
    target() {
      let target = this.fields?.link?.value?.target;
      if (this.fields?.link?.value?.querystring !== undefined) {
        target = target + '?' + this.fields?.link?.value?.querystring;
      }
      return this.isEditing ? '' : target;
    },
    design() {
      // returns default or dark
      return this.fields._extendedParams?.BackgroundDesign?.fields?.Type?.value || 'default';
    },
  },

  methods: {
    linkHasValue,
  },
};
</script>

<style lang="scss" scoped>
.editorial-button-wrapper {
  @include spacing;
}

.editorial-component--dark {
  overflow: auto;
  background: $color-concret;
  margin: $unit-double 0;

  @include helper__greater($bp-768) {
    margin: $unit-double * 2 auto;
  }
}

// must be defined before .nolte-button to enable overwriting
.editorial-button {
  @include text-button;
  word-wrap: normal;
  @include helper__transition((color, border-color, background-color));
  color: $color-ironside-gray;
  display: inline-block;
}

.editorial-button--yellow {
  border: 2px solid $color-citrine;
  border-radius: $radius-form;
  padding: $unit-base;
  min-width: 200px;
  max-width: 320px;
  background-color: $color-aureolin;
  @include helper__greater($bp-320) {
    padding: $unit-base $unit-double;
  }
}

// the button in the experience editor is by default the yellow-button
// if you want to explicitly show other styles add them below

// this is the default style for neo
.editorial-button--white--outline {
  background-color: transparent;
  color: $color-concret;
  border-color: $color-concret;
  text-align: center;
}
</style>
