<template>
  <button
    type="button"
    class="nolte-global-header-menu-button"
    :class="{ 'nolte-global-header-menu-button--open': open }"
  >
    <div class="label">{{ MENU }}</div>
    <div class="icon">
      <div />
      <div />
      <div />
      <div />
    </div>
  </button>
</template>

<script>
// INFO: this term shoul intentionally not be translated.
// the reason is an allusion to the also not translated 'ü' in the 'Nolte Küchen' logo
const MENU = 'MENÜ';

export default {
  name: 'GlobalHeaderNavIcon',

  props: {
    open: {
      type: Boolean,
      required: true,
    },
  },

  data() {
    return { MENU };
  },
};
</script>

<style lang="scss" scoped>
$transition-timing: 0.5s;

.nolte-global-header-menu-button {
  @include helper__button;
  position: relative;
}

.label {
  @include helper__transition((opacity, color), $transition-timing);
  // TODO: add font style
  font-family: $font-graphit-bold;
  font-size: 14px;
}

.nolte-global-header-menu-button:hover .label {
  color: $color-delta;
}

.nolte-global-header-menu-button--open .label {
  opacity: 0;
}

.icon {
  @include helper__transition(transform, $transition-timing);
  position: relative;
  height: 20px;
  margin-top: 2px;
  margin-bottom: 4px;
  @include helper__composited-layer;

  div {
    @include helper__transition((transform, background-color, margin-top), $transition-timing);
    position: absolute;
    height: 4px;
    width: 100%;
    background: $color-aureolin;
    opacity: 1;
    left: 0;
  }

  div:nth-child(1) {
    @include helper__transition((transform, background-color), $transition-timing / 2, ease-out);
    top: 0;
  }

  div:nth-child(2),
  div:nth-child(3) {
    top: 50%;
    margin-top: -2px;
  }

  div:nth-child(4) {
    @include helper__transition((transform, background-color), $transition-timing / 2, ease-out);
    bottom: 0;
  }
}

.nolte-global-header-menu-button--open .icon {
  @include helper__until($bp-425) {
    transform: translateY(-9px);
  }

  div {
    background-color: $color-ironside-gray;
    border-radius: 2px;
  }

  div:nth-child(1) {
    background-color: transparent !important;
    transform: scaleX(0);
  }

  div:nth-child(2) {
    transform: rotate(45deg);
  }

  div:nth-child(3) {
    transform: rotate(-45deg);
  }

  div:nth-child(4) {
    background-color: transparent !important;
    transform: scaleX(0);
  }
}

.nolte-global-header-menu-button--open:hover .icon div {
  background-color: $color-delta;
}
</style>

<style lang="scss">
// styles for neo
html.template--neopage .nolte-global-header {
  .nolte-global-header-menu-button {
    color: $color-concret;
  }
}
</style>
