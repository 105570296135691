<template>
  <div class="nolte-form-checkbox" :class="{ 'nolte-form-checkbox--invalid': invalid }">
    <input
      v-bind="$attrs"
      type="checkbox"
      class="nolte-form-checkbox__checkbox"
      :id="id || randomId"
      :checked="isChecked"
      v-on="listeners"
      :value="value"
    />
    <label :for="id || randomId">
      {{ label }}
    </label>
  </div>
</template>

<script>
import '../keyboard-modality';
import eventBus from '@/lib/eventBus';

import { getRandomString } from '../helper';
import { isProxy, toRaw } from 'vue';

export default {
  name: 'NolteFormCheckbox',

  inheritAttrs: false,

  model: {
    prop: 'checked',
    event: 'change',
  },

  props: {
    label: {
      type: String,
      default: '',
    },
    checked: {
      type: [Array, Boolean, String],
      default: false,
    },
    checkboxListItems: {
      type: [Array, Boolean, String],
      default: false,
    },
    value: {
      type: [Array, Boolean, String],
      // Use true as default because type of checked
      // is boolean or array but no string
      default: true,
    },
    id: {
      type: String,
      default: undefined,
    },
    invalid: {
      type: Boolean,
    },
  },

  emits: ['update:modelValue'],

  computed: {
    // ATTENTION: magic happening here!!! :D
    listeners() {
      return {
        ...this.$attrs,
        // we overwrite the change event from the component model...
        // with our own change method
        change: event => {
          // console.log('name', this.$attrs.name);
          // console.log('change!', this.checked);
          // console.log('event.target.checked!', event.target.checked);
          // console.log('this.checkboxListItems', this.checkboxListItems);

          const checkboxListItems = isProxy(this.checkboxListItems)
            ? toRaw(this.checkboxListItems)
            : this.checkboxListItems;

          if (Array.isArray(checkboxListItems)) {
            eventBus.$emit(this.$attrs.name + ':checkbox-change', {
              value: this.value,
              checked: event.target.checked,
            });
          } else {
            this.$emit('update:modelValue', event.target.checked);

            eventBus.$emit(this.$attrs.name + ':checkbox-change', {
              value: this.value,
              checked: event.target.checked,
            });
          }
        },
      };
    },

    isChecked() {
      return this.checked;
    },

    randomId() {
      return getRandomString();
    },
  },
};
</script>

<style lang="scss" scoped>
.nolte-form-checkbox {
  display: inline-block;
  margin-top: $unit-half;
  margin-bottom: $unit-half;
}

.nolte-form-checkbox__checkbox {
  @include helper__visually-hidden;
}

.nolte-form-checkbox__checkbox + label {
  cursor: pointer;
  white-space: nowrap;
  position: relative;
  display: inline-flex;
  align-items: center;
  color: $color-ironside-gray;
  user-select: none;
}

.nolte-form-checkbox__checkbox + label::before {
  @include helper__transition((background-color, border-color));
  content: '';
  width: 26px;
  height: 26px;
  border-radius: 3px;
  border: 2px solid $color-athens-gray;
  background-color: $color-white;
  margin-right: $unit-half;
  margin-bottom: 3px;
}

.nolte-form-checkbox__checkbox:checked + label::before {
  background-color: $color-aureolin;
  border-color: $color-citrine;
}

.nolte-form-checkbox--invalid .nolte-form-checkbox__checkbox + label::before {
  border-color: $color-red-orange;
}

.nolte-form-checkbox__checkbox + label:hover::before {
  border-color: $color-tuatara;
}

// Style when focus by keyboard interaction
body[modality='keyboard'] .nolte-form-checkbox__checkbox:focus + label::before {
  border-color: $color-azure;
}

.nolte-form-checkbox__checkbox + label::after {
  @include helper__transition((opacity, transform));
  @include helper__composited-layer;
  content: '';
  height: 5px;
  width: 9px;
  border-left: 2px solid;
  border-bottom: 2px solid;
  transform: rotate(-45deg) scale(0.7);
  left: 9px;
  top: 10px;
  position: absolute;
  color: $color-tuatara;
  opacity: 0;
}

.nolte-form-checkbox__checkbox:checked + label::after {
  opacity: 1;
  transform: rotate(-45deg) scale(1);
}
</style>
