import config from '../../temp/config';

export async function fetchResults(searchParams, callback) {
  const valid = validate(searchParams, searchParamsSchema);

  if (!valid) return [];

  const searchUrl = buildSearchUrl(searchParams);

  fetch(searchUrl)
    .then(response => {
      if (!response.ok) {
        throw `EditorialSearchResults: There are no search results for '${searchUrl}'.`;
      } else {
        return response.json();
      }
    })
    .then(callback);
}

const buildSearchUrl = searchParams => {
  const baseUrl = process.env.VUE_APP_API_BASE_URL || '';
  const endpoint = '/api/extendedsearch/searchresults';
  const searchUrlParams = new URLSearchParams();
  searchUrlParams.append('sc_apikey', config.sitecoreApiKey);
  searchUrlParams.append('q', searchParams.term);
  searchUrlParams.append('l', searchParams.language);
  searchUrlParams.append('e', searchParams.offset);
  searchUrlParams.append('p', searchParams.batchSize);
  searchUrlParams.append('s', searchParams.searchScope);
  searchUrlParams.append('itemid', searchParams.itemId);

  return baseUrl + endpoint + '?' + searchUrlParams.toString();
};

function validate(object, schema) {
  const errors = Object.keys(schema)
    .filter(function (key) {
      return !schema[key](object[key]);
    })
    .map(function (key) {
      return new Error(key + ' is invalid.');
    });

  if (errors.length > 0) {
    console.log(`Object does not fit schema:`, object);
    errors.forEach(error => {
      console.log(error);
    });
  }

  return errors.length === 0;
}

const searchParamsSchema = {
  term: function (value) {
    return stringValid(value);
  },
  language: function (value) {
    return stringValid(value);
  },
  offset: function (value) {
    return stringValid(value);
  },
  batchSize: function (value) {
    return stringValid(value);
  },
  searchScope: function (value) {
    return stringValid(value);
  },
  itemId: function (value) {
    return stringValid(value);
  },
};

const stringValid = value => {
  // check for blank, null and undefined
  return value || /^\s*$/.test(value);
};
