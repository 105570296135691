<template>
  <div v-if="galleryType === 'grid' && !isEditing">
    <NolteGridCell
      v-for="(component, index) in galleryComponents"
      :key="`gallery_${index}`"
      :type="component.aspectRatio.attribute"
    >
      <component :is="component" />
    </NolteGridCell>
  </div>

  <NolteScroller
    v-if="galleryType === 'slider'"
    class="editorial-gallery-slider"
    :class="{ 'editorial-gallery gallery-editing': isEditing }"
    :show-arrows="true"
  >
    <sc-placeholder name="nolte-gallery_default" :rendering="rendering" />
  </NolteScroller>

  <div
    v-else-if="galleryType === 'grid' && isEditing"
    class="editorial-gallery"
    :class="{ 'gallery-editing': isEditing }"
  >
    <!--
      When experience editor is active we want to render all tabs in a stack,
      to simplify editing.
      Note: additional props passed to the `<component />` here will not be forwarded to the actual component.
    -->
    <sc-placeholder name="galleryPlaceholder" :rendering="rendering" />
  </div>
</template>

<script>
import { mapGetters } from 'vuex';

import NolteGrid from '../nolte-ui/NolteGrid';
import NolteGridCell from '../nolte-ui/NolteGrid/NolteGridCell';
import NolteScroller from '../nolte-ui/NolteScroller';
import { populateAspectRatio } from './helper';
import { Placeholder } from '@sitecore-jss/sitecore-jss-vue';

export default {
  name: 'EditorialGallery',

  components: {
    NolteGrid,
    NolteGridCell,
    NolteScroller,
    ScPlaceholder: Placeholder,
  },

  props: {
    rendering: {
      type: Object,
      default: undefined,
    },
  },

  computed: {
    ...mapGetters('jss', ['isEditing']),

    galleryType() {
      return this.fields?._extendedParams?.GalleryType?.fields?.Type?.value || 'slider';
    },

    galleryComponents() {
      return this.galleryPlaceholder.map(component => {
        const processedComponent = this.processComponent(component);
        this.populateAspectRatio(processedComponent);
        return processedComponent;
      });
    },
  },

  methods: {
    populateAspectRatio,

    processComponent(component) {
      const { fields, rendering } = component.props;
      const componentFields = fields.data ? fields.data.datasource : fields;
      let preferedSize = 'default';
      if (rendering.componentName === 'EditorialTeaser')
        preferedSize = fields?._extendedParams?.PreferedSize?.fields?.Size?.value;

      return {
        ...component,
        type: rendering.componentName,
        image: componentFields.image,
        copy: componentFields.copy,
        preferedSize: preferedSize,
      };
    },
  },
};
</script>

<style lang="scss">
.editorial-gallery {
  @include spacing;

  &.nolte-scroller {
    height: auto !important;
  }

  .swiper-slide {
    display: block;
    width: 100%;

    > * {
      width: auto;
      max-width: calc(100vw - #{$unit-double});

      & + & {
        margin-left: $unit-half;
      }
    }
  }
}

// MARKER: A
.editorial-gallery-slider.nolte-scroller {
  @include helper__until($bp-600) {
    box-sizing: content-box;
  }

  height: 225px;

  @include helper__greater($bp-1024) {
    height: 600px;
  }

  padding: 0 0 $unit-base;

  @include helper__greater($bp-600) {
    padding: 0 0 $unit-half;
  }

  .swiper-slide {
    @include helper__until($bp-600) {
      margin: 0 $unit-base 0 $unit-base;
    }
  }

  .editorial-image--fit-cover {
    max-width: none;
    height: auto;
  }

  .editorial-image--fit-cover img {
    object-fit: initial;
    width: initial;
    max-width: none;
    height: 225px;

    @include helper__greater($bp-1024) {
      height: 600px;
    }
  }

  // the editor has to upload videos with aspect ratio 3/2
  .content-video-wistia {
    width: 225px * 3 / 2;

    @include helper__greater($bp-1024) {
      width: 600px * 3 / 2;
    }
  }
}

.gallery-editing {
  width: 35%;

  .editorial-image {
    display: block;
  }
}
</style>
