<template>
  <NolteDetailSimple v-if="!isEditing" :headline="headline" :copy="copy" :link="link" />

  <div v-else-if="fields.headline && fields.copy && fields.link" class="editorial-detail-simple">
    <sc-text :field="fields.headline" />
    <sc-text :field="fields.copy" />
    <sc-link :field="fields.link" />
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import { Text, Link } from '@sitecore-jss/sitecore-jss-vue';

import NolteDetailSimple from '../nolte-ui/NolteDetailSimple';

export default {
  name: 'EditorialDetailSimple',

  components: {
    ScText: Text,
    ScLink: Link,
    NolteDetailSimple,
  },

  computed: {
    ...mapGetters('jss', ['isEditing']),

    headline() {
      return this.fields?.headline?.value;
    },
    copy() {
      return this.fields?.copy?.value;
    },

    link() {
      return this.fields?.link;
    },
  },
};
</script>
