<template>
  <NolteHighlight
    v-if="!isEditing && fields.image"
    :image="fields.image"
    :headline="headline"
    :copy="copy"
    :design="highlightDesign"
    :editable="false"
  />
  <div v-else-if="isEditing" class="editorial-highlight">
    <EditorialImage
      v-if="fields.image"
      class="editorial-highlight__img"
      :media="fields.image"
      default-aspect-ratio="3:2"
      default-sizes="500px"
    />
    <sc-text v-if="fields.headline" class="headline" :field="fields.headline" tag="h3" />
    <sc-text v-if="fields.copy" class="copy" :field="fields.copy" tag="p" />
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import { Text } from '@sitecore-jss/sitecore-jss-vue';
import EditorialImage from '@/components/EditorialImage';

import NolteHighlight from '../nolte-ui/NolteHighlight';

export default {
  name: 'EditorialHighlight',

  components: {
    NolteHighlight,
    ScText: Text,
    EditorialImage,
  },

  computed: {
    ...mapGetters('jss', ['isEditing']),

    headline() {
      return this.fields?.headline?.value;
    },
    copy() {
      return this.fields?.copy?.value;
    },

    highlightDesign() {
      return this.fields?._extendedParams?.HighlightDesign?.fields?.Design?.value || 'card';
    },
  },
};
</script>

<style lang="scss" scoped>
.editorial-highlight:not(:last-child) {
  margin-bottom: $unit-double;
  max-width: 90%;
}

.editorial-image {
  background-color: $color-concret;
}

.editorial-image__img {
  // TODO: maybe remove in future
  width: 100%;
}
</style>
