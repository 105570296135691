<template>
  <ContentVideoWistia
    v-if="textHasValue(wistiaVideoId)"
    end-video-behavior="loop"
    :auto-play="true"
    :muted="true"
    :playbar="false"
    :fullscreen-button="false"
    :settings-control="false"
    :volume-control="false"
    :small-play-button="false"
    :video-id="wistiaVideoId.value"
  />
  <component
    v-else
    :is="linkUrl ? 'ContentLink' : 'div'"
    :editable="false"
    class="nolte-tip"
    :class="[
      `nolte-tip--design-${design}`,
      size && `nolte-tip--size-${size}`,
      imageHasSource(image) && 'nolte-tip--has-image',
    ]"
    tabindex="0"
    v-touch-hover
    :field="{ value: { href: linkUrl, linktype: linkExternal ? 'external' : 'internal' } }"
  >
    <div class="nolte-tip__content">
      <IconBulb class="nolte-tip__icon-bulb" />
      <NolteHeadline class="nolte-tip__headline">{{ headline }}</NolteHeadline>
      <p class="nolte-tip__copy">{{ copy }}</p>
    </div>
    <EditorialImage
      v-if="imageHasSource(image)"
      class="nolte-tip__image"
      :media="image"
      default-sizes="1024px"
      fit="cover"
      :editable="false"
    />
  </component>
</template>

<script>
import IconBulb from './assets/bulb.svg?inline';

import NolteHeadline from '../NolteHeadline';
import '../keyboard-modality';
import { touchHover } from '../directives';
import ContentLink from '@/components/ContentLink';
import EditorialImage from '@/components/EditorialImage';
import ContentVideoWistia from '@/components/ContentVideoWistia';

import { imageHasSource, textHasValue } from '@/components/helper';

export const CHARACTER_LENGTH_BREAKPOINT = 85;

export default {
  name: 'NolteTip',

  directives: {
    touchHover,
  },

  components: {
    NolteHeadline,
    IconBulb,
    ContentLink,
    EditorialImage,
    ContentVideoWistia,
  },

  props: {
    headline: {
      type: String,
      required: true,
    },
    copy: {
      type: String,
      required: true,
    },
    wistiaVideoId: {
      type: Object,
      default: undefined,
    },
    image: {
      type: Object,
      required: true,
    },
    linkUrl: {
      type: String,
      default: undefined,
    },
    linkExternal: {
      type: Boolean,
      default: false,
    },
    design: {
      type: String,
      default: 'yellow',
      validator(value) {
        return ['yellow', 'dark'].includes(value);
      },
    },
    size: {
      type: String,
      default: undefined,
      validator(value) {
        return ['large'].includes(value);
      },
    },
  },

  methods: {
    imageHasSource,
    textHasValue,
  },
};
</script>

<style lang="scss" scoped>
.nolte-tip {
  text-align: center;
  background-size: cover;
  background-position: bottom;
  display: flex;
  flex-direction: column;
  justify-content: center;
  position: relative;
}

.nolte-tip__content {
  padding: $unit-base;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  @include helper__greater($bp-768) {
    padding: $unit-base * 2;
  }
}

.nolte-tip__icon-bulb {
  margin-left: auto;
  margin-right: auto;
  margin-bottom: $unit-base;
  display: none;

  @include helper__greater($bp-375) {
    display: block;
  }

  @include helper__greater-until($bp-1024, $bp-1440) {
    display: none;

    .nolte-tip--size-large & {
      display: block;
    }
  }
}

.nolte-tip__headline {
  margin-bottom: $unit-half;

  @include helper__greater($bp-768) {
    margin-bottom: $unit-base;
  }
}

.nolte-tip--design-yellow {
  color: $color-tuatara;
  background-color: $color-aureolin;
  background-image: url('./assets/yellow_landscape.svg');

  &.nolte-tip--size-large {
    background-image: url('./assets/yellow_portrait.svg');
  }
}

.nolte-tip--design-dark {
  color: $color-white;
  background-color: $color-tuatara-dark;
  background-image: url('./assets/dark_landscape.svg');

  &.nolte-tip--size-large {
    background-image: url('./assets/dark_portrait.svg');
  }
}

.nolte-tip__copy {
  @include text-base;
  font-size: 20px;
  line-height: (28 / 20); // [Intended lineheight] / [font size]

  @include helper__greater($bp-425) {
    font-size: 28px;
    line-height: (36 / 28); // [Intended lineheight] / [font size]
  }

  @include helper__greater($bp-768) {
    font-size: 24px;
    line-height: (32 / 24); // [Intended lineheight] / [font size]
  }
}

.nolte-tip__image {
  @include helper__transition(opacity, 500ms);
  height: 100%;
  width: 100%;
  opacity: 0;
}

.nolte-tip--has-image[data-touch-hover],
.nolte-tip--has-image:hover,
// Style when focus by keyboard interaction
body[modality='keyboard'] .nolte-tip--has-image:focus {
  .nolte-tip__image {
    opacity: 1;
  }
}
</style>
