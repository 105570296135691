<template>
  <input type="hidden" :name="field.name" :value="field.value" />
</template>

<script>
export default {
  name: 'SitecoreFormsFieldsHidden',
  props: {
    field: {
      type: Object,
      default: () => {},
    },
  },
};
</script>
