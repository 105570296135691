<template>
  <div
    class="nolte-catalog-menu-inspiration-filter"
    :class="{ 'nolte-catalog-menu-inspiration-filter--expanded': expandedTabs }"
    v-click-outside="handleClickOutside"
  >
    <NolteCatalogMenuTabs
      class="nolte-catalog-menu-inspiration-filter__tabs"
      :links="inspirationFilter"
      :selected-index="selectedTabIndex"
      :expanded="expandedTabs"
      @select="handleTabSelect"
    />
  </div>
</template>

<script>
import vClickOutside from 'click-outside-vue3';

import NolteCatalogMenuTabs from './NolteCatalogMenuTabs.vue';
import '../composedPathPolyfill';

export default {
  name: 'NolteCatalogMenuInspirationFilter',

  components: {
    NolteCatalogMenuTabs,
  },

  directives: {
    clickOutside: vClickOutside.directive,
  },

  model: {
    prop: 'selectedOptionIndex',
  },

  props: {
    inspirationFilter: {
      type: Array,
      required: true,
    },
    activeOptionId: {
      type: String,
      default: undefined,
    },
    expandedTabs: {
      type: Boolean,
      default: false,
    },
    selectedOptionIndex: {
      type: Number,
      default: undefined,
    },
  },
  emits: ['update:modelValue'],
  data() {
    return {
      tabsHeight: 0,
      offsetLeft: 0,
      selectedIndex: undefined,
    };
  },

  computed: {
    activeInspirationFilterIndex() {
      return this.inspirationFilter.findIndex(({ children }) =>
        children.find(({ id }) => id === this.activeOptionId)
      );
    },

    /**
     * This computed returns an index of the tab which should show an indicator.
     * The indicator will be showed when
     *   1. the related entry was selected by user/or passed in url.
     *   2. user opens the menu and navigate through the list.
     *
     * As long the menu is opened (this.selectedOptionIndex !== undefined),
     * this.selectedOptionIndex will be used to show the indicator.
     * Otherwise this.activeInspirationFilterIndex will be used.
     */
    selectedTabIndex() {
      const isOptionSelected = this.selectedOptionIndex !== undefined;
      const isOptionActive = this.activeInspirationFilterIndex !== -1;
      const activeOptionIndex = isOptionActive ? this.activeInspirationFilterIndex : undefined;

      if (this.selectedIndex != undefined) {
        return this.selectedIndex;
      } else {
        return isOptionSelected ? this.selectedOptionIndex : activeOptionIndex;
      }
    },
  },

  methods: {
    handleTabSelect(selectedIndex) {
      if (selectedIndex === this.selectedIndex) {
        this.selectedIndex = undefined;
        this.$emit('update:modelValue', undefined);
      } else {
        if (selectedIndex === this.selectedOptionIndex) {
          this.$emit(
            'update:modelValue',
            this.selectedOptionIndex === undefined ? selectedIndex : undefined
          );
        } else {
          this.selectedIndex = selectedIndex;
          this.$emit('update:modelValue', selectedIndex);
        }
      }
    },

    handleClickOutside(event) {
      // event.path is a non-standard which is only supported by Google Chrome
      // https://stackoverflow.com/questions/39245488/event-path-undefined-with-firefox-and-vue-js/39245638#39245638
      const path = event.path || (event.composedPath && event.composedPath());

      // since the filter options are not always part of click outside child we have to
      // additionaly check if the element is part of the filter
      const isClickTargetPartOfFilter = !!path.find(
        el =>
          typeof el.className === 'string' &&
          el.className.includes('nolte-catalog-menu-inspiration-filter-options')
      );

      if (!isClickTargetPartOfFilter) {
        // Close
        this.$emit('update:modelValue', undefined);
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.nolte-catalog-menu-inspiration-filter {
  @include helper__transition((margin-bottom, border-radius));
  position: relative;
  background-color: $color-white;
  border-radius: $radius-default;

  &.nolte-catalog-menu-inspiration-filter--expanded {
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
    margin-bottom: -$unit-half;
  }
}

.nolte-catalog-menu-inspiration-filter__tabs {
  position: relative;
  z-index: 2;
}
</style>
