<template>
  <div class="editorial-kitchen-talk-feed">
    <div class="editorial-kitchen-talk-feed__header">
      <EditorialKitchenTalkFeedHeader :header="header" />

      <EditorialArticleTeaser
        v-for="(teaser, index) in articleTeasers.slice(0, 1)"
        :key="`teaser_${index}`"
        :link-url="teaser.link"
        :image="teaser.image"
        image-default-sizes="(min-width: 1700px) 1024px, (min-width: 1024px) 500px, 100vw"
        :headline="teaser.headline"
        :copy="teaser.copy"
        :tags="teaser.tags"
      />
    </div>

    <div ref="masonry">
      <div v-for="(teaser, index) in articleTeasers.slice(1)" :key="`teaser_${index}`" class="item">
        <EditorialArticleTeaser
          v-show="showTeaser(index)"
          :link-url="teaser.link"
          :image="teaser.image"
          image-default-sizes="(min-width: 1700px) 1024px, (min-width: 768px) 500px, 100vw"
          :headline="teaser.headline"
          :copy="teaser.copy"
          :tags="teaser.tags"
        />
      </div>
    </div>
    <NolteButton
      class="editorial-kitchen-talk-feed__button"
      type="yellow--shadow"
      v-if="showButton && !isLoading"
      @click="showNextBatch"
    >
      <template #icon><IconArrowDown /></template>
      {{ $t('kitchen-talk-feed-discover-more') }}
    </NolteButton>
  </div>
</template>

<script>
import EditorialArticleTeaser from './EditorialArticleTeaser';
import EditorialKitchenTalkFeedHeader from './EditorialKitchenTalkFeedHeader';
import NolteButton from '../nolte-ui/NolteButton';
import IconArrowDown from '../assets/icons/IconArrowDown.svg?inline';
import MiniMasonry from 'minimasonry';
import { mapMutations } from 'vuex';

import { useScreen } from 'vue-screen';
import { bp768, bp1024 } from '../styles/variables.scss';

export default {
  name: 'EditorialKitchenTalkFeed',
  setup() {
    const screen = useScreen();

    return {
      screen,
    };
  },
  components: {
    EditorialArticleTeaser,
    EditorialKitchenTalkFeedHeader,
    NolteButton,
    IconArrowDown,
  },

  props: {
    header: {
      type: Object,
      required: true,
    },
    articleTeasers: {
      type: Array,
      default: () => [],
    },
  },

  data() {
    return {
      batchSize: 10,
      batchPosition: 0,
      masonry: null,
      resizedFinished: false,
      loading: true,
    };
  },

  mounted() {
    if (typeof window !== 'undefined') {
      window.addEventListener('resize', this.handleMasonryGrid);
      this.handleMasonryGrid();
    }
  },
  beforeUnmount() {
    if (typeof window !== 'undefined') {
      window.removeEventListener('resize', this.handleMasonryGrid);
    }
  },
  updated() {
    this.handleMasonryGrid();
  },

  computed: {
    getBatchPosition() {
      return this.batchPosition;
    },
    isLoading() {
      return this.loading;
    },
    showButton() {
      return this.batchPosition + this.batchSize < this.articleTeasers.length - 1;
    },
    masonryBaseWidth() {
      let screenWidth = this.screen?.width ? this.screen.width : 0;

      let baseWidth = screenWidth / 3 - 20;

      if (screenWidth < parseInt(bp768, 10)) {
        baseWidth = screenWidth - 20;
      } else if (screenWidth < parseInt(bp1024, 10)) {
        baseWidth = screenWidth / 2 - 50;
      }

      return baseWidth;
    },
    width() {
      return this.screen?.width;
    },
  },

  methods: {
    ...mapMutations(['setFooterVisibility']),
    showTeaser(index) {
      return index < this.batchPosition + this.batchSize;
    },
    showNextBatch() {
      this.loading = true;
      this.setFooterVisibility(false);

      this.batchPosition += this.batchSize;
      setTimeout(this.setMasonryGrid, 1000);
    },
    handleMasonryGrid() {
      this.$nextTick(() => {
        clearTimeout(this.resizedFinished);
        this.resizedFinished = setTimeout(this.setMasonryGrid, 1000);
      });
    },
    setMasonryGrid() {
      this.masonry?.destroy();

      if (this.$refs.masonry) {
        this.masonry = new MiniMasonry({
          container: this.$refs.masonry,
          baseWidth: this.masonryBaseWidth,

          surroundingGutter: false,
          gutterX: 4,
          gutterY: 4,
          minify: false,
        });
      }
      this.loading = false;
      this.setFooterVisibility(true);
    },
  },
};
</script>

<style lang="scss" scoped>
.editorial-kitchen-talk-feed {
  margin-bottom: $unit-base * 5;

  &:last-of-type {
    margin-bottom: 0;
  }

  overflow-anchor: none;
}

.editorial-kitchen-talk-feed__header {
  @include helper__greater($bp-1024) {
    display: flex;
  }

  .nolte-kitchen-talk-feed-header {
    border-bottom: 4px solid #fff;

    @include helper__greater($bp-1024) {
      flex: 0 0 auto;
      width: calc(66.66666% + 2px);
      border-right: 4px solid #fff;
    }
  }
}

.editorial-kitchen-talk-feed__button {
  display: inherit;
  margin: $unit-double * 2 auto;
}

.item {
  position: absolute;
}
</style>
