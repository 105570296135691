<template>
  <ul class="editorial-search-results__search-result-list">
    <li
      v-for="(result, index) in searchResults"
      :key="`search-result_${index}`"
      class="search-result__item"
    >
      <div class="search-result__inner">
        <ContentLink
          :editable="false"
          class="search-result__link"
          :field="{
            value: { href: result.Url, linktype: 'internal' },
          }"
        >
          <div class="search-result__content">
            <div class="search-result__text">
              <h2 class="search-result__headline">{{ result.Headline }}</h2>
              <p class="search-result__copy">{{ result.Copy }}</p>
              <p class="search-result__path">
                {{ formatPath(result) }}
              </p>
            </div>
            <div class="search-result__image">
              <EditorialImage
                :media="result.Image"
                default-aspect-ratio="3:2"
                default-sizes="500px"
                :class="{ 'has-image': imageHasSource(result.Image) }"
              />
            </div>
          </div>
        </ContentLink>
      </div>
    </li>
  </ul>
</template>

<script>
import ContentLink from '@/components/ContentLink';
import EditorialImage from '@/components/EditorialImage';
import { imageHasSource } from '@/components/helper';

export default {
  name: 'SearchResultList',

  components: {
    ContentLink,
    EditorialImage,
  },

  props: {
    searchResults: {
      type: Array,
      default: () => [],
    },
  },

  methods: {
    imageHasSource,

    formatPath(result) {
      let path = result.Url;
      // remove language
      path = path.replace(`/${result.Language.toLowerCase()}/`, '');
      // remove language for startpage
      path = path.replace(`/${result.Language.toLowerCase()}`, result.Headline.toLowerCase());
      return path;
    },
  },
};
</script>

<style lang="scss" scoped>
.editorial-search-results__search-result-list {
  background: $color-concret;
}

.search-result__item {
  background: $color-white;
  margin-bottom: $unit-half;
}

.search-result__inner {
  box-sizing: content-box;
  margin: 0;
  padding: 0;

  @include helper__greater($bp-768) {
    max-width: calc(1200px + #{$unit-double});
    margin: 0 auto;
    padding: 0 0 0 $unit-double;
  }
}

.search-result__content {
  display: flex;

  @include helper__until($bp-768) {
    flex-direction: column;
  }
}

.search-result__headline {
  font-family: $font-graphit-bold;
  font-weight: bold;
  font-size: 22px;
  line-height: (24 / 22); // [Intended lineheight] / [font size]
  margin: $unit-double 0 $unit-base;

  @include helper__greater($bp-768) {
    font-size: 32px;
    line-height: (35 / 32); // [Intended lineheight] / [font size]
  }
}

.search-result__copy {
  margin: $unit-base 0;
}

.search-result__path {
  margin: $unit-double 0;
  text-decoration: underline;
}

.search-result__text {
  flex: 1 1 auto;

  @include helper__until($bp-768) {
    padding: 0 $unit-base;
  }
}

.search-result__image {
  .editorial-image {
    display: none;

    &.has-image {
      display: block;
    }
  }

  @include helper__greater($bp-768) {
    flex: 0 0 auto;
    width: 320px;
    margin: 0 0 0 $unit-double;

    .editorial-image {
      width: auto;
      height: 100%;
    }
  }
}
</style>
