<template>
  <div
    class="nolte-download"
    :class="{
      'nolte-download--has-image': imageDoesExist,
      'nolte-download--portrait': breakpointPortrait,
      'nolte-download--landscape': breakpointLandscape,
    }"
  >
    <ContentLink
      :editable="false"
      class="nolte-download__inner"
      :field="{
        value: { href: fileUrl ?? externalUrl, linktype: isExternalLink ? 'external' : 'internal' },
      }"
    >
      <div v-if="imageDoesExist" class="nolte-download__image-wrapper">
        <EditorialImage
          class="nolte-download__image"
          :media="image"
          fit="cover"
          default-aspect-ratio="3:2"
          default-sizes="(min-width: 532px) 250px, 100vw"
          :editable="false"
        />
      </div>
      <div class="nolte-download__detail">
        <div class="nolte-download__text-wrapper">
          <div class="nolte-download__label">{{ label }}</div>
          <p class="nolte-download__description" v-if="description">{{ description }}</p>
        </div>

        <div v-if="fileUrl" class="nolte-download__icon-area">
          <IconDownload class="nolte-download__icon-file-size" />
          <span v-if="humanFileSize" class="nolte-download__human-file-size">
            {{ humanFileSize }}
          </span>
        </div>
        <div v-else-if="externalUrl" class="nolte-download__icon-area">
          <IconLinkArrow class="nolte-download__icon-link-arrow" />
        </div>
      </div>
    </ContentLink>
  </div>
</template>

<script>
import ResizeObserver from 'resize-observer-polyfill';

import ContentLink from '@/components/ContentLink';
import EditorialImage from '@/components/EditorialImage';

import IconLinkArrow from './assets/IconLinkArrow.svg?inline';
import IconDownload from './assets/IconDownload.svg?inline';
import eventBus from '@/lib/eventBus';
// import EmptyImage from './assets/EmptyImage.svg?inline';

const LANDSCAPE_BREAKPOINT = 500;

// convert bytes to human readable string
// https://stackoverflow.com/a/20463021
function fileSizeSI(a, b, c, d, e) {
  return (
    ((b = Math), (c = b.log), (d = 1e3), (e = (c(a) / c(d)) | 0), a / b.pow(d, e)).toFixed(2) +
    ' ' +
    (e ? 'kMGTPEZY'[--e] + 'B' : 'Bytes')
  );
}
//kB,MB,GB,TB,PB,EB,ZB,YB

export default {
  name: 'NolteDownload',

  components: {
    IconDownload,
    IconLinkArrow,
    // EmptyImage,
    ContentLink,
    EditorialImage,
  },

  props: {
    label: {
      type: String,
      required: true,
    },

    description: {
      type: String,
      default: undefined,
    },

    image: {
      type: Object,
      default: () => {},
    },

    fileUrl: {
      type: String,
      default: undefined,
    },

    externalUrl: {
      type: String,
      default: undefined,
    },

    fileSize: {
      type: Number,
      default: undefined,
    },

    isExternalLink: {
      type: Boolean,
      default: false,
    },
  },

  data() {
    return {
      componentWidth: 0,
    };
  },

  mounted() {
    this.initComponentWidthObserver();
  },

  computed: {
    imageDoesExist() {
      return this.image?.value?.src ? true : false;
    },

    // convert bytes to human readable string
    humanFileSize() {
      if (!this.fileSize) {
        return;
      }

      return fileSizeSI(this.fileSize);
    },

    breakpointPortrait() {
      return this.componentWidth <= LANDSCAPE_BREAKPOINT;
    },

    breakpointLandscape() {
      return this.componentWidth > LANDSCAPE_BREAKPOINT;
    },
  },

  beforeUnmount() {
    eventBus.$emit('NolteDownload:beforeUnmount');
  },

  methods: {
    initComponentWidthObserver() {
      const ro = new ResizeObserver(entries => {
        entries.forEach(({ contentRect }) => {
          this.componentWidth = contentRect.width;
        });
      });

      ro.observe(this.$el);

      eventBus.$once('NolteDownload:beforeUnmount', () => {
        if (this.$el) {
          ro.unobserve(this.$el);
        }
      });
    },
  },
};
</script>

<style lang="scss" scoped>
$border-width: 2px;

// to be able to calculate the available size we have to use an empty wrapper
.nolte-download__inner {
  border-radius: $radius-default;
  border: $border-width solid $color-athens-gray;
  background-color: $color-white;
  position: relative;
  @include helper__transition(border-color);

  width: 100%;
  display: block;

  .nolte-download--portrait & {
    max-width: auto;

    @include helper__greater($bp-768) {
      max-width: 360px;
    }
  }

  .nolte-download--landscape & {
    display: flex;
    max-width: 600px;
  }

  &::before {
    content: '';
    position: absolute;
    top: 25%;
    bottom: 0;
    left: $unit-base;
    right: $unit-base;
    box-shadow: 0 2px 50px 0 rgba($color-black, 0.15);
    z-index: -1;
    @include helper__transition(opacity);
  }

  &:hover {
    border-color: $color-ironside-gray;
  }
}

.nolte-download--has-image.nolte-download--landscape {
  max-height: 180px;
}

.nolte-download__image-wrapper {
  @include helper__aspect-ratio(3/2);
  background-color: $color-athens-gray;
  overflow: hidden;
  border-top-left-radius: $radius-default - $border-width;

  .nolte-download--portrait & {
    border-top-right-radius: $radius-default - $border-width;
  }

  .nolte-download--landscape & {
    flex: 0 0 auto;
    border-bottom-left-radius: $radius-default - $border-width;
    width: 180px;
  }
}

.nolte-download__image {
  height: 100%;
}

.nolte-download__detail {
  display: flex;
  position: relative;

  .nolte-download--portrait & {
    padding: $unit-base;
    flex-direction: column;

    @include helper__greater($bp-768) {
      padding: $unit-double;
    }
  }

  .nolte-download--landscape & {
    flex: 1 1 auto !important;
    padding: $unit-base;

    @include helper__greater($bp-768) {
      padding: $unit-double;
    }
  }
}

.nolte-download__text-wrapper {
  flex: 1 1 auto;
}

.nolte-download__label {
  font-family: $font-graphit-bold;
  font-weight: bold;
  text-transform: uppercase;
  font-size: 16px;
  line-height: (28 / 16); // [Intended lineheight] / [font size]
  letter-spacing: 1px;
}

.nolte-download__description {
  @include text-caption;
  color: $color-delta;

  // multiline text-overflow
  // https://developer.mozilla.org/en-US/docs/Web/CSS/-webkit-line-clamp
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 3;
  overflow: hidden;

  .nolte-download--portrait & {
    align-self: flex-end;
  }
}

.nolte-download__icon-area {
  @include text-caption--small;
  flex: 0 0 auto;
  width: 100px;
  color: $color-ironside-gray;
  justify-content: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-left: $unit-base;

  .nolte-download--portrait & {
    padding: $unit-base 0 0 0;
    width: auto;
  }
}

.nolte-download__human-file-size {
  color: $color-delta;
}
</style>
