<template>
  <footer class="nolte-global-footer">
    <div v-if="isVisible">
      <nav class="navigation">
        <ul class="navigation-list">
          <li v-for="(navItem, index) in navItems" :key="index">
            <div v-if="!navItem.children?.find(x => x.headline)">
              <div @click="toggle(index)">
                <h4 v-if="navItem.headline?.value" class="navigation-heading">
                  {{ navItem.headline?.value }}
                </h4>
                <IconArrowDown
                  v-if="screen?.width < 1025"
                  class="arrow"
                  :class="{ rotated: isActive[index] }"
                />
              </div>

              <ul v-if="screen?.width > 1024 || isActive[index]">
                <template v-for="(navItemChild, index) in navItem.children" :key="index">
                  <li v-if="!navItemChild?.icon" class="navigation-item">
                    <ContentLink
                      v-if="navItemChild.link.jss.value.href !== ''"
                      :class="'navigation-link'"
                      :editable="false"
                      :field="navItemChild.link.jss"
                    />
                  </li>
                  <li v-else class="social-link-item">
                    <ContentLink
                      v-if="
                        navItemChild?.link?.jss?.value?.href !== '' &&
                        navItemChild?.icon?.value !== ''
                      "
                      :class="'social-link'"
                      :editable="false"
                      :field="navItemChild.link.jss"
                    >
                      <component :is="navItemChild.icon.value" />
                    </ContentLink>
                  </li>
                </template>
              </ul>
            </div>

            <div v-else class="career-social-container">
              <h4 v-if="navItem.headline?.value" class="navigation-heading">
                {{ navItem.headline?.value }}
              </h4>
              <ul>
                <li v-for="(navItemChild, index) in navItem.children" :key="index">
                  <div>
                    <div @click="toggleCareerList(index)">
                      <h4
                        v-if="navItemChild.headline?.value || screen.width > 1024"
                        class="navigation-heading"
                      >
                        {{ navItemChild.headline.value }}
                      </h4>
                      <IconArrowDown
                        v-if="screen?.width < 1025 && index === 0"
                        class="arrow"
                        :class="{ rotated: isCareerListShown }"
                      />
                    </div>

                    <ul
                      v-if="screen?.width > 1024 || isCareerListShown || index === 1"
                      :class="{ 'social-links': navItemChild?.children?.some(item => item.icon) }"
                    >
                      <template
                        v-for="(careerItemChild, index) in navItemChild.children"
                        :key="index"
                      >
                        <li v-if="!careerItemChild.icon" class="navigation-item">
                          <ContentLink
                            v-if="careerItemChild.link.jss.value.href !== ''"
                            :class="'navigation-link'"
                            :editable="false"
                            :field="careerItemChild.link.jss"
                          />
                        </li>
                        <li v-else class="social-link-item">
                          <ContentLink
                            v-if="
                              careerItemChild?.link.jss?.value?.href !== '' &&
                              careerItemChild?.icon?.value !== ''
                            "
                            :class="'social-link'"
                            :editable="false"
                            :field="careerItemChild.link.jss"
                          >
                            <component :is="careerItemChild.icon.value" />
                          </ContentLink>
                        </li>
                      </template>
                    </ul>
                  </div>
                </li>
              </ul>
            </div>
          </li>
        </ul>
      </nav>

      <ul class="legal" v-if="metaNavItems.length">
        <li class="legal-item" v-for="(linkField, index) in metaNavItems" :key="index">
          <ContentLink :class="'legal-link'" :editable="false" :field="linkField" />
        </li>
      </ul>
    </div>
  </footer>
</template>

<script>
import 'intersection-observer';

import ContentLink from './ContentLink';

import IconFacebook from '../assets/icons/IconFacebook.svg?inline';
import IconYoutube from '../assets/icons/IconYoutube.svg?inline';
import IconInstagram from '../assets/icons/IconInstagram.svg?inline';
import IconPinterest from '../assets/icons/IconPinterest.svg?inline';
import IconLinkedin from '../assets/icons/IconLinkedin.svg?inline';
import IconTiktok from '../assets/icons/IconTiktok.svg?inline';
import IconArrowDown from '../assets/icons/IconArrowDown.svg?inline';

import eventBus from '@/lib/eventBus';

import { mapGetters } from 'vuex';
import { useScreen } from 'vue-screen';

export default {
  name: 'NolteGlobalFooter',
  setup() {
    const screen = useScreen();

    return {
      screen,
    };
  },

  components: {
    IconFacebook,
    IconYoutube,
    IconInstagram,
    IconPinterest,
    IconLinkedin,
    IconTiktok,
    IconArrowDown,
    ContentLink,
  },

  props: {
    navItems: {
      type: Array,
      default: () => [],
    },
    metaNavItems: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      isActive: [],
      isCareerListShown: false,
    };
  },

  beforeUnmount() {
    eventBus.$emit('GlobalFooter:beforeUnmount');
  },

  mounted() {
    const observer = new IntersectionObserver(this.intersectionObserverCallback);

    observer.observe(this.$el);

    eventBus.$once('GlobalFooter:beforeUnmount', () => {
      if (this.$el) {
        observer.unobserve(this.$el);
      }
    });
  },

  methods: {
    intersectionObserverCallback(entries) {
      entries.forEach(entry => {
        if (entry.isIntersecting) {
          eventBus.$emit('global-footer:visible');
        } else {
          eventBus.$emit('global-footer:hidden');
        }
      });
    },
    toggle(index) {
      this.isActive[index] = !this.isActive[index];
    },
    toggleCareerList(index) {
      if (index === 1) return;
      this.isCareerListShown = !this.isCareerListShown;
    },
  },

  computed: {
    ...mapGetters({ isVisible: 'getFooterVisibility' }),
  },
};
</script>

<style lang="scss" scoped>
svg:not([fill]) {
  fill: white;
}

.arrow {
  transition-property: transform;
  transition-duration: 0.3s;
  transition-timing-function: cubic-bezier(0.23, 1, 0.32, 1);
}

.rotated {
  transform: rotate(180deg);
}

.nolte-global-footer {
  text-align: center;
  background-color: $color-tuatara;
  color: $color-delta;
  font-family: $font-graphit-bold;

  position: relative;

  > * {
    // make content appear on top
    position: relative;
    z-index: 10;
  }

  &::before {
    content: '';
    position: absolute;
    z-index: 0;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background-repeat: no-repeat;
    background-size: 100% 100%;
    background-image: url('../assets/images/footer_texture_desktop.svg');

    @include helper__until($bp-600) {
      background-image: url('../assets/images/footer_texture_mobile.svg');
    }
  }
}

.navigation {
  display: flex;
  justify-content: center;
  align-items: center;
  max-width: $grid-width;
  margin: auto;

  // Mobile
  @include helper__until($bp-1024) {
    padding-top: $unit-base * 3;
  }

  @include helper__greater($bp-1024) {
    // https://github.com/philipwalton/flexbugs/issues/231
    // the height of 1px fixes the "align-items/min-height" bug in IE11
    height: 1px;
    min-height: 290px;
  }
}

.career-social-container {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: space-between;

  > ul {
    @include helper__until($bp-1024) {
      width: 100%;
    }
    & > li {
      @include helper__until($bp-1024) {
        border-bottom: 1px solid $color-delta;
        width: 100%;
      }
    }

    & > li:first-child > div > ul {
      @include helper__until($bp-1024) {
        padding-left: $unit-double;
      }
      @include helper__until($bp-768) {
        padding-left: $unit-base;
      }
    }
  }
}

.navigation-heading {
  @include text-h5;
  margin-top: $unit-base;
  margin-bottom: $unit-base;
  word-spacing: 4px;
  color: white;
  font-size: 13px;
  @include helper__until($bp-1024) {
    // border-bottom: 1px solid $color-tuatara;
    margin-top: 20px;
    margin-bottom: 20px;
  }
}

ul {
  @include helper__until($bp-1024) {
    display: flex;
    flex-direction: column;
  }
}
.navigation-list {
  list-style: none;
  padding-left: 0;
  flex: 1 auto;

  @include helper__until($bp-1024) {
    height: 100%;
    display: flex;
    align-items: start;
    flex-direction: column;
  }

  @include helper__greater($bp-1024) {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-around;
  }

  li {
    text-align: left;

    &:empty {
      display: none;
    }

    > div {
      & > div {
        @include helper__until($bp-1024) {
          display: flex;
          justify-content: space-between;
          align-items: center;
          padding-left: $unit-double;
          padding-right: $unit-double;
        }
        @include helper__until($bp-768) {
          padding-left: $unit-base;
          padding-right: $unit-base;
        }
      }
    }
  }

  > li {
    &:not(:last-child) {
      @include helper__until($bp-1024) {
        width: 100%;
        border-top: 1px solid $color-delta; //$color-tuatara
      }
      > div > ul {
        @include helper__until($bp-1024) {
          padding-left: $unit-double;
          padding-right: $unit-double;
        }
        @include helper__until($bp-768) {
          padding-left: $unit-base;
          padding-right: $unit-base;
        }
      }
    }
    &:last-child {
      @include helper__until($bp-1024) {
        width: 100%;
        border-top: 1px solid $color-delta; //$color-tuatara
      }
    }
  }
}

.navigation-item {
  margin-top: $unit-half;
  display: flex;
  flex-direction: column;
  align-items: flex-start;

  @include helper__greater($bp-1024) {
    flex-basis: 33%;
  }

  &:last-child {
    @include helper__until($bp-1024) {
      margin-bottom: 20px;
    }
  }
  &:first-child {
    @include helper__until($bp-1024) {
      margin-top: 0;
    }
  }
}

.navigation-link {
  font-family: Futura-PT-Book;
  text-transform: uppercase;
  @include helper__transition(all);
  display: block;
  line-height: 1.3;

  &:hover {
    color: $color-aureolin;
  }
}

.social-links {
  height: 100%;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  @include helper__until($bp-1024) {
    margin-bottom: $unit-base * 3;
    flex-direction: row;
    justify-content: start;
    padding-left: $unit-double;
  }
  @include helper__until($bp-768) {
    // margin-bottom: 48px;
    // flex-direction: row;
    // justify-content: start;
    padding-left: $unit-base;
  }
}

.social-link {
  @include helper__transition(all);
  display: block;
  opacity: 0.8;
  width: $unit-base * 3;

  &:hover {
    opacity: 1;

    svg {
      fill: $color-aureolin;
    }
  }
}

.legal {
  list-style: none;
  display: flex;
  flex-direction: row;
  justify-content: center;
  padding-left: 0;
  color: $color-concret;
  border-top: 1px solid $color-delta;

  @include helper__until($bp-1024) {
    padding: 20px $unit-double;
    justify-content: start;
    flex-wrap: wrap;
    border-top: none;
    row-gap: 20px;
    column-gap: $unit-base;
  }

  @include helper__until($bp-768) {
    padding-left: $unit-base;
    padding-right: $unit-base;
  }

  &-item {
    @include helper__until($bp-1024) {
      text-align: start;
    }
  }

  &-link {
    @include text-footer;
    @include helper__transition(all);
    color: $color-athens-gray;
    display: block;
    text-transform: none;
    padding: 20px $unit-half;

    @include helper__until($bp-1024) {
      padding: 0;
    }

    &:hover {
      color: $color-white;
    }
  }
}
</style>
