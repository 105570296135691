<template>
  <EditorialInternationalProjectsTemplate :fields="fields" :projects="projects" />
</template>

<script>
import EditorialInternationalProjectsTemplate from './EditorialInternationalProjectsTemplate';

export default {
  name: 'EditorialInternationalProjectsWorldwideProjects',

  components: {
    EditorialInternationalProjectsTemplate,
  },

  computed: {
    projects() {
      const projects = [];

      this.fields?.data?.projects?.parent?.currentSiblings.forEach(area => {
        area.children?.forEach(project => {
          projects.push({ area, project });
        });
      });

      return projects;
    },
  },
};
</script>
