<template>
  <div class="nolte-detail-showroom">
    <NolteHeadline class="nolte-detail-showroom__headline" level="h2">{{ headline }}</NolteHeadline>
    <div class="nolte-detail-showroom__link">
      <NolteButton v-if="copy" type="link-bright" @click="showAddress = !showAddress">
        {{ buttonLabel }}
      </NolteButton>
    </div>
    <div class="nolte-detail-showroom__content">
      <p v-show="copy && !showAddress" class="nolte-detail-showroom__copy">{{ copy }}</p>
      <div v-show="!copy || showAddress">
        <NolteAddress
          :name="address.name"
          :street="address.street"
          :locality="address.locality"
          :zip="address.zip"
          :country="address.country"
          :phone="address.phone"
          :email="address.email"
        />
      </div>
    </div>
  </div>
</template>

<script>
import NolteAddress from '../NolteAddress';
import NolteButton from '../NolteButton';
import NolteHeadline from '../NolteHeadline';

export default {
  name: 'NolteDetailShowroom',

  components: {
    NolteAddress,
    NolteButton,
    NolteHeadline,
  },

  data() {
    return {
      showAddress: false,
    };
  },

  props: {
    headline: {
      type: String,
      required: true,
    },
    copy: {
      type: String,
      default: undefined,
    },
    address: {
      type: Object,
      required: true,
    },
  },

  computed: {
    buttonLabel() {
      return this.showAddress ? this.$t('hide-address') : this.$t('show-address');
    },
  },
};
</script>

<style lang="scss">
.nolte-detail-showroom {
  padding: $unit-base;
  display: grid;
  grid-gap: $unit-base;
  grid-template-areas:
    'headline'
    'content'
    'link';
  grid-template-columns: 1fr;

  @include helper__greater($bp-768) {
    padding: $unit-double;
    grid-template-areas:
      'headline content'
      'link content';
    grid-template-columns: 1fr 1fr;
  }
}

.nolte-detail-showroom__headline {
  grid-area: headline;
  align-self: center;
  justify-self: flex-start;

  // TODO: Define font size in typography.scss
  // Overriding NolteHeadline styles
  @include helper__until($bp-768) {
    font-size: 22px !important;
    line-height: (28 / 22) !important; // [Intended lineheight] / [font size]
  }

  @include helper__greater($bp-768) {
    font-size: 32px !important;
    line-height: (38 / 32) !important; // [Intended lineheight] / [font size]
  }
}

.nolte-detail-showroom__content {
  grid-area: content;
  align-self: center;
  justify-self: flex-start;
}

.nolte-detail-showroom__link {
  grid-area: link;
  align-self: center;
  justify-self: flex-start;
}
</style>
