<template>
  <div
    class="editorial-simple-header"
    :class="{
      'editorial-simple-header--has-image': imageHasSource(fields.headerImage),
      'editorial-simple-header--has-copy-or-link':
        textHasValue(fields.copy) || linkHasValue(fields.link),
    }"
  >
    <EditorialImage
      v-if="imageHasSource(fields.headerImage) || isEditing"
      img-class="editorial-simple-header__image"
      :media="fields.headerImage"
      :sources="{
        '(min-width: 769px)': '21:9',
        '(min-width: 601px)': '16:10',
      }"
      default-aspect-ratio="3:4"
      default-sizes="100vw"
      fit="cover"
    />

    <div class="editorial-simple-header__overlay"></div>
    <!-- Extra div needed because of ratio helper -->
    <div class="editorial-simple-header__content-wrapper">
      <div class="editorial-simple-header__content">
        <div class="editorial-simple-header__content__left">
          <sc-text class="editorial-simple-header__headline" :field="fields.headline" tag="h1" />
        </div>
        <div
          class="editorial-simple-header__content__right"
          v-if="textHasValue(fields.copy) || linkHasValue(fields.link)"
        >
          <sc-text class="editorial-simple-header__copy" :field="fields.copy" tag="p" />

          <EditorialButton
            v-if="linkHasValue(fields.link) || isEditing"
            class="editorial-simple-header__link"
            :fields="{ link: fields.link, _extendedParams: buttonParams }"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import { Text } from '@sitecore-jss/sitecore-jss-vue';

import { textHasValue, linkHasValue, imageHasSource } from './helper';

import EditorialButton from './EditorialButton';
import EditorialImage from '@/components/EditorialImage';

export default {
  name: 'EditorialSimpleHeader',

  components: {
    EditorialButton,
    ScText: Text,
    EditorialImage,
  },

  data() {
    return {
      buttonParams: {
        ButtonType: {
          fields: {
            Type: {
              value: 'yellow--shadow',
            },
          },
        },
      },
    };
  },

  computed: {
    ...mapGetters('jss', ['isEditing']),
  },

  methods: {
    textHasValue,
    linkHasValue,
    imageHasSource,
  },
};
</script>

<style lang="scss" scoped>
.editorial-simple-header {
  background-color: $color-concret;
  background-image: linear-gradient(to bottom, $color-white, $color-concret);
  background-size: cover;
  background-repeat: no-repeat;
  background-position: 0 15%;
  border-bottom: 1px solid $color-athens-gray;

  &.editorial-simple-header--has-image {
    max-width: 2200px;
    margin-left: auto;
    margin-right: auto;

    @include helper__aspect-ratio(9 / 16);

    @include helper__greater($bp-600) {
      @include helper__aspect-ratio(16 / 10);
    }

    @include helper__greater($bp-1024) {
      @include helper__aspect-ratio(21 / 9);
    }

    > * {
      height: 100%;
    }
  }
}

.editorial-simple-header--has-image .editorial-simple-header__overlay {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  background-color: rgba($color-black, 0.6);
  pointer-events: none;
}

.editorial-simple-header__content {
  display: flex;
  max-width: $grid-width;
  height: 100%;
  margin: 0 auto;
  padding: $unit-double $unit-base;
  min-height: 200px;
  flex-direction: column;
  justify-content: center;
  overflow: hidden;

  @include helper__greater($bp-1024) {
    flex-direction: row;
    align-items: center;

    .editorial-simple-header--has-image & {
      padding: $unit-base $unit-base * 2;
    }
  }

  & > div {
    @include helper__greater($bp-600) {
      margin: 0 $unit-double;
    }

    .editorial-simple-header--has-copy-or-link & {
      @include helper__greater($bp-1024) {
        flex-basis: 50%;
      }
    }
  }

  .editorial-simple-header--has-image & {
    color: $color-white;
  }
}

.editorial-simple-header__copy {
  @include text-copy;
  margin: $unit-base 0;
}

.editorial-simple-header__headline {
  @include text-header-hero;
  margin: $unit-base 0;
  line-height: 1;

  @include helper__until($bp-768) {
    font-size: 40px;
  }

  @include helper__greater-until($bp-768, $bp-1024) {
    font-size: 60px;
  }

  @include helper__greater-until($bp-1024, $bp-1280) {
    font-size: 80px;
  }

  .editorial-simple-header--has-copy-or-link & {
    @include helper__greater($bp-1024) {
      text-align: right;
      word-wrap: normal;
    }
  }
}

.editorial-simple-header__link {
  margin: $unit-base 0;

  :deep(.editorial-button-wrapper) {
    padding: 0;
  }
}
</style>
