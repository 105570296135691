<template>
  <component
    class="nolte-headline"
    v-if="$slots.default"
    :is="level"
    :class="[`headline--${headlineClass}`]"
  >
    <slot />
  </component>
</template>

<script>
export default {
  name: 'NolteHeadline',

  props: {
    level: {
      type: String,
      default: 'h3',
      validator(value) {
        return ['h1', 'h2', 'h3', 'h4'].includes(value);
      },
    },

    design: {
      type: String,
      default: 'default',
      validator(value) {
        return ['centered', 'level2', 'level3', 'level4', 'default'].includes(value);
      },
    },
  },

  computed: {
    headlineClass() {
      if (this.design !== 'default') {
        return this.design;
      } else {
        return 'level' + this.level.replace(/[^\d.]/g, '');
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.nolte-headline {
  box-sizing: content-box;
  max-width: 700px;
}

.headline--level1 {
  @include text-h1;
}

.headline--level2 {
  @include text-h2;
}

.headline--level3 {
  @include text-h3;
}

.headline--level4 {
  @include text-h4;
}

.headline--centered {
  // we ignore the size defined by the level
  @include text-h2;
  max-width: none;
  text-align: center;
  padding-top: $unit-half;
  padding-bottom: $unit-half;
  position: relative;
  margin-bottom: $unit-double;

  &::before,
  &::after {
    content: '';
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    height: 4px;
    background-color: $color-athens-gray;
    min-width: 250px;
    max-width: 100%;
  }

  &::before {
    top: 0;
  }

  &::after {
    bottom: 0;
  }
}
</style>
