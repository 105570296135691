<template>
  <div class="global-map-searchlocation">
    <div
      class="global-map-searchlocation__inner"
      ref="searchlocation_wrapper"
      :class="{
        'show-dropdown': showDropdown,
        'is-mobile': isMobile,
        'has-focus': hasFocus,
      }"
    >
      <div class="autocomplete-input-container">
        <NolteFormInput
          type="text"
          name="zipCity"
          :value="searchModel.query"
          v-model="searchModel.query"
          autocomplete="off"
          @blur="hideResultsContainer"
          @focus="
            clearCityValue();
            debounceSearch();
          "
          @input="debounceSearch()"
          @keydown.enter.prevent="
            hideResultsContainer();
            clickFirstSuggestion();
          "
          class="global-map-searchlocation__zipcity"
          ref="autocompleteInput"
          role="combobox"
          :placeholder="$t('editorial-map-searchinput-placeholder')"
        />
        <div class="autocomplete-results" ref="resultsContainer">
          <ul ref="places" class="places" />
          <div class="divider" v-if="showroomsAvailable" />
          <ul ref="showrooms" class="showrooms" />
        </div>
      </div>
      <div @click="setFocus">
        <NolteFormDropdown
          class="global-map-searchlocation__country-dropdown"
          :class="{ 'global-map-searchlocation__country-dropdown--loaded': !!countryOptions }"
          :searchable="true"
          :options="countryOptions"
          v-model="searchModel.country.code"
          @update:modelValue="
            clearCityValue();
            countrySelected();
          "
          @click="setFocus()"
        />
      </div>
      <button class="global-map-searchlocation__button" @click="clickFirstSuggestion">
        <IconInputSearch />
      </button>
    </div>
  </div>
</template>

<script>
/*global google*/
import { mapState, mapGetters, mapActions } from 'vuex';
import NolteFormInput from '../../nolte-ui/NolteForm/NolteFormInput';
import NolteFormDropdown from '../../nolte-ui/NolteForm/NolteFormDropdown';
import IconInputSearch from '@/assets/icons/IconInputSearch.svg?inline';
import gmapsInit from '../../lib/gmaps';

import { highlight } from './utils';

const debug = false;

export default {
  name: 'MapSearchLocation',

  components: { NolteFormInput, NolteFormDropdown, IconInputSearch },
  data: function () {
    return {
      googlePlaces: null,
      googleGeometry: null,
      showDropdown: false,
      autocompleteService: null,
      placesService: null,
      geocoderService: null,
      hasFocus: false,
      searchModel: {
        query: '',
        country: { code: '', label: '' },
        hits: 15,
      },
    };
  },
  computed: {
    ...mapState('jss', ['sitecoreContext']),
    ...mapState(['user']),
    ...mapGetters('jss', ['currentRouteLanguage']),
    ...mapGetters(['isMobile']),
    ...mapGetters('map', {
      allCountries: 'getAllCountries',
      allShowrooms: 'getAllShowrooms',
      googleMapsApiKey: 'getGoogleMapsApiKey',
    }),
    showroomsAvailable() {
      return this.showroomsOfCountry?.length > 0;
    },
    showroomsOfCountry() {
      return this.allShowrooms?.filter(
        item => item.countryAcronym?.value.toUpperCase() === this.countryCode
      );
    },
    currentSearchModel() {
      return this.searchModel;
    },
    countryCode() {
      return this.searchModel.country.code;
    },
    countryOptions() {
      if (this.isMobile) {
        // in mobile view display value instead of label
        let allCountriesMobile = new Array();
        for (let i = 0; i < this.allCountries.length; i++) {
          allCountriesMobile[i] = {
            label: this.allCountries[i].value,
            value: this.allCountries[i].value,
            id: this.allCountries[i].id,
          };
        }
        return allCountriesMobile;
      } else {
        return this.allCountries;
      }
    },
    siteCountryCode() {
      // set the fallback to GB if we are using the system language
      return this.currentRouteLanguage?.locale?.split('-')[1] || 'GB';
    },
  },
  methods: {
    ...mapActions('map', {
      setLocation: 'setLocation',
      setFetchNewData: 'setFetchNewData',
      setFilteredShowrooms: 'setFilteredShowrooms',
      updateSearchModel: 'updateSearchModel',
    }),
    setCountryByCode(countryCode, fetchNewData = true) {
      let country = {
        code: countryCode.toUpperCase(),
        label: this.allCountries.find(item => item.value === countryCode.toUpperCase())?.label,
      };

      this.searchModel.country = country;
      this.searchModel.hits = 9999;

      this.updateSearchModel(this.searchModel);

      if (country.label) {
        this.getGmapLocation(country.label, results => {
          const latLng = JSON.parse(JSON.stringify(results[0].geometry.location));

          let location = {
            longitude: latLng.lng,
            latitude: latLng.lat,
            name: country.label,
            isShowroom: true,
            isValid: true,
            id: null,
            zoom: false,
          };

          if (debug) {
            console.log('setLocation to ', location);
          }
          this.setLocation(location);
          // save it in store
          this.setFilteredShowrooms(this.showroomsOfCountry);
        });
      }
      if (fetchNewData) {
        // triggers watcher in EditorialMap
        this.setFetchNewData(true);
      }
    },
    getGmapLocation(address, callback) {
      try {
        this.geocoderService.geocode({ address }, (results, status) => {
          if (status !== 'OK' || !results[0]) {
            throw new Error(status);
          }
          if (typeof callback === 'function') {
            callback(results, status);
          }
        });
      } catch (e) {
        console.log(e);
        console.log(`Can't find address`);
      }
    },
    setResultsContainerWidth() {
      if (this.$refs.resultsContainer) {
        if (this.$refs.searchlocation_wrapper) {
          this.$refs.resultsContainer.style.width =
            this.$refs.searchlocation_wrapper?.offsetWidth + 'px';
        }
        if (this.showDropdown) {
          this.$refs.resultsContainer.style.visibility = 'visible';
        }
      }
    },
    // search callback
    displaySuggestions(predictions, status) {
      let results = this.$refs.places;
      if (results) {
        // empty results
        results.innerHTML = '';

        let query = this.$refs.autocompleteInput.$el.value;

        if (status != google.maps.places.PlacesServiceStatus.OK) {
          let errorMessage = '';
          if (status != google.maps.places.PlacesServiceStatus.ZERO_RESULTS) {
            errorMessage = this.$t('editorial-map-error');
          } else {
            errorMessage = this.$t('editorial-map-zero-results');
          }
          results.innerHTML =
            '<li class="pac-item"><span class="pac-result">' +
            errorMessage +
            '</span><span class="pac-icon"></span></li>';
          return;
        }

        const borderEl = document.createElement('li');
        borderEl.innerHTML += '<span class="pac-border" />';

        // build output for each prediction
        for (let i = 0, prediction; (prediction = predictions[i]); i++) {
          // get place details to inject more details in autocomplete results
          const self = this;
          this.placesService.getDetails(
            {
              placeId: prediction.place_id,
            },
            function (place, serviceStatus) {
              if (serviceStatus === google.maps.places.PlacesServiceStatus.OK) {
                // create a new result element
                const el = document.createElement('li');

                const result = query
                  ? highlight(place.formatted_address, query) // highlight search query
                  : place.adr_address;

                // and insert inner HTML
                el.innerHTML +=
                  '<span class="pac-result">' +
                  result +
                  '</span><span class="pac-icon pac-icon-pin"></span>';
                el.className = 'pac-item';

                // bind click event
                el.onclick = function () {
                  // Store clicked location
                  let location = {
                    longitude: place.geometry.location.lng(),
                    latitude: place.geometry.location.lat(),
                    name: place.name,
                    isShowroom: false,
                    isValid: true,
                    zoom: false,
                  };
                  self.searchModel.query = place.formatted_address;
                  self.searchModel.hits = 15;
                  self.setLocation(location);
                  self.updateSearchModel(self.searchModel);
                  self.setFetchNewData(true);
                };

                // Append new element to results
                results.appendChild(el);
              }
            }
          );
        }
      }
    },

    addShowRooms() {
      let results = this.$refs.showrooms;
      results.innerHTML = '';
      const self = this;

      this.showroomsOfCountry?.forEach(showroom => {
        const el = document.createElement('li');
        const result =
          '<span class="highlight">' +
          showroom.addressName?.value +
          '</span>, ' +
          showroom.addressStreet?.value +
          ', ' +
          showroom.addressZip?.value +
          ' ' +
          showroom.addressLocality?.value;

        el.innerHTML +=
          '<span class="pac-result">' +
          result +
          '</span><span class="pac-icon pac-icon-eye"></span>';
        el.className = 'pac-item';
        el.onclick = function () {
          // Store clicked location
          let location = {
            longitude: showroom.addressLng?.value,
            latitude: showroom.addressLat?.value,
            name: self.currentSearchModel?.country?.label,
            isShowroom: true,
            isValid: true,
            zoom: false,
          };
          self.setLocation(location);
          self.updateSearchModel(self.searchModel);
        };

        results.appendChild(el);
      });
    },
    hideResultsContainer() {
      // here we need a little timeout to still be able to list on the clickevent in the dropdown field
      setTimeout(() => {
        this.showDropdown = false;
        this.hasFocus = false;
        this.$refs.resultsContainer.style.visibility = 'hidden';
      }, 300);
    },
    setFocus() {
      this.hasFocus = true;
    },
    countrySelected() {
      if (this.countryCode) {
        this.setCountryByCode(this.countryCode.value);
      }
    },
    // Clear selected city.
    clearCityValue() {
      this.searchModel.query = '';
    },
    // To prevent the autocompleteService and placesService from being called every time a key is entered,
    // debouncing is used here. The search starts only when the user does not make any keystrokes for 300ms.
    debounceSearch() {
      // user is typing (raised by @input)
      clearTimeout(this.debounce);

      this.debounce = setTimeout(() => {
        this.hasFocus = true;
        // here: typing stopps
        if (!this.searchModel.query || this.searchModel.query === '') {
          let results = this.$refs.results;
          if (results) {
            // empty results container
            results.innerHTML = '';
            this.hideResultsContainer();
          }
        } else {
          // show results
          this.autocompleteService
            ?.getPlacePredictions(
              {
                input: this.searchModel.query,
                types: ['(regions)'],
                componentRestrictions: {
                  country: this.searchModel.country.code,
                },
              },
              this.displaySuggestions
            )
            .then(function () {
              const dividerElement = document.getElementsByClassName('divider')[0];

              if (dividerElement !== undefined) {
                dividerElement.style.display = 'block';
              }
            });

          this.addShowRooms();
          this.showDropdown = true;
        }
      }, 200);
    },
    clickFirstSuggestion() {
      this.showDropdown = false;
      const firstElement = document.getElementsByClassName('pac-item')[0];
      if (firstElement !== undefined) firstElement.click();
    },
  },
  async mounted() {
    try {
      this.googlePlaces = await gmapsInit(this.googleMapsApiKey, 'places,geometry').then(() => {
        this.autocompleteService = new google.maps.places.AutocompleteService();
        this.placesService = new google.maps.places.PlacesService(document.createElement('div'));
        this.geocoderService = new google.maps.Geocoder();
      });

      if (typeof window !== 'undefined') {
        // resizing ResultContainer
        window.addEventListener('resize', this.setResultsContainerWidth);
      }
      // preset country with the user's country if user is logged in
      if (this.user?.country) {
        if (debug) {
          console.log('country', this.user.country);
        }
        this.setCountryByCode(this.user.country, false);
      } else {
        // set country by site language
        this.setCountryByCode(this.siteCountryCode, false);
      }
    } catch (error) {
      console.error(error);
    }
  },
  updated() {
    this.setResultsContainerWidth();
  },

  beforeUnmount() {
    if (typeof window !== 'undefined') {
      window.removeEventListener('resize', this.setResultsContainerWidth);
    }
  },
};
</script>

<style lang="scss" scoped>
.global-map-searchlocation {
  @include helper__greater($bp-1024) {
    position: relative;
    top: 0;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  @include helper__greater($bp-1280) {
    margin-left: 100px;
    margin-left: auto;
    margin-right: auto;
  }
}

.global-map-searchlocation__inner {
  display: flex;
  box-shadow: 1px 6px 25px #0000001a;
  width: 100%;
  margin-right: 0;
}

.autocomplete-input-container {
  @include helper__greater($bp-1024) {
    border-radius: 5px 0px 0px 5px;

    .show-dropdown & {
      border-radius: 5px 0px 0px 0px;
    }

    .has-focus & {
      border-radius: 5px 0px 0px 5px;
    }
  }

  border-top: 2px solid #dedede;
  border-bottom: 2px solid #dedede;
  border-left: 2px solid #dedede;
  background-color: $color-white;
  height: $map-search-bar-height;

  .has-focus & {
    border-top: 2px solid #a5a19d;
    border-bottom: 2px solid #a5a19d;
    border-left: 2px solid #a5a19d;
  }
}

.global-map-searchlocation__zipcity {
  cursor: pointer;
  margin-bottom: 0;
  padding-left: $unit-base;

  height: $map-search-bar-height;
  border-bottom: none;

  @include helper__greater($bp-1280) {
    min-width: 300px;
  }
  @include helper__greater($bp-1440) {
    min-width: 400px;
  }
  &:focus,
  &:hover {
    border-bottom-color: #dedede !important;
  }
}
.global-map-searchlocation__country-dropdown {
  border-top: 2px solid #dedede;
  border-bottom: 2px solid #dedede;
  border-left: 2px solid #dedede;

  .has-focus & {
    border-top: 2px solid #a5a19d;
    border-bottom: 2px solid #a5a19d;
    border-left: 2px solid #a5a19d;
  }

  text-align: center;
  margin-bottom: 0;
  height: $map-search-bar-height;
  width: auto;

  cursor: pointer;
  min-width: 78px;
  @include helper__greater($bp-768) {
    width: auto;
    min-width: 140px;
  }
  @include helper__greater($bp-1024) {
    min-width: 200px;
  }

  @include helper__greater($bp-1280) {
    min-width: 290px;
  }

  :deep() {
    .vs__clear {
      display: none;
    }
    .vs__dropdown-toggle {
      border: none;
      border-radius: 0;
      height: 54px;

      @include helper__until($bp-768) {
        padding: 10px 3px 13px 0px;
      }
    }

    .vs__dropdown-menu {
      margin-top: 3px;
      margin-left: -2px;

      min-width: 70px !important;

      border-color: #a5a19d !important;

      @include helper__greater($bp-768) {
        width: 262px;
      }
      @include helper__greater($bp-1280) {
        width: 292px;
      }

      & li:hover {
        background-color: $color-concret;
        cursor: pointer;
      }
    }
  }
}
.global-map-searchlocation__button {
  background-color: $color-concret;
  border: 2px solid #dedede;

  border-radius: 0 5px 5px 0;

  width: $map-search-bar-height;
  height: $map-search-bar-height;

  cursor: pointer;
  text-align: center;

  .has-focus & {
    background-color: $color-aureolin;
    border: 2px solid $color-citrine;
  }
}

.autocomplete-results {
  visibility: hidden;
  margin: 0 auto;
  margin-top: -2px;
  margin-left: -2px;
  position: absolute;

  background-color: white;
  padding: 0;
  list-style-type: none;
  border: 2px solid #a5a19d;
  border-top: 0;
  border-radius: 0px 0px 5px 5px;

  box-sizing: border-box;
  z-index: 40;

  :deep() {
    .pac-item {
      margin: 0;
      position: relative;
      display: flex;
      justify-content: space-between;
      align-items: center;
    }

    .pac-result {
      padding-left: $unit-base;
      width: 100%;
    }
    .pac-icon {
      width: 48px;
      height: 48px;
    }
    .pac-item:hover {
      background-color: $color-concret;
      cursor: pointer;
    }
    .pac-icon-pin {
      background-image: url('../../assets/icons/editorial-map/IconMapPin.svg');
    }
    .pac-icon-eye {
      background-image: url('../../assets/icons/editorial-map/IconEyeAutocomplete.svg');
    }
    .highlight {
      font-weight: bold;
    }
    .nolte-form-input:focus {
      border-bottom-color: #dedede !important;
    }
  }
}

.show-dropdown {
  .autocomplete-results {
    display: block;
  }

  .global-map-searchlocation__zipcity {
    border-radius: 0px 0px 0px 0px;
  }

  .global-map-searchlocation__button {
    border-radius: 0 5px 0 0;
  }
}
.showrooms {
  margin-top: $unit-base;
  margin-bottom: $unit-base;
}
.divider {
  display: none;
  border-top: 1px solid #e7e7e7;
  margin: 24px $unit-base 8px $unit-base;
}
</style>
