<template>
  <header
    class="nolte-global-header"
    :class="{
      'nolte-global-header--sticking':
        headerStickingOverride !== undefined ? headerStickingOverride : sticking,
      'nolte-global-header--sticking-mobile': stickingMobile,
      'nolte-global-header--overlay-open': overlayIsOpen,
    }"
  >
    <Banner
      v-if="bannerData"
      :bannerData="bannerData"
      class="banner"
      @banner-closed="$emit('bannerClosed')"
    ></Banner>
    <nav
      class="navigation"
      :class="{
        'search-location--visible': searchLocationIsVisible,
      }"
    >
      <GlobalHeaderLogo class="logo" :language="language" :overlay-is-open="overlayIsOpen" />

      <div
        class="navigation-group"
        v-click-outside="clickOutsideOverlay"
        ref="navigationGroup"
        v-if="!globalProgressbar"
      >
        <ul class="navigation-list" v-if="navItems">
          <li class="navigation-item" v-for="(item, index) in navItems" :key="index">
            <!-- the current item should show subitems -->
            <template v-if="item.children && item.children.length">
              <input
                type="checkbox"
                class="submenu-checkbox"
                :id="`submenu-navigation-link_${index}`"
              />

              <!-- see https://router.vuejs.org/api/#v-slot-api-3-1-0 (Applying Active Class to Outer Element)

              Update Vue 2 => Vue 3:
              Routes that are unrelated from a record point of view but share a common path
              are no longer active.

              Further information: https://github.com/vuejs/rfcs/blob/master/active-rfcs/0028-router-active-link.md#unrelated-but-similiar-routes

              So we use the workaround with the method 'linkIsPartOfUrl'
              -->
              <!-- <router-link :to="item.href" v-slot="{ isActive, isExactActive }">
                <label
                  :for="`submenu-navigation-link_${index}`"
                  class="navigation-link"
                  :class="[
                    isActive && 'router-link-active',
                    isExactActive && 'router-link-exact-active',
                  ]"
                >
                  <span class="navigation-label">{{ item.text }}</span>
                </label>
              </router-link> -->
              <label
                :for="`submenu-navigation-link_${index}`"
                class="navigation-link"
                :class="[
                  (linkIsPartOfUrl(item.href) || $route.path.indexOf(item.href_original) === 0) &&
                    'router-link-active',
                ]"
                @click="e => e.target.classList.toggle('submenu-open')"
              >
                <span class="navigation-label">{{ item.text }}</span>
              </label>

              <GlobalHeaderSubmenu
                class="submenu"
                :links="item.children"
                @toggle-overlay="closeOverlay"
              />
            </template>

            <!--
              the current item does not need subitems

              The ":class" construct is a special case where the navi item points directly to the
              first child (/parent/child1). If you now navigate to the second child its href
              "/parent/child2" is not seen as a child of the navi item by <router-link> and thus not
              highlighted. So this construct highlights the item in this case.
            -->
            <ContentLink
              v-else
              class="navigation-link"
              :class="{
                'router-link-active':
                  (item.href_original && $route.path.indexOf(item.href_original) === 0) ||
                  (!item.href_original && linkIsPartOfUrl(item.href)),
              }"
              :editable="false"
              :field="{ value: item }"
              @click="closeOverlay"
            >
              <span class="navigation-label">{{ item.text }}</span>
            </ContentLink>
          </li>
          <li class="navigation-item">
            <div class="navigation-label">
              <LanguageSwitcher class="navigation-item-language-switcher" />
            </div>
          </li>
        </ul>
      </div>

      <GlobalMNKProgressbar :fields="globalProgressbar" v-if="globalProgressbar" />

      <div class="close-progressbar" v-if="globalProgressbar">
        <Cross @click="closeProgressbar" />
      </div>

      <MapSearchLocation v-if="searchLocationIsVisible" class="navigation-search-location" />

      <GlobalHeaderSearch v-if="searchItem" :endpoint="searchItem.href" />

      <div class="navigation-cta-list-wrapper" v-if="metaNavItems.length && !globalProgressbar">
        <ul class="navigation-cta-list">
          <li class="navigation-cta-item" v-for="(item, index) in metaNavItems" :key="index">
            <ContentLink
              class="navigation-cta-link"
              :class="{
                'router-link-active':
                  (item.icon === 'IconUser' && user) ||
                  (item.icon === 'IconHeart' && moodboardHasEntries),
                'navigation-cta-link-heart-animation':
                  item.icon === 'IconHeart' && moodboardItemWasAdded,
              }"
              :data-icon="item.icon"
              :editable="false"
              :field="{ value: item }"
              :no-router-link="item.icon === 'IconSearch' || item.icon === 'IconUser'"
              @click="ctaEventHandler($event, item.icon)"
            >
              <!-- uses the name of the SVG imports below and is defined by items in /sitecore/content/Nolte JSS/Corporate Website/Data/Params/Header/Submenu Icons/ -->
              <component :is="item.icon" />
              <span>
                {{ item.text }}
              </span>
            </ContentLink>
          </li>
        </ul>
      </div>

      <GlobalHeaderMenuButton
        class="menu-button"
        ref="menuButton"
        :open="overlayIsOpen"
        @click="toggleOverlay"
        v-if="!globalProgressbar"
      />

      <GlobalHeaderMNKMenu :children="mnkNavItems" />
    </nav>

    <transition name="fade">
      <GlobalAnchorNavigation v-if="anchorNavigationVisible" />
    </transition>
  </header>
</template>

<script>
import { mapState, mapMutations, mapGetters, mapActions } from 'vuex';
import { disableBodyScroll, enableBodyScroll, clearAllBodyScrollLocks } from 'body-scroll-lock';
import vClickOutside from 'click-outside-vue3';
import 'intersection-observer';

import ContentLink from './ContentLink';

import IconLocation from '../assets/icons/IconLocation.svg?inline';
import IconHeart from '../assets/icons/IconHeart.svg?inline';
import IconUser from '../assets/icons/IconUser.svg?inline';
import IconSearch from '../assets/icons/IconSearch.svg?inline';
import Cross from '../assets/icons/Cross.svg?inline';

import GlobalHeaderLogo from './GlobalHeaderLogo';
import GlobalHeaderMenuButton from './GlobalHeaderMenuButton';
import GlobalHeaderSubmenu from './GlobalHeaderSubmenu';
import GlobalHeaderSearch from './GlobalHeaderSearch';
import GlobalHeaderMNKMenu from './GlobalHeaderMNKMenu';
import GlobalMNKProgressbar from './GlobalMNKProgressbar';
import MapSearchLocation from './map/MapSearchLocation';
import GlobalAnchorNavigation from './GlobalAnchorNavigation';
import Banner from './Banner.vue';

import LanguageSwitcher from './LanguageSwitcher';

import eventBus from '@/lib/eventBus';
import { useScreen } from 'vue-screen';
import { bp600 } from '../styles/variables.scss';

const CLOSE_OVERLAY_DELAY = 300;

const getScrollTop = () => {
  if (typeof window !== 'undefined') {
    return (
      window.scrollY ||
      document.documentElement.scrollTop - (document.documentElement.clientTop || 0)
    );
  }
  return 0;
};

const bodyScrollOptions = {
  reserveScrollBarGap: true,
};

export default {
  name: 'NolteGlobalHeader',
  emits: ['bannerClosed'],
  setup() {
    const screen = useScreen();

    return {
      screen,
    };
  },
  components: {
    GlobalHeaderLogo,
    GlobalHeaderMenuButton,
    GlobalHeaderSubmenu,
    GlobalHeaderSearch,
    GlobalHeaderMNKMenu,
    GlobalMNKProgressbar,
    MapSearchLocation,
    GlobalAnchorNavigation,
    LanguageSwitcher,
    ContentLink,
    IconLocation,
    IconHeart,
    IconUser,
    IconSearch,
    Cross,
    Banner,
  },

  directives: {
    clickOutside: vClickOutside.directive,
  },

  props: {
    navItems: {
      type: Array,
      default: () => [],
    },
    metaNavItems: {
      type: Array,
      default: () => [],
    },
    mnkNavItems: {
      type: Array,
      default: () => [],
    },
    language: {
      type: String,
      default: () => undefined,
    },
    bannerData: {
      type: Object,
      default: () => {},
    },
  },

  data() {
    return {
      sticking: false,
      stickingMobile: false,
      overlayIsOpen: false,

      scrollListener: () => {
        requestAnimationFrame(() => {
          this.sticking = getScrollTop() !== 0;
        });
      },
      scrollListenerMobile: () => {
        requestAnimationFrame(() => {
          this.stickingMobile = this.$refs?.navigationGroup?.scrollTop !== 0;
        });
      },
      /* Initially the progressbar is allowed to be open if it exists */
    };
  },

  mounted() {
    // request the initial state of the data
    this.getMoodboardDataIfNotDoneAlready();

    //detects whether header is inside or outside of viewport
    this.initIntersectionListener();

    if (this.metaNavItems.length === 0) return '';

    this.initScrollListener();
  },

  beforeUnmount() {
    clearAllBodyScrollLocks();
    eventBus.$emit('GlobalHeader:beforeUnmount');
  },

  computed: {
    ...mapState('jss', ['sitecoreContext', 'routeData']),
    ...mapState(['headerStickingOverride', 'user', 'anchorNavigationVisible']),
    ...mapGetters('jss', ['isEditing']),
    ...mapGetters({ progressbarOpenAllowed: 'getMnkProgressbarVisibility' }),
    ...mapGetters('moodboard', { moodboardHasEntries: 'hasEntries' }),
    ...mapState('moodboard', { moodboardItemWasAdded: 'itemAddedWithTimeout' }),
    ...mapGetters('map', { searchLocationIsVisible: 'searchLocationIsVisible' }),

    mnkRootItem() {
      return this.sitecoreContext.site?.login || null;
    },

    searchItem() {
      if (this.metaNavItems.length === 0) return undefined;
      return this.metaNavItems.find(item => item.icon === 'IconSearch');
    },

    globalProgressbar() {
      if (!this.progressbarOpenAllowed) return false;
      if (!this.routeData?.placeholders['jss-mnk-progressbar']) return false;
      // In Experience Editor the rendering is located in the JSON at index [2]
      if (!this.routeData?.placeholders['jss-mnk-progressbar'][this.isEditing ? 2 : 0]) {
        return false;
      }
      return this.routeData.placeholders['jss-mnk-progressbar'][this.isEditing ? 2 : 0].fields;
    },
  },

  methods: {
    ...mapMutations([
      'setSearchBarVisibility',
      'setSearchMnkMenuVisibility',
      'setMnkProgressbarVisibility',
      'setAnchorNavigationVisible',
    ]),
    ...mapActions('moodboard', { getMoodboardDataIfNotDoneAlready: 'getItems' }),

    clickOutsideOverlay({ target }) {
      let screenWidth = this.screen?.width ? this.screen.width : 0;
      if (screenWidth > parseInt(bp600, 10)) {
        if (
          target === this.$refs?.menuButton?.$el ||
          target.closest('.menu-button') === this.$refs?.menuButton?.$el
        ) {
          return;
        }

        if (this.overlayIsOpen) {
          this.overlayIsOpen = false;
        }
      }
    },

    toggleOverlay() {
      this.overlayIsOpen = !this.overlayIsOpen;
    },

    closeOverlay() {
      setTimeout(() => {
        this.overlayIsOpen = false;

        // close all sub menus by deselecting all input items
        this.$el.querySelectorAll('input.submenu-checkbox').forEach($input => {
          $input.checked = false;
        });
      }, CLOSE_OVERLAY_DELAY);
    },

    initScrollListener() {
      if (typeof window !== 'undefined') {
        window.addEventListener('scroll', this.scrollListener);

        eventBus.$once('GlobalHeader:beforeUnmount', () => {
          window.removeEventListener('scroll', this.scrollListener);
        });
      }
    },
    initIntersectionListener() {
      const observer = new IntersectionObserver(entries => {
        entries.forEach(entry => {
          if (entry.isIntersecting) {
            this.setAnchorNavigationVisible(false);
          } else {
            this.setAnchorNavigationVisible(true);
          }
        });
      });
      observer.observe(this.$el);

      eventBus.$once('GlobalHeader:beforeUnmount', () => {
        if (this.$el) {
          observer.unobserve(this.$el);
        }
      });
    },

    ctaEventHandler(e, iconName) {
      if (iconName === 'IconSearch') {
        e.preventDefault();
        this.setSearchBarVisibility(true);
      }
      if (iconName === 'IconUser' && this.mnkRootItem) {
        e.preventDefault();
        this.setSearchMnkMenuVisibility(true);
      }
      if (this.overlayIsOpen) {
        this.overlayIsOpen = false;
      }
    },

    closeProgressbar() {
      const progressbarReferrer = localStorage.getItem('progressbarReferrer');
      if (progressbarReferrer) {
        this.$router.push(progressbarReferrer);
      } else {
        this.$router.push(this.sitecoreContext.site.login.href);
      }

      this.setMnkProgressbarVisibility(false);
    },

    // with vue 3 routes that are unrelated from a record point of view but share a common path
    // are no longer active
    // see: https://github.com/vuejs/rfcs/blob/master/active-rfcs/0028-router-active-link.md#unrelated-but-similiar-routes
    linkIsPartOfUrl(link) {
      return this.$route.path.includes(link);
    },
  },

  watch: {
    overlayIsOpen(overlayIsOpen) {
      if (overlayIsOpen) {
        disableBodyScroll(this.$refs?.navigationGroup, bodyScrollOptions);

        // Register inner scroll listener only when overlay is open
        this.$refs?.navigationGroup?.addEventListener('scroll', this.scrollListenerMobile);
        eventBus.$once('GlobalHeader:beforeUnmount', () => {
          if (typeof window !== 'undefined') {
            window.removeEventListener('scroll', this.scrollListenerMobile);
          }
        });
      } else {
        enableBodyScroll(this.$refs?.navigationGroup);
        if (this.$refs?.navigationGroup?.scrollTop) {
          this.$refs.navigationGroup.scrollTop = 0;
          this.$refs?.navigationGroup?.removeEventListener('scroll', this.scrollListenerMobile);
        }
      }
    },
  },
};
</script>

<style lang="scss">
// Fix to show the header relative in EE and preview mode.
// The second selector is necessary if you click a header link in EE and then click the back button
// to return to the EE. For some reason there is an element with #root inserted.
.nolte-app.nolte-app--is-editing,
.nolte-app.nolte-app--is-preview {
  .nolte-global-header {
    position: relative;
  }
  main {
    padding: 0;
  }
}
</style>

<style lang="scss" scoped>
.nolte-global-header {
  background-color: $color-white;

  &::before {
    content: '';
    position: absolute;
    z-index: -1;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    @include header-shadow;
  }
}

.nolte-global-header--sticking::before {
  opacity: 1;
}

.navigation {
  position: relative;
  margin-left: auto;
  margin-right: auto;
  display: flex;
  justify-content: space-between;

  @include helper__until($bp-425) {
    .nolte-global-header--overlay-open &::before,
    .nolte-global-header--overlay-open &::after {
      content: '';
      position: absolute;
      left: 0;
      top: 0;
      width: 100%;
      height: $header-height-mobile;
      z-index: 25;
    }

    .nolte-global-header--overlay-open &::before {
      background: $color-white;
    }

    .nolte-global-header--overlay-open &::after {
      @include header-shadow;
    }

    .nolte-global-header--sticking-mobile &::after {
      opacity: 1;
    }
  }

  @include helper__until($bp-768) {
    height: $header-height-mobile;
  }

  @include helper__greater($bp-768) {
    height: $header-height;
  }
}

.logo {
  padding: 21px $unit-base 0;
  z-index: 70;

  @include helper__greater($bp-768) {
    padding: 31px $unit-double 0;
  }
}

.navigation-group {
  @include helper__until($bp-1280) {
    @include helper__composited-layer;
    @include helper__transition((opacity, transform));
    position: fixed;
    opacity: 0;
    transform: translateX(100%);
    top: 0;
    right: 0;
    bottom: 0;
    background-color: $color-white;
    box-shadow: 0 0 19px 8px rgba(104, 102, 99, 0.16);
    z-index: 60;
    height: 0;
    overflow: hidden;

    .nolte-global-header--overlay-open & {
      overflow-y: auto;
      opacity: 1;
      transform: translateX(0);
      height: auto;
    }
  }

  @include helper__until($bp-425) {
    transform: translateX(0);
    left: 0;
    pointer-events: none;

    .nolte-global-header--overlay-open & {
      pointer-events: auto;
    }
  }

  @include helper__greater-until($bp-425, $bp-1280) {
    width: 375px;
    margin-top: 6px;
    margin-right: 6px;
    margin-bottom: 6px;
    border-radius: 5px;
  }

  @include helper__greater($bp-1280) {
    display: flex;
    flex: 1 1 auto;
    justify-content: center;
  }

  & > :first-child {
    @include helper__until($bp-1280) {
      margin-top: $header-height-mobile + $unit-base;
    }
  }

  & > :last-child {
    // same amount as .navigation-cta-list + $unit-base
    @include helper__until($bp-320) {
      margin-bottom: $header-height + $unit-base;
    }
    @include helper__greater-until($bp-320, $bp-1280) {
      margin-bottom: $header-height-mobile + $unit-base;
    }
  }
}

.navigation-list {
  display: flex;

  @include helper__until($bp-1280) {
    flex-direction: column;
  }
  @include helper__greater($bp-1280) {
    height: 100%;
  }
}

.navigation-item {
  @include text-h5;
  color: $color-ironside-gray;

  // Sort the font size out, re-position nav ul
  @include helper__until($bp-1280) {
    font-size: 13px;
    margin-bottom: 24px;
    position: relative;
    display: block;
  }

  @include helper__greater($bp-1280) {
    display: flex;
    align-items: center;
    height: 100%;

    & + & {
      margin-left: $unit-double;
    }
  }
}

.navigation-link {
  display: block;

  // for label element
  cursor: pointer;

  @include helper__until($bp-1280) {
    padding: $unit-half 0 $unit-half 60px;
  }
  @include helper__greater-until($bp-320, $bp-1280) {
    padding-left: 90px;
  }

  @include helper__greater($bp-1280) {
    display: flex;
    height: 100%;
    align-items: center;
  }

  .submenu-open {
    @include helper__until($bp-1280) {
      margin-bottom: $unit-base;
    }
  }
}

.navigation-label {
  @include helper__transition((box-shadow, color));
  display: inline-block;
  padding-top: 2px;
  padding-bottom: 2px;
  text-align: center;
  box-shadow: 0 0 rgba($color-aureolin, 0);

  @include helper__greater($bp-1280) {
    padding-top: 8px;
    padding-bottom: 8px;
  }
}

.navigation-link:hover .navigation-label {
  color: $color-delta;
}

.navigation-link.router-link-active .navigation-label {
  color: inherit;
  box-shadow: 0 8px rgba($color-aureolin, 1);
}

.submenu-checkbox {
  @include helper__until($bp-1280) {
    @include helper__visually-hidden;
  }

  @include helper__greater($bp-1280) {
    display: none;
  }
}

.submenu {
  @include helper__composited-layer;
  @include helper__transition(all, $transition-duration-default * 2);
  background-color: $color-concret;
  width: 100%;
  will-change: opacity;

  opacity: 0;
  overflow: hidden;
  height: 0;
  pointer-events: none;

  @include helper__until($bp-1280) {
    position: relative;
    left: 0px;

    &::before {
      content: '';
      position: absolute;
      left: 98px;
      width: 0;
      height: 0;
      border-top: 10px solid $color-white;
      border-right: 10px solid transparent;
      border-left: 10px solid transparent;
      line-height: 0;
    }

    // show submenu via checkbox
    .submenu-checkbox:checked ~ & {
      height: auto;
      pointer-events: auto;
      overflow: auto;
      opacity: 1;
    }
  }

  @include helper__greater($bp-1280) {
    // show submenu via hover
    position: absolute;
    left: 0;
    top: 100%;
    box-shadow: 0 24px 28px -1px rgba($color-black, 0.23);
    height: auto;

    .navigation-item:hover & {
      pointer-events: auto;
      opacity: 1;
      overflow: auto;
    }
  }
}

.navigation-cta-list-wrapper {
  height: 100%;
  align-items: center;
  justify-content: flex-end;
  position: relative;
  z-index: 61;
  display: none;

  .nolte-global-header--overlay-open & {
    display: flex;
  }

  @include helper__greater($bp-600) {
    z-index: 30;
    // align to right
    margin-left: auto;
    margin-right: $unit-base;
    display: flex;
  }

  @include helper__greater($bp-1280) {
    margin-right: $unit-double;
  }
}

.navigation-cta-list {
  display: flex;
  align-items: flex-start;

  //@include helper__until($bp-425) {
  @include helper__until($bp-600) {
    @include helper__transition(opacity);
    position: absolute;
    left: 0;
    right: 0;
    bottom: 0;
    height: 0;
    justify-content: center;
    align-items: center;
    background-color: white;
    box-shadow: 0 18px 23px 20px rgba($color-black, 0.1);
    z-index: 30;
    opacity: 0;

    .nolte-global-header--overlay-open & {
      opacity: 1;
      position: fixed;
      height: $header-height-mobile;
    }
  }
}

.navigation-cta-item {
  @include helper__greater-until($bp-600, $bp-1024) {
    flex: 1 auto;
  }

  & + & {
    margin-left: $unit-base;
  }
}

.navigation-cta-link {
  @include helper__transition(color);
  @include text-caption--small;
  height: 100%;
  display: flex;
  flex-wrap: nowrap;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  color: $color-ironside-gray;
  text-align: center;

  span {
    @include helper__transition(color);
    &:hover {
      color: $color-delta;
    }
  }

  // since we use scoped styles we have to use the :deep selector
  // since our svg-to-vue-component prefixes class names out of unknown reasons we
  // just check the end of the class name
  & :deep [class$='active'] {
    @include helper__composited-layer;
    @include helper__transition((opacity, transform));
    position: relative;
    opacity: 0;
    transform: translateX(-2px);
  }

  svg {
    margin-top: -6px;
  }
}

.navigation-cta-link.router-link-active {
  color: inherit;

  :deep [class$='active'] {
    opacity: 1;
    transform: translateX(0);
  }
}

.navigation-cta-link-heart-animation svg {
  animation: 700ms ease-in-out heartbeat;
}
@keyframes heartbeat {
  0% {
    transform: scale(1);
  }
  25% {
    transform: scale(1.4);
  }
  50% {
    transform: scale(1);
  }
  75% {
    transform: scale(1.4);
  }
  100% {
    transform: scale(1);
  }
}

.navigation-item-language-switcher {
  @include helper__transition(color);

  @include helper__until($bp-320) {
    margin-left: 60px;
  }

  @include helper__greater-until($bp-320, $bp-1280) {
    margin-left: 80px;
  }

  &:hover {
    color: $color-delta;
  }
}

.menu-button {
  @include helper__until($bp-1280) {
    padding-left: $unit-base;
    margin-right: $unit-base;
    z-index: 60;
  }

  @include helper__greater($bp-1280) {
    display: none;
  }
}

.close-progressbar {
  display: flex;
  padding: 0 $unit-base;
  align-items: center;

  @include helper__greater($bp-768) {
    padding: 0 $unit-double;
  }

  svg {
    cursor: pointer;
  }
}

.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.3s ease;
}
</style>

<style lang="scss">
// styles for neo
html.template--neopage .nolte-global-header {
  background-color: $color-neo;

  position: relative;

  @include helper__greater($bp-1024) {
    position: relative;
  }

  // standard navigation item
  // we only want to change the colors in desktop vp as the look is kept in the mobile vp
  @include helper__greater($bp-1280) {
    .navigation-group .navigation-item,
    .navigation-cta-list-wrapper .navigation-item {
      color: $color-concret;

      &:hover {
        color: $color-delta;
      }
    }
  }

  @include helper__greater($bp-600) {
    // meta navigation item
    .navigation-cta-link {
      color: $color-concret;

      span &:hover {
        color: $color-delta;
      }
    }
  }
}

// stlyes for landing page
html.template--landingpage .nolte-global-header {
  background-color: $color-white;

  position: relative;

  @include helper__greater($bp-1024) {
    position: relative;
  }

  // standard navigation item
  // we only want to change the colors in desktop vp as the look is kept in the mobile vp
  @include helper__greater($bp-1280) {
    .navigation-group .navigation-item,
    .navigation-cta-list-wrapper .navigation-item {
      //color: $color-tuatara-dark;

      &:hover {
        //color: $color-ironside-gray;
      }
    }
  }

  @include helper__greater($bp-425) {
    // meta navigation item
    .navigation-cta-link {
      //color: $color-tuatara-dark;

      span &:hover {
        //color: $color-ironside-gray;
      }
    }
  }
}

// styles for editorial map with searchlocation and mobile menu-button

.navigation-search-location {
  display: none !important;

  @include helper__greater($bp-1024) {
    display: flex !important;
  }
}
.search-location--visible {
  .navigation-cta-list-wrapper {
    width: auto;
    @include helper__greater($bp-768) {
      margin-left: $unit-double;
    }
    @include helper__until($bp-1024) {
      width: 100%;
    }
  }
  .navigation-group {
    position: fixed;
    opacity: 0;
    //transform: translateX(100%);
    transform: translateX(0);

    @include helper__greater($bp-425) {
      transform: translateX(100%);
    }
    top: 0;
    right: 0;
    bottom: 0;
    background-color: $color-white;
    box-shadow: 0 0 19px 8px rgba(104, 102, 99, 0.16);
    z-index: 60;
    height: 0;
    overflow: hidden;
    display: block;

    .nolte-global-header--overlay-open & {
      overflow-y: auto;
      opacity: 1;
      transform: translateX(0);
      height: auto;
    }

    @include helper__greater-until($bp-425, $bp-1280) {
      width: 375px;
      margin-top: 6px;
      margin-right: 6px;
      margin-bottom: 6px;
      border-radius: 5px;
    }

    & > :first-child {
      margin-top: $header-height-mobile + $unit-base;
    }
  }

  .navigation-list {
    display: flex;
    flex-direction: column;
    height: auto;
  }

  .navigation-item {
    font-size: 13px;
    margin-bottom: 24px;
    position: relative;
    display: block;
    margin-left: 0 !important;
  }

  .navigation-link {
    display: block;
    height: auto;
    padding: $unit-half 0 $unit-half 60px;

    @include helper__greater($bp-320) {
      padding-left: 90px;
    }
  }

  .global-header-search__canvas {
    @include helper__greater($bp-1280) {
      padding: $unit-double 112px 0 150px;
    }
  }

  .mnk-menu-loggedin {
    .navigation-link {
      padding-left: 0px;
    }
  }
  .navigation-label {
    padding-top: 2px;
    padding-bottom: 2px;
  }

  .submenu {
    display: none;
    position: relative;
    left: 0px;
    margin-top: 16px;
    box-shadow: none;

    &::before {
      content: '';
      position: absolute;
      left: 98px;
      width: 0;
      height: 0;
      border-top: 10px solid $color-white;
      border-right: 10px solid transparent;
      border-left: 10px solid transparent;
      line-height: 0;
    }
  }

  .submenu-checkbox:checked ~ .submenu {
    display: block;
    height: auto;
    pointer-events: auto;
    overflow: auto;
    opacity: 1;

    .submenu-list {
      visibility: visible;
    }

    margin-bottom: $unit-base * (-1);
  }

  .submenu-list {
    width: 100%;
    padding-top: 48px;
    padding-bottom: 48px;
    visibility: hidden;
    display: block;
    width: 100%;
    padding-top: $unit-base * 3;
    padding-bottom: $unit-base * 3;
    box-shadow: inset 0 18px 23px -20px rgba($color-black, 0.1);
  }

  .submenu-link {
    flex-direction: row;
    justify-content: flex-start;
    font-size: 18px;
    flex-direction: row;
    justify-content: flex-start;
    font-size: 18px;
    padding-left: $unit-base * 3;
    padding-right: $unit-base * 3;
  }

  .submenu-label {
    min-height: 0;
  }

  .submenu-icon {
    svg {
      width: 50px;
      height: 50px;
      margin-right: $unit-double;
      margin-top: 0;
    }
  }

  .navigation-item-language-switcher {
    @include helper__until($bp-320) {
      margin-left: 60px;
    }

    @include helper__greater($bp-320) {
      margin-left: 90px;
    }
  }

  .language-switcher {
    display: flex;
    align-items: center;
    position: relative;
    user-select: none;
    margin-top: $unit-base;
  }

  .language-switcher__button {
    margin: 0 $unit-double 0 0;
    padding: 0;
  }

  .language-switcher__button.language-switcher__trigger {
    display: none;
  }

  .language-switcher__button--active {
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    background-color: $color-aureolin;
    width: 40px;
    height: 40px;
  }

  .language-switcher__options {
    position: unset;
    left: 0;
    top: 0;
    padding: 0;
    flex-direction: row;
    opacity: 1;
    transform: scaleY(1);
    box-shadow: none;
  }

  .menu-button {
    padding-left: $unit-base;
    margin-right: $unit-base;
    z-index: 60;

    display: block;
  }
}
</style>
