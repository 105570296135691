<template>
  <ContentLink
    :editable="false"
    :field="{ value: { href: logoHref, linktype: 'internal' } }"
    @click="setSearchBarVisibility(false)"
    class="global-header-logo"
  >
    <transition name="fade">
      <img key="1" :src="src" width="89" height="40" alt="Logo" v-if="isDarkBackground" />
      <img key="2" :src="src" width="89" height="40" alt="Logo" v-else />
    </transition>
  </ContentLink>
</template>

<script>
import { mapGetters, mapMutations, mapState } from 'vuex';
import ContentLink from './ContentLink';
import { bp425 } from '../styles/variables.scss';

export default {
  name: 'GlobalHeaderLogo',

  components: {
    ContentLink,
  },

  props: {
    language: {
      type: String,
      default: () => undefined,
    },
    overlayIsOpen: {
      type: Boolean,
      required: true,
    },
  },
  computed: {
    ...mapGetters('jss', ['isNeoPage', 'getSearchBarVisibility']),
    ...mapGetters(['getSearchBarVisibility']),
    ...mapState(['windowWidth']),

    isDarkBackground() {
      return (
        process.env.VUE_APP_PROJECT === 'corporate' &&
        this.isNeoPage &&
        (!this.overlayIsOpen || (this.overlayIsOpen && this.isGreater425)) &&
        !this.getSearchBarVisibility
      );
    },

    isGreater425() {
      return this.windowWidth > parseInt(bp425, 10);
    },

    src() {
      const env = process.env;
      const project = env.VUE_APP_PROJECT;
      const logo = this.isDarkBackground ? 'logo-neo.svg' : 'logo.svg';
      return `${env.VUE_APP_ASSETS_PATH}/assets/icons/${project}/${logo}`;
    },

    logoHref() {
      return this.language ? `/${this.language}/` : '/';
    },
  },

  methods: {
    ...mapMutations(['setSearchBarVisibility']),
  },
};
</script>

<style lang="scss" scoped>
.global-header-logo {
  width: 153px;
  height: 100px;

  @include helper__until($bp-768) {
    width: 99px;
    height: 70px;
  }
}
img {
  position: absolute;
  width: 89px;
  height: 40px;
  max-width: none;

  @include helper__until($bp-768) {
    width: 67px;
    height: 30px;
  }
}
</style>
