<template>
  <div class="editorial-media-group">
    <sc-placeholder name="nolte-media-group_default" :rendering="rendering" />
    <!--<template v-for="(EditorialMedia, index) in mediaGroupPlaceholder" :key="`media_${index}`">
      <component :is="EditorialMedia" />
    </template>-->
  </div>
</template>

<script>
//import EditorialMedia from './EditorialMedia.vue';
import { Placeholder } from '@sitecore-jss/sitecore-jss-vue';

export default {
  name: 'EditorialMediaGroup',

  components: {
    //EditorialMedia,
    ScPlaceholder: Placeholder,
  },

  props: {
    // The SitecoreJssPlaceholderPlugin uses a mixin that attaches to the beforeCreate hook to look
    // for a withPlaceholder property on a component definition. The plugin then uses the value
    // provided by the withPlaceholder property to find the specified placeholder data, e.g. tabs
    // in the "rendering" prop data.
    rendering: {
      type: Object,
      default: undefined,
    },
  },

  /*withPlaceholder: {
    placeholders: [
      {
        placeholder: 'nolte-media-group_default',
        computedPropName: 'mediaGroupPlaceholder',
      },
    ],
  },*/
};
</script>

<style lang="scss" scoped>
// fixes that two dark items in a row have a white vertical gap between them
:deep(.editorial-component--dark + .editorial-component--dark .editorial-media) {
  margin-top: 0 !important;
}

// switch .image and .details for every second item
:deep(.editorial-media-wrapper:nth-child(odd)) {
  @include helper__greater($bp-768) {
    .details {
      @include helper__greater-until($bp-768, $bp-1024) {
        order: 2;
        padding: 0 0 0 $unit-double;
      }

      @include helper__greater($bp-1024) {
        order: 2;
        padding: 0 0 0 $unit-double * 2;
      }
    }
  }
}
</style>
