<template>
  <v-field-template
    name="singlelinetext"
    :field="field"
    :hidden="hidden"
    :disabled="disabled"
    :errors="errors"
  >
    <template #field>
      <NolteFormWrapper :errors="errors" #default="{ hasError }">
        <NolteFormInput
          type="text"
          :id="field.valueField.id"
          :name="field.valueField.name"
          :placeholder="field.model.placeholderText"
          :required="field.model.required"
          :minlength="field.model.minLength"
          :maxlength="field.model.maxLength"
          :disabled="disabled"
          v-model="value"
          :value="value"
          @blur="fieldBlur"
          :invalid="hasError"
        />
      </NolteFormWrapper>
    </template>
  </v-field-template>
</template>

<script>
import FieldMixin from '../structure/FieldMixin';
import FieldConditionMixin from '../structure/conditions/FieldConditionMixin';
import FieldValidationMixin from '../structure/validation/FieldValidationMixin';

import NolteFormInput from '../../nolte-ui/NolteForm/NolteFormInput';
import NolteFormWrapper from '../../nolte-ui/NolteForm/NolteFormWrapper';

import { getStorage } from '@/useLocalStorage';

export default {
  name: 'SitecoreFormsFieldsSinglelinetext',

  components: {
    NolteFormInput,
    NolteFormWrapper,
  },

  mixins: [FieldMixin, FieldConditionMixin, FieldValidationMixin],

  // errors prop is defined in FieldValidationMixin
  mounted() {
    const savedValue = getStorage(this.field.valueField.name);
    this.value = savedValue ? savedValue : this.field.model.value;
  },
  data() {
    return {
      value: this.field.model.value,
    };
  },
};
</script>
