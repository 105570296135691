<template>
  <div class="nolte-form-wrapper">
    <slot :hasError="hasError" />

    <div class="nolte-form-wrapper__error-msg">
      <!-- Keep this div below as it is restyled in the MNK login form -->
      <div v-if="hasError">
        {{ firstError }}
        <IconExclamationTriangle class="nolte-form-wrapper__error-icon" />
      </div>
    </div>
  </div>
</template>

<script>
import IconExclamationTriangle from './assets/IconExclamationTriangle.svg?inline';

export default {
  name: 'NolteFormWrapper',

  components: {
    IconExclamationTriangle,
  },

  props: {
    errors: {
      type: Array,
      default: undefined,
    },
  },

  computed: {
    hasError() {
      return this.errors && !!this.errors.length;
    },

    firstError() {
      return this.hasError ? this.errors[0] : undefined;
    },
  },
};
</script>

<style lang="scss" scoped>
.nolte-form-wrapper__error-msg {
  @include text-form-subline--small;
  color: $color-red-orange;
  min-height: $unit-base + $unit-half;
  margin-top: -$unit-half;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.nolte-form-wrapper__error-icon {
  margin-left: $unit-half;
}
</style>
