<template>
  <EditorialInternationalProjectsTemplate :fields="fields" :projects="projects" />
</template>

<script>
import EditorialInternationalProjectsTemplate from './EditorialInternationalProjectsTemplate';

export default {
  name: 'EditorialInternationalProjectsAreaProjects',

  components: {
    EditorialInternationalProjectsTemplate,
  },

  computed: {
    projects() {
      const area = this.fields?.data?.projects;
      return area?.children?.map(project => ({ area, project }));
    },
  },
};
</script>
