<script>
import { h } from 'vue';

export default {
  name: 'SitecoreFormsFieldsDynamicHtmlTag',
  props: {
    tag: { type: String, default: 'p' },
    text: { type: String, default: '' },
    raw: { type: Boolean, default: false },
  },
  render() {
    if (this.raw) {
      // allow html tags in text
      return h(this.tag, { innerHTML: this.text });
    } else {
      // Sitecore Bug 474103
      if (this.tag === '_span') {
        return h('span', this.text);
      }
      // disallow html tags in text
      return h(this.tag, this.text);
    }
  },
};
</script>
