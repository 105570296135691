<template>
  <div class="root" :class="{ visible: open }" @click.stop>
    <button
      class="close"
      type="button"
      aria-label="Close"
      @click="setSearchMnkMenuVisibility(false)"
    >
      <div />
      <div />
    </button>

    <GlobalHeaderMNKMenuLoggedIn v-if="user" :children="children" />
    <GlobalHeaderMNKMenuLoggedOut v-else />
  </div>
</template>

<script>
import { mapState, mapActions, mapGetters, mapMutations } from 'vuex';
import GlobalHeaderMNKMenuLoggedIn from '@/components/GlobalHeaderMNKMenuLoggedIn';
import GlobalHeaderMNKMenuLoggedOut from '@/components/GlobalHeaderMNKMenuLoggedOut';

export default {
  name: 'GlobalHeaderMNKMenu',

  components: {
    GlobalHeaderMNKMenuLoggedIn,
    GlobalHeaderMNKMenuLoggedOut,
  },

  props: {
    children: {
      type: Array,
      required: true,
    },
  },

  computed: {
    ...mapState('jss', ['sitecoreContext']),
    ...mapState(['user']),
    ...mapGetters({ open: 'getMnkMenuVisibility' }),
  },

  methods: {
    ...mapActions(['checkUser']),
    ...mapMutations(['setSearchMnkMenuVisibility']),
  },

  mounted() {
    this.checkUser();

    // close navigation on click outside the navigation
    // works together with @click.stop on root element in template block above
    document.body.addEventListener('click', () => {
      if (this.open) {
        this.setSearchMnkMenuVisibility(false);
      }
    });
  },
};
</script>

<style lang="scss" scoped>
.root {
  @include helper__composited-layer;
  @include helper__transition((opacity, transform));
  position: fixed;
  z-index: 60;
  width: 100%;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  margin-top: $header-height-mobile;
  background-color: $color-white;
  box-shadow: 0 0 19px 8px rgba(104, 102, 99, 0.16);
  overflow-x: hidden;
  overflow-y: auto;

  transform: translateX(100%);
  opacity: 0;
  pointer-events: none;

  &.visible {
    transform: translateX(0);
    opacity: 1;
    pointer-events: auto;
  }

  @include helper__greater($bp-425) {
    width: 375px;
    top: 6px;
    right: 6px;
    left: auto;
    bottom: auto;
    margin-top: 0;
    max-height: 95vh;
  }
}

.close {
  position: absolute;
  top: 0;
  right: 0;
  width: 40px;
  height: 40px;
  border: 0;
  margin: $unit-base;
  cursor: pointer;

  div {
    position: absolute;
    height: 4px;
    width: 100%;
    background: $color-delta;
    top: 50%;
    left: 0;
    border-radius: 2px;
    margin-top: -2px;
    transform: rotate(45deg);
  }

  div:nth-child(2) {
    transform: rotate(-45deg);
  }
}
</style>
