// This prototype attaches/manages metadata in the form of a modality attribute
// to the body, as a way to allow adapting style based on the user's active
// input modality (i.e., how they are interacting with the UI right now).
// Source: https://github.com/alice/modality v1.0.2
if (typeof window !== 'undefined' && typeof document !== 'undefined') {
  document.addEventListener('DOMContentLoaded', function () {
    let hadKeyboardEvent = false,
      isHandlingKeyboardThrottle;
    const keyboardModalityWhitelist = [
        // 'input:not([type])',
        // 'input[type=email]',
        // 'input[type=text]',
        // 'input[type=password]',
        // 'input[type=number]',
        // 'input[type=date]',
        // 'input[type=time]',
        // 'input[type=datetime]',
        // 'textarea',
        // '[role=textbox]',
        '[supports-modality=keyboard]',
      ].join(','),
      matcher = (function () {
        const el = document.body;
        if (el.matchesSelector) return el.matchesSelector;
        if (el.webkitMatchesSelector) return el.webkitMatchesSelector;
        if (el.mozMatchesSelector) return el.mozMatchesSelector;
        if (el.msMatchesSelector) return el.msMatchesSelector;
        console.error("Couldn't find any matchesSelector method on document.body.");
      })(),
      disableFocusRingByDefault = function () {
        const css = 'body:not([modality=keyboard]) :focus { outline: none; }',
          head = document.head || document.getElementsByTagName('head')[0],
          style = document.createElement('style');

        style.type = 'text/css';
        style.id = 'disable-focus-ring';
        if (style.styleSheet) {
          style.styleSheet.cssText = css;
        } else {
          style.appendChild(document.createTextNode(css));
        }

        head.insertBefore(style, head.firstChild);
      },
      focusTriggersKeyboardModality = function (el) {
        let triggers = false;
        if (matcher) {
          triggers =
            matcher.call(el, keyboardModalityWhitelist) && matcher.call(el, ':not([readonly])');
        }
        return triggers;
      };

    disableFocusRingByDefault();

    document.body.addEventListener(
      'keydown',
      function () {
        hadKeyboardEvent = true;
        if (isHandlingKeyboardThrottle) {
          clearTimeout(isHandlingKeyboardThrottle);
        }
        isHandlingKeyboardThrottle = setTimeout(function () {
          hadKeyboardEvent = false;
        }, 100);
      },
      true
    );

    document.body.addEventListener(
      'focus',
      function (e) {
        if (hadKeyboardEvent || focusTriggersKeyboardModality(e.target))
          document.body.setAttribute('modality', 'keyboard');
      },
      true
    );

    document.body.addEventListener(
      'blur',
      function () {
        document.body.removeAttribute('modality');
      },
      true
    );
  });
}
