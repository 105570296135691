<template>
  <div class="editorial-mnk-retailer-request-form">
    <EditorialHeadline
      v-if="textHasValue(fields['Headline'])"
      :fields="{ headline: fields['Headline'] }"
      class="headline"
      headline-level="h2"
    />

    <EditorialTextMultiLine
      v-if="textHasValue(fields['Copy'])"
      :fields="{ copy: fields['Copy'] }"
      class="subline"
    />

    <GlobalForm
      :fields="formDefinitions.retailerRequestForm"
      v-if="formDefinitions.retailerRequestForm"
      @mounted="onMountedInjectCancelButton"
    />
    <div v-else>Menu data for MNK Retailer Request Form is missing.</div>
  </div>
</template>

<script>
import { mapState } from 'vuex';
import { textHasValue, linkHasValue } from './helper';

import GlobalForm from '@/components/GlobalForm';
import EditorialHeadline from '@/components/EditorialHeadline';
import EditorialTextMultiLine from './EditorialTextMultiLine';

export default {
  name: 'EditorialMNKRetailerRequestForm',

  components: {
    GlobalForm,
    EditorialHeadline,
    EditorialTextMultiLine,
  },

  computed: {
    ...mapState('jss', ['sitecoreContext', 'routeData']),

    // get all form definitions in placeholder "jss-mnk-dialog"
    formDefinitions() {
      const mappings = {
        retailerRequestForm: '{9AF62498-018D-4936-8687-A39FA0D7E009}',
      };
      const forms = {};
      for (const mappingName in mappings) {
        let definition = false;
        try {
          definition = this.routeData?.placeholders['jss-mnk-dialog']?.find(
            item => item.dataSource === mappings[mappingName]
          ).fields;
        } catch {
          return false;
        }
        forms[mappingName] = definition;
      }
      return forms;
    },
  },
  methods: {
    textHasValue,
    linkHasValue,

    onMountedInjectCancelButton($form) {
      if (!this.linkHasValue(this.fields['Back Button'])) return;

      // We need the timeout because although we react to the event "mounted", the DOM elements
      // are not yet rendered
      setTimeout(() => {
        const back = this.fields['Back Button'].value;
        const $submitWrapper = $form.querySelector('.form-field-type-submit .nolte-form-wrapper');
        const $cancelWrapper = $submitWrapper.cloneNode(true);
        const $cancel = $cancelWrapper.querySelector('button');
        const $content = $cancel.querySelector('.nolte-button__content');

        $cancel.classList.add('nolte-button--grey--plain');
        $content.textContent = back.text;
        $cancel.addEventListener('click', e => {
          e.preventDefault();
          this.$router.push(back.href);
        });

        $submitWrapper.parentElement.insertBefore($cancelWrapper, $submitWrapper);
      }, 0);
    },
  },
};
</script>

<style lang="scss" scoped>
.editorial-mnk-retailer-request-form {
  background: $color-white;

  // triggers the complete rendering of the background
  padding-top: 1px;
}

.spacing {
  @include spacing;
}

.headline {
  :deep() > * {
    @include helper__greater($bp-768) {
      max-width: none;
      font-size: 36px;
      line-height: (48 / 36);
    }
  }
}

.subline {
  margin: $unit-double auto;
}
</style>
