<template>
  <div class="content-anchor--editing" v-if="isEditing">
    <div class="field">
      <label>Anchor Title:</label>
      <sc-text :field="fields.anchorTitle" />
      &ndash;
      <label>Anchor Name:</label>
      <sc-text :field="fields.anchorName" />
    </div>
  </div>
  <a class="content-anchor" v-else-if="anchorValid" :name="anchorName" :data-title="anchorTitle" />
</template>
<script>
import { mapGetters } from 'vuex';
import { Text } from '@sitecore-jss/sitecore-jss-vue';
import { textHasValue } from './helper';

export default {
  name: 'ContentAnchor',
  components: {
    ScText: Text,
  },
  props: {
    fields: {
      type: Object,
      default: () => {},
    },
  },
  computed: {
    ...mapGetters('jss', ['isEditing']),

    anchorName() {
      return this.fields.anchorName?.value
        ?.toString()
        .toLowerCase()
        .replace(/[^a-z0-9]/gi, '');
    },

    anchorTitle() {
      return this.fields.anchorTitle?.value;
    },

    anchorValid() {
      return this.textHasValue(this.anchorName) && this.textHasValue(this.anchorTitle);
    },
  },
  methods: {
    textHasValue,
  },
};
</script>

<style lang="scss" scoped>
.content-anchor--editing {
  display: flex;
  flex-direction: column;
  margin: 0 0 $unit-half $unit-half !important;

  label {
    margin: 0 $unit-half 0 0;
  }
}
</style>
