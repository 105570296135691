<template>
  <div class="nolte-variant-switcher">
    <NolteVariantGroup
      v-for="(variantGroup, index) in variantGroups"
      :key="`variant-group-${index}`"
      :variants="variantGroup.variants"
      :label="variantGroup.label"
      :active-item="activeItem"
      class="nolte-variant-switcher__group"
    />
  </div>
</template>

<script>
import NolteVariantGroup from './NolteVariantGroup';

export default {
  name: 'NolteVariantSwitcher',

  components: {
    NolteVariantGroup,
  },

  props: {
    variantGroups: {
      type: Array,
      required: true,
    },
    activeItem: {
      type: Object,
      default: undefined,
    },
  },
};
</script>

<style lang="scss" scoped>
.nolte-variant-switcher {
  width: 100%;
}

.nolte-variant-switcher__group {
  & + & {
    margin-top: $unit-double;
  }
}
</style>
