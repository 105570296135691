<template>
  <NolteDownload
    v-if="!isEditing && (fileUrl || externalUrl)"
    class="nolte-download"
    :label="label"
    :description="description"
    :image="fields.image"
    :file-url="fileUrl"
    :external-url="externalUrl"
    :file-size="fileSize"
    :is-external-link="true"
  />

  <div class="editorial-download" v-else-if="isEditing">
    <p>{{ getFieldDisplayName(fields.label) }}: <sc-text :field="fields.label" /></p>
    <p>{{ getFieldDisplayName(fields.description) }}: <sc-text :field="fields.description" /></p>
    <p>
      {{ getFieldDisplayName(fields.file) }}:
      <sc-file v-if="fileUrl" :field="fields.file" />
      <template v-else>{{ emptyMessage }}</template>
    </p>
    <p>{{ getFieldDisplayName(fields.externalFile) }}: <sc-link :field="fields.externalFile" /></p>
    <EditorialImage
      class="editorial-download__image"
      :media="fields.image"
      default-aspect-ratio="3:2"
      default-sizes="500px"
    />
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import { File, Text, Link } from '@sitecore-jss/sitecore-jss-vue';
import { getFieldDisplayName } from './helper';
import EditorialImage from '@/components/EditorialImage';

import NolteDownload from '../nolte-ui/NolteDownload';

export default {
  name: 'EditorialDownload',

  components: {
    ScFile: File,
    ScLink: Link,
    ScText: Text,
    NolteDownload,
    EditorialImage,
  },

  data() {
    return {
      emptyMessage: 'Field is empty. Please select a file.',
    };
  },

  computed: {
    ...mapGetters('jss', ['isEditing']),

    label() {
      return this.fields?.label?.value;
    },
    description() {
      return this.fields?.description?.value;
    },
    fileUrl() {
      return this.fields?.file?.value?.src;
    },
    externalUrl() {
      return this.fields?.externalFile?.value?.href;
    },
    fileSize() {
      return parseInt(this.fields?.file?.value?.size, 10);
    },
  },

  methods: {
    getFieldDisplayName,
  },
};
</script>

<style lang="scss">
.editorial-download {
  @include spacing;
}

.editorial-download__image {
  max-width: 450px !important;
}

.nolte-download {
  @include spacing;
  &__detail {
    /*
      :active selector does not work in IE11
      https://stackoverflow.com/questions/31061544/active-selector-in-ie11-doesnt-work-if-there-are-nested-elements-how-do-i-mak
     */
    pointer-events: none;

    background-color: $color-white;
    border-radius: $unit-half;
  }
  &__inner {
    transition-property: all !important;
  }
  &__inner:hover {
    &:before {
      top: 0;
      right: 0;
      left: 0;
    }

    .nolte-download__icon-area {
      transform: translateY(2px);
    }
  }
  &__inner:active {
    &:before {
      top: 0;
      right: 0;
      left: 0;
    }
    transform: scale3d(1.02, 1.02, 1);
  }
}

.nolte-download__icon-area {
  transition: transform 300ms ease-in;
}
</style>
