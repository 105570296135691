import detectPassiveEvents from 'detect-passive-events';

/**
 * Directive which adds a attribute to an element when a touch event occures.
 * And remove when touch event ends.
 * Main usage will be for mobile, where :hover isn't usable well.
 *
 * How use this directive?
 * Import "touchHover" and register directive on component like this:
 *
 * directives: {
 *   touchHover,
 * }
 *
 * Then use directive on component itself:
 *
 * <div v-touch-hover="'customAttrName'">...</div>
 *
 * Value is optional. When set, attribute will named like the value.
 * Default attribute name is "data-touch-hover".
 */

const setAttrOnElement = (el, attrName) => el.setAttribute(attrName, '');
const removeAttrFromElement = (el, attrName) => el.removeAttribute(attrName);

export const touchHover = {
  bind(el, binding) {
    const attrName = binding.value || 'data-touch-hover';

    el.addEventListener(
      'touchstart',
      setAttrOnElement.bind(this, el, attrName),
      detectPassiveEvents.hasSupport ? { passive: true } : false
    );
    el.addEventListener('touchend', removeAttrFromElement.bind(this, el, attrName));
    el.addEventListener('touchcancel', removeAttrFromElement.bind(this, el, attrName));

    /**
     * The modifier "mobileFlag" adds the event touchstart to its element.
     * After this event gets triggered, a separate data-attribute will set.
     * This helps in combination with :not to prevent :hover from staying active after
     * the element got touched.
     *
     * E.g.:
     * :not([data-touch-hover--mobile]):hover, [data-touch-hover] { ... }
     */
    if ('mobileFlag' in binding.modifiers && binding.modifiers.mobileFlag) {
      el.addEventListener(
        'touchstart',
        setAttrOnElement.bind(this, el, `${attrName}--mobile`),
        detectPassiveEvents.hasSupport ? { passive: true } : false
      );
    }
  },
  unbind(el, binding) {
    const attrName = binding.value || 'data-touch-hover';

    el.removeEventListener(
      'touchstart',
      setAttrOnElement.bind(this, el, attrName),
      detectPassiveEvents.hasSupport ? { passive: true } : false
    );
    el.removeEventListener('touchend', removeAttrFromElement.bind(this, el, attrName));
    el.removeEventListener('touchcancel', removeAttrFromElement.bind(this, el, attrName));
  },
};
