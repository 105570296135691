<template>
  <ContentLink
    :editable="false"
    class="nolte-teaser"
    v-touch-hover
    :field="{
      value: {
        href: linkUrl,
        title: linkTitle,
        linktype: linkExternal ? 'external' : 'internal',
        target: target,
      },
    }"
  >
    <EditorialImage
      class="nolte-teaser__image"
      :media="image"
      fit="cover"
      :default-sizes="defaultSize"
      :default-aspect-ratio="defaultAspectRatio"
      :editable="false"
    />

    <div class="nolte-teaser__description-wrapper">
      <div class="nolte-teaser__description">
        <component class="nolte-teaser__headline" :is="headlineLevel">{{ headline }}</component>
        <p v-if="copy" class="nolte-teaser__copy">{{ copy }}</p>
      </div>
    </div>
  </ContentLink>
</template>

<script>
import ContentLink from '@/components/ContentLink';
import EditorialImage from '@/components/EditorialImage';

import '../keyboard-modality';
import { touchHover } from '../directives';

export default {
  name: 'NolteTeaser',

  directives: {
    touchHover,
  },

  components: {
    ContentLink,
    EditorialImage,
  },

  props: {
    linkUrl: {
      type: String,
      required: true,
    },
    target: {
      type: String,
      default: undefined,
    },
    linkTitle: {
      type: String,
      default: undefined,
    },
    image: {
      type: Object,
      required: true,
    },
    headline: {
      type: String,
      required: true,
    },
    headlineLevel: {
      type: String,
      default: 'h3',
    },
    copy: {
      type: String,
      default: undefined,
    },
    linkExternal: {
      type: Boolean,
      default: false,
    },
    aspectRatio: {
      type: Object,
      default: undefined,
    },
  },

  computed: {
    defaultSize() {
      return this.aspectRatio?.size === 'large'
        ? '(min-width: 1024px) 66vw, (min-width: 768px) 50vw, 100vw'
        : '(min-width: 1024px) 33vw, (min-width: 768px) 50vw, 100vw';
    },

    defaultAspectRatio() {
      return this.aspectRatio?.value || '3:2';
    },
  },
};
</script>

<style lang="scss" scoped>
.nolte-teaser {
  display: block;
  position: relative;
  height: 100%;
}

.nolte-teaser__image {
  width: 100%;
  height: 100%;

  .editorial-feed & {
    display: inline;
  }
}

.nolte-teaser__description-wrapper {
  @include helper__transition(opacity, 500ms);
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  padding: $unit-base;

  // easing gradient via post-css
  // https://github.com/larsenwork/postcss-easing-gradients
  background: linear-gradient(
    157deg,
    rgba($color-black, 0.7),
    ease-in-out,
    rgba($color-black, 0.1)
  );
  color: $color-white;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  opacity: 0;
  user-select: none;
  pointer-events: none;

  @include helper__greater($bp-425) {
    padding-left: $unit-base * 3;
    padding-right: $unit-base * 3;
  }
}

.nolte-teaser__description {
  max-width: 450px;
  margin: 0 auto;
  text-align: center;
}

.nolte-teaser[data-touch-hover] .nolte-teaser__description-wrapper,
.nolte-teaser:hover .nolte-teaser__description-wrapper,
// Style when focus by keyboard interaction
body[modality='keyboard'] .teaser:focus .nolte-teaser__description-wrapper {
  opacity: 1;
  user-select: auto;
  pointer-events: auto;
}

.nolte-teaser__headline {
  @include text-h3;
  margin-bottom: $unit-half;
}

.nolte-teaser__copy {
  @include text-copy;
}
</style>
