<template>
  <div class="editorial-international-projects-header">
    <div class="editorial-international-projects-header__background">
      <div class="editorial-international-projects-header__image-wrapper">
        <EditorialImage
          class="editorial-international-projects-header__image"
          :media="headerImage"
          :sources="{
            '(min-width: 769px)': '21:9',
            '(min-width: 426px)': '16:10',
          }"
          default-aspect-ratio="3:4"
          fit="cover"
        />
        <div class="editorial-international-projects-header__horizontal-gradients" />
        <div class="editorial-international-projects-header__vertical-gradient" />
      </div>
    </div>
    <div class="editorial-international-projects-header__wave">
      <BackgroundWaveBottom class="editorial-international-projects-header__wave-svg" />
    </div>
    <div class="editorial-international-projects-header__inner">
      <div class="editorial-international-projects-header__content" v-if="datasource">
        <sc-text
          class="editorial-international-projects-header__headline"
          :field="datasource.headline"
          tag="h1"
        />
        <sc-text
          class="editorial-international-projects-header__copy"
          :field="datasource.copy"
          tag="p"
        />
      </div>
      <NolteNavImagePill
        class="editorial-international-projects-header__nav"
        :links="regionLinks"
        ref="navImagePill"
      />
    </div>

    <NolteStickyMenu :show-if-possible="isNavImagePillHidden" :is-mobile="isMobile">
      <NolteStickyMenuTabs :links="navLinks" :styled="isMobile" />
    </NolteStickyMenu>
  </div>
</template>

<script>
import { Text } from '@sitecore-jss/sitecore-jss-vue';
import { mapGetters } from 'vuex';
import 'intersection-observer';

import NolteNavImagePill from '../nolte-ui/NolteNavImagePill';
import BackgroundWaveBottom from '../assets/background_wave_bottom.svg?inline';
import NolteStickyMenu from '../nolte-ui/NolteStickyMenu';
import NolteStickyMenuTabs from '../nolte-ui/NolteStickyMenu/NolteStickyMenuTabs';
import intersectionObserverHelper from '../nolte-ui/NolteStickyMenu/helper.js';
import EditorialImage from '@/components/EditorialImage';
import eventBus from '@/lib/eventBus';

export default {
  name: 'EditorialInternationalProjectsHeader',

  components: {
    ScText: Text,
    NolteNavImagePill,
    BackgroundWaveBottom,
    NolteStickyMenu,
    NolteStickyMenuTabs,
    EditorialImage,
  },

  data() {
    return {
      isNavImagePillHidden: false,
    };
  },

  computed: {
    ...mapGetters(['isMobile']),

    regionLinks() {
      const items = this.fields?.data?.navigation?.parent?.children;

      return items.map(item => {
        return {
          label: item.navigationTitle?.value,
          href: item.url,
          image: item.headerImage?.jss,
        };
      });
    },

    datasource() {
      return this.fields?.data?.datasource?.fields?.reduce((items, { jss, name }) => {
        items[name] = jss;
        return items;
      }, {});
    },

    headerImage() {
      // check if a headerImage was passed via fields otherwise use the headerImage of the route definition
      return this.datasource?.headerImage?.value?.src
        ? this.datasource?.headerImage
        : this.fields?.data?.headerImage?.headerImage?.jss;
    },

    navLinks() {
      return this.regionLinks?.map(({ label, href }) => ({ label, linkUrl: href }));
    },
  },

  mounted() {
    intersectionObserverHelper.call(
      this,
      { rootMargin: `${this.isMobile ? '-100px' : '-170px'} 0px 0px 0px` },
      this.$refs?.navImagePill?.$el,
      this.intersectionObserverCallback
    );
  },
  beforeUnmount() {
    eventBus.$emit('intersectionObserverHelper:beforeUnmount');
  },
  methods: {
    intersectionObserverCallback(entries) {
      entries.forEach(entry => {
        this.isNavImagePillHidden = !entry.isIntersecting;
      });
    },
  },
};
</script>

<style lang="scss" scoped>
$shadow-depth: 300px;

.editorial-international-projects-header {
  position: relative;
  margin-bottom: $unit-base * 3;
}

.editorial-international-projects-header__background {
  background-color: $color-tuatara-dark;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.editorial-international-projects-header__image {
  @include helper__greater($bp-1440) {
    height: 100%;
  }

  &::after {
    content: '';
    position: absolute;
    left: 0;
    right: 0;
  }

  &::after {
    bottom: 0;
    height: 40%;
    background: linear-gradient(
      to top,
      rgba($color-tuatara-dark, 1),
      ease-in-out,
      rgba($color-tuatara-dark, 0)
    );
  }
}

.editorial-international-projects-header__horizontal-gradients {
  position: relative;
  z-index: 2;
  height: 100%;

  &::before,
  &::after {
    content: '';
    position: absolute;
    top: 0;
    bottom: 0;
    width: $shadow-depth;
  }

  &::before {
    background: linear-gradient(
      to right,
      rgba($color-tuatara-dark, 1),
      ease-in-out,
      rgba($color-tuatara-dark, 0)
    );
  }

  &::after {
    right: 0;
    background: linear-gradient(
      to left,
      rgba($color-tuatara-dark, 1),
      ease-in-out,
      rgba($color-tuatara-dark, 0)
    );
  }

  @include helper__until(2200px) {
    display: none;
  }
}

.editorial-international-projects-header__vertical-gradient {
  position: relative;
  z-index: 2;
  height: 100%;

  &::before,
  &::after {
    content: '';
    position: absolute;
    left: 0;
    right: 0;
  }

  &::before {
    bottom: 100px;
    height: 40%;
    background: linear-gradient(
      to top,
      rgba($color-tuatara-dark, 1),
      ease-in-out,
      rgba($color-tuatara-dark, 0)
    );
  }

  &::after {
    bottom: 0;
    height: 100px;
    background-color: $color-tuatara-dark;
  }
}

.editorial-international-projects-header__image-wrapper {
  @include helper__aspect-ratio(9 / 16);
  max-width: 2200px;
  margin-left: auto;
  margin-right: auto;
  height: 100%;
  overflow: hidden;

  @include helper__greater($bp-425) {
    @include helper__aspect-ratio(16 / 10);
  }

  @include helper__greater($bp-768) {
    @include helper__aspect-ratio(21 / 9);
  }

  &::before {
    content: '';
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background-color: rgba($color-tuatara, 0.4);
    z-index: 1;
  }
}

.editorial-international-projects-header__wave {
  color: $color-tuatara-dark;
  width: 100%;
  position: absolute;
  bottom: 0;
  transform: translateY(100%);
  overflow: hidden;
}

.editorial-international-projects-header__wave-svg {
  width: 100%;
  min-width: 1400px;
}

.editorial-international-projects-header__inner {
  position: relative;
  z-index: 3;
}

.editorial-international-projects-header__content {
  position: relative;
  padding: $unit-double $unit-base;
  color: $color-white;
  display: flex;
  max-width: $grid-width;
  margin-left: auto;
  margin-right: auto;
  flex-direction: column;

  @include helper__greater($bp-768) {
    flex-direction: row;
    padding: $unit-double * 4 $unit-base * 4 $unit-base * 6;
    align-items: center;
  }

  @include helper__greater($bp-1024) {
    padding: $unit-double * 6 $unit-base * 8 $unit-base * 6;
  }
}

.editorial-international-projects-header__headline {
  @include text-header-hero;
  flex: 1 auto;
  margin-bottom: $unit-base;

  @include helper__greater($bp-768) {
    margin-right: $unit-base * 2;
    margin-bottom: 0;
    word-wrap: normal;
  }
}

.editorial-international-projects-header__copy {
  @include text-copy;
  flex: 1 auto;
}

.editorial-international-projects-header__nav {
  margin-left: auto;
  margin-right: auto;
  padding-bottom: $unit-double;
  line-height: 1.3;
}
</style>
