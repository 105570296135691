<template>
  <NolteMoodDetail
    v-if="!isEditing"
    :header-image="headerImage"
    :title="label"
    :description="description"
    :product-variants="productVariants"
    :images="images"
    :images-default-sizes="imagesDefaultSizes"
    :highlights="highlights"
    :parent-ref="parentRef"
    :planning-alternatives-headline="planningAlternativesHeadline"
    :planning-alternatives-copy="planningAlternativesCopy"
    :highlight-group-headline="highlightGroupHeadline"
    :rendering="rendering"
    :planning-alternatives="planningAlternativesPlaceholder"
    :related-moods-headline="$t('mood-detail-related-moods-headline')"
    :related-moods="relatedMoods"
  />

  <div v-else class="nolte-mood-detail">
    <div class="nolte-mood-detail__header">
      <div class="nolte-mood-detail__header-image-wrapper">
        <EditorialImage
          img-class="nolte-mood-detail__header-image"
          :media="routeFields['Header Image']"
          :sources="{
            '(min-width: 769px)': {
              aspectRatio: '21:9',
              sizes: '(min-width: 1440px) 1440px, 100vw',
            },
          }"
          default-aspect-ratio="16:10"
          default-sizes="100vw"
          fit="cover"
          :editable="false"
        />
      </div>

      <!-- We need to reference the product variants -->
      <NolteMoodProductVariants
        v-if="productVariants && productVariants.length"
        class="nolte-mood-detail__product-variants"
        :product-variants="productVariants"
      />

      <div class="nolte-mood-detail__introduction">
        <ContentLink
          v-if="parentRef.url"
          class="nolte-mood-detail__breadcrumb"
          :field="{ value: { text: parentRef.name, href: parentRef.url, linktype: 'internal' } }"
        />
        <NolteHeadline class="nolte-mood-detail__headline" level="h1">
          <sc-text :field="routeFields.label" />
        </NolteHeadline>
        <sc-text class="nolte-mood-detail__description" :field="routeFields.description" tag="p" />
      </div>
    </div>

    <p v-if="images && images.length" class="nolte-mood-detail__gallery-headline">
      {{ $t('mood-detail-gallery-headline') }}
    </p>

    <!-- Gallery type slider -->
    <div class="nolte-mood-detail__gallery-wrapper">
      <NolteScroller class="nolte-mood-detail__gallery">
        <EditorialImage
          v-for="(image, index) in images"
          :key="`image-${index}`"
          class="nolte-mood-detail__gallery-image"
          :media="image"
          default-aspect-ratio="3:2"
          :default-sizes="imagesDefaultSizes[index]"
          :editable="false"
        />
      </NolteScroller>
    </div>

    <BackgroundWave class="nolte-mood-detail__background-wave" />

    <div class="nolte-mood-detail__secondary-background">
      <div class="nolte-mood-detail__planning-alternatives-wrapper">
        <NolteHeadline class="nolte-mood-detail__planning-alternatives__headline" level="h2">
          {{ planningAlternativesHeadline }}
        </NolteHeadline>
        <p class="nolte-mood-detail__planning-alternatives__copy">
          {{ planningAlternativesCopy }}
        </p>
      </div>
      <EditorialPlanningAlternativeGroup
        class="nolte-mood-detail__planning-alternatives"
        :rendering="rendering"
        :planning-alternatives="planningAlternativesPlaceholder"
      />

      <div
        v-if="highlights && highlights.length"
        class="nolte-mood-detail__highlight-group-wrapper"
      >
        <NolteHeadline level="h2" class="nolte-mood-detail__highlight-headline">
          {{ highlightGroupHeadline }}
        </NolteHeadline>
        <NolteHighlightGroup>
          <NolteHighlight
            v-for="(highlight, index) in highlights"
            :key="`highlight-${index}`"
            :image="highlight.image"
            :headline="highlight.headline"
            :copy="highlight.copy"
          />
        </NolteHighlightGroup>
      </div>

      <div class="nolte-mood-detail__related-moods" v-if="relatedMoods && relatedMoods.length">
        <EditorialHeadline
          class="nolte-mood-detail__related-moods_headline"
          :fields="{ headline: { value: $t('mood-detail-related-moods-headline') } }"
        />

        <NolteScroller>
          <div v-for="(product, index) in relatedMoods" :key="`relatedproduct-${index}`">
            <EditorialTeaser :fields="product" :editable="false" />
          </div>
        </NolteScroller>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapState } from 'vuex';
import { Text } from '@sitecore-jss/sitecore-jss-vue';
import NolteMoodProductVariants from '../nolte-ui/NolteMoodProductVariants';
import NolteHighlightGroup from '../nolte-ui/NolteHighlightGroup';
import NolteHighlight from '../nolte-ui/NolteHighlight';
import NolteMoodDetail from '../nolte-ui/NolteMoodDetail';
import NolteHeadline from '../nolte-ui/NolteHeadline';
import NolteScroller from '../nolte-ui/NolteScroller';
import BackgroundWave from '../assets/background_wave_2.svg?inline';
import ContentLink from '@/components/ContentLink';
import EditorialPlanningAlternativeGroup from './EditorialPlanningAlternativeGroup';
import EditorialImage from './EditorialImage';
import EditorialHeadline from '@/components/EditorialHeadline';
import EditorialTeaser from '@/components/EditorialTeaser';

import { useScreen } from 'vue-screen';

const mapField = (field, mapFunction) => {
  if (!field || !field.length || typeof mapFunction !== 'function') {
    return [];
  }
  return field.map(mapFunction);
};

export default {
  name: 'EditorialMoodDetail',

  setup() {
    const screen = useScreen();

    return {
      screen,
    };
  },

  components: {
    ScText: Text,
    NolteMoodProductVariants,
    NolteHighlightGroup,
    NolteHighlight,
    NolteMoodDetail,
    NolteHeadline,
    NolteScroller,
    BackgroundWave,
    ContentLink,
    EditorialPlanningAlternativeGroup,
    EditorialImage,
    EditorialHeadline,
    EditorialTeaser,
  },

  props: {
    rendering: {
      type: Object,
      default: undefined,
    },
  },

  computed: {
    ...mapGetters('jss', ['isEditing']),
    ...mapState('jss', ['routeData']),
    ...mapState(['referrer']),

    routeFields() {
      return this.routeData?.fields;
    },
    parentRef() {
      const parentUrl = this.fields?.data?.item?.parent?.url;
      return {
        name: this.fields?.data?.item?.parent?.title?.value,
        url: this.referrer?.indexOf(parentUrl) >= 0 ? this.referrer : parentUrl,
      };
    },
    headerImage() {
      return this.routeFields['Header Image'];
    },
    label() {
      return this.routeFields?.label?.value;
    },
    description() {
      return this.routeFields?.description?.value;
    },
    productVariants() {
      return mapField(this.fields?.data?.productVariants?.variants, variant => {
        return {
          url: variant?.url,
          variantLabel: variant?.label,
          internalTag: variant?.internalTags,
          productLabel: variant?.productLabel,
          variantImage: variant?.previewImage.jss?.value,
          showProductInformation: variant?.showProductInformation === 'true',
          categoryLabel: variant?.categoryLabel,
        };
      });
    },
    highlights() {
      return mapField(this.routeData?.fields?.Highlights, highlight => ({
        image: highlight?.fields?.Image,
        headline: highlight?.fields?.Headline?.value,
        copy: highlight?.fields?.Copy.value,
      }));
    },
    images() {
      return mapField(this.routeData?.fields['Gallery Images'], image => image.fields?.Image.value);
    },
    imagesDefaultSizes() {
      return this.images.map(image => {
        const galleryHeight = this.screen?.width > 1024 ? 600 : 320;
        const calculatedWidth = Math.ceil(galleryHeight * (image.width / image.height));
        return `${calculatedWidth}px`;
      });
    },
    planningAlternativesHeadline() {
      return this.$t('mood-detail-planning-alternatives-headline');
    },
    planningAlternativesCopy() {
      return this.$t('mood-detail-planning-alternatives-copy');
    },
    planningAlternativesPlaceholder() {
      return this.rendering.placeholders['jss-mood-detail-planning-alternatives'];
    },
    highlightGroupHeadline() {
      return this.$t('mood-detail-highlight-group-headline');
    },
    relatedMoods() {
      const related = this.fields?.data?.moodDetail?.relatedProducts?.targetItems;
      // adjust data for EditorialTeaser
      return related.length
        ? related.map(item => ({
            headline: {
              value:
                item.template?.id === '096018C08E9B563FB662D9FD9373ADCC' // MoodDetail
                  ? this.$t('cataloghub-planning-example')
                  : item.category?.label?.value,
            },
            copy: item.label,
            link: { value: { href: item.url } },
            image: { value: item.teaserImage?.jss?.value },
          }))
        : [];
    },
  },

  /*withPlaceholder: {
    placeholders: [
      {
        placeholder: 'jss-mood-detail-planning-alternatives',
        computedPropName: 'planningAlternativesPlaceholder',
      },
    ],
  },*/
};
</script>

<style lang="scss" scoped>
.nolte-mood-detail__header {
  max-width: $grid-width;
  margin-left: auto;
  margin-right: auto;

  // fix issue with overlay under image
  // swiper has a z-index of 1
  position: relative;
  z-index: 10;
}

.nolte-mood-detail__header-image-wrapper {
  @include helper__aspect-ratio(16 / 10);

  @include helper__greater($bp-768) {
    @include helper__aspect-ratio(21 / 9);
  }

  & > * {
    height: 100%;
  }

  & > .editorial-image {
    height: 100%;
  }
}

.nolte-mood-detail__introduction {
  @include spacing;
  margin-bottom: $unit-double * 2;

  @include helper__greater($bp-768) {
    margin-bottom: $unit-double * 4;
  }
}

.nolte-mood-detail__headline {
  @include text-hero;
  max-width: $bp-768;
}

.nolte-mood-detail__description {
  @include text-intro;
  max-width: $bp-768;
  margin-top: $unit-base;
}

.nolte-mood-detail__breadcrumb {
  @include breadcrumb;
  max-width: $bp-768;
}

.nolte-mood-detail__gallery-headline {
  @include text-h3;
  @include spacing;
}

.nolte-mood-detail__related-moods_headline {
  @include text-h3;
  @include spacing;
}

.nolte-mood-detail__gallery-wrapper {
  @include helper__greater($bp-1024) {
    // we align the start of the viewport vertically with the main content
    padding-left: calc((100vw - 1200px) / 2);
    padding-right: calc((100vw - 1200px) / 2);
  }
}

.nolte-mood-detail__gallery-image {
  width: auto;
  max-width: calc(100vw - #{$unit-double});

  & + & {
    margin-left: $unit-half;
  }
}

.nolte-mood-detail__highlight-group-wrapper {
  padding-top: $unit-base * 4;

  @include helper__greater($bp-768) {
    padding-top: $unit-base * 4;
  }
}

.nolte-mood-detail__highlight-headline {
  @include text-hero;
  @include spacing;
}

.nolte-mood-detail__background-wave {
  width: 100%;
  color: $color-concret;
  margin-top: $unit-base * 4;
  height: 24px;

  @include helper__greater($bp-768) {
    height: auto;
  }
}

.nolte-mood-detail__secondary-background {
  background-color: $color-concret;
}

.editorial-media-detail-group {
  margin: 0 auto;
}

.nolte-mood-detail__planning-alternatives-wrapper {
  max-width: 850px;
  margin: 0 auto;
  padding: $unit-double $unit-base;
  box-sizing: content-box;

  @include helper__greater($bp-768) {
    padding: $unit-double * 2 $unit-base;
  }
}

.nolte-mood-detail__planning-alternatives__copy {
  margin-top: $unit-base;
  margin-bottom: $unit-double;
}

.nolte-mood-detail__highlight-offset {
  margin: 0 !important;
}

.nolte-mood-detail__planning-alternatives {
  max-width: 850px;
}

.nolte-mood-detail__related-moods {
  padding: 0 0 $unit-base;

  @include helper__greater($bp-600) {
    padding: 0 0 $unit-half;
  }

  img {
    max-width: 500px;
  }
  .editorial-headline {
    @include text-h3;
    @include spacing;
  }
}
</style>
