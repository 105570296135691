<template>
  <nav class="anchor-navigation viewport-narrow" ref="wrapper">
    <div ref="logo" class="logo">
      <a href="#top">
        <NolteNeoLogo v-if="isNeoPage" ref="logo" class="img" />
        <NolteLogo v-else ref="logo" class="img" />
      </a>
    </div>

    <NolteScroller :show-arrows="true" class="anchor-group" :name="{ getCurrentAnchorName }">
      <ul class="navigation-list" ref="navigation">
        <li
          class="anchor-item"
          v-for="(item, index) in items"
          :key="index"
          :class="{ active: item.active }"
        >
          <a :href="item.url.href" class="anchor-item-label">
            {{ item.title }}
          </a>
        </li>
      </ul>
    </NolteScroller>
  </nav>
</template>
<script>
import { mapGetters } from 'vuex';
import NolteScroller from '../nolte-ui/NolteScroller';
import NolteNeoLogo from '../assets/images/nolte-neo-logo.svg?inline';
import NolteLogo from '../assets/images/logo.svg?inline';
import eventBus from '@/lib/eventBus';
import 'intersection-observer';

export default {
  name: 'GlobalAnchorNavigation',

  components: { NolteScroller, NolteNeoLogo, NolteLogo },
  data: () => ({
    items: {},
    startY: 0,
    scrollingUp: false,
    currentAnchorName: '',
  }),
  computed: {
    ...mapGetters(['isMobile']),
    ...mapGetters('jss', ['isNeoPage']),
    isScrollingUp() {
      return this.scrollingUp;
    },
    getCurrentAnchorName() {
      return this.currentAnchorName;
    },
  },
  updated() {
    const wrapperElement = this.$el.querySelector('.swiper-wrapper');
    const anchorElement = this.$el.querySelector('.anchor-item.active');
    const slideElement = this.$el.querySelector('.swiper-slide');

    // "slide" it into the viewport
    if (anchorElement && wrapperElement && slideElement) {
      if (wrapperElement.offsetWidth <= slideElement.offsetWidth && typeof window !== 'undefined') {
        let offset = anchorElement.offsetLeft - (window.innerWidth - wrapperElement.offsetWidth); // adds some margin to the left
        wrapperElement.style.transition = 'transform .3s';

        if (offset < 0) {
          offset = 0;
        }
        wrapperElement.style.transform = `translate(-${offset}px, 0px)`;
      }
    }
    this.handleAnchorNavigationWidth();
  },
  mounted() {
    this.items = this.initItems();
    this.initScrollSpy();
    if (typeof window !== 'undefined') {
      window.addEventListener('resize', this.handleAnchorNavigationWidth);
      window.addEventListener('scroll', this.handleScroll);
    }
    this.handleAnchorNavigationWidth();
  },
  beforeUnmount() {
    eventBus.$emit('GlobalAnchorNavigation:beforeUnmount');
    if (typeof window !== 'undefined') {
      window.removeEventListener('resize', this.handleAnchorNavigationWidth);
      window.removeEventListener('scroll', this.handleScroll);
    }
  },
  methods: {
    initItems() {
      const items = {};
      [].forEach.call(
        document.querySelectorAll('a.content-anchor[name][data-title]'),
        ($el, index) => {
          const url = new URL(location.href);
          const hash = $el.getAttribute('name');
          url.hash = hash;

          items[hash] = {
            title: $el.getAttribute('data-title'),
            url,
            el: $el,
            active: index === 0,
          };
        }
      );
      return items;
    },
    setItemActive(currentAnchorName) {
      this.currentAnchorName = currentAnchorName;
      for (const anchorName in this.items) {
        this.items[anchorName].active = false;
      }
      this.items[currentAnchorName].active = true;
    },
    setPreviousItemActive(anchorNameLeft) {
      this.currentAnchorName = anchorNameLeft;
      let previous;
      for (const anchorName in this.items) {
        if (anchorName === anchorNameLeft && this.items[previous]) {
          this.items[previous].active = true;
        }
        this.items[anchorName].active = false;
        previous = anchorName;
      }
    },
    handleAnchorNavigationWidth() {
      let navipluslogo = this.$refs?.navigation?.clientWidth + this.$refs?.logo?.clientWidth + 120;

      if (typeof window !== 'undefined' && navipluslogo < window.innerWidth) {
        // reset to center view
        this.$el.querySelector('.swiper-wrapper')?.removeAttribute('style');
        this.$refs?.wrapper?.classList?.remove('viewport-narrow');
      } else {
        this.$refs?.wrapper?.classList?.add('viewport-narrow');
      }
    },
    handleScroll: function () {
      if (typeof window !== 'undefined') {
        const scrollY = window.scrollY;
        if (scrollY > this.startY) {
          this.scrollingUp = false;
        } else {
          this.scrollingUp = true;
        }
        this.startY = scrollY;
      }
    },
    initScrollSpy() {
      const observer = new IntersectionObserver(
        entries => {
          entries.forEach(entry => {
            let anchorName = entry.target.getAttribute('name');
            if (anchorName) {
              if (entry.intersectionRatio === 1) {
                this.setItemActive(anchorName);
              } else {
                if (this.scrollingUp) {
                  this.setPreviousItemActive(anchorName);
                }
              }
            }
          });
        },
        { rootMargin: '0px 0px -50% 0px' }
      );

      for (const hash in this.items) {
        observer.observe(this.items[hash].el);
      }

      eventBus.$once('GlobalAnchorNavigation:beforeUnmount', () => {
        for (const hash in this.items) {
          if (this.items[hash] && this.items[hash].el) {
            observer.unobserve(this.items[hash].el);
          }
        }
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.anchor-navigation {
  position: fixed;
  top: 0;
  width: 100%;
  height: $anchor-navigation-height-mobile;
  z-index: 21;
  display: flex;
  box-shadow: 0px 3px 6px #00000029;

  @include helper__greater($bp-768) {
    height: $anchor-navigation-height;
  }
}

.anchor-group {
  //display: flex;
  //flex: 1 1 auto;
  justify-content: center;
  margin-left: auto;
  margin-right: auto;

  &::before {
    background: linear-gradient(90deg, $color-neo 0%, rgba($color-neo, 0) 100%);
  }
  &::after {
    background: linear-gradient(270deg, $color-neo 0%, rgba($color-neo, 0) 100%);
  }

  .viewport-narrow {
    justify-content: left;
    margin-left: 0px;
  }

  ul {
    display: flex;
    flex-direction: row;

    li {
      margin: auto 8px;

      &:last-child {
        margin: auto 0px auto 8px;
      }

      @include helper__greater($bp-768) {
        margin: auto 20px;

        &:last-child {
          margin: auto 0px auto 20px;
        }
      }
    }
  }
}

// neo
html.template--neopage .anchor-item {
  cursor: pointer;
  display: flex;
  align-items: stretch;
  height: 100%;

  border-bottom: 8px solid $color-neo;

  &.active {
    border-bottom: 8px solid $color-aureolin;
    & .anchor-item-label {
      color: $color-white;
    }
  }

  &:hover {
    & .anchor-item-label {
      color: $color-white;
    }
  }
}

// landingpage
html.template--landingpage .anchor-item {
  cursor: pointer;
  display: flex;
  align-items: stretch;
  height: 100%;

  border-bottom: 8px solid $color-white;

  &.active {
    border-bottom: 8px solid $color-aureolin;
    & .anchor-item-label {
      color: $color-black;
    }
  }

  &:hover {
    & .anchor-item-label {
      color: $color-black;
    }
  }
}

.anchor-item-label {
  @include text-h5;
  @include helper__transition;
  font-family: $font-futura-pt-demi;
  font-weight: normal;
  color: #7c7c7b;
  margin-top: 8px;

  display: flex;
  align-items: center;
}

.logo {
  //position: absolute;
  position: absolute;
  top: 20px;
  left: 0;

  margin: auto;

  min-width: 114px;

  padding-right: 16px;
  padding-left: 16px;

  & .img {
    height: 14px !important;
    margin-left: -2px;
  }

  z-index: 22 !important;
  z-index: inherit;
  cursor: pointer;

  @include helper__greater($bp-768) {
    min-width: 180px;
    & .img {
      margin-left: 14px;
      height: 20px !important;
    }
  }
  @include helper__greater($bp-1024) {
    padding-left: 0px;
    & .img {
      height: 20px;
      margin-left: 34px;
    }
  }
}

.template--landingpage .logo {
  & .img {
    height: 30px;
    margin-left: 15px;
  }

  @include helper__greater($bp-768) {
    & .img {
      margin-left: 14px;
    }
  }
  @include helper__greater($bp-1024) {
    & .img {
      height: 35px;
      margin-left: 34px;
    }
  }
}

.nolte-scroller {
  @include helper__greater($bp-768) {
    padding-left: 16px;
  }

  :deep {
    .swiper-wrapper {
      width: auto;
      margin-left: 16px;
      //padding-left: 16px;
    }
    .navigation {
      display: none;
    }
  }
}

.viewport-narrow {
  .logo {
    position: relative;

    top: -1px;

    @include helper__greater($bp-768) {
      top: 0px;
    }
    left: 0;
  }

  :deep {
    .swiper-wrapper {
      width: 100%;
    }
    .navigation {
      display: none;
    }
  }
}

// styles for neo
html.template--neopage .anchor-navigation {
  background-color: $color-neo;
}

html.template--landingpage .anchor-navigation {
  background-color: $color-white;
}
</style>

<style lang="scss">
html.template--neopage.anchor-navigation--visible {
  scroll-padding-top: $anchor-navigation-height-mobile;
  @include helper__greater($bp-768) {
    scroll-padding-top: $anchor-navigation-height;
  }
}

html.template--landingpage.anchor-navigation--visible {
  scroll-padding-top: $anchor-navigation-height-mobile;
  @include helper__greater($bp-768) {
    scroll-padding-top: $anchor-navigation-height;
  }
}
</style>
