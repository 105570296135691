<template>
  <div class="global-form">
    <div v-if="errorMessage === 'no_metadata'">
      <p>
        No form data found. Maybe you have to configure the datasource of this component to the form
        item to use:
      </p>
      <p>
        Click on this component › <code>More</code> › <code>Edit component properties</code> ›
        <code>Data Source</code> › Choose the desired form.
      </p>
    </div>
    <div v-else-if="errorMessage === 'wrong_mode'">
      <p>
        The form can only be displayed in JSS application modes "Connected dev mode" or "Integrated
        mode".
      </p>
      <p>
        To use it in "Disconnected mode" you have to mock form data in
        <code>disconnected-mode-proxy.js</code> › <code>customizeRendering()</code>.
      </p>
    </div>
    <div v-else-if="errorMessage === 'live_error'">
      <p>The form cannot be displayed at the moment.</p>
    </div>
    <sitecore-form v-else :page="fields" />
  </div>
</template>

<script>
import { mapState, mapGetters } from 'vuex';
import eventBus from '@/lib/eventBus';
import { mapMutations } from 'vuex';

// const SitecoreForm = () => import(/* webpackChunkName: 'SitecoreForm' */ '../forms/structure/Page');
import SitecoreForm from '../forms/structure/Page';
//import ContentLightbox from '@/components/ContentLightbox';
// ATTENTION: "Html Text" and "LabelWithHtml" render HTML and are not filtered for allowed HTML.
// Lost all definitions by entering only a closing div in "Html Text" field.

export default {
  name: 'GlobalForm',

  components: {
    SitecoreForm,
  },

  computed: {
    ...mapState('jss', ['sitecoreContext']),
    ...mapGetters('jss', ['isConnected', 'isEditing']),

    errorMessage() {
      if (this.isConnected && !this.fields?.metadata) {
        if (this.isEditing) {
          return 'no_metadata';
        } else {
          return 'live_error';
        }
      } else if (!this.isConnected && !this.fields?.metadata) {
        return 'wrong_mode';
      }
      return false;
    },
  },
  created() {
    eventBus.$on(this.fields.antiForgeryToken.value + ':invalid-session', () => {
      this.saveInvalidSessionToStore(true);
    });
  },
  methods: {
    ...mapMutations(['setInvalidSession']),
    saveInvalidSessionToStore(status) {
      this.setInvalidSession(status);
    },
  },
  mounted() {
    this.$emit('GlobalForm:mounted', this.$el);

    function updateHeight(item) {
      if (item.classList.contains('form-item-is-hidden')) {
        item.style.height = item.scrollHeight + 'px';
      }
    }

    // Allow to animate the height of the ".form-field"s via CSS transition.
    const items = [].slice.call(document.querySelectorAll('.form-field'));
    items.forEach(item => {
      updateHeight(item);

      // We use a observer to check when a field is getting hidden. In this case we set the height
      const observer = new MutationObserver(mutations => {
        mutations.forEach(mutation => {
          updateHeight(mutation.target);
        });
      });
      observer.observe(item, { attributes: true });

      eventBus.$once('GlobalForm:beforeUnmount', () => {
        // Mutation Observer does not have Method unobserve(), but disconnect()!
        // observer.unobserve();
        observer.disconnect();
      });
    });

    items.forEach(item => {
      item.style.transition = 'height 0.3s';
    });
  },
  beforeUnmount() {
    eventBus.$emit('GlobalForm:beforeUnmount');
  },
};
</script>

<style lang="scss" scoped>
.global-form {
  @include spacing;
  background: $color-concret;
  color: $color-tuatara;

  :deep() {
    .form-item-is-hidden {
      height: 0 !important;
      overflow: hidden;
    }

    .form-fields {
      margin: 0 auto;

      // Optimal line length
      // https://baymard.com/blog/line-length-readability
      // max-width: 55ch;
      // As seen in comment 1 in https://caniuse.com/#feat=ch-unit IE 11 does not support ch correctly
      max-width: 560px;
    }

    form.fullwidth {
      .form-fields {
        margin: 0 auto;
        max-width: 1200px;

        @include helper__greater($bp-768) {
          display: flex;
          flex-wrap: wrap;
        }
      }
    }

    .halfAvailableWidthLeft {
      max-width: 100%;
      @include helper__greater($bp-768) {
        flex: 0 0 50%;
        max-width: 50%;
        padding-right: $unit-quadruple;
      }
    }

    .halfAvailableWidthRight {
      max-width: 100%;
      @include helper__greater($bp-768) {
        flex: 0 0 50%;
        max-width: 50%;
        padding-left: $unit-quadruple;
      }
    }

    .form-field {
      display: flex;
      flex-direction: column;
      margin: $unit-base 0;
    }

    .form-buttons {
      width: 100%;
      display: flex;
      justify-content: flex-end;

      .form-fields {
        margin: 0 !important;
      }

      .form-field-element {
        display: flex;
      }

      .nolte-button {
        margin: 0 0 0 $unit-base;
      }

      @include helper__until($bp-768) {
        .form-fields {
          width: 100%;
        }
        .nolte-form-wrapper {
          flex: 0 1 50%;
        }
        .nolte-form-wrapper:first-child {
          margin: 0 $unit-base 0 0;
        }
        .nolte-button {
          width: 100%;
          margin: 0;
          min-width: 0;
          max-width: none;
        }
      }
    }
  }
}

.mnk-default .global-form {
  background: none;
  @include spacing;

  :deep() {
    .left {
      margin-bottom: 0 !important;
      min-width: 100%;

      @include helper__greater($bp-768) {
        margin-right: $unit-base * 2;
        min-width: 50%;
      }

      @include helper__greater($bp-1024) {
        min-width: 60%;
      }
    }
  }
}

.form-section.progress {
  flex-basis: 100%;
  max-width: 1200px;
  display: flex;
  align-items: flex-end;

  @include helper__until($bp-1024) {
    align-items: center;
    justify-content: center;
  }

  @include helper__greater($bp-1024) {
    padding-bottom: $unit-half;
  }
}

.form-section.progress__inner {
  display: flex;
}
.form-section.progress__step {
  display: flex;
  flex-direction: column;
  h3 {
    font-weight: 700;
    font-size: 0.8em;
    padding-right: $unit-base + 38px;
    line-height: 0;
    text-transform: uppercase;
    @include helper__until($bp-1024) {
      display: none;
    }
  }
  @include helper__until($bp-1024) {
    &:first-child {
      .form-section.progress__trail {
        .trail__inner {
          display: none;
        }
      }
    }
  }
  &:not(:first-child) {
    h3 {
      padding-left: $unit-base;
    }
  }
}
.form-section.progress__trail {
  display: flex;
  align-items: center;
  color: #dedede;
  .trail__inner {
    height: $unit-half;
    background-color: #dedede;
    width: 100%;
    min-width: 64px;
  }
  &.active {
    color: $color-aureolin;
    .trail__inner {
      background-color: $color-aureolin;
    }
  }
}
.icon {
  flex-basis: 100%;
  max-width: 38px;
}
</style>
