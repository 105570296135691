<template>
  <form
    enctype="multipart/form-data"
    method="POST"
    novalidate
    :id="mirroredPage.htmlPrefix"
    :name="mirroredPage.metadata.name"
    :class="mirroredPage.metadata.cssClass"
    :styles="mirroredPage.metadata.styles"
    @submit.prevent="submit"
  >
    <!-- necessary hidden fields -->
    <v-hidden :field="mirroredPage.antiForgeryToken" />
    <v-hidden :field="mirroredPage.formSessionId" />
    <v-hidden :field="mirroredPage.formItemId" />
    <v-hidden :field="mirroredPage.pageItemId" />

    <v-fields :fields="mirroredPage.fields" :form-id="formId" />
  </form>
</template>

<script>
// https://jss.sitecore.com/docs/techniques/forms

// Tracking
// TODO: Default tracking is built-in... maybe we add specific field tracking later if there is a documentation from Sitecore

// Conditions
// TODO: Conditions have a "goto" action. This has not yet been implemented.

import VHidden from '../fields/Hidden';
import VFields from './Fields';
import { serializeForm, submitForm } from '@sitecore-jss/sitecore-jss-forms';
import config from '../../temp/config';
import eventBus from '@/lib/eventBus';
import { isProxy, toRaw } from 'vue';
import { saveStorage, clearStorage } from '@/useLocalStorage';

// if you remove this mixin you have a form without condition handling
import PageConditionMixin from './conditions/PageConditionMixin';

// if you remove this mixin you have a form without validation handling
import PageValidationMixin from './validation/PageValidationMixin';

const endpointUrl = process.env.NODE_ENV === 'production' ? '' : process.env.VUE_APP_API_BASE_URL;

export default {
  name: 'SitecoreFormsStructurePage',
  mixins: [PageConditionMixin, PageValidationMixin],

  components: {
    VHidden,
    VFields,
  },

  data() {
    // modification of the form url for local development
    let formUrl =
      process.env.NODE_ENV === 'development'
        ? `${process.env.VUE_APP_API_BASE_URL}/api/jss/formbuilder?fxb.FormItemId=`
        : `/api/jss/formbuilder?fxb.FormItemId=`;

    return {
      endpoint:
        formUrl +
        endpointUrl +
        this.page.metadata.itemId +
        '&fxb.HtmlPrefix=' +
        this.page.htmlPrefix +
        '&sc_apikey=' +
        config.sitecoreApiKey +
        '&sc_itemid=' +
        '{' +
        this.page.contextItemId +
        '}',
      mirroredPage: {},
      changedFieldsData: {},

      // we use the AntiForgeryToken to have an id other components are able to listen to via EventBus
      formId: this.page.antiForgeryToken.value,
    };
  },
  props: {
    page: {
      type: Object,
      default: undefined,
    },
  },

  created() {
    // we mirror the page to decouple the data from the passed prop
    this.mirroredPage = this.page;

    // for every event we use the formId as prefix to make sure we are able to use multiple forms on the same webpage
    eventBus.$on(this.formId + ':field-change', component => {
      if (isProxy(component.value)) {
        component.value = toRaw(component.value).value;
      }
      this.changedFieldsData[component.field.valueField.name] = component.value;
    });
  },

  mounted() {
    // allows to manually submit the form
    eventBus.$on(this.formId + ':form-submit', rerenderForm => {
      this.submit(rerenderForm);
    });

    eventBus.$on(this.formId + ':field-change', component => {
      this.changedFieldsData[component.field.valueField.name] = component.value;
    });
  },

  onBeforeUnmount() {
    eventBus.$off(this.formId + ':field-change');
    eventBus.$off(this.formId + ':field-blur');
    eventBus.$off(this.formId + ':validation:register-component');
    eventBus.$off(this.formId + ':form-submitted-failure');
    eventBus.$off(this.formId + ':conditions:register-component');
    eventBus.$off(this.formId + ':conditions:process');
  },

  methods: {
    submit(rerenderForm) {
      if (typeof rerenderForm !== 'boolean') {
        rerenderForm = true;
      }

      // jssData is the structure of the fields that will be POSTed to the server
      const jssData = serializeForm(this.mirroredPage);

      // but it does not contain the user data / let's add them
      for (const key in this.changedFieldsData) {
        // first get all items with this key and remove them
        jssData.data = jssData.data.filter(item => item.key !== key);
        // then add the user changed values

        if (Array.isArray(this.changedFieldsData[key])) {
          this.changedFieldsData[key].forEach(value => {
            jssData.data.push({
              key: key,
              value: value,
            });
          });
        } else {
          jssData.data.push({
            key: key,
            value: this.changedFieldsData[key],
          });
        }
      }

      // be sure to bring the antiforgery token up to date
      const tokenField = jssData.data.find(item => item.key === this.page.antiForgeryToken.name);
      tokenField.value = this.page.antiForgeryToken.value;

      // the file input fields we have to process exclusively
      [].forEach.call(this.$el.querySelectorAll('input[type="file"]'), $file => {
        const key = jssData.data.findIndex(item => item.key == $file.name);
        if ($file.files[0] !== undefined) {
          jssData.data[key] = {
            key: $file.name,
            value: $file.files[0],
          };
        }
      });
      // workaround to fetch data from nin checkbox
      [].forEach.call(this.$el.querySelectorAll('input[name$=".CheckboxValue"]'), $checkbox => {
        saveStorage($checkbox.name, $checkbox.checked);
        if ($checkbox.checked) {
          jssData.data.push({
            key: $checkbox.name,
            value: true,
          });
        }
      });

      // workaround to fetch data from password confirm field
      [].forEach.call(this.$el.querySelectorAll('input[name$=".ConfirmPassword"]'), $password => {
        jssData.data.push({
          key: $password.name,
          value: $password.value,
        });
      });
      // workaround to fetch data from email confirm field
      [].forEach.call(this.$el.querySelectorAll('input[name$=".ConfirmEmail"]'), $email => {
        jssData.data.push({
          key: $email.name,
          value: $email.value,
        });
      });

      // we need an own fetcher to get the file upload field to work (we need a multipart/form-data form)
      submitForm(jssData, this.endpoint, { fetcher: this.fetcher })
        .then(result => {
          if (result.success && Object.keys(result.validationErrors).length === 0) {
            // delete data in localStorage
            for (let key in this.changedFieldsData) {
              clearStorage(key);
            }
            if (result.nextForm !== null) {
              if (rerenderForm) {
                this.mirroredPage = result.nextForm;
              }
              eventBus.$emit(this.formId + ':form-submitted-success', { result, rerenderForm });
            } else if (result.redirectUrl !== '') {
              location.href = result.redirectUrl;
            } else {
              eventBus.$emit(this.formId + ':form-submitted-success', { result, rerenderForm });
            }
          } else {
            // save keys and values to localStorage
            for (let key in this.changedFieldsData) {
              saveStorage(key, this.changedFieldsData[key]);
            }
            eventBus.$emit(this.formId + ':form-submitted-failure', { result, rerenderForm });
          }
        })
        .catch(error => {
          eventBus.$emit(this.formId + ':form-submitted-failure', error);
        });
    },

    fetcher(jssFormData, endpoint) {
      return fetch(endpoint, {
        body: jssFormData.toMultipartFormData(),
        method: 'post',
        // IMPORTANT: Sitecore forms relies on cookies for some state management, so credentials must be included.
        credentials: 'include',
        // Browser set 'Content-Type' automatically with multipart/form-data; boundary
      })
        .then(res => res.json())
        .catch(() => {
          return {
            success: false,
            errors: 'Something went wrong. Error was thrown when submit form.',
          };
        });
    },
  },
};
</script>
