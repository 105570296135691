export const feedData = [
  {
    source: 'products',
    label: { value: 'Teaser' },
    description: {
      value: 'Teaser Description',
    },
    link: '#',
    headerImage: {
      jss: {
        value: {
          src: '/-/media/project/nolte-jss/corporate-website/data/media/img/example.jpg',
          alt: 'alt text',
          width: '934',
          height: '630',
        },
      },
    },
    teaserImage: {
      jss: {
        value: {
          src: '/-/media/project/nolte-jss/corporate-website/data/media/img/example.jpg',
          alt: 'alt text',
          width: '934',
          height: '630',
        },
      },
    },
    galleryImages: { targetItems: [] },
    category: {
      label: { value: 'Arbeitsplatten' },
    },
  },
  {
    source: 'products',
    label: { value: 'Teaser' },
    description: {
      value: 'Teaser Description',
    },
    link: '#',
    headerImage: {
      jss: {
        value: {
          src: '/-/media/project/nolte-jss/corporate-website/data/media/img/example.jpg',
          alt: 'alt text',
          width: '934',
          height: '630',
        },
      },
    },
    teaserImage: {
      jss: {
        value: {
          src: '/-/media/project/nolte-jss/corporate-website/data/media/img/example.jpg',
          alt: 'alt text',
          width: '934',
          height: '630',
        },
      },
    },
    galleryImages: { targetItems: [] },
    category: {
      label: { value: 'Arbeitsplatten' },
    },
  },
  {
    source: 'products',
    label: { value: 'Teaser' },
    description: {
      value: 'Teaser Description',
    },
    link: '#',
    headerImage: {
      jss: {
        value: {
          src: '/-/media/project/nolte-jss/corporate-website/data/media/img/example.jpg',
          alt: 'alt text',
          width: '934',
          height: '630',
        },
      },
    },
    teaserImage: {
      jss: {
        value: {
          src: '/-/media/project/nolte-jss/corporate-website/data/media/img/example.jpg',
          alt: 'alt text',
          width: '934',
          height: '630',
        },
      },
    },
    galleryImages: { targetItems: [] },
    category: {
      label: { value: 'Arbeitsplatten' },
    },
  },
];
