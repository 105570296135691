<template>
  <ContentLink
    :editable="false"
    :class="{ 'nolte-variant': url, 'nolte-variant--active': active }"
    :field="{ value: { href: url, linktype: 'internal' } }"
  >
    <figure :class="!url && 'nolte-variant'">
      <div class="nolte-variant__image-wrapper">
        <EditorialImage
          v-if="image.src"
          class="nolte-variant__image"
          :media="{ value: image }"
          default-aspect-ratio="1:1"
          default-sizes="250px"
          fit="cover"
          :editable="false"
        />
      </div>

      <span v-if="internalTag" class="nolte-variant__info">
        {{ internalTag }}
      </span>

      <figcaption class="nolte-variant__label-wrapper">
        <p class="nolte-variant__category-label">{{ categoryLabel }}</p>
        <p v-if="productLabel && showProductInformation" class="nolte-variant__product-label">
          {{ productLabel }}
        </p>
        <p class="nolte-variant__label">{{ label }}</p>
      </figcaption>
    </figure>
  </ContentLink>
</template>

<script>
import EditorialImage from '@/components/EditorialImage';
import ContentLink from '@/components/ContentLink';

export default {
  name: 'NolteVariant',

  components: {
    EditorialImage,
    ContentLink,
  },

  props: {
    image: {
      type: Object,
      required: true,
    },
    internalTag: {
      type: String,
      default: undefined,
    },
    label: {
      type: String,
      required: true,
    },
    productLabel: {
      type: String,
      default: undefined,
    },
    url: {
      type: String,
      default: undefined,
    },
    categoryLabel: {
      type: String,
      default: '',
    },
    showProductInformation: {
      type: Boolean,
      default: false,
    },
    active: {
      type: Boolean,
      default: false,
    },
  },
};
</script>

<style lang="scss" scoped>
.nolte-variant {
  display: block;
}

// INFO: for the shadow to appear behind the element we need to create
// a new stacking context that makes shadow appear behind content
// see NolteVariantGroup for an example

.nolte-variant__image-wrapper {
  @include helper__aspect-ratio(1/1);
  background-color: $color-concret;

  // for the shadow pseudo element
  position: relative;

  &::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    box-shadow: 0 0 30px rgba($color-black, 0.1);
    z-index: -1;

    .nolte-variant--active & {
      box-shadow: 0 0 30px rgba($color-black, 0.2); //, 0 0 30px rgba($color-black, 0.3);
      z-index: 1;
    }
  }
}

.nolte-variant__image {
  height: 100%;

  &::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 1;

    .nolte-variant--active & {
      box-shadow: inset 0 0 0 2px $color-delta; //, 0 0 30px rgba($color-black, 0.3);
    }
  }

  // this is necessary if a variant is in a noltescroller
  :deep(img) {
    height: 100% !important;
  }
}

.nolte-variant__info {
  @include text-caption--small;
  @include helper__transition((opacity), 200ms);
  position: absolute;
  background-color: rgba($color-tuatara, 0.3);
  color: $color-concret;
  left: 0;
  top: 0;
  padding: 5px;
  pointer-events: none;
  opacity: 0;
  transform-origin: top left;
}

.nolte-variant:hover .nolte-variant__info,
.nolte-variant--active .nolte-variant__info {
  opacity: 1;
  pointer-events: auto;
}

.nolte-variant__label-wrapper {
  padding: 8px 5px 0;
}

.nolte-variant__label {
  color: $color-delta;

  @include helper__until($bp-768) {
    @include text-caption--small;
  }

  @include helper__greater($bp-768) {
    @include text-caption;
  }
}

.nolte-variant__category-label,
.nolte-variant__product-label {
  @include text-h4;

  @include helper__until($bp-768) {
    font-size: 13px;
  }
}

.nolte-variant--active .nolte-variant__label {
  color: $color-ironside-gray;
}
</style>
