<template>
  <div class="editorial-group">
    <ContentAnchor
      :fields="{
        anchorTitle: fields['Anchor Title'],
        anchorName: fields['Anchor Name'],
      }"
    /><EditorialHeadline
      v-if="textHasValue(fields.headline)"
      :fields="fields"
      headline-level="h2"
      headline-style="h3"
    />
    <!--<component
      :is="component"
      v-for="(component, index) in groupPlaceholder"
      :key="`group_${index}`"
    />-->
    <sc-placeholder name="nolte-neo-group_default" :rendering="rendering" />
  </div>
</template>

<script>
import EditorialHeadline from '../components/EditorialHeadline';
import ContentAnchor from '@/components/ContentAnchor';
import { Placeholder } from '@sitecore-jss/sitecore-jss-vue';

import { textHasValue } from './helper';

export default {
  name: 'EditorialNeoGroup',

  components: {
    EditorialHeadline,
    ContentAnchor,
    ScPlaceholder: Placeholder,
  },

  props: {
    rendering: {
      type: Object,
      default: undefined,
    },
  },

  methods: {
    textHasValue,
  },

  computed: {
    showInAnchorNavigation() {
      return this.fields && this.fields['Show Anchor in Navigation']?.value ? true : false;
    },
  },

  /*withPlaceholder: {
    placeholders: [
      {
        placeholder: 'nolte-neo-group_default',
        computedPropName: 'groupPlaceholder',
      },
    ],
  },*/
};
</script>

<style lang="scss" scoped>
.editorial-group {
  margin: $unit-double 0;

  @include helper__greater($bp-768) {
    margin: $unit-double * 2 0;
  }

  :deep() {
    .editorial-text-multi-line-wrapper,
    .editorial-rich-text-wrapper,
    .editorial-button-wrapper,
    .editorial-video {
      margin: $unit-double auto;
    }
  }
}
</style>

<style lang="scss">
[data-component-placeholder] > .editorial-group {
  &:first-child {
    margin-top: 0 !important;
  }
  &:last-child {
    margin-bottom: 0 !important;
  }
}
</style>
