<template>
  <transition name="fade">
    <div
      v-if="
        !loading &&
        bannerData &&
        textHasValue(bannerData.Text) &&
        !Array.isArray(bannerData) &&
        isBannerVisible
      "
      class="banner"
      :style="{ backgroundColor: bannerBackgroundColor }"
    >
      <div class="banner__content">
        <span class="banner__text" :style="{ color: bannerTextColor }">
          {{ bannerData?.Text?.value }}
        </span>
        <NolteButton
          v-if="linkHasValue(bannerData.Link?.jss)"
          class="banner__button"
          :style="{ color: buttonTextColor, backgroundColor: buttonColor }"
          :linkUrl="buttonLinkUrl"
          :target="buttonTarget"
          @click="closeBanner"
          >{{ buttonText }}</NolteButton
        >
        <Cross tabindex="0" class="banner__close" @click="closeBanner" />
      </div>
    </div>
  </transition>
</template>

<script>
import { linkHasValue, textHasValue } from './helper';
import Cross from '../assets/icons/Cross.svg?inline';
import NolteButton from '../nolte-ui/NolteButton';

export default {
  name: 'Banner',
  emits: ['bannerClosed'],
  data() {
    return {
      isBannerVisible: true,
      loading: true,
    };
  },
  components: {
    Cross,
    NolteButton,
  },
  methods: {
    linkHasValue,
    textHasValue,
    closeBanner() {
      this.isBannerVisible = false;
      window.sessionStorage.setItem('bannerVisible', 'false');
      this.$emit('bannerClosed');
    },
  },
  props: {
    bannerData: {
      type: Object,
    },
  },
  mounted() {
    const bannerVisible = window.sessionStorage.getItem('bannerVisible');
    if (bannerVisible === 'false') {
      this.isBannerVisible = false;
    }
    this.loading = false;
  },
  computed: {
    buttonLinkUrl() {
      return this.bannerData?.Link?.jss?.value?.href;
    },
    buttonText() {
      return this.bannerData?.Link?.jss?.value?.text;
    },
    buttonTarget() {
      return this.bannerData?.Link?.jss?.value?.target;
    },
    bannerBackgroundColor() {
      return `#${this.bannerData?.BackgroundColor?.value}`;
    },
    bannerTextColor() {
      return `#${this.bannerData?.TextColor?.value}`;
    },
    buttonColor() {
      return `#${this.bannerData?.ButtonColor?.value}`;
    },
    buttonTextColor() {
      return `#${this.bannerData?.ButtonTextColor?.value}`;
    },
    isLoading() {
      return this.loading;
    },
  },
};
</script>

<style lang="scss" scoped>
.banner {
  position: relative;
  width: 100%;
  background: $color-aureolin 0% 0% no-repeat padding-box;
  opacity: 1;
  padding: $unit-double;

  @include helper__greater($bp-600) {
    padding-top: $unit-base;
    padding-bottom: $unit-base;
  }

  @include helper__greater($bp-1280) {
    padding-left: $unit-half;
    padding-right: $unit-half;
  }

  &__content {
    box-sizing: border-box;
    margin: 0 auto;
    display: flex;
    flex-direction: column;
    justify-content: center;
    gap: $unit-double;
    max-width: 1200px;

    @include helper__greater($bp-600) {
      flex-direction: row;
      align-items: center;
    }
  }

  &__text {
    font-family: $font-futura-bold;
    font-size: 15px;
    line-height: 17px;
    letter-spacing: 0.42px;
    padding-right: $unit-double;
    color: $color-ironside-gray;

    @include helper__greater($bp-600) {
      padding-right: 0;
      font-size: 17px;
      line-height: 21px;
      letter-spacing: 0.51px;
    }
  }

  &__button {
    font-family: $font-graphit-bold !important;
    font-size: 13px;
    line-height: 17px;
    letter-spacing: 1px;
    width: 100%;
    border: 1px solid $color-concret;
    border-radius: 10px;
    background-color: $color-concret;
    max-width: none;
    margin-right: $unit-double;

    @include helper__greater($bp-600) {
      width: auto;
      max-width: 320px;
    }
    @include helper__greater($bp-1440) {
      margin-right: 0;
    }

    &:hover,
    &:focus,
    &:focus-visible,
    &:active {
      outline: none;
      border: 1px solid #d0b504 !important;
      background-color: #fff34d !important;
    }
  }

  &__close {
    pointer-events: all;
    position: absolute;
    top: $unit-base;
    right: $unit-base;
    cursor: pointer;

    @include helper__greater($bp-600) {
      top: 50%;
      transform: translateY(-50%);
    }
  }

  svg:hover,
  svg:focus-visible {
    outline: none;
    :deep(rect) {
      fill: $color-ironside-gray;
    }
  }
}
.fade-enter-active,
.fade-leave-active {
  @include helper__transition(opacity);
}

.fade-enter,
.fade-leave-to {
  opacity: 0;
}
</style>
