<template>
  <div class="editorial-mnk-userregconfirm">
    <div class="editorial-mnk-userregconfirm__inner">
      <div class="editorial-mnk-userregconfirm__inner__left">
        <ContentLink
          v-if="isAuthenticated && mnkRootItem"
          :editable="false"
          class="editorial-mnk-userregconfirm__breadcrumb"
          :field="{
            value: { text: mnkRootItem.text, href: mnkRootItem.href, linktype: 'internal' },
          }"
        />
        <div v-else-if="mnkRootItem" class="editorial-mnk-userregconfirm__overline">
          {{ mnkRootItem.text }}
        </div>
        <h1 class="editorial-mnk-userregconfirm__headline">{{ datasource.headline }}</h1>
      </div>
      <div class="editorial-mnk-userregconfirm__inner__right">
        <p class="editorial-mnk-userregconfirm__copy">
          {{ datasource.copy }}
        </p>
        <div class="editorial-button-outer-wrapper editorial-mnk-userregconfirm__link">
          <div class="editorial-button-wrapper" v-if="!isEditing && datasource.linkLabel">
            <NolteButton
              :link-url="datasource.linkUrl"
              v-if="datasource.showMnkMenu"
              class="editorial-button nolte-button--yellow--plain"
              @click="setSearchMnkMenuVisibility(true)"
              >{{ datasource.linkLabel }}</NolteButton
            >
            <NolteButton
              :link-url="datasource.linkUrl"
              v-else
              class="editorial-button nolte-button--yellow--plain"
              >{{ datasource.linkLabel }}</NolteButton
            >
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { mapState, mapMutations } from 'vuex';
import NolteButton from '@/nolte-ui/NolteButton';
import ContentLink from '@/components/ContentLink';

export default {
  name: 'EditorialMNKUserRegistrationConfirmation',

  components: {
    ContentLink,
    NolteButton,
  },

  methods: {
    ...mapMutations(['setSearchMnkMenuVisibility']),
  },

  computed: {
    ...mapState('jss', ['sitecoreContext']),

    datasource() {
      return this.fields?.data?.datasource;
    },

    mnkRootItem() {
      return this.sitecoreContext.site.login || null;
    },

    isAuthenticated() {
      return false;
    },
  },
};
</script>

<style lang="scss" scoped>
.editorial-mnk-userregconfirm {
  @include spacing;

  @include helper__greater($bp-768) {
    margin-top: $unit-double * 4;
  }
}
.editorial-mnk-userregconfirm__inner {
  display: flex;
  flex-direction: column;

  @include helper__greater($bp-1024) {
    flex-direction: row;
  }
}

.editorial-mnk-userregconfirm__inner__left {
  margin-bottom: 0 !important;
  min-width: 60%;

  @include helper__greater($bp-1024) {
    margin-right: $unit-base * 2;
  }
}

.editorial-mnk-userregconfirm__inner__right {
  margin-top: $unit-double !important;
}

.editorial-mnk-userregconfirm__breadcrumb {
  @include breadcrumb;
}

.editorial-mnk-userregconfirm__overline {
  @include text-h3;
}

.editorial-mnk-userregconfirm__headline {
  @include mnk-text-h1;
}

.editorial-mnk-userregconfirm__copy {
  @include text-intro;
}

.editorial-mnk-userregconfirm__link {
  :deep(.editorial-button-wrapper) {
    margin: $unit-double 0;
    padding: 0;
  }
  :deep(.nolte-button--yellow--plain) {
    font-size: 13px;
  }
}
</style>
