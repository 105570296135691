<template>
  <div
    class="content-video-wistia"
    :class="[startedPlaying ? 'startedPlaying' : false, `content-video-wistia--fit-${fit}`]"
  >
    <div class="wistia_responsive_padding" :style="{ padding: calculatedPadding }">
      <div class="wistia_responsive_wrapper">
        <div :class="`wistia_embed wistia_async_${videoId}`"></div>
      </div>
    </div>
    <div
      class="content-video-wistia__play"
      v-if="meta && !activated && !autoPlay"
      @click="activate"
    ></div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';

export default {
  name: 'ContentVideoWistia',

  props: {
    videoId: {
      type: String,
      required: true,
    },
    endVideoBehavior: {
      type: String,
      default: 'default',
    },
    autoPlay: {
      type: Boolean,
      default: false,
    },
    muted: {
      type: Boolean,
      default: false,
    },
    playbar: {
      type: Boolean,
      default: true,
    },
    fullscreenButton: {
      type: Boolean,
      default: true,
    },
    settingsControl: {
      type: Boolean,
      default: true,
    },
    volumeControl: {
      type: Boolean,
      default: true,
    },
    smallPlayButton: {
      type: Boolean,
      default: true,
    },
    fit: {
      type: String,
      default: 'fill',
    },
  },

  data: () => ({
    startedPlaying: false,
    meta: null,
    player: null,
    playerId: Infinity,
    activated: false,
    calculatedPadding: '0',
  }),

  mounted() {
    // meta data not used at the moment
    this.getMetadata(result => {
      this.meta = result;
      this.calculatedPadding = (result.height / result.width) * 100 + '% 0 0';
    });
    if (typeof window !== 'undefined') {
      window._wq = window._wq || [];

      // load Wistia API only once per page
      if (window._wq.length === 0) {
        let wistiaApi = document.createElement('script');
        wistiaApi.setAttribute('src', 'https://fast.wistia.com/assets/external/E-v1.js');
        document.head.appendChild(wistiaApi);
      }

      window.playerCounter = window.playerCounter ?? 0;
      window.nolteWistiaPlayers = window.nolteWistiaPlayers || {};

      window._wq.push({
        id: this.videoId,
        // https://wistia.com/support/developers/embed-options#list-of-all-options
        options: {
          videoFoam: 'true',
          playerColor: '000000',
          playButton: 'false',
          controlsVisibleOnLoad: 'false',
          doNotTrack: 'true',
          googleAnalytics: 'false',
          endVideoBehavior: this.endVideoBehavior,
          autoPlay: this.autoPlay ? 'true' : 'false',
          muted: this.muted ? 'true' : 'false',
          playbar: this.playbar ? 'true' : 'false',
          fullscreenButton: this.fullscreenButton ? 'true' : 'false',
          settingsControl: this.settingsControl ? 'true' : 'false',
          volumeControl: this.volumeControl ? 'true' : 'false',
          smallPlayButton: this.smallPlayButton ? 'true' : 'false',
        },
        onReady: player => {
          this.player = player;
          //let counter = window.playerCounter = window.playerCounter || 0;
          const playerId = window.playerCounter++;
          window.nolteWistiaPlayers[playerId] = player;
          this.playerId = playerId;

          // "ready" does not mean that the video is already playing. On my PC it sometimes took up
          // to 900ms until it started running. Presumably, buffering is only started with onReady.
          // Since there is no corresponding event, we have to monitor the playing state ourselves
          // to be able to fade in the video when it is ready to play.
          // That makes only sense if autoplay is used.
          if (this.autoPlay) {
            const WhenStartedPlaying = new Promise((resolve, reject) => {
              const interval = setInterval(() => {
                if (player.state() === 'playing') {
                  clearInterval(interval);
                  clearInterval(timeout);
                  resolve();
                }
              }, 50);

              // if the video did not start playing after 5 seconds we will show the fallback image
              const timeout = setTimeout(() => {
                clearInterval(interval);
                reject(
                  `ContentVideoWistia: Video took more than 20 seconds to start (video id '${this.videoId}'). Falling back to the fallback image.`
                );
              }, 20000);
            });

            WhenStartedPlaying.then(() => {
              this.startedPlaying = true;
            }).catch(error => {
              this.$emit('error', error);
              throw error;
            });
          } else {
            this.startedPlaying = true;
          }
        },
      });
    }
  },

  computed: {
    ...mapGetters('jss', ['isEditing']),
  },

  methods: {
    activate() {
      // if video player is not instantiated so far
      if (!this.player) return;

      // Todo: Why are players started like this failing?
      //this.player.play();
      if (this.playerId < Infinity) window.nolteWistiaPlayers[this.playerId].play();
      this.activated = true;
    },
    pause() {
      // if video player is not instantiated so far
      if (!this.player) return;

      this.player.pause();
      this.activated = false;
    },

    // get meta data of video by using oembed API
    getMetadata(callback) {
      const endpoint = 'https://fast.wistia.com/oembed';
      const url =
        `${endpoint}?url=` +
        encodeURIComponent(`https://nolte-kuechen.wistia.com/medias/${this.videoId}`);

      fetch(url)
        .then(response => {
          if (response.status !== 200) {
            const error = `ContentVideoWistia: API Status Code ${response.status} for video id '${this.videoId}'.`;
            this.$emit('error', error);
            throw error;
          } else {
            return response.json();
          }
        })
        .then(callback);
    },
  },
};
</script>

<style lang="scss">
// default styles the API returned
.wistia_responsive_padding {
  position: relative;
  // padding: 56.25% 0 0 0; calculated dynamically
}
.wistia_responsive_wrapper {
  position: absolute;
  left: 0;
  top: 0;
  height: 100%;
  width: 100%;

  .wistia_embed {
    position: relative;
    height: 100%;
    width: 100%;
  }
}
.wistia_swatch {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  overflow: hidden;
  transition: opacity 200ms;

  img {
    filter: blur(2px);
    height: 100%;
    object-fit: contain;
    width: 100%;
  }
}
// / default styles the API returned

// our own styles on top
.content-video-wistia {
  position: relative;
  @include helper__transition(opacity, 1s);
  opacity: 0;

  &.startedPlaying {
    opacity: 1;
  }

  &--fit-cover div:not(.w-css-reset):not(.w-css-reset *),
  &--fit-cover video {
    object-fit: cover !important;
    position: absolute !important;
    width: 100% !important;
    height: 100% !important;
    padding: 0 !important;
    margin: 0 !important;
    left: auto !important;
  }

  &--fit-cover div,
  &--fit-cover video {
    button.w-vulcan-v2-button {
      z-index: 300;
    }

    .w-vulcan--background {
      z-index: 100;
    }

    .w-bottom-bar {
      z-index: 400;
    }
  }

  &--fit-contain div,
  &--fit-contain video {
    object-fit: contain !important;
    position: absolute !important;
    width: 100% !important;
    height: 100% !important;
    padding: 0 !important;
    margin: 0 !important;
    left: auto !important;
  }

  // prevents displaying context menu
  .w-foreground {
    display: none !important;
  }

  .w-context-menu-anchor,
  .w-context-menu-anchor *,
  [data-handle='loadingHourglass'] {
    width: auto !important;
    height: auto !important;
    left: 10px !important;
    top: auto !important;
    bottom: 10px !important;
  }
}

.content-video-wistia__play {
  background-color: rgba($color-black, 0.4);
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  cursor: pointer;
  z-index: 299;

  &::before {
    content: '';
    border-style: solid;
    border-width: 18px 0 18px 30px;
    border-color: transparent transparent transparent $color-aureolin;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate3d(-50%, -50%, 0);
  }
}

// / our own styles on top
</style>
