<template>
  <div class="content-neo-overlay" v-if="isReallyNeo">
    <IconNeo />
  </div>
</template>

<script>
import IconNeo from '@/assets/icons/IconNeo.svg?inline';

export default {
  name: 'ContentNeoOverlay',

  components: {
    IconNeo,
  },

  props: {
    isNeo: {
      type: Object,
      default: () => {},
    },
  },

  computed: {
    isReallyNeo() {
      // this is the default structure JSS uses if you request it via graphQL
      return this?.isNeo?.value && this.isNeo.value === '{9E80003C-963F-4FE9-99D7-6B75E8E84A25}';
    },
  },
};
</script>

<style lang="scss" scoped>
.content-neo-overlay {
  position: absolute;
  width: 330px;
  height: 235px;
  background: transparent linear-gradient(146deg, #00000000 0%, #00000000 55%, #00000099 100%) 0% 0%
    no-repeat;
  top: auto !important;
  right: 0;
  bottom: 0;
  left: auto !important;
  pointer-events: none;

  svg {
    position: absolute;
    bottom: $unit-base;
    right: $unit-base;
    color: $color-concret;
    width: 90px;
    height: 14px;
  }
}
</style>
