<template>
  <div class="nolte-product-detail">
    <div class="nolte-product-detail__header">
      <div class="nolte-product-detail__header-image-wrapper">
        <ContentFavorite
          v-if="headerImage.value.src"
          :item-id="pageItemId"
          :image-id="headerMediaId"
          ><EditorialImage
            class="nolte-product-detail__header-image"
            :media="headerImage"
            default-aspect-ratio="3:2"
            default-sizes="(min-width: 1024px) 1024px, 100vw"
            fit="cover"
            :editable="false"
          />
          <ContentNeoOverlay :is-neo="isNeo" />
        </ContentFavorite>
      </div>

      <div class="nolte-product-detail__introduction-wrapper">
        <div class="nolte-product-detail__introduction">
          <ContentLink
            v-if="category && category.url"
            :editable="false"
            class="nolte-product-detail__breadcrumb"
            :field="{ value: { text: category.label, href: category.url, linktype: 'internal' } }"
          />
          <NolteHeadline class="nolte-product-detail__title" level="h1">{{ title }}</NolteHeadline>
          <p class="nolte-product-detail__description">{{ description }}</p>
          <div v-if="activeVariant" class="active-variant__wrapper">
            <EditorialImage
              class="active-variant__image"
              :media="activeVariant.image"
              default-sizes="500px"
              :editable="false"
              fit="none"
              :alt="`${activeVariant.productLabel}, ${activeVariant.internalTag}`"
            />
            <p class="active-variant__label">{{ activeVariant.productLabel }}</p>
            <p class="active-variant__internal-tag">{{ activeVariant.internalTag }}</p>
          </div>
        </div>
      </div>

      <div class="nolte-product-detail__variants">
        <NolteVariantSwitcher
          v-if="variantGroups && variantGroups.length"
          :variant-groups="variantGroups"
          :active-item="activeVariant"
        />
        <NolteVariantGroup
          class="nolte-product-detail__variant-group-worktop"
          v-if="activeVariant && activeVariant.dimensionValues"
          :label="productVariantDimensionsLabel"
          :variants="activeVariant.dimensionValues"
        />
      </div>
    </div>

    <div class="nolte-product-detail__background-wave-wrapper">
      <BackgroundWave class="nolte-product-detail__background-wave" />
    </div>

    <div class="nolte-product-detail__section">
      <div
        v-if="highlights && highlights.length"
        class="nolte-product-detail__highlight-group-wrapper"
      >
        <NolteHighlightGroup>
          <NolteHighlight
            v-for="(highlight, index) in highlights"
            :key="`highlight-${index}`"
            :image="highlight.image"
            :headline="highlight.headline"
            :copy="highlight.copy"
            design="plain"
          />
        </NolteHighlightGroup>
      </div>

      <!-- Gallery type slider -->
      <NolteScroller
        class="nolte-product-detail__images"
        :show-arrows="true"
        v-if="images && images.length"
        :count="images.length"
      >
        <EditorialImage
          class="nolte-product-detail__image"
          v-for="(image, index) in images"
          :key="`image-${index}`"
          :media="image"
          default-aspect-ratio="3:2"
          default-sizes="(min-width: 768px) 800px, 100vw"
          :editable="false"
        />
      </NolteScroller>
    </div>

    <section class="nolte-product-detail__details-wrapper">
      <NolteHeadline class="nolte-product-detail__details-headline" level="h2">
        {{ detailsHeadline }}
      </NolteHeadline>

      <div>
        <Details v-if="details" class="nolte-product-detail__details" v-html="details" />

        <ul v-if="downloads && downloads.length" class="nolte-product-detail__download-list">
          <li
            class="nolte-product-detail__download-item"
            v-for="(download, index) in downloads"
            :key="`download-${index}`"
          >
            <NolteDownload
              v-if="download.url"
              class="nolte-product-detail__download"
              :label="download.displayName"
              :image="download.image"
              :file-url="download.url"
              :is-external-link="true"
            />
          </li>
        </ul>
      </div>
    </section>

    <div
      class="nolte-product-detail__related-products"
      v-if="relatedProducts && relatedProducts.length"
    >
      <EditorialHeadline :fields="{ headline: { value: relatedProductsHeadline } }" />

      <NolteScroller :show-arrows="true" :count="relatedProducts.length">
        <div v-for="(product, index) in relatedProducts" :key="`relatedproduct-${index}`">
          <EditorialTeaser :fields="product" :editable="false" />
        </div>
      </NolteScroller>
    </div>
  </div>
</template>

<script>
import NolteDownload from '../NolteDownload';
import NolteHeadline from '../NolteHeadline';
import NolteHighlight from '../NolteHighlight';
import NolteHighlightGroup from '../NolteHighlightGroup';
import NolteScroller from '../NolteScroller';
import NolteVariantGroup from '../NolteVariant/NolteVariantGroup';
import NolteVariantSwitcher from '../NolteVariant/NolteVariantSwitcher';
import BackgroundWave from '../../assets/background_wave_2.svg?inline';
import Details from './Details';
import ContentLink from '@/components/ContentLink';
import ContentNeoOverlay from '@/components/ContentNeoOverlay';
import ContentFavorite from '@/components/ContentFavorite.vue';
import EditorialImage from '@/components/EditorialImage';
import EditorialTeaser from '@/components/EditorialTeaser';
import EditorialHeadline from '@/components/EditorialHeadline';

export default {
  name: 'NolteProductDetail',

  components: {
    Details,
    NolteDownload,
    NolteHeadline,
    NolteHighlight,
    NolteHighlightGroup,
    NolteScroller,
    NolteVariantGroup,
    NolteVariantSwitcher,
    BackgroundWave,
    ContentLink,
    ContentNeoOverlay,
    ContentFavorite,
    EditorialImage,
    EditorialTeaser,
    EditorialHeadline,
  },

  props: {
    headerImage: {
      type: Object,
      required: true,
    },
    headerMediaId: {
      type: String,
      required: true,
    },
    pageItemId: {
      type: String,
      default: undefined,
    },
    category: {
      type: Object,
      required: true,
    },
    title: {
      type: String,
      required: true,
    },
    description: {
      type: String,
      required: true,
    },
    activeVariant: {
      type: Object,
      default: undefined,
    },
    variantGroups: {
      type: Array,
      required: true,
    },
    productVariantDimensionsLabel: {
      type: String,
      default: undefined,
    },
    detailsHeadline: {
      type: String,
      default: undefined,
    },
    highlights: {
      type: Array,
      default: () => [],
    },
    images: {
      type: Array,
      default: () => [],
    },
    details: {
      type: String,
      default: undefined,
    },
    downloads: {
      type: Array,
      default: () => [],
    },
    relatedProductsHeadline: {
      type: String,
      default: undefined,
    },
    relatedProducts: {
      type: Array,
      default: () => [],
    },
    isNeo: {
      type: Object,
      default: () => {},
    },
  },
};
</script>

<style lang="scss" scoped>
$header-breakpoint: $bp-1024;

.nolte-product-detail__header {
  @include centered-content;
  display: block;
  position: relative;
  margin-bottom: $unit-base * 2;

  @include helper__greater($header-breakpoint) {
    padding: 0 0 0 420px;
    margin-bottom: $unit-base * 3;
  }
}

.nolte-product-detail__header-image-wrapper {
  @include helper__aspect-ratio(3/2);

  @include helper__greater($header-breakpoint) {
    margin-bottom: $unit-base * 4;

    // for the shadow
    position: relative;

    &::before {
      // since the image is positioned absolute there is no need for
      // z-index tricks :)
      content: '';
      position: absolute;
      top: $unit-base;
      right: $unit-base;
      bottom: $unit-base;
      left: $unit-base;
      box-shadow: 0 0 100px 0 rgba($color-black, 0.3);
    }
  }
}

.nolte-product-detail__header-image {
  height: 100%;
}

.nolte-product-detail__introduction-wrapper {
  display: flex;
  padding: $unit-base $unit-base $unit-double * 2;

  @include helper__greater($bp-768) {
    padding: $unit-double;
  }

  @include helper__greater($header-breakpoint) {
    display: block;
    padding: 0 $unit-base * 3 $unit-base;

    position: absolute;
    left: 0;
    top: 0;
    width: 420px;
    height: 100%;
  }
}

.nolte-product-detail__introduction {
  margin-right: auto;

  @include helper__greater($header-breakpoint) {
    display: block;
    position: sticky;
    top: $header-height + $unit-double;
    padding: 0;
  }
}

.nolte-product-detail__breadcrumb {
  @include breadcrumb;
}

.nolte-product-detail__title {
  @include text-h1-detail;
  max-width: none;
  margin-bottom: $unit-base;

  @include helper__greater($bp-1024) {
    word-wrap: normal;
  }
}

.nolte-product-detail__description {
  @include text-intro;
}

.nolte-product-detail__variants {
  padding: 0 $unit-base;

  @include helper__greater($header-breakpoint) {
    padding: 0 0 0 90px;
  }
}

.active-variant__wrapper {
  margin: $unit-double 0 0;
  text-align: center;
  flex: 0 1 auto;
}

.active-variant__image {
  display: block;
  margin: 0 auto $unit-half;

  @include helper__until($header-breakpoint) {
    max-width: 100px;
  }
}

.active-variant__label,
.active-variant__internal-tag {
  @include text-caption--small;

  @include helper__greater($bp-768) {
    // TODO: unify text styles
    @include text-caption;
  }
}

.nolte-product-detail__variant-group-worktop {
  margin-top: $unit-double;
}

.nolte-product-detail__background-wave-wrapper {
  max-width: 100vw;
  overflow: hidden;
}

.nolte-product-detail__background-wave {
  width: 100%;
  color: $color-concret;
  margin-top: $unit-base;
  height: 24px;

  @include helper__greater($bp-768) {
    height: auto;
  }
}

.nolte-product-detail__section {
  background-color: $color-concret;
  overflow: auto;
}

.nolte-product-detail__highlights {
  padding-top: $unit-double;
  padding-bottom: $unit-base * 6;

  @include helper__greater($bp-768) {
    padding-bottom: $unit-base * 10;
  }
}

.nolte-product-detail__images {
  :deep(.swiper-slide) {
    @include helper__until($bp-600) {
      margin: 0 $unit-base 0 $unit-base;
    }
  }
}

.nolte-product-detail__image {
  width: 800px;
  max-width: calc(100vw - #{$unit-double});
}

.nolte-product-detail__images :deep(img) {
  height: 100%;
}

$detail-breakpoint: $bp-768;

.nolte-product-detail__details-wrapper {
  @include spacing;

  @include helper__greater($detail-breakpoint) {
    display: flex;
  }

  > div {
    @include helper__greater($detail-breakpoint) {
      flex: 0 0 auto;
      width: 66%;
    }

    @include helper__greater($header-breakpoint) {
      width: 33%;
    }
  }
}

.nolte-product-detail__details,
.nolte-product-detail__download-list {
  margin-bottom: $unit-base * 3;
}

.nolte-product-detail__details-headline {
  @include text-h2;
  padding: 0 $unit-double $unit-double 0;

  @include helper__greater($detail-breakpoint) {
    width: 33%;
    flex: 0 0 auto;
  }
}

.nolte-product-detail__download-item {
  // create new z-index stack to show shadow from NolteDowload behind element
  position: relative;
  z-index: 0;

  & + & {
    margin-top: $unit-double;
  }

  :deep() > .nolte-download {
    padding: 0 !important;
  }
}

.nolte-product-detail__highlight-group-wrapper {
  margin: $unit-double 0;

  @include helper__greater($bp-768) {
    margin: $unit-double * 2 0;
  }
}

.nolte-product-detail__related-products {
  padding: 0 0 $unit-base;

  @include helper__greater($bp-600) {
    padding: 0 0 $unit-half;
  }

  :deep() {
    .swiper-slide {
      @include helper__until($bp-600) {
        margin: 0 $unit-base 0 $unit-base;
      }
    }
    .nolte-headline {
      @include text-h3;
    }
  }
}

// MARKER: A
.nolte-product-detail__related-products :deep(.nolte-scroller) {
  height: 225px;

  @include helper__greater($bp-1024) {
    height: 600px;
  }

  .editorial-image--fit-cover {
    max-width: none;
    height: auto;
  }

  .editorial-image--fit-cover img {
    object-fit: initial;
    width: initial;
    max-width: none;
    height: 225px;

    @include helper__greater($bp-1024) {
      height: 600px;
    }
  }
}
</style>
