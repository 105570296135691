<template>
  <div class="language-switcher" v-if="visible" @click.stop>
    <a
      class="language-switcher__button language-switcher__trigger"
      @click="toggleCollapsable"
      v-if="visible"
    >
      {{ currentRouteLanguage.language }}
      <IconArrowDown
        class="language-switcher__button-arrow"
        :class="{ 'language-switcher__button-arrow--open': open }"
      />
    </a>

    <ul class="language-switcher__options" :class="{ 'language-switcher__options--open': open }">
      <li v-for="(lang, index) in sitecoreContext.versions" :key="index">
        <a
          v-if="lang.language !== currentRouteLanguage.language"
          :href="lang.url"
          class="language-switcher__button"
        >
          {{ lang.language }}
        </a>
        <div v-else class="language-switcher__button language-switcher__button--active">
          {{ lang.language }}
        </div>
      </li>
    </ul>
  </div>
</template>

<script>
import { mapGetters, mapState } from 'vuex';
import IconArrowDown from '../assets/icons/IconArrowDown.svg?inline';

export default {
  name: 'LanguageSwitcher',

  components: {
    IconArrowDown,
  },

  data: () => ({
    open: false,
  }),

  computed: {
    ...mapState('jss', ['sitecoreContext']),
    ...mapGetters('jss', ['currentRouteLanguage']),

    visible() {
      return this.currentRouteLanguage && this.sitecoreContext.versions?.length >= 2;
    },
  },

  methods: {
    toggleCollapsable() {
      this.open = !this.open;
    },
  },

  mounted() {
    // close navigation on click outside the navigation
    // works together with @click.stop on root element in template block above
    document.body.addEventListener('click', () => {
      this.open = false;
    });
  },
};
</script>

<style lang="scss" scoped>
.language-switcher {
  display: flex;
  align-items: center;
  position: relative;
  user-select: none;
}

.language-switcher__button {
  @include text-h5;
  @include helper__transition;
  color: $color-delta;
  cursor: pointer;

  @include helper__until($bp-1280) {
    align-items: center;
    width: 40px;
    height: 40px;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  @include helper__greater($bp-1280) {
    display: block;
    margin: $unit-base 0 0;
    padding: 0 $unit-double 0 0;
  }
}

.language-switcher__button.language-switcher__trigger {
  margin: 0;

  @include helper__until($bp-1280) {
    display: none;
  }
}

.language-switcher__button svg {
  position: absolute;
  margin-top: -4px;
}

.language-switcher__button--active {
  color: $color-ironside-gray;
  cursor: initial;

  @include helper__until($bp-1280) {
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    background-color: $color-aureolin;
    width: 40px;
    height: 40px;
  }
}

.language-switcher__button:hover {
  color: $color-ironside-gray;
}

.language-switcher__button-arrow {
  @include helper__transition(transform);

  &--open {
    transform: rotate(90deg);
  }
}

.language-switcher__options {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  overflow: auto;

  @include helper__greater($bp-1280) {
    @include header-shadow;
    position: absolute;
    left: -$unit-base;
    top: $unit-double;
    padding: 0 $unit-base $unit-base;
    flex-direction: column;
    background: $color-white;
    opacity: 0;
    transform: scaleY(0);
    transform-origin: top center;
    @include helper__transition((opacity, transform));
    &--open {
      opacity: 1;
      transform: scaleY(1);
    }
  }

  li {
    margin: 0 $unit-double $unit-base 0;

    @include helper__greater($bp-1280) {
      margin: 0;
    }
  }
}
</style>

<style lang="scss">
// styles for neo
html.template--neopage {
  .language-switcher > .language-switcher__button:hover {
    color: $color-concret;
  }
}
</style>
