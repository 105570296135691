<template>
  <div class="editorial-text-multi-line-wrapper">
    <ScText
      class="editorial-text-multi-line"
      :class="{ 'text-multiline-category': isCategoryPage }"
      :field="fields.copy"
      tag="div"
    />
  </div>
</template>

<script>
import { Text } from '@sitecore-jss/sitecore-jss-vue';
import { mapState } from 'vuex';

export default {
  name: 'EditorialTextMultiLine',

  components: {
    ScText: Text,
  },

  computed: {
    ...mapState('jss', ['routeData']),

    isCategoryPage() {
      return this.routeData?.templateId == 'eedf68e4-274a-54c2-a495-a0208cd5e430';
    },
  },
};
</script>

<style lang="scss" scoped>
.editorial-text-multi-line-wrapper {
  @include spacing;
}

.editorial-text-multi-line {
  @include text-intro;
  box-sizing: content-box;
  max-width: 750px;
}

.text-multiline-category {
  margin-bottom: $unit-base;
}
</style>
