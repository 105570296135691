import VFieldTemplate from './FieldTemplate';
import VHidden from '../fields/Hidden';
import VLabel from '../fields/Label';
import eventBus from '@/lib/eventBus';

export default {
  components: {
    // we don't want to import the label component for every field
    VFieldTemplate,
    VLabel,
    VHidden,
  },

  props: {
    field: {},
    formId: String,
  },

  methods: {
    fieldBlur() {
      eventBus.$emit(this.formId + ':field-blur', this);
    },
    cleanItemId(value) {
      value = value.replace(/[{}]/g, '');
      value = value.toLowerCase();
      return value;
    },
  },

  watch: {
    value() {
      // if a field changes by user interaction we want to inform the world of the component that changed
      // This way other mixin can react to the changed field
      // console.log('Fieldmixin mit this.formId', this.formId);

      eventBus.$emit(this.formId + ':field-change', this);
    },
  },
};
