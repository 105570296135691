<template>
  <NolteStickyMenu
    :class="{
      'nolte-catalog-menu-sticky--is-mobile': isMobile,
      'nolte-catalog-menu-sticky--is-desktop': !isMobile,
      'nolte-catalog-menu-sticky--inspiration-filter-open':
        inspirationFilterOptionIndex !== undefined,
    }"
    :is-mobile="isMobile"
    :show-if-possible="showIfPossible"
    :disable-main-scroller="categoriesOpen"
  >
    <div class="nolte-catalog-menu-sticky__main">
      <NolteCatalogMenuCategories
        v-model="categoriesOpen"
        :value="categoriesOpen"
        :categories="categories"
        :catalog-url="catalogUrl"
        :active-option-id="activeOptionId"
        class="nolte-catalog-menu-sticky__categories"
      />

      <div v-if="!isMobile" class="nolte-catalog-menu-sticky__separator" />

      <NolteCatalogMenuInspirationFilter
        v-if="!categoriesOpen"
        v-model="inspirationFilterOptionIndex"
        :inspiration-filter="inspirationFilter"
        :active-option-id="activeOptionId"
        :expanded-tabs="isMobile && inspirationFilterOptionIndex !== undefined"
        class="nolte-catalog-menu-sticky__inspiration-filter"
      />

      <div v-if="!isMobile" class="nolte-catalog-menu-sticky__separator" />

      <NolteCatalogMenuNeo
        v-if="!categoriesOpen && neoFilters.length"
        :catalog-url="catalogUrl"
        :active-option-id="activeOptionId"
        :neo-filters="neoFilters"
      />
    </div>

    <template #sub>
      <NolteCatalogMenuInspirationFilterOptions
        v-if="inspirationFilterOptionIndex !== undefined"
        :key="`inspiration-filter-options-${inspirationFilterOptionIndex}`"
        :catalog-url="catalogUrl"
        :active-option-id="activeOptionId"
        :children="activeInspirationFilter && activeInspirationFilter.children"
        @close="inspirationFilterOptionIndex = undefined"
      />
    </template>
  </NolteStickyMenu>
</template>

<script>
import eventBus from '@/lib/eventBus';
import NolteStickyMenu from '../NolteStickyMenu';
import NolteCatalogMenuCategories from './NolteCatalogMenuCategories';
import NolteCatalogMenuInspirationFilter from './NolteCatalogMenuInspirationFilter';
import NolteCatalogMenuInspirationFilterOptions from './NolteCatalogMenuInspirationFilterOptions';
import NolteCatalogMenuNeo from './NolteCatalogMenuNeo';

export default {
  name: 'NolteCatalogMenuSticky',

  components: {
    NolteStickyMenu,
    NolteCatalogMenuCategories,
    NolteCatalogMenuInspirationFilter,
    NolteCatalogMenuInspirationFilterOptions,
    NolteCatalogMenuNeo,
  },

  props: {
    categories: {
      type: Array,
      required: true,
    },
    catalogUrl: {
      type: String,
      required: true,
    },
    inspirationFilter: {
      type: Array,
      required: true,
    },
    activeOptionId: {
      type: String,
      default: undefined,
    },
    isMobile: {
      type: Boolean,
      default: false,
    },
    showIfPossible: {
      type: Boolean,
      default: false,
    },
    neoFilters: {
      type: Array,
      required: true,
    },
    neoMoreButton: {
      type: Object,
      default: null,
    },
  },

  data() {
    return {
      categoriesOpen: false,
      inspirationFilterOptionIndex: undefined,
    };
  },

  computed: {
    activeInspirationFilter() {
      return this.inspirationFilter[this.inspirationFilterOptionIndex];
    },
  },

  beforeUnmount() {
    eventBus.$emit('NolteCatalogMenuSticky:beforeUnmount');
  },

  mounted() {
    document.addEventListener('scroll', this.handleScroll.bind(this));

    eventBus.$once('NolteCatalogMenuSticky:beforeUnmount', () => {
      document.removeEventListener('scroll', this.handleScroll.bind(this));
    });
  },

  methods: {
    handleScroll() {
      requestAnimationFrame(() => {
        this.categoriesOpen = false;
        this.inspirationFilterOptionIndex = undefined;
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.nolte-catalog-menu-sticky__main {
  display: flex;
  justify-content: center;
  align-items: flex-start;
  width: 100%;

  .nolte-catalog-menu-sticky--is-mobile & {
    padding-left: $unit-base;
    padding-right: $unit-base;
    padding-top: 50px;
  }
}

.nolte-catalog-menu-sticky__categories,
.nolte-catalog-menu-sticky__inspiration-filter {
  @include helper__transition(border-radius);

  .nolte-catalog-menu-sticky--is-desktop & {
    background: none;

    &::before {
      content: none;
    }
  }

  .nolte-catalog-menu-sticky--is-desktop & :deep(.nolte-catalog-menu-categories__menu) {
    border-radius: 0;
  }

  .nolte-catalog-menu-sticky--is-mobile & {
    position: relative;

    &::before {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      box-shadow: 0 -5px 25px 5px rgba($color-black, 0.1);
      z-index: -1;
      border-radius: $radius-default;
    }
  }
}

.nolte-catalog-menu-sticky--is-mobile:not(.nolte-catalog-menu-sticky--inspiration-filter-open)
  .nolte-catalog-menu-sticky__categories,
.nolte-catalog-menu-sticky__inspiration-filter {
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
}

.nolte-catalog-menu-sticky--is-mobile .nolte-catalog-menu-sticky__categories {
  margin-right: $unit-half;
}

.nolte-catalog-menu-sticky__separator {
  width: 1px;
  height: $unit-base * 1.5;
  background-color: $color-athens-gray;
  align-self: center;
}

.nolte-catalog-menu-sticky--is-mobile.nolte-catalog-menu-sticky--inspiration-filter-open
  .nolte-catalog-menu-sticky__inspiration-filter {
  box-shadow: inset 0 -11px 0 -10px $color-athens-gray;
  margin-bottom: 0;
}

.nolte-catalog-menu-neo {
  margin: 0;
  height: 100%;

  .nolte-catalog-menu-sticky--is-desktop & {
    background: none;
  }

  .nolte-catalog-menu-sticky--is-mobile & {
    margin-left: $unit-half;
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
  }
}
</style>
