<template>
  <div class="content-favorite">
    <slot />
    <div class="icon" :class="{ 'icon--checked': checked }" v-if="getShowMoodboardSetting">
      <IconHeartWithShadow />
    </div>
    <button
      class="click-area"
      @click="toggle({ itemId, imageId })"
      v-if="getShowMoodboardSetting"
    ></button>
  </div>
</template>

<script>
import { mapActions, mapGetters, mapState } from 'vuex';
import { unifySitecoreId } from '@/components/helper';

import IconHeartWithShadow from '../assets/icons/IconHeartWithShadow.svg?inline';

export default {
  name: 'ContentFavorite',

  components: {
    IconHeartWithShadow,
  },

  props: {
    itemId: {
      type: String,
      required: true,
    },
    imageId: {
      type: String,
      required: true,
    },
  },

  data: () => ({
    enabled: true,
  }),

  computed: {
    ...mapState('moodboard', ['items']),
    ...mapGetters(['getShowMoodboardSetting']),

    checked() {
      const hit = this.items.find(item => {
        return (
          item.itemId === unifySitecoreId(this.itemId) &&
          item.imageId === unifySitecoreId(this.imageId)
        );
      });
      return hit ? true : false;
    },
  },

  methods: {
    ...mapActions('moodboard', {
      toggle: 'toggleItem',
    }),
  },
};
</script>

<style lang="scss" scoped>
.content-favorite {
  position: relative;
  overflow: hidden;
}

.icon {
  position: absolute;
  z-index: 1;
  right: -59px;
  top: -57px;
  pointer-events: none;

  :deep([class$='checked']) {
    @include helper__transition(fill);
    fill: $color-white;
  }

  &--checked {
    :deep([class$='checked']) {
      fill: $color-aureolin;
    }
  }
}

.click-area {
  position: absolute;
  z-index: 1;
  right: 0;
  top: 0;
  width: 2 * $unit-double;
  height: 2 * $unit-double;
  border: 0;
}
</style>
