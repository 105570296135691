export const searchResultsData = {
  TotalTime: 105,
  CountTime: 0,
  QueryTime: 53,
  Signature: null,
  Index: 'sitecore_sxa_master_index',
  Count: 148,
  Results: [
    {
      Headline: 'Beleuchtung',
      Copy: 'Ein individuelles Lichtkonzept in Ihrer neuen Küche schafft eine wohnliche Atmosphäre und setzt tolle Akzente. ',
      Image: { value: {} },
      Id: '19bb9158-a0dc-4b49-a089-67315f8dcb7e',
      Language: 'de-DE',
      Path: '/sitecore/content/Nolte JSS/Corporate Website/home/kuechenideen/beleuchtung',
      Url: '',
      Name: null,
      Html: '',
    },
    {
      Headline: 'Schränke',
      Copy: 'Offene Regale oder Hängeschränke bis unter die Decke? Kombinieren Sie verschiedene Schränke ganz nach Ihrem Geschmack.',
      Image: { value: {} },
      Id: '042c42b7-f6dc-43e5-833d-4117ebc17772',
      Language: 'de-DE',
      Path: '/sitecore/content/Nolte JSS/Corporate Website/home/kuechenideen/schraenke',
      Url: '',
      Name: null,
      Html: '',
    },
    {
      Headline: 'Innenorganisation',
      Copy: 'Kunststoff oder Echtholz – mit der passenden Innenorganisation bleibt in Ihrer neuen Küche alles in bester Ordnung.',
      Image: { value: {} },
      Id: '7faabf62-3b7c-4a21-8253-cdd0aa70a3ff',
      Language: 'de-DE',
      Path: '/sitecore/content/Nolte JSS/Corporate Website/home/kuechenideen/innenorganisation',
      Url: '',
      Name: null,
      Html: '',
    },
  ],
};
