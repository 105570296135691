<template>
  <main class="international-project-article">
    <Placeholder name="jss-international-projects-project-header" :rendering="route" />
    <Placeholder name="jss-international-projects-project-main" :rendering="route" />
    <Placeholder name="jss-dataprovider" :rendering="route" />
  </main>
</template>

<script>
import Placeholder from '@/components/Placeholder';
import LayoutMixin from './LayoutMixin';

export default {
  name: 'InternationalProjectArticle',
  mixins: [LayoutMixin],

  components: {
    Placeholder,
  },
};
</script>
