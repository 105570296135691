<template>
  <main class="mood-detail-layout">
    <Placeholder name="jss-mood-detail-main" :rendering="route" />
    <Placeholder name="jss-dataprovider" :rendering="route" />
  </main>
</template>
<script>
import Placeholder from '@/components/Placeholder';
import LayoutMixin from './LayoutMixin';

export default {
  name: 'MoodDetail',
  mixins: [LayoutMixin],
  components: {
    Placeholder,
  },
  props: {
    rendering: {
      type: Object,
      default: undefined,
    },
  },
};
</script>
