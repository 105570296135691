<template>
  <div class="editorial-mnk-selectdata">
    <EditorialHeadline
      v-if="textHasValue(fields['Headline'])"
      :fields="{ headline: fields['Headline'] }"
      class="headline"
      headline-level="h2"
    />
    <div class="spacing">
      <sc-text
        v-if="textHasValue(fields['Headline Moods'])"
        :field="fields['Headline Moods']"
        tag="p"
        class="subline"
      />

      <div
        class="buttons selection-buttons"
        :class="{ 'selection-buttons--moodboard-visible': moodboardIsLoading === false }"
      >
        <NolteButton
          :type="sendMoodsToDealer ? 'yellow--plain' : 'grey--plain'"
          @click="sendMoodsToDealer = true"
          :fully-disabled="!moodboardHasEntries"
        >
          <sc-text :field="fields['Set Moods Yes Label']" />
        </NolteButton>

        <NolteButton
          :type="!sendMoodsToDealer ? 'yellow--plain' : 'grey--plain'"
          @click="sendMoodsToDealer = false"
          :fully-disabled="!moodboardHasEntries"
        >
          <sc-text :field="fields['Set Moods No Label']" />
        </NolteButton>

        <EditorialButton
          v-if="!moodboardHasEntries && linkHasValue(buttonNoMoods.link)"
          :fields="buttonNoMoods"
        />
      </div>
    </div>

    <div class="spacing">
      <sc-text
        v-if="textHasValue(fields['Headline Checklist'])"
        :field="fields['Headline Checklist']"
        tag="p"
        class="subline"
      />

      <div
        class="buttons selection-buttons"
        :class="{ 'selection-buttons--checklist-visible': userHasChecklistEntries !== null }"
      >
        <NolteButton
          :type="sendChecklistToDealer ? 'yellow--plain' : 'grey--plain'"
          @click="sendChecklistToDealer = true"
          :fully-disabled="!userHasChecklistEntries"
        >
          <sc-text :field="fields['Set Checklist Yes Label']" />
        </NolteButton>

        <NolteButton
          :type="!sendChecklistToDealer ? 'yellow--plain' : 'grey--plain'"
          @click="sendChecklistToDealer = false"
          :fully-disabled="!userHasChecklistEntries"
        >
          <sc-text :field="fields['Set Checklist No Label']" />
        </NolteButton>

        <EditorialButton
          v-if="!userHasChecklistEntries && linkHasValue(buttonNoChecklist.link)"
          :fields="buttonNoChecklist"
        />
      </div>
    </div>

    <div class="spacing">
      <div v-if="plans === null && !isEditing"></div>
      <div class="no-plans" v-else-if="isEditing || plans.length === 0">
        <sc-text :field="fields['No Plans Headline']" tag="p" class="subline" />

        <EditorialRichtext
          v-if="textHasValue(fields['No Plans Text'])"
          :fields="{ content: fields['No Plans Text'] }"
        />

        <EditorialButton v-if="linkHasValue(fields['No Plans Link'])" :fields="buttonNoPlans" />
      </div>

      <div v-else>
        <sc-text
          v-if="textHasValue(fields['Headline Kitchenplans'])"
          :field="fields['Headline Kitchenplans']"
          tag="p"
          class="subline"
        />

        <table class="table">
          <tbody>
            <tr v-for="plan in plans" :key="plan.id">
              <td class="table-checkbox">
                <NolteFormCheckbox :id="plan.id" name="fsd" v-model="plan.selected" />
              </td>
              <td class="table-thumbnail">
                <img :src="plan.thumbnail" alt="" />
              </td>
              <td class="table-name">
                {{ plan.title }}
              </td>
            </tr>
          </tbody>
        </table>
      </div>

      <div class="buttons">
        <EditorialButton v-if="linkHasValue(fields['Back Button'])" :fields="buttonBack" />

        <EditorialButton v-if="isEditing" :fields="buttonNext" />
        <div class="editorial-button-wrapper" v-else-if="linkHasValue(fields['Next Button'])">
          <NolteButton type="yellow--plain" @click="pushToSession" :disabled="buttonNextDisabled">
            {{ fields['Next Button'].value.text }}
          </NolteButton>
        </div>
      </div>
    </div>

    <ContentLightbox
      @open="setSetPlanFailedLightboxVisible(true)"
      @close="setSetPlanFailedLightboxVisible(false)"
      :visible="setPlanFailedLightboxVisible"
    >
      <NolteHeadline v-if="textHasValue(fields['Set Plan Failed Headline'])">
        {{ fields['Set Plan Failed Headline'].value }}
      </NolteHeadline>

      <p
        v-if="textHasValue(fields['Set Plan Failed Text'])"
        v-html="fields['Set Plan Failed Text'].value"
      />

      <NolteButton
        v-if="textHasValue(fields['Set Plan Failed Button Text'])"
        type="yellow--plain"
        @click="setSetPlanFailedLightboxVisible(false)"
      >
        {{ fields['Set Plan Failed Button Text'].value }}
      </NolteButton>
    </ContentLightbox>
  </div>
</template>

<script>
/* eslint-disable */
import { mapGetters, mapState } from 'vuex';
import { dataFetcher } from '@/dataFetcher';
import { textHasValue, linkHasValue } from './helper';

import { Text } from '@sitecore-jss/sitecore-jss-vue';
import ContentLightbox from '@/components/ContentLightbox';
import EditorialHeadline from '@/components/EditorialHeadline';
import EditorialButton from '@/components/EditorialButton';
import EditorialRichtext from '@/components/EditorialRichText';
import NolteHeadline from '@/nolte-ui/NolteHeadline';
import NolteButton from '@/nolte-ui/NolteButton';
import NolteFormCheckbox from '@/nolte-ui/NolteForm/NolteFormCheckbox';

export default {
  name: 'EditorialMNKSelectData',

  components: {
    ScText: Text,
    ContentLightbox,
    EditorialHeadline,
    EditorialButton,
    EditorialRichtext,
    NolteHeadline,
    NolteButton,
    NolteFormCheckbox,
  },

  data: () => ({
    plans: null,
    setPlanFailedLightboxVisible: false,
    userHasChecklistEntries: null,
    sendMoodsToDealer: true,
    sendChecklistToDealer: true,
  }),

  computed: {
    ...mapState('jss', ['sitecoreContext']),
    ...mapGetters('jss', ['isEditing']),
    ...mapGetters('moodboard', { moodboardHasEntries: 'hasEntries' }),
    ...mapState('moodboard', { moodboardIsLoading: 'isLoading' }),

    buttonNoPlans() {
      return {
        link: this.fields['No Plans Link'],
        _extendedParams: this.buttonParams('yellow--plain'),
      };
    },

    buttonNoMoods() {
      return {
        link: this.fields['No Moods Link'],
        _extendedParams: this.buttonParams('grey--outline'),
      };
    },

    buttonNoChecklist() {
      return {
        link: this.fields['No Checklist Link'],
        _extendedParams: this.buttonParams('grey--outline'),
      };
    },

    buttonBack() {
      return {
        link: this.fields['Back Button'],
        _extendedParams: this.buttonParams('grey--plain'),
      };
    },

    buttonNext() {
      return {
        link: this.fields['Next Button'],
        _extendedParams: this.buttonParams('yellow--plain'),
      };
    },

    buttonNextDisabled() {
      return (
        (this.plans === null || this.plans?.find(item => item.selected) === undefined) && // No plans selected
        (this.userHasChecklistEntries === false ||
          this.userHasChecklistEntries === null ||
          this.sendChecklistToDealer === false) && // Checklist without entries or user selected "no" (send Checklist to dealer)
        (this.moodboardHasEntries === false || this.sendMoodsToDealer === false)
      ); // Moodboard without entries or user selected "no" (send Moodboard to dealer)
    },
  },

  methods: {
    textHasValue,
    linkHasValue,

    buttonParams(type) {
      return {
        ButtonType: {
          fields: {
            Type: {
              value: type,
            },
          },
        },
      };
    },

    setSetPlanFailedLightboxVisible(status) {
      this.setPlanFailedLightboxVisible = status;
    },

    getPlans() {
      this.api('/Kitchenplanning/GetPlans').then(result => {
        this.plans = result.data.map(item => ({
          id: item.Item1.PlnId,
          title: item.Item1.Title,
          thumbnail: item.Item1.MediaData.PlanSnapshotThumb,
          selected: false,
        }));
      });
    },

    getSessionData() {
      this.api('/Contacts/RetailerRequest/GetSessionData').then(result => {
        this.userHasChecklistEntries = result.data.hasChecklistEntries;
        this.sendChecklistToDealer = result.data.hasChecklistEntries;
      });
    },

    pushToSession() {
      const idString = this.plans
        .filter(plan => plan.selected)
        .map(plan => plan.id)
        .join(',');
      const payload = {
        sendMoodsToDealer: this.sendMoodsToDealer && this.moodboardHasEntries,
        sendChecklistToDealer: this.sendChecklistToDealer && this.userHasChecklistEntries,
        planIds: idString,
      };

      this.api('/Contacts/RetailerRequest/SetToSession', payload)
        .then(result => {
          this.$router.push(this.fields['Next Button'].value.href);

          // this is a workaround as we lose the session sometimes when switching to the success
          // page and thus loose the kitchenplan ids. we save the payload into the session storage
          // as we need those kitchenplan ids on the success page in component
          // "EditorialMNKKitchenPlanDownload"
          sessionStorage.setItem('dealerTransmission', JSON.stringify(payload));
        })
        .catch(() => {
          this.setSetPlanFailedLightboxVisible(true);
          sessionStorage.removeItem('dealerTransmission');
        });
    },

    api(node, data) {
      return dataFetcher(process.env.VUE_APP_API_BASE_URL + node, data).then(response => {
        if (response.status !== 200) {
          throw new Error('No HTTP 200');
        }
        return response;
      });
    },
  },

  mounted() {
    this.getPlans();
    this.getSessionData();
  },
};
</script>

<style lang="scss" scoped>
.editorial-mnk-selectdata {
  background: $color-white;

  // triggers the complete rendering of the background
  padding-top: 1px;
}

.spacing {
  @include spacing;

  .editorial-headline-wrapper {
    padding: 0;
  }
  .subline {
    @include text-intro;
  }
  :deep(.headline--level3) {
    text-transform: none;
    letter-spacing: 0;
  }
}

.headline {
  :deep() > * {
    @include helper__greater($bp-768) {
      font-size: 40px;
      line-height: (48 / 40);
    }
  }
}

.no-plans {
  .editorial-rich-text-wrapper {
    padding: 0;
    margin: $unit-double 0;
  }
  :deep(.editorial-button-wrapper) {
    margin: $unit-double 0;
    padding: 0;
    @include helper__greater($bp-768) {
      margin: $unit-double 0;
      padding: 0;
    }

    .editorial-button {
      @include text-h5;
    }
  }
}
p {
  margin: $unit-base 0;
}
.table {
  width: 100%;

  td {
    transition: all 0.5s;
    text-align: center;
    border-top: 2px solid $color-concret;
    padding: $unit-base $unit-base;
  }

  th.table-name,
  td.table-name {
    text-align: left;
    width: 95%;
  }
  td.table-thumbnail img {
    max-width: 2 * $unit-double;
    max-height: 2 * $unit-double;
    border: 2px solid $color-athens-gray;
  }
  td.table-checkbox {
    .nolte-form-checkbox {
      margin-bottom: 2px;
    }
  }
}

.buttons {
  display: flex;
  justify-content: flex-end;

  :deep() {
    .editorial-button-wrapper {
      margin: $unit-base 0;
    }
    .editorial-button-wrapper:first-child {
      padding: 0 $unit-base 0 0;
    }
    .editorial-button {
      @include text-h5;
    }
  }

  @include helper__until($bp-768) {
    width: 100%;
    flex: 0 0 50%;

    > * {
      width: 100%;
    }

    :deep(.editorial-button-wrapper) {
      padding: 0;
    }

    :deep(.editorial-button),
    :deep(.nolte-button) {
      min-width: 0;
      max-width: none;
      width: 100%;
      display: block;
    }
  }
}

.selection-buttons {
  justify-content: flex-start;
  visibility: hidden;

  @include helper__until($bp-768) {
    display: block;
  }

  :deep(.nolte-button) {
    margin: 0 $unit-base 0 0;
    min-width: 0;

    @include helper__until($bp-768) {
      display: inline-block;
      width: auto;
    }
  }

  :deep(.editorial-button-wrapper) {
    @include helper__greater($bp-768) {
      margin: 0;
    }
  }
}
.selection-buttons--checklist-visible,
.selection-buttons--moodboard-visible {
  visibility: visible;
}
</style>
