<template>
  <div class="mnk-menu-loggedin">
    <ul class="navigation-list">
      <li class="navigation-item" v-for="(item, index) in items" :key="index">
        <ContentLink
          class="navigation-link"
          :editable="false"
          :field="{ value: item }"
          @click="setSearchMnkMenuVisibility(false)"
        >
          <span class="navigation-label">{{ item.text }}</span>
        </ContentLink>
      </li>
    </ul>

    <NolteButton :link-url="logoutLink.url" type="yellow--plain" @click="logoutUser">
      {{ $t('mnk-logout') }}
    </NolteButton>
  </div>
</template>

<script>
import { mapState, mapActions, mapMutations, mapGetters } from 'vuex';

import ContentLink from '@/components/ContentLink';
import NolteButton from '@/nolte-ui/NolteButton';

export default {
  name: 'GlobalHeaderMNKMenuLoggedIn',

  components: {
    ContentLink,
    NolteButton,
  },

  props: {
    children: {
      type: Array,
      required: true,
    },
  },

  data: () => ({
    logoutLink: {
      url: '',
    },
  }),

  computed: {
    ...mapState('jss', ['sitecoreContext']),
    ...mapGetters({ actionLinks: 'getMnkActionLinks' }),

    mnkRootItem() {
      return this.sitecoreContext.site?.login || null;
    },

    items() {
      // the passed children complemented by the root item of MNK
      if (!this.mnkRootItem) return [];
      if (!this.children) return [];
      return [{ ...this.mnkRootItem, linktype: 'internal' }, ...this.children];
    },
  },

  methods: {
    ...mapActions(['logout']),
    ...mapMutations(['setSearchMnkMenuVisibility']),

    logoutUser() {
      // hide mnk navigation
      this.setSearchMnkMenuVisibility(false);

      // logout via api
      this.logout().then(() => {
        // redirect to logout success page
        // the query parameter "loggedout" is necessary to force a reload if we are already on the
        // "logout-success-page", because we need a fresh AntiForgery-Token from the
        // JSS Layout Service to be able to login again
        this.$router.push({
          path: this.actionLinks['logout-success-page'],
          query: { loggedout: true },
        });
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.mnk-menu-loggedin {
  margin: $header-height-mobile + $unit-base $unit-base $header-height-mobile + $unit-base 60px;

  @include helper__greater($bp-320) {
    margin-left: 90px;
  }
}

.navigation-list {
  display: flex;
  flex-direction: column;
  margin: 0 0 $unit-base 0;
}

.navigation-item {
  @include text-h5;
  color: $color-ironside-gray;
  margin-bottom: 24px;

  @include helper__greater($bp-1280) {
    display: flex;
    align-items: center;
  }
}

.navigation-link {
  display: block;
  padding: $unit-half 0;
  display: flex;
  height: 100%;
  align-items: center;

  .navigation-label {
    @include helper__transition(color);
    padding-bottom: 2px;
  }
}

.navigation-link:hover .navigation-label {
  color: $color-delta;
}

.navigation-link.router-link-exact-active .navigation-label {
  color: inherit;
  box-shadow: 0 8px rgba($color-aureolin, 1);
}

// this first item is the MNK home item and we don't want to show the active marker if one of its
// siblings (which are actually children in the tree) is active
.navigation-link.router-link-active:not(:first-child) .navigation-label {
  color: inherit;
  box-shadow: 0 8px rgba($color-aureolin, 1);
}
</style>
