<template>
  <ul
    class="nolte-sticky-menu-tabs"
    :class="{
      'nolte-sticky-menu-tabs--selected': selectedIndex !== undefined,
      'nolte-sticky-menu-tabs--styled': styled,
    }"
  >
    <li
      class="nolte-sticky-menu-tabs__tab"
      :class="{
        'nolte-sticky-menu-tabs__tab--selected': index === selectedIndex,
      }"
      v-for="({ label, title, linkUrl }, index) in links"
      :key="`nolte-sticky-menu-tabs-${index}`"
    >
      <ContentLink
        :editable="false"
        class="nolte-sticky-menu-tabs__link"
        :field="{ value: { href: linkUrl, linktype: 'internal', title: title || label } }"
      >
        <div class="nolte-sticky-menu-tabs__indicator">{{ label }}</div>
      </ContentLink>
    </li>
  </ul>
</template>

<script>
import ContentLink from '@/components/ContentLink';

export default {
  name: 'NolteStickyMenuTabs',

  components: {
    ContentLink,
  },

  props: {
    links: {
      type: Array,
      required: true,
    },
    selectedIndex: {
      type: Number,
      default: undefined,
    },
    styled: {
      type: Boolean,
      default: false,
    },
  },
};
</script>

<style lang="scss" scoped>
.nolte-sticky-menu-tabs {
  display: inline-flex;
  // Fix stacking context
  position: relative;
}

.nolte-sticky-menu-tabs--styled {
  @include helper__transition((margin-bottom, border-radius));
  position: relative;
  background-color: $color-white;
  border-top-left-radius: $radius-default;
  border-top-right-radius: $radius-default;
  margin-left: 16px;
  margin-right: 16px;
  margin-top: 50px;

  &::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    box-shadow: 0 -5px 25px 5px rgba($color-black, 0.1);
    z-index: -1;
    border-top-left-radius: $radius-default;
    border-top-right-radius: $radius-default;
  }
}

.nolte-sticky-menu-tabs__link {
  @include helper__button;
  @include helper__transition(color);
  @include text-h5;
  display: inline-block;
  padding: $unit-base + $unit-half $unit-base;

  &:hover {
    color: $color-delta;
  }
}

.nolte-sticky-menu-tabs__tab:first-of-type .nolte-sticky-menu-tabs__link {
  padding-left: $unit-base * 2;
}

.nolte-sticky-menu-tabs__tab:last-of-type .nolte-sticky-menu-tabs__link {
  padding-right: $unit-base * 2;
}

.nolte-sticky-menu-tabs__indicator {
  position: relative;

  &::before {
    @include helper__transition((transform, background-color, bottom));
    content: '';
    position: absolute;
    background-color: $color-ironside-gray;
    height: $unit-half;
    width: 100%;
    left: 0;
    bottom: $unit-base * -1.5;
    // rotate(0.01deg) avoids jitter in Chrome
    // https://greensock.com/forums/topic/16385-chrome-bug-when-i-scale-an-element-with-background-image-the-image-flickers/
    transform: scaleY(0) rotate(0.01deg);
    // Fix for Chrome breaks Firefox. Need another solution to avoid jitter in Firefox
    @include helper__composited-layer;
    transform-origin: bottom;
  }
}

.nolte-sticky-menu-tabs__tab--selected,
:deep(.router-link-active) {
  .nolte-sticky-menu-tabs__indicator::before {
    transform: scaleY(1);
  }
}
</style>
