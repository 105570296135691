<template>
  <div class="project-gallery-container-wrapper" :class="`editorial-component--${design}`">
    <div class="project-gallery-container">
      <EditorialHeadline class="project-gallery__headline" :fields="{ headline: headline }" />
      <NolteGrid>
        <NolteGridCell
          v-for="(component, index) in formattedProjects"
          :key="`project-item_${index}`"
          :type="component.aspectRatio.attribute"
        >
          <NolteTeaser
            class="project-gallery-item"
            :key="index"
            :link-url="component.url"
            :image="component.image"
            :headline="component.region"
            :copy="component.title"
            :aspect-ratio="component.aspectRatio"
          />
        </NolteGridCell>
      </NolteGrid>
    </div>
  </div>
</template>

<script>
import EditorialHeadline from './EditorialHeadline';
import NolteGrid from '../nolte-ui/NolteGrid';
import NolteGridCell from '../nolte-ui/NolteGrid/NolteGridCell';
import NolteTeaser from '../nolte-ui/NolteTeaser';
import { processGrid } from './helper';

export default {
  name: 'EditorialInternationalProjectsTemplate',

  components: {
    EditorialHeadline,
    NolteGrid,
    NolteGridCell,
    NolteTeaser,
  },

  props: {
    fields: {
      type: Object,
      default: () => ({}),
    },
    projects: {
      type: Array,
      default: () => [],
    },
  },

  computed: {
    headline() {
      return this.fields.data.datasource.fields[0].jss;
    },

    formattedProjects() {
      const projects = this.projects.map(item => {
        return this.formatTeaser(item.area, item.project);
      });

      return this.processGrid(projects);
    },

    design() {
      // returns default or dark
      return this.fields._extendedParams?.BackgroundDesign?.fields?.Type?.value || 'default';
    },
  },

  methods: {
    processGrid,

    formatTeaser(area, project) {
      return {
        type: 'Teaser',
        region: area.title.value,
        image: project.image.jss,
        title: project.title.value,
        url: project.url,
      };
    },
  },
};
</script>

<style scoped lang="scss">
.editorial-component--dark {
  overflow: auto;
  background: $color-concret;
  margin: $unit-double 0;

  @include helper__greater($bp-768) {
    margin: $unit-double * 2 auto;
  }
}

.project-gallery-container {
  padding-bottom: $unit-half;
}
</style>
