<template>
  <div class="nolte-media-detail-group">
    <div
      class="nolte-media-detail-group__swiper"
      v-if="mediaSlotComponents && mediaSlotComponents[activeIndex]"
    >
      <button
        v-if="isSlider"
        class="nolte-media-detail-group__swiper-navigation-left"
        ref="swiperNavigationLeft"
      >
        <IconArrowLeft />
      </button>

      <div class="nolte-media-detail-group__media-container">
        <div class="swiper-container" ref="swiperContainer">
          <div class="swiper-wrapper">
            <div
              v-for="(component, index) in mediaSlotComponents"
              :key="`media_${index}`"
              class="swiper-slide"
            >
              <EditorialImage
                :media="component[0].fields.media"
                default-aspect-ratio="3:2"
                default-sizes="1024px"
                :editable="false"
              />
            </div>
          </div>
          <div
            v-if="isSlider && mediaSlotComponents"
            class="nolte-media-detail-group__swiper-counter"
          >
            {{ activePosition }}/{{ mediaSlotComponents.length }}
          </div>
        </div>
      </div>

      <button
        v-if="isSlider"
        class="nolte-media-detail-group__swiper-navigation-right"
        ref="swiperNavigationRight"
      >
        <IconArrowRight />
      </button>
    </div>

    <div v-if="isSlider" class="nolte-media-detail-group__pagination" ref="swiperPagination"></div>

    <div
      class="nolte-media-detail-group__detail-container"
      v-if="detailSlotComponents && detailSlotComponents[activeIndex]"
    >
      <transition name="fade" mode="out-in">
        <EditorialDetailSimple
          v-if="detailSlotComponents[activeIndex][0].componentName === 'EditorialDetailSimple'"
          :fields="detailSlotComponents[activeIndex][0].fields"
          :key="`detail_${activeIndex}`"
        /><EditorialDetailShowroom
          v-else
          :fields="detailSlotComponents[activeIndex][0].fields"
          :key="`detail_${activeIndex}`"
        />
      </transition>
    </div>
  </div>
</template>

<script>
import Swiper from 'swiper';

import { unitHalf } from '../../styles/variables.scss';
import IconArrowLeft from '../../assets/icons/IconArrowLeft.svg?inline';
import IconArrowRight from '../../assets/icons/IconArrowRight.svg?inline';
import eventBus from '@/lib/eventBus';
import EditorialImage from '@/components/EditorialImage';
import EditorialDetailShowroom from '@/components/EditorialDetailShowroom';
import EditorialDetailSimple from '@/components/EditorialDetailSimple';

export default {
  name: 'NolteMediaDetailGroup',

  components: {
    IconArrowLeft,
    IconArrowRight,
    EditorialImage,
    EditorialDetailShowroom,
    EditorialDetailSimple,
  },

  data() {
    return {
      swiperInitialized: false,
      activeIndex: 0,
    };
  },

  props: {
    components: {
      type: Array,
      default: () => [],
    },
  },

  computed: {
    mediaSlotComponents() {
      return this.components.map(
        ({ $props }) => $props.rendering.placeholders['nolte-media-detail_media']
      );
      //.filter(component => component[0]?.componentName === 'EditorialImage');
    },

    detailSlotComponents() {
      //return this.slots.map(({ detail }) => wrapSlotContent('DetailSlot', detail));
      return this.components
        .map(({ $props }) => $props.rendering.placeholders['nolte-media-detail_detail'])
        .filter(
          component =>
            component[0]?.componentName === 'EditorialDetailSimple' ||
            component[0]?.componentName === 'EditorialDetailShowroom'
        );
    },

    activePosition() {
      return this.activeIndex + 1;
    },

    isSlider() {
      return this.components.length > 1;
    },
  },

  mounted() {
    // Register watcher dynamically in mounted because before
    // this.$refs is not fulfilled
    this.$watch(
      'isSlider',
      isSlider => {
        if (isSlider) {
          this.initSwiper();
        }
      },
      { immediate: true }
    );
  },

  beforeUnmount() {
    eventBus.$emit('NolteMediaDetailGroup:beforeUnmount');
  },

  methods: {
    initSwiper() {
      if (this.swiperInitialized) {
        return;
      }

      this.swiperInitialized = true;

      const swiper = new Swiper(this.$refs?.swiperContainer, {
        grabCursor: true,
        spaceBetween: parseInt(unitHalf, 10),
        pagination: {
          el: this.$refs?.swiperPagination,
          clickable: true,
        },
        navigation: {
          prevEl: this.$refs?.swiperNavigationLeft,
          nextEl: this.$refs?.swiperNavigationRight,
        },
        dynamicBullets: true,
        iOSEdgeSwipeDetection: true,
        loop: true,
        keyboard: true,
      });

      swiper.on('slideChange', () => {
        this.activeIndex = swiper.realIndex;
      });

      eventBus.$once('NolteMediaDetailGroup:beforeUnmount', () => {
        swiper.destroy();
      });
    },
  },
};
</script>

<style lang="scss" scoped>
@import '~swiper/swiper.scss';
@import '../../styles/swiper-styles.scss';

.fade-enter-active,
.fade-leave-active {
  @include helper__transition(opacity, $transition-duration-default / 2);
}

.fade-enter,
.fade-leave-to {
  opacity: 0;
}

.nolte-media-detail-group {
  // make element appear on top of shadow of media-container mixin
  z-index: 0;
  position: relative;
  @include media-detail-container;
}

.nolte-media-detail-group__swiper {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
}

.nolte-media-detail-group__swiper-navigation-left,
.nolte-media-detail-group__swiper-navigation-right {
  @include helper__button;
  @include helper__transition(color);
  width: $unit-base * 4;
  height: $unit-base * 4;
  background-color: $color-white;
  border-radius: 50%;
  color: $color-delta;
  flex-shrink: 0;
  margin-left: $unit-double;
  margin-right: $unit-double;
  position: relative;

  &:hover {
    color: $color-ironside-gray;
  }

  @include helper__until($bp-1024) {
    display: none;
  }

  &::before {
    content: '';
    position: absolute;
    top: $unit-base;
    right: $unit-half;
    bottom: 0;
    left: $unit-half;
    border-radius: 50%;
    z-index: -1;
    box-shadow: 0 0 50px rgba($color-black, 0.2);
  }
}

.nolte-media-detail-group__swiper-counter {
  @include helper__until($bp-768) {
    display: none;
  }

  @include helper__greater($bp-768) {
    @include text-h4;
    position: absolute;
    top: $unit-base;
    left: $unit-base;
    z-index: 1;
    background-color: rgba($color-tuatara, 0.8);
    color: $color-white;
    padding: $unit-half;
    border-radius: $radius-default / 2;
    min-width: 3ch;
    box-sizing: content-box;
    text-align: center;
  }
}

.nolte-media-detail-group__detail-container {
  @include detail-container;
  z-index: 1;
}

.nolte-media-detail-group__media-container {
  @include media-container;
}

.swiper-container {
  // Override swiper styles
  margin: 0;
  background-color: $color-concret;
}

$bullet-size--default: $unit-half;
$bullet-size--active: 22px;
.nolte-media-detail-group__pagination {
  margin-top: -$unit-base * 3.5;
  margin-bottom: $unit-base + $unit-half;
  display: flex;
  justify-content: center;
  align-items: center;

  @include helper__greater($bp-768) {
    display: none;
  }

  :deep() {
    .swiper-pagination-bullet {
      width: $bullet-size--active;
      height: $bullet-size--active;
      cursor: pointer;
      transform: scale(#{$bullet-size--default / $bullet-size--active});
      background-color: $color-white;
      border-radius: 50%;
      box-shadow: 0 0 10px rgba($color-black, 0.4);
      @include helper__transition((transform));

      & + .swiper-pagination-bullet {
        margin-left: 10px;
      }

      .swiper-pagination-clickable & {
        cursor: pointer;
      }
    }

    .swiper-pagination-bullet-active {
      transform: scale(1);
      box-shadow: 0 0 15px rgba($color-black, 0.35);
    }
  }
}
</style>
