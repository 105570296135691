<template>
  <div class="editorial-newsdetail" v-if="item">
    <EditorialSimpleHeader :fields="simpleHeaderFields" />

    <div class="editorial-newsdetail__content">
      <div
        class="editorial-newsdetail__content__dateline"
        v-if="isEditing || textHasValue(item.dateline.jss.value)"
      >
        <EditorialTextMultiLine :fields="{ copy: item.dateline.jss }" />
      </div>

      <div
        class="editorial-newsdetail__content__introduction"
        v-if="isEditing || textHasValue(item.introduction.jss.value)"
      >
        <EditorialTextMultiLine :fields="{ copy: item.introduction.jss }" />
      </div>

      <div class="editorial-newsdetail__content__body">
        <EditorialRichText :fields="{ content: item.body.jss }" />
      </div>
    </div>
  </div>
  <div v-else>EditorialNewsDetail: Did not find any data for the current language.</div>
</template>

<script>
import EditorialSimpleHeader from './EditorialSimpleHeader';
import EditorialRichText from './EditorialRichText';
import EditorialTextMultiLine from './EditorialTextMultiLine';
import { textHasValue } from './helper';
import { mapGetters } from 'vuex';

export default {
  name: 'EditorialNewsDetail',

  components: {
    EditorialSimpleHeader,
    EditorialRichText,
    EditorialTextMultiLine,
  },

  computed: {
    ...mapGetters('jss', ['isEditing']),

    simpleHeaderFields() {
      return {
        headline: this.item?.headline?.jss,
        copy: this.item?.subheadline?.jss,
        headerImage: this.item?.headerImage?.jss,
        link: { value: { href: '' } },
      };
    },

    item() {
      return this.fields?.data?.contextItem;
    },
  },

  methods: {
    textHasValue,
  },
};

/*
The used graphQL query:
query NewsDetail($contextItem: String!) {
	contextItem: item(path: $contextItem) {
    headline: field(name: "Headline") {
      jss
    }
    subheadline: field(name: "Subheadline") {
      jss
    }
    dateline: field(name: "Dateline") {
      jss
    }
    introduction: field(name: "Introduction") {
      jss
    }
    body: field(name: "Body") {
      jss
    }
    headerImage: field(name: "Header Image") {
      jss
    }
  }
}
*/
</script>

<style lang="scss" scoped>
.editorial-newsdetail__content {
  @include text-copy;
  padding: $unit-double 0;

  // Reduce the margins for *__dateline, *__introduction and *__copy
  > * > * {
    margin-top: $unit-double;
    margin-bottom: $unit-double;
  }
}

.editorial-newsdetail__content__dateline {
  @include text-h4;
}

.editorial-newsdetail__content__introduction {
  @include text-intro;
}
</style>
