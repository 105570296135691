<template>
  <li
    class="nolte-catalog-menu-inspiration-filter-option"
    :class="{ 'nolte-catalog-menu-inspiration-filter-option--active': active }"
  >
    <ContentLink
      :editable="false"
      class="nolte-catalog-menu-inspiration-filter-option__link"
      :field="{ value: { href: url, linktype: 'internal', title: title } }"
      @click="$emit('click')"
    >
      <figure class="nolte-catalog-menu-inspiration-filter-option__figure">
        <div class="nolte-catalog-menu-inspiration-filter-option__icon-wrapper">
          <component
            v-if="isVector"
            :is="iconComponent"
            class="nolte-catalog-menu-inspiration-filter-option__icon-vector"
          />
          <keep-alive v-else>
            <img
              :src="imageUrl"
              alt=""
              class="nolte-catalog-menu-inspiration-filter-option__icon-raster"
            />
          </keep-alive>
        </div>

        <figcaption class="nolte-catalog-menu-inspiration-filter-option__label">
          {{ label }}
        </figcaption>
      </figure>
    </ContentLink>
  </li>
</template>

<script>
import { capitalizeFirstLetter } from '../helper';

import IconDesignGlass from '../../assets/icons/catalog/IconDesignGlass.jpg';
import IconDesignMetal from '../../assets/icons/catalog/IconDesignMetal.png';
import IconDesignPlain from '../../assets/icons/catalog/IconDesignPlain.png';
import IconDesignStoneAndConcrete from '../../assets/icons/catalog/IconDesignStoneAndConcrete.jpg';
import IconDesignVarnish from '../../assets/icons/catalog/IconDesignVarnish.png';
import IconDesignWood from '../../assets/icons/catalog/IconDesignWood.jpg';

import IconHandleWith from '../../assets/icons/catalog/IconHandleWith.svg?inline';
import IconHandleWithout from '../../assets/icons/catalog/IconHandleWithout.svg?inline';

import IconShapeG from '../../assets/icons/catalog/IconShapeG.svg?inline';
import IconShapeIsland from '../../assets/icons/catalog/IconShapeIsland.svg?inline';
import IconShapeL from '../../assets/icons/catalog/IconShapeL.svg?inline';
import IconShapeLine from '../../assets/icons/catalog/IconShapeLine.svg?inline';
import IconShapeU from '../../assets/icons/catalog/IconShapeU.svg?inline';

import IconUtilityroom from '../../assets/icons/catalog/IconRoomHousekeeping.svg?inline';
import IconKitchen from '../../assets/icons/catalog/IconRoomKitchen.svg?inline';
import IconLiving from '../../assets/icons/catalog/IconRoomLiving.svg?inline';

import ContentLink from '@/components/ContentLink';

function preloadImage(url) {
  const img = new Image();
  img.src = url;
}

const optionImages = {
  IconDesignGlass,
  IconDesignMetal,
  IconDesignPlain,
  IconDesignStoneAndConcrete,
  IconDesignVarnish,
  IconDesignWood,
};

export default {
  name: 'NolteCatalogMenuInspirationFilterOption',

  components: {
    IconHandleWith,
    IconHandleWithout,

    IconShapeG,
    IconShapeIsland,
    IconShapeL,
    IconShapeLine,
    IconShapeU,

    IconUtilityroom,
    IconKitchen,
    IconLiving,

    ContentLink,
  },

  props: {
    url: {
      type: String,
      required: true,
    },
    label: {
      type: String,
      required: true,
    },
    title: {
      type: String,
      default: '',
    },
    iconId: {
      type: String,
      required: true,
    },
    active: {
      type: Boolean,
      default: false,
    },
  },

  created() {
    // preload
    Object.values(optionImages).forEach(image => preloadImage(image));
  },

  computed: {
    capitalizedId() {
      return capitalizeFirstLetter(this.iconId);
    },

    isVector() {
      if (Object.keys(optionImages).includes(`Icon${this.capitalizedId}`)) {
        return false;
      }
      return true;
    },

    iconComponent() {
      if (!this.isVector) {
        return;
      }

      return `Icon${this.capitalizedId}`;
    },

    imageUrl() {
      if (this.isVector) {
        return;
      }

      return optionImages[`Icon${this.capitalizedId}`];
    },
  },
};
</script>

<style lang="scss" scoped>
$element-width: $unit-base * 6;
$element-height: $unit-base * 5.5;

.nolte-catalog-menu-inspiration-filter-option {
  box-sizing: content-box;

  &:hover .nolte-catalog-menu-inspiration-filter-option__icon-wrapper {
    box-shadow: inset 0 0 0 1px rgba($color-black, 0.1);
  }

  &:first-of-type .nolte-catalog-menu-inspiration-filter-option__link {
    padding-left: $unit-base;
  }

  &:last-of-type .nolte-catalog-menu-inspiration-filter-option__link {
    padding-right: $unit-base;
  }
}

.nolte-catalog-menu-inspiration-filter-option--active
  .nolte-catalog-menu-inspiration-filter-option__icon-wrapper {
  background-color: $color-aureolin;
  color: $color-ironside-gray;
}

.nolte-catalog-menu-inspiration-filter-option__link {
  display: block;
  min-width: $element-width;
  text-align: center;
  height: $element-height;
  padding: $unit-base $unit-half $unit-half;
  box-sizing: content-box;
}

.nolte-catalog-menu-inspiration-filter-option__figure {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.nolte-catalog-menu-inspiration-filter-option__icon-wrapper {
  @include helper__transition((background-color, box-shadow, color));
  padding: 5px;
  margin: 5px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: $radius-default;
  color: $color-delta;
}

.nolte-catalog-menu-inspiration-filter-option__icon-vector,
.nolte-catalog-menu-inspiration-filter-option__icon-raster {
  height: 30px;
  width: 30px;
}

.nolte-catalog-menu-inspiration-filter-option__icon-raster {
  border-radius: 5px;
  overflow: hidden;
  display: inline-block;
}

.nolte-catalog-menu-inspiration-filter-option__label {
  @include text-h5;
}
</style>
