<template>
  <!-- as a section is used to show or hide fields we don't use the HTML tag fieldset  -->
  <div
    :id="'id-' + field.model.itemId"
    class="form-section"
    :class="field.model.cssClass + (hidden ? 'form-item-hidden' : '')"
  >
    <v-fields :fields="field.fields" :form-id="formId" />
  </div>
</template>

<script>
import SectionConditionMixin from '../structure/conditions/SectionConditionMixin';
import VFields from './Fields';

export default {
  name: 'SitecoreFormsStructureSection',

  mixins: [SectionConditionMixin],

  components: {
    VFields,
  },

  data() {
    return {
      hidden: false,
    };
  },

  props: {
    field: {
      type: Object,
      default: undefined,
    },
    formId: {
      type: String,
      required: true,
    },
  },
  beforeCreate: function () {
    this.$options.components.VFields = require('./Fields').default;
  },
};
</script>
