<template>
  <div class="editorial-rich-text-wrapper" :class="`editorial-rich-text--list-style-${listStyle}`">
    <RichText class="editorial-rich-text" :field="fields.content" />
  </div>
</template>

<script>
import { RichText } from '@sitecore-jss/sitecore-jss-vue';

export default {
  name: 'EditorialRichText',

  components: {
    RichText,
  },

  computed: {
    listStyle() {
      // returns 'default', 'tick', 'plus' or 'minus'
      return this.fields._extendedParams?.ListStyle?.fields?.Type?.value || 'default';
    },
  },
};
</script>

<style lang="scss" scoped>
.editorial-rich-text-wrapper {
  @include spacing;
}

.editorial-rich-text {
  box-sizing: content-box;
  max-width: 750px;

  :deep() {
    ul {
      margin: 1em 0;
      list-style: none;
      padding-left: 0.5em;

      @include helper__greater($bp-768) {
        padding-left: 1em;
      }
    }
    a {
      text-decoration: underline;
    }

    & > {
      h4 {
        @include text-h4;
      }

      p {
        margin-bottom: 20px;
      }
    }
  }
}

.editorial-rich-text--list-style-default {
  :deep(ul) {
    list-style: disc;
    padding-left: 2.3em;

    @include helper__greater($bp-768) {
      padding-left: 2.8em;
    }
  }
}

.editorial-rich-text--list-style-tick {
  :deep() {
    li {
      background: url(../assets/icons/IconTick.svg) 0 0.13em/1em 1em no-repeat;
      padding-left: 1.8em;
    }
  }
}

.editorial-rich-text--list-style-plus {
  :deep() {
    li {
      background: url(../assets/icons/IconPlus.svg) 0 0.13em/1em 1em no-repeat;
      padding-left: 1.8em;
    }
  }
}

.editorial-rich-text--list-style-minus {
  :deep() {
    li {
      background: url(../assets/icons/IconMinus.svg) 0 0.13em/1em 1em no-repeat;
      padding-left: 1.8em;
    }
  }
}
</style>
