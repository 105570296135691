<template>
  <div
    class="nolte-scroller swiper-container"
    :class="{
      'nolte-scroller--shadow-left': isShadowLeftVisible,
      'nolte-scroller--shadow-right': isShadowRightVisible,
      'nolte-scroller--single-item': count <= 1,
    }"
    @mouseenter.once="updateSwiper"
  >
    <div class="swiper-wrapper">
      <component :is="scrollerEl" class="swiper-slide viewport">
        <slot />
      </component>
    </div>

    <div class="navigation" v-if="showArrows">
      <button class="navigation__prev" :class="{ 'navigation__prev--hidden': !showArrowPrev }">
        <IconArrowLeft />
      </button>
      <button class="navigation__next" :class="{ 'navigation__next--hidden': !showArrowNext }">
        <IconArrowRight />
      </button>
    </div>
  </div>
</template>

<script>
import Swiper from 'swiper';
import { mapGetters } from 'vuex';
import waitForImages from './waitForImages';
import IconArrowLeft from '@/assets/icons/IconArrowLeft.svg?inline';
import IconArrowRight from '@/assets/icons/IconArrowRight.svg?inline';
import eventBus from '@/lib/eventBus';

export default {
  name: 'NolteScroller',

  components: {
    IconArrowLeft,
    IconArrowRight,
  },

  props: {
    scrollerEl: {
      type: String,
      default: 'div',
    },
    shadowOnly: {
      type: String,
      default: undefined,
      validator(shadowOnly) {
        return ['left', 'right', ''].includes(shadowOnly);
      },
    },
    bounceEdges: {
      type: Boolean,
      default: false,
    },
    showArrows: {
      type: Boolean,
      default: false,
    },
    count: {
      type: Number,
      default: 2,
    },
  },

  data() {
    return {
      shadowLeft: false,
      shadowRight: false,
      swiperInstance: null,
      prevOffset: 0,
      nextOffset: 0,
      showArrowPrev: false,
      showArrowNext: false,
    };
  },

  computed: {
    ...mapGetters('jss', ['isEditing']),

    isShadowLeftVisible() {
      return this.shadowLeft && this.shadowOnly !== '' && this.shadowOnly !== 'right';
    },

    isShadowRightVisible() {
      return this.shadowRight && this.shadowOnly !== '' && this.shadowOnly !== 'left';
    },
  },

  methods: {
    updateSwiper() {
      if (this.swiperInstance) {
        this.swiperInstance.update();
      }
    },
    swiperInit() {
      if (this.showArrows) {
        const $navigationPrev = this.$el.querySelector('.navigation__prev');
        const $navigationNext = this.$el.querySelector('.navigation__next');

        $navigationPrev.addEventListener('click', () => {
          this.scrollToOffset(this.prevOffset);
        });

        $navigationNext.addEventListener('click', () => {
          this.scrollToOffset(this.nextOffset);
        });

        this.calculatePrevNextOffset();
        if (typeof window !== 'undefined') {
          window.addEventListener('resize', this.calculatePrevNextOffset);
        }
      }
    },

    scrollToOffset(offset) {
      const $wrapper = this.$el.querySelector('.swiper-wrapper');

      $wrapper.style.transitionDuration = '400ms';
      $wrapper.style.transitionTimingFunction = 'cubic-bezier(0.5, 0, 0.5, 1)';
      $wrapper.style.transform = `translate3d(-${offset}px, 0, 0)`;

      const transitionEnd = () => {
        this.calculatePrevNextOffset();
        // sometimes we don't get the correct transform matrix if we try to read it too early
        // so we have to to recheck iz
        setTimeout(() => {
          this.calculatePrevNextOffset();
        }, 500);

        // reset to easing for manual sliding
        $wrapper.style.transitionDuration = '0ms';
        $wrapper.style.transitionTimingFunction = 'ease';

        $wrapper.removeEventListener('transitionend', transitionEnd);
      };
      $wrapper.addEventListener('transitionend', transitionEnd);
    },

    calculatePrevNextOffset() {
      // get widths of all slider items
      const widths = [].map.call(this.$el.querySelectorAll('.swiper-slide > *'), $item => {
        const margins = getComputedStyle($item)
          ['margin'].split(' ')
          .map(value => parseInt(value), 10);
        // width + right margin
        return $item.getBoundingClientRect().width + (margins[1] || 0);
      });

      const fullWidth = this.$el.querySelector('.swiper-slide').clientWidth;

      // calculate our position and which item should be next on click on "next"
      // all browsers return matrix(...) for transform value
      const $wrapper = this.$el.querySelector('.swiper-wrapper');
      const matrix = getComputedStyle($wrapper)['transform'];
      let offset = 0;

      // in case the slider is not at the beginning
      if (matrix !== '' && matrix !== 'none') {
        // can either be 2d or 3d transform (3d is returned from IE11)
        const matrixType = matrix.includes('3d') ? '3d' : '2d';
        const matrixValues = matrix.match(/matrix.*\((.+)\)/)[1].split(', ');
        if (matrixType === '2d') {
          offset = matrixValues[4];
        }
        if (matrixType === '3d') {
          offset = matrixValues[12];
        }
        offset = Math.round(Math.abs(offset));

        // now we have all widths, let's find out what the next offset is we have to slide to
        let cumulatedWidth = 0;
        let cumulatedWidthRounded = 0;

        this.nextOffset = 0;
        this.prevOffset = 0;

        for (let i = 0; i < widths.length; i++) {
          cumulatedWidth += widths[i];
          cumulatedWidthRounded = Math.round(cumulatedWidth);

          if (offset < cumulatedWidthRounded) {
            this.nextOffset = cumulatedWidth;
            break;
          }

          if (Math.floor(Math.abs(cumulatedWidthRounded - offset)) > 1) {
            this.prevOffset = cumulatedWidth;
          }
        }

        // be careful: we shouldn't allow the slider to show empty space at the end
        this.nextOffset = Math.min(this.nextOffset, fullWidth - $wrapper.offsetWidth);

        // show/hide next/prev buttons
        this.showArrowNext = this.nextOffset > offset;
        this.showArrowPrev = this.prevOffset < offset;
      }
    },
  },

  beforeUnmount() {
    eventBus.$emit('NolteScroller:beforeUnmount');
  },

  mounted() {
    if (this.isEditing || this.count <= 1) {
      return;
    }

    const swiper = new Swiper(this.$el, {
      slidesPerView: 'auto',
      freeMode: true,
      freeModeMomentumBounce: this.bounceEdges ? undefined : false,
      touchReleaseOnEdges: this.bounceEdges ? undefined : true,
      mousewheel: {
        releaseOnEdges: true,
        forceToAxis: true,
        invert: true,
      },
      grabCursor: true,
      watchOverflow: true,
      freeModeMomentumRatio: 0.5,
      freeModeMomentumVelocityRatio: 0.4,
      navigation: true,

      on: {
        init: this.swiperInit,
        transitionEnd: this.calculatePrevNextOffset,
        touchEnd: this.calculatePrevNextOffset,
      },
    });

    swiper.on('reachBeginning', () => {
      this.shadowLeft = false;
    });

    swiper.on('reachEnd', () => {
      this.shadowRight = false;
    });

    swiper.on('fromEdge', () => {
      this.shadowLeft = true;
      this.shadowRight = true;
    });

    eventBus.$once('NolteScroller:beforeUnmount', () => {
      swiper.destroy();
    });

    if (!swiper.isBeginning) {
      this.shadowLeft = true;
    }

    if (!swiper.isEnd) {
      this.shadowRight = true;
    }

    this.swiperInstance = swiper;

    // swiper has problems to determine the correct slider width if not all images were already
    // loaded. so we update swiper when all images are loaded.
    waitForImages(this.$el.querySelectorAll('img')).then(() => {
      this.updateSwiper();
      this.calculatePrevNextOffset();
    });
  },
};
</script>
<style lang="scss" scoped>
@import '~swiper/swiper.scss';

.swiper-slide {
  display: flex;
  width: auto;

  :deep() > * {
    margin: 0 $unit-half 0 0;
    &:last-child {
      margin: 0;
    }
  }
}

.nolte-scroller {
  /* cut scrollbar */
  overflow: hidden;
  position: relative;
  margin: 0;

  &::before,
  &::after {
    @include helper__transition(opacity, 1s);
    content: '';
    position: absolute;
    top: 0;
    height: 100%;
    z-index: 5;
    width: 30px;
    opacity: 0;
    pointer-events: none;
  }

  &::before {
    left: 0;
    background: linear-gradient(
      to right,
      rgba($color-ironside-gray, 0.2),
      ease-in-out,
      rgba($color-ironside-gray, 0)
    );
  }

  &::after {
    right: 0;
    background: linear-gradient(
      to left,
      rgba($color-ironside-gray, 0.2),
      ease-in-out,
      rgba($color-ironside-gray, 0)
    );
  }

  &.nolte-scroller--shadow-left::before {
    opacity: 1;
  }

  &.nolte-scroller--shadow-right::after {
    opacity: 1;
  }

  &.nolte-scroller--single-item {
    height: auto;

    :deep() {
      .swiper-wrapper {
        display: block;
      }
      .editorial-image {
        max-width: 100% !important;
      }
      .editorial-image img {
        height: initial;
        width: 100%;
      }
    }
  }
}

.viewport {
  :deep() > * {
    flex-wrap: nowrap;
    flex-shrink: 0;
    user-select: none; // Prevent selecting of text/images when dragging by mouse
  }
}

.navigation {
  position: absolute;
  z-index: 1;
  width: 100%;
  top: 50%;

  @include helper__until($bp-768) {
    display: none;
  }
}

.navigation__prev,
.navigation__next {
  background: rgba(255, 255, 255, 0.7);
  border: 0;
  padding: 10px;
  position: absolute;
  width: $unit-quadruple;
  height: $unit-quadruple;
  box-shadow: 5px 5px 15px #0005;
  color: $color-delta;

  svg {
    transition: opacity 0.3s;
    opacity: 0.7;
  }

  transition: all 0.3s;
  &:hover {
    background: rgba(255, 255, 255, 0.9);
    svg {
      opacity: 1;
    }
  }
}

.navigation__prev {
  text-align: right;
  left: -16px;
  transform: translate(-100%, -50%);
  border-radius: 0 50% 50% 0;

  &--hidden {
    transform: translate(-100%, -50%) !important;
  }
}

.navigation__next {
  text-align: left;
  right: -16px;
  transform: translate(100%, -50%);
  border-radius: 50% 0 0 50%;

  &--hidden {
    transform: translate(100%, -50%) !important;
  }
}

.nolte-scroller:hover .navigation__prev {
  transform: translate(0%, -50%);
}

.nolte-scroller:hover .navigation__next {
  transform: translate(0, -50%);
}
</style>
