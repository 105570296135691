<template>
  <nav
    v-if="!isMobile"
    class="nolte-top-menu"
    :class="{ 'nolte-top-menu--visible': visible }"
    :style="{ top: `${headerHeight}px` }"
  >
    <div class="nolte-top-menu__main">
      <slot />
    </div>
    <Transition name="fade">
      <div class="nolte-top-menu__sub-container" v-if="$slots.sub">
        <slot name="sub" />
      </div>
    </Transition>
  </nav>

  <nav v-else class="nolte-bottom-menu" :class="{ 'nolte-bottom-menu--visible': visible }">
    <div class="nolte-bottom-menu__main">
      <component
        :is="disableMainScroller ? 'div' : 'NolteScroller'"
        :shadow-only="disableMainScroller ? undefined : ''"
        :style="disableMainScroller ? { width: '100%' } : undefined"
      >
        <slot />
      </component>
    </div>
    <Transition name="fade">
      <div class="nolte-bottom-menu__sub-container" v-if="$slots.sub">
        <slot name="sub" />
      </div>
    </Transition>
  </nav>
</template>

<script>
import { mapMutations } from 'vuex';

import NolteScroller from '../NolteScroller';
import EventBus from '@/lib/eventBus';

export default {
  name: 'NolteStickyMenu',

  components: {
    NolteScroller,
  },

  props: {
    isMobile: {
      type: Boolean,
      defaul: false,
    },
    showIfPossible: {
      type: Boolean,
      defaul: false,
    },
    disableMainScroller: {
      type: Boolean,
      default: false,
    },
  },

  data() {
    return {
      isFooterVisible: false,
      headerHeight: 0,
    };
  },

  computed: {
    visible() {
      return this.showIfPossible && (!this.isFooterVisible || !this.isMobile);
    },
  },

  mounted() {
    this.$watch(
      vm => [vm.isMobile, vm.visible].join(),
      () => {
        this.setHeaderStickingOverride(!this.isMobile && this.visible ? false : undefined);

        // Get header height dynamically when sticky menu gets visible on top
        if (!this.isMobile && this.visible) {
          const { height } = document.querySelector('header').getBoundingClientRect();
          this.headerHeight = height;
        }
      }
    );

    EventBus.$on('global-footer:visible', () => {
      // console.log('NolteStickyMenu EventBus.on: global-footer:visible');
      this.isFooterVisible = true;
    });

    EventBus.$on('global-footer:hidden', () => {
      // console.log('NolteStickyMenu EventBus.on: global-footer:hidden');
      this.isFooterVisible = false;
    });
  },

  methods: {
    ...mapMutations(['setHeaderStickingOverride']),
  },
};
</script>

<style lang="scss" scoped>
.expand-enter-active,
.expand-leave-active {
  @include helper__transition(height);
}

/**
 * Style Top Menu
 */

.nolte-top-menu {
  @include helper__transition((transform, opacity));
  background-color: $color-white;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  z-index: $z-index-400;
  transform: translateY(-100%);
  opacity: 0;

  &::before {
    content: '';
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 100%;

    @include header-shadow;
    opacity: 1;
  }

  /**
  * Element to prevent background from rendering through thin
  * gap between sticky top menu and global header by placing
  * solid element with same background color behind it
  */
  &::after {
    content: '';
    position: absolute;
    width: 100%;
    background-color: $color-white;
    height: 100%;
    top: -50%;
    z-index: -1;
  }
}

.nolte-top-menu--visible {
  transform: translateY(0);
  opacity: 1;
}

.nolte-top-menu :deep() {
  .nolte-catalog-menu-categories {
    max-width: 100%;
  }
}

.nolte-top-menu__main {
  display: flex;
  justify-content: center;
  box-shadow: inset 0 11px 0 -10px $color-athens-gray, inset 0 -11px 0 -10px $color-athens-gray;
  background: $color-white;
}

.nolte-top-menu__sub-container {
  overflow: hidden;
  display: flex;
  justify-content: center;
}

/**
 * Style Bottom Menu
 */

.nolte-bottom-menu {
  @include helper__transition((transform, opacity));
  position: fixed;
  bottom: 0;
  left: 0;
  z-index: $z-index-400;
  width: 100%;
  transform: translateY(100%);
  opacity: 0;
}

.nolte-bottom-menu--visible {
  transform: translateY(0);
  opacity: 1;
}

.nolte-bottom-menu__sub-container {
  background-color: $color-white;
  overflow: hidden;
  width: 100%;
}

.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.3s ease-in-out;
}

.fade-enter-from,
.fade-leave-to {
  opacity: 0;
}
</style>
