<template>
  <metainfo />
  <div
    id="root"
    class="nolte-app"
    :class="{ 'nolte-app--is-editing': isEditing, 'nolte-app--is-preview': isPreview }"
  >
    <VisitorIdentification />
    <div class="inner" :class="{ 'is-banner-visible': isBannerVisible }">
      <CaptureError>
        <GlobalHeader
          class="header"
          :nav-items="getHeaderNav"
          :meta-nav-items="getHeaderMetaNav"
          :mnk-nav-items="getHeaderMnkNav"
          :language="language"
          :bannerData="getBannerData"
          @bannerClosed="bannerClosed = true"
        />
      </CaptureError>
      <router-view v-slot="{ Component }" class="view">
        <transition name="fade">
          <component :is="Component" />
        </transition>
      </router-view>

      <CaptureError>
        <GlobalFooter :nav-items="getFooterNav" :meta-nav-items="getFooterMetaNav" />
      </CaptureError>
      <ContentLightbox
        :visible="getInvalidSession"
        overlay-class="overlay"
        @close="setLightboxVisible(false)"
        >{{ $t('form-invalid-session') }}
      </ContentLightbox>

      <div v-if="languageIsChanging" class="alert alert-info">Language is changing...</div>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapActions, mapState, mapMutations } from 'vuex';

import VisitorIdentification from './components/VisitorIdentification';
import GlobalHeader from './components/GlobalHeader';
import GlobalFooter from './components/GlobalFooter';
import setupDatalayer from './datalayer';
import loadGTM from './googleTagManager.js';
import loadusercentrics from './usercentrics.js';
import CaptureError from '@/components/CaptureError';
import i18nInit from './i18n';
import eventBus from '@/lib/eventBus';
import ContentLightbox from '@/components/ContentLightbox';
import loadNootiz from './nootiz.js';

export default {
  name: 'AppRoot',
  provide() {
    // Use Vue's provide/inject capabilities to "provide" functions to
    // any descendant component that want to use/"inject" the functions.
    return {
      languageIsChanging: this.languageIsChanging,
      changeAppLanguage: this.changeAppLanguage,
    };
  },

  components: {
    VisitorIdentification,
    GlobalHeader,
    GlobalFooter,
    CaptureError,
    ContentLightbox,
  },

  data() {
    return {
      languageIsChanging: false,
      bannerClosed: false,
    };
  },

  // Very dirty hack for iframes... TODO: make nolte code great again
  beforeMount() {
    if (typeof window !== 'undefined') {
      if (window.self !== window.top) {
        window.location.href = window.location.origin + '/mnk/iframe/login';
      }
    }
  },

  beforeUnmount() {
    eventBus.$emit('AppRoot:beforeUnmount');
  },

  mounted() {
    if (!this.$isServer) {
      window.addEventListener('resize', this.updateWindowSize);

      eventBus.$once('AppRoot:beforeUnmount', () => {
        window.removeEventListener('resize', this.updateWindowSize);
      });

      if (this.hasTagManagerId) {
        setupDatalayer();
        loadGTM(this.getGoogleTagManagerId);
      }
      if (this.hasUserCentricsId) {
        loadusercentrics(this.getUserCentricsId);
      }
      if (this.sitecoreContext?.reloadPage) {
        window.location.reload();
      }

      if (this.hasNootizId) {
        loadNootiz(this.sitecoreContext?.site?.nootizId);
      }
    }
  },

  methods: {
    // TODO: TAB - 01.12.2022: not nice! we should use only mapActions instead of mapMutations
    // actions are the correct way! Mutations shoould only be used inside the store itsself
    ...mapActions(['updateWindowSize']),
    ...mapMutations(['setInvalidSession']),

    changeAppLanguage(language) {
      const i18n = this.$i18n;
      // have to check in lowercase cause the all language codes in the urls are lowercase. The initial language is de-DE so the comparison to de-de is always false and we have no dictionary
      // TODO: FE please check if this is the correct implementation, the app works fine.
      if (i18n.locale.toLowerCase() !== language?.toLowerCase()) {
        // Changing languages is an async action, therefore the `languageIsChanging` property can be used
        // to show a loading/switching screen when language is being changed.
        this.languageIsChanging = true;

        i18nInit(language).then(phrases => {
          i18n.setLocaleMessage(language, phrases);
          i18n.locale = language;
          this.languageIsChanging = false;
        });
      }
    },
    setLightboxVisible(status) {
      this.setInvalidSession(status);
    },
  },

  computed: {
    ...mapGetters([
      'getHeaderNav',
      'getHeaderMetaNav',
      'getHeaderMnkNav',
      'getFooterNav',
      'getFooterMetaNav',
      'getGoogleTagManagerId',
      'getUserCentricsId',
      'getInvalidSession',
      'getBannerData',
    ]),
    ...mapGetters('jss', ['isEditing', 'isPreview']),
    ...mapState('jss', ['sitecoreContext']),

    hasTagManagerId() {
      return this.getGoogleTagManagerId?.length > 0 ? true : false;
    },
    hasUserCentricsId() {
      return this.getUserCentricsId?.length > 0 ? true : false;
    },
    hasNootizId() {
      return this.sitecoreContext?.site?.nootizId?.length > 0 ? true : false;
    },
    language() {
      return this.sitecoreContext?.language
        ? this.sitecoreContext.language.toLowerCase()
        : undefined;
    },
    isBannerVisible() {
      if (typeof window !== 'undefined') {
        return (
          /* Banner is visible in client only if it was not hidden before in this session. See components/Banner.vue:70 */
          window.sessionStorage.getItem('bannerVisible') !== 'false' && !this.bannerClosed
        );
      }
      return false;
    },
  },
};
</script>

<style lang="scss">
// define global styles
@import './styles/global.scss';
</style>

<style lang="scss" scoped>
.nolte-app {
  display: flex;
  min-height: 100vh;
  flex-direction: column;
  justify-content: space-between;

  .inner > * {
    width: 100%;
  }

  .inner.is-banner-visible {
    padding-top: 181px;

    @include helper__greater($bp-600) {
      padding-top: 95px;
    }

    @include helper__greater(657px) {
      padding-top: 83px;
    }
  }
}

.template--neopage .inner.is-banner-visible {
  padding-top: 0px !important;
}

.template--landingpage .inner.is-banner-visible {
  padding-top: 0px !important;
}

.header {
  position: fixed;
  top: 0;
  z-index: $z-index-800;
}

:deep(main) {
  padding: $header-height-mobile 0 0 0;

  @include helper__greater($bp-768) {
    padding: $header-height 0 0 0;
  }
}

.view {
  // https://pmueller.de/flexbox-ie-flex1/
  // flex: 1 auto fixes the flex: 1 bug in IE11
  flex: 1 auto;
  min-height: 100vh;
}
</style>
