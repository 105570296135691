<template>
  <div
    class="editorial-image"
    :class="[fit ? `editorial-image--fit-${fit}` : false, editedInEE ? 'edited-in-ee' : false]"
    :key="defaultSrc"
  >
    <picture>
      <source
        v-for="({ aspectRatio, sizes }, mediaQuery) in enhancedSources"
        :key="aspectRatio"
        :data-srcset="sourceSets[aspectRatio]"
        :media="mediaQuery"
        :sizes="sizes"
      />

      <img
        :fetchpriority="parentComponentNameIsHeader ? 'high' : undefined"
        :loading="parentComponentNameIsHeader ? 'eager' : undefined"
        :data-src="defaultSrc"
        :data-srcset="defaultSourceSet"
        :src="parentComponentNameIsHeader ? defaultSrc : undefined"
        :srcset="parentComponentNameIsHeader ? defaultSourceSet : undefined"
        :alt="imageAlt"
        :sizes="defaultSizesComputed"
        :class="[imgClass, !parentComponentNameIsHeader ? 'lazyload' : '']"
        :width="image.width"
        :height="image.height"
      />
    </picture>
    <!--
      don't use loading="lazy" on the img tag because swiper won't initialize cleanly and won't allow to scroll sometimes
    -->

    <sc-image v-if="isEditing && editable && scImage.editable" :media="scImage" />
  </div>
</template>

<script>
/*
In case the "source" attribute does not exist we add all aspectRatios that are defined in
"aspectRatios" below. Images defined in YAML-Routes do not have the "source" key even if you have
defined them. Mocked Integrated-GraphQL-Queries get them nevertheless if you define them.
The import process does not create the cropped images.

Example usage:

  <EditorialImage
    class="header-image"
    :media="headerImage"
    :sources="{
      '(min-width: 769px)': '21:9',
    }"
    default-aspect-ratio="16:10"
    default-sizes="100vw"
  />

*/

import { mapGetters } from 'vuex';
import { Image } from '@sitecore-jss/sitecore-jss-vue';
import { generateScaling } from '@/components/helper';
import eventBus from '@/lib/eventBus';
import 'lazysizes';
import { useScreen } from 'vue-screen';

// this list should be the same as configured in Sitecore
const aspectRatios = ['3:4', '3:2', '21:9', '16:10'];

// for each of these widths we have to whitelist the parameter combination in Sitecore
// e.g. "mw=1920&as=0"
const scalings = [1920, 1440, 1024, 500, 250];

// will be used <picture><img src="[here]" srcset="..."> for browsers that don't support srcset
// https://caniuse.com/#feat=srcset
const defaultScaling = 1440;

// a base 64 representation of a transparent GIF
const transparentGifDataUrl =
  'data:image/gif;base64,R0lGODlhAQABAIAAAAAAAP///yH5BAEAAAAALAAAAAABAAEAAAIBRAA7';

export default {
  name: 'EditorialImage',

  setup() {
    const screen = useScreen();

    return {
      screen,
    };
  },

  components: {
    ScImage: Image,
  },

  data: () => ({
    editedInEE: false, // edited in Experience Editor
  }),

  props: {
    /**
     * This component prefers an image from the Sitecore "fields" object as it is an Editorial
     * component. But with media it is also possible to pass a Sitecore Image.
     */
    media: {
      type: Object,
      default: undefined,
    },

    /**
     * key-value-pairs:
     * If you pass a string it should match the aspectRatio value in the "source" array of the
     * sitecore data:
     *   { '(min-width: 1600px)': '21:9' }
     * If you pass an object you are also able to define a sizes value that is different from the
     * defaultSizes value:
     *   { '(min-width: 1200px)': { aspectRatio: '16:10', sizes: '(min-width: 1400px) 100vw, 20vw' }}
     * In this example: Above 1200px it is assumed that we need an image from the sourceset that is
     * wide enough for 20vw, so 240px. Above 1400px the image get loaded that is wide enough for 1400px
     */
    sources: {
      type: Object,
      default: () => {},
    },

    /**
     * This value be used to determine the "sizes" value of the default img tag. It will also be
     * used as value for each entry in "sources" if "sizes" doesn't get overwritten there.
     */
    defaultSizes: {
      type: String,
      default: '100vw',
    },

    /**
     * Use this to define a specific aspect ratio for the default image. This way you can force a
     * specific aspect ratio as the image uploaded by the user could be of any aspect ratio.
     */
    defaultAspectRatio: {
      type: String,
      default: null,
    },

    /**
     * A class that will be added to the image instead of the container.
     */
    imgClass: {
      type: String,
      default: '',
    },

    /**
     * Set to cover sets the container to width and height 100% and object-fit: cover.
     */
    fit: {
      type: String,
      default: 'cover',
    },

    /**
     * Defines if the image is editable in the Experience Editor.
     */
    editable: {
      type: Boolean,
      default: true,
    },

    /**
     * Allows to override the default image tag alt attribute defined in Sitecore.
     */
    alt: {
      type: String,
      default: null,
    },

    /**
     * Defines if query parameters of the image src should be kept. Useful if you want to use
     * this component for an image that does not come from Sitecore.
     */
    keepQueryParameters: {
      type: Boolean,
      default: false,
    },
  },

  computed: {
    ...mapGetters('jss', ['isEditing', 'isConnected']),

    scImage() {
      let image = this.fields?.media || this.media;
      // we don't want to show the original image in the experience editor so let's show a one with the highest value of the scalings

      // if the image field was not filled it is possible that image is "undefined"
      // let's add a simple image object to prevent the SSR mode from breaking
      if (!image) {
        image = {
          editable: '',
        };
      }

      if (image.editable) {
        image.editable = image.editable.replace(
          /src="[^"]+?"/,
          // replace the image that is loaded by the SC Experience Editor with a transparent GIF
          // this way we don't load the image uploaded by the editor that wouldn't be visible
          // to him as we show our correctly cropped image. We use the sc-image only to trigger the
          // "edit image" dialog and for this reason this image is not visible (opacity: 0).
          `src="${transparentGifDataUrl}"`
        );
      }

      return image;
    },

    image() {
      // 1. data from sitecore component fields
      // 2. data was passed via prop but came from sitecore component fields
      // 3. directly passed image object without value subkey
      const imageSelector = this.fields?.media?.value || this.media?.value || this.media;
      const image = { ...imageSelector };

      // show an image placeholders if the image doesn't have a valid src (maybe a wrong passed object)
      // but we dont't want the complete component to fail
      if (!image.src) {
        if (!this.isConnected) {
          image.src = '//dummyimage.com/600x400/000/fff&text=no-src|';
        } else {
          image.src = transparentGifDataUrl;
        }
      }

      // Let's overwrite the alt attribute set in the experience editor
      // if prop or the alt text of the media element is set.
      const altText = new URLSearchParams(
        // URL needs a domain for relative URLs. For absolute URLs the domain parameter is ignored.
        new URL(image.src, 'https://dummy.domain').searchParams
      ).get('alt');

      if (this.alt) {
        image.alt = this.alt;
      } else if (!image.alt && altText != null) {
        image.alt = altText;
      }

      // if the image doesn't have cropped image variants we don't have a source attribute
      // so let's add it so we dont't break the functionality
      if (typeof image.source === 'undefined' && image.src !== transparentGifDataUrl) {
        image.source = [];
        aspectRatios.forEach(ar => {
          image.source.push({
            aspectRatio: ar,
            srcset: `${image.src}?${ar}`,
          });
        });
      }

      // use the src for the aspect ratio defined in defaultAspectRatio as default src
      if (this.defaultAspectRatio && typeof image.source !== 'undefined') {
        const AspectRatioImage = image.source.find(
          img => img.aspectRatio === this.defaultAspectRatio
        );
        // maybe an aspect ratio was added later to the sitecore definition. in that case we
        // wouldn't have this image and have to fallback to an image we have.
        if (AspectRatioImage) {
          image.src = AspectRatioImage.srcset;
        } else {
          image.src = image.source[0].srcset;
        }
      }

      return image;
    },

    /**
     * Returns the maintained alt attribute or en empty string so the alt attribute will be also
     * rendered empty.
     */
    imageAlt() {
      return this.image.alt || '';
    },

    /**
     * Allows to pass the aspectRatio directly without the need of using an object.
     */
    enhancedSources() {
      const sources = {};
      for (const key in this.sources) {
        // eslint-disable-next-line no-prototype-builtins
        if (this.sources.hasOwnProperty(key)) {
          sources[key] =
            typeof this.sources[key] === 'string'
              ? {
                  aspectRatio: this.sources[key],
                  sizes: this.defaultSizes,
                }
              : this.sources[key];
        }
      }
      return sources;
    },

    /**
     * Generate the sourcesets for all picture>source tags.
     */
    sourceSets() {
      // rewrite the sources passed by sitecore to an object with aspect ratios as key
      let sourceSets = {};
      this.image.source?.forEach(item => {
        sourceSets[item.aspectRatio] = this.generateSourceset(item.srcset);
      });
      return sourceSets;
    },

    /**
     * Generate the sourceset for the default image.
     */
    defaultSourceSet() {
      if (this.image.src === transparentGifDataUrl) return [];

      return this.generateSourceset(this.image.src);
    },

    /**
     * Generate the default src for the default image (as we have a polyfill for IE11, which is
     * the only supported browser that does not support the picture element, that shouldn't be
     * necessary but could be useful for search engine indexing).
     */
    defaultSrc() {
      if (this.image.src === transparentGifDataUrl) return this.image.src;

      return this.generateScaling(this.image.src, defaultScaling).replace(
        /( .+)$/,
        '',
        false,
        this.keepQueryParameters
      );
    },

    defaultSizesComputed() {
      // for some components where the editor is able to insert images into SC placeholders we set
      // the sizes attribute here as it is too complicated to pass the parameter through all child
      // components
      const $grandParentName = this.$parent.$parent.$options.name;
      const $grandGrandParentName = this.$parent.$parent.$parent.$options.name;

      if ($grandGrandParentName === 'NolteMediaDetailGroup') {
        return '(min-width: 850px) 850px, 100vw';
      }
      if ($grandParentName === 'EditorialGallery') {
        const galleryHeight = this.screen?.width > 1024 ? 600 : 320;
        const calculatedWidth = Math.ceil(galleryHeight * (this.image.width / this.image.height));
        return `${calculatedWidth}px`;
      }

      return this.defaultSizes;
    },

    parentComponentNameIsHeader() {
      return this.$parent.$options.name === 'EditorialSimpleHeader';
    },
  },

  methods: {
    generateScaling,

    /**
     * Generates the complete srcset for all scalings for the given src.
     */
    generateSourceset(src) {
      return scalings
        .map(scaling => this.generateScaling(src, scaling, false, this.keepQueryParameters))
        .join(', ');
    },
  },

  mounted() {
    // In the EE we have to show the sc-image if the image was deleted or a new one was chosen
    // because we cannot update the picture element as we don't have access to the cropping information
    if (this.isEditing && this.editable && this.image.editable) {
      // SC does not change attributes of the existing image but always adds a new node
      // for this reason we cannot observe the image directly and observe the wrapper and its children
      const scImageWrapper = this.$el.querySelector('.sc-image-wrapper');

      const observer = new MutationObserver(mutations => {
        mutations.forEach(mutation => {
          if (mutation.type === 'childList' && mutation.addedNodes.length) {
            this.editedInEE = true;
          }
        });
      });
      observer.observe(scImageWrapper, { childList: true });

      eventBus.$once('EditorialImage:beforeUnmount', () => {
        // Mutation Observer does not have Method unobserve(), but disconnect()!
        // observer.unobserve();
        observer.disconnect();
      });
    }
  },

  beforeUnmount() {
    eventBus.$emit('EditorialImage:beforeUnmount');
  },
};
</script>

<style scoped lang="scss">
.editorial-image {
  position: relative;
  display: inline-block;
  max-width: 100vw;
}

.editorial-image--fit-cover img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  font-family: 'object-fit: cover;';
}

/*
make the sc-image fill the container transparently so we see the correct styled image but also get
the image editing toolbar in the experience editor
*/
:deep(.sc-image-wrapper) img {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  opacity: 0;
}

/*
If the image was edited show the sc-image and not the picture anymore
*/
.editorial-image.edited-in-ee picture img {
  opacity: 0;
}
.editorial-image.edited-in-ee :deep(.sc-image-wrapper) img {
  opacity: 1;
}

.lazyload,
.lazyloading {
  opacity: 0;
}
.lazyloaded {
  opacity: 1;
  transition: opacity 300ms;
}
</style>
