<template>
  <NolteDetailPlanningAlternatives
    v-if="!isEditing"
    :headline="headline"
    :copy="copy"
    :shape-id="shapeId"
  />

  <div v-else class="editorial-detail-planning-alternatives">
    <p><sc-text :field="fields.headline" /></p>
    <p><sc-text :field="fields.copy" /></p>
    <p>Selected shape ID: {{ shapeId }}</p>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import { Text } from '@sitecore-jss/sitecore-jss-vue';

import NolteDetailPlanningAlternatives from '../nolte-ui/NolteDetailPlanningAlternatives';

export default {
  name: 'EditorialDetailPlanningAlternatives',

  components: {
    ScText: Text,
    NolteDetailPlanningAlternatives,
  },

  computed: {
    ...mapGetters('jss', ['isEditing']),
    headline() {
      return this.fields?.headline?.value;
    },
    copy() {
      return this.fields?.copy?.value;
    },
    shapeId() {
      return this.fields?.shapeId?.fields?.Shape?.value || 'shapeL';
    },
  },
};
</script>
