<template>
  <div class="editorial-neo-media-wrapper" :class="`wrapper--${alignment}`">
    <ContentAnchor
      :fields="{
        anchorTitle: fields['Anchor Title'],
        anchorName: fields['Anchor Name'],
      }"
    />
    <figure class="editorial-neo-media">
      <div class="details" v-if="fields.headline || fields.copy">
        <sc-text v-if="fields.headline" class="headline" :field="fields.headline" tag="h2" />
        <sc-text v-if="fields.copy" class="copy" :field="fields.copy" tag="p" />
        <EditorialButton
          v-if="linkHasValue(fields.CTA) || isEditing"
          class="editorial-simple-header__link"
          :fields="{ link: fields.CTA }"
        />
      </div>
      <div class="media">
        <div class="is-editing" v-if="isEditing">
          <span>Enter the Wistia video ID: </span>
          <sc-text :field="fields.wistiaVideoId" />
        </div>
        <ContentVideoWistia
          v-if="textHasValue(fields.wistiaVideoId) && videoDoesExist"
          :video-id="fields.wistiaVideoId.value"
          ref="video"
          end-video-behavior="loop"
          @error="videoDoesExist = false"
        />
        <EditorialImage
          v-else-if="imageHasSource(fields.image) || isEditing"
          :media="fields.image"
          default-aspect-ratio="3:2"
          default-sizes="(min-width: 1024px) 60vw, (min-width: 768px) 50vw, 100vw"
        />
      </div>
    </figure>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import { Text } from '@sitecore-jss/sitecore-jss-vue';
import { textHasValue, linkHasValue, imageHasSource } from '@/components/helper';
import 'intersection-observer';
import eventBus from '@/lib/eventBus';

import EditorialButton from './EditorialButton';
import EditorialImage from '@/components/EditorialImage';
import ContentAnchor from '@/components/ContentAnchor';
import ContentVideoWistia from '@/components/ContentVideoWistia';

export default {
  name: 'EditorialNeoMedia',

  components: {
    EditorialButton,
    EditorialImage,
    ContentAnchor,
    ScText: Text,
    ContentVideoWistia,
  },
  data: () => ({
    videoDoesExist: true,
  }),
  computed: {
    ...mapGetters('jss', ['isEditing']),
    alignment() {
      return this.fields?._extendedParams?.['RightAlign']?.value ? 'right' : 'left';
    },
    showInAnchorNavigation() {
      return this.fields && this.fields['Show Anchor in Navigation']?.value ? true : false;
    },
  },

  mounted() {
    this.playVideoOnScroll(this.$refs?.video?.$el);
  },

  beforeUnmount() {
    eventBus.$emit('EditorialNeoLightFullscreen:beforeUnmount');
  },

  methods: {
    textHasValue,
    linkHasValue,
    imageHasSource,

    playVideoOnScroll($el) {
      if (!$el) return;
      const observer = new IntersectionObserver(
        entries => {
          entries.forEach(entry => {
            let video = this.$refs.video;
            if (entry.isIntersecting) {
              video?.activate();
            } else {
              video?.pause();
            }
          });
        },
        {
          rootMargin: '0px 0px -100px 0px',
        }
      );

      observer.observe($el);

      eventBus.$once('EditorialNeoLightFullscreen:beforeUnmount', () => {
        if ($el) {
          observer.unobserve($el);
        }
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.editorial-neo-media {
  @include spacing;
  padding: 0 !important;
  @include helper__greater($bp-768) {
    max-width: none;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  @include helper__until($bp-768) {
    margin-top: $unit-base * 3;
  }

  :deep(.editorial-button-wrapper) {
    margin: $unit-double 0;
    padding: 0;
  }
  :deep(.editorial-image) {
    position: relative;
    display: block;
    max-width: 100vw;
  }
}

.media {
  justify-content: flex-start;
  width: 100%;
  max-width: 100%;
  margin-bottom: $unit-base;
  flex: 0 1 100%;
  padding: 0 !important;

  @include helper__greater-until($bp-768, $bp-1024) {
    flex: 0 1 50%;
  }

  @include helper__greater($bp-1024) {
    flex: 0 1 58.3333%;
  }
}

.details {
  flex: 0 1 100%;
  padding: 0 $unit-double * 1 0 $unit-double * 1;
  padding: 0 $unit-double * 0.5 0;
  @include helper__greater-until($bp-768, $bp-1024) {
    flex: 0 1 50%;
    padding: 0 $unit-double * 1;
  }

  @include helper__greater($bp-1024) {
    flex: 0 1 41.6666%;
    padding: 0 $unit-double * 2 0 $unit-double * 2;
  }
}

.headline {
  @include text-h2;
  margin-bottom: $unit-base;

  @include helper__greater($bp-768) {
    font-size: 40px;
    line-height: (48 / 40);
  }
}

.copy {
  @include text-copy;
  margin-bottom: $unit-base;
}

.is-editing {
  margin-bottom: $unit-half !important;
}

.wrapper--right {
  .details {
    @include helper__greater-until($bp-768, $bp-1024) {
      order: 2;
      padding: 0 $unit-double;
    }

    @include helper__greater($bp-1024) {
      order: 2;
      padding: 0 $unit-double * 2 0 $unit-double * 2;
    }
  }
}
</style>
