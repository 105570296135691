<template>
  <main class="kitchen-talk-article">
    <Placeholder name="jss-kitchen-talk-article-header" :rendering="route" />
    <Placeholder name="jss-kitchen-talk-article-main" :rendering="route" />
    <Placeholder name="jss-dataprovider" :rendering="route" />
  </main>
</template>

<script>
import Placeholder from '@/components/Placeholder';
import LayoutMixin from './LayoutMixin';

export default {
  name: 'KitchenTalkArticle',
  mixins: [LayoutMixin],

  components: {
    Placeholder,
  },
};
</script>
