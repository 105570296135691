<template>
  <EditorialInternationalProjectsTemplate :fields="fields" :projects="projects" />
</template>

<script>
import EditorialInternationalProjectsTemplate from './EditorialInternationalProjectsTemplate';

export default {
  name: 'EditorialInternationalProjectsSiblingProjects',

  components: {
    EditorialInternationalProjectsTemplate,
  },

  computed: {
    projects() {
      let projects = [];

      const area = this.fields?.data?.projects?.parent;
      area?.children?.forEach(project => {
        projects.push({ area, project });
      });

      return projects;
    },
  },
};
</script>
