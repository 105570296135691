<template>
  <div
    class="nolte-product-reference"
    :class="{
      'nolte-product-reference--open': open,
      'nolte-product-reference--closed': !open,
    }"
    v-click-outside="closeCollapsable"
  >
    <button
      type="button"
      ref="scrollto"
      class="nolte-product-reference__toggle-button"
      @click="openVariants"
    >
      <span class="nolte-product-reference__toggle-button-inner">
        <IconArrowDown class="nolte-product-reference__toggle-button-icon" />
        {{ $t('products-in-our-planning-example') }}
      </span>
    </button>

    <transition name="fade">
      <div class="nolte-product-reference__variants-wrapper" v-show="open">
        <!-- the key attribute is needed to enable the slider to reinitialize.
      thus it is able to get the current dimension -->
        <NolteVariantGroup
          :key="open"
          :variants="productVariants"
          type="scroller"
          class="nolte-product-reference__variants"
        />
      </div>
    </transition>
  </div>
</template>

<script>
import vClickOutside from 'click-outside-vue3';

import NolteVariantGroup from '../NolteVariant/NolteVariantGroup';

import IconArrowDown from '../../assets/icons/IconArrowDown.svg?inline';

import smoothscroll from 'smoothscroll-polyfill';

export default {
  name: 'NolteMoodProductVariants',

  components: {
    IconArrowDown,
    NolteVariantGroup,
  },

  directives: {
    clickOutside: vClickOutside.directive,
  },

  props: {
    productVariants: {
      type: Array,
      required: true,
    },
  },

  data() {
    return {
      open: false,
    };
  },

  mounted() {
    smoothscroll.polyfill();
  },

  methods: {
    closeCollapsable() {
      this.open = false;
    },

    openVariants() {
      if (!this.open) {
        this.open = true;
        this.$nextTick(() => {
          this.$refs.scrollto.scrollIntoView({ behavior: 'smooth' });
        });
      } else {
        this.open = false;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
// to make the shadow appear we trick a little
$shadow-compensation: $unit-base;
$shadow-compensation--desktop: $unit-base * 2;
$shadow-size: 50px;
$max-variants-width: $bp-1024;

.fade-enter-active,
.fade-leave-active {
  @include helper__transition(opacity);
}

.fade-enter,
.fade-leave-to {
  opacity: 0;
}

.nolte-product-reference {
  position: relative;
}

.nolte-product-reference__toggle-button {
  @include helper__transition((color, background-color));

  @include helper__button;
  padding-top: $unit-base;
  padding-bottom: $unit-base;
  position: relative;
  width: 100%;
  z-index: 1; // make the button clickable

  @include helper__until($bp-768) {
    // TODO: align font styles
    @include text-h4;
    text-transform: inherit;
  }

  @include helper__greater($bp-768) {
    @include text-button;
    padding: $unit-half * 3;
    background-color: rgba($color-black, 0.3);
    color: $color-white;
    position: absolute;
    right: 0;
    transform: translateY(-100%);
    border-top-left-radius: $radius-default;
    width: auto;
  }
}

.nolte-product-reference--open .nolte-product-reference__toggle-button {
  @include helper__greater($bp-768) {
    color: $color-ironside-gray;
    background-color: $color-white;
    text-align: right;
    width: $max-variants-width;
    max-width: 100vw;
  }

  @include helper__greater-until($bp-768, $max-variants-width) {
    border-top-left-radius: 0;
  }
}

.nolte-product-reference--closed .nolte-product-reference__toggle-button {
  @include helper__until($bp-768) {
    box-shadow: 0 1px $color-concret;
  }
}

.nolte-product-reference__toggle-button-inner {
  display: flex;
  justify-content: center;
  align-items: center;

  @include helper__greater($bp-375) {
    justify-content: flex-end;
  }

  @include helper__greater-until($bp-375, $bp-768) {
    padding-right: $unit-base;
  }
}

.nolte-product-reference__toggle-button-icon {
  @include helper__transition(transform);
}

.nolte-product-reference--open .nolte-product-reference__toggle-button-icon {
  transform: rotate(180deg);
}

.nolte-product-reference__variants-wrapper {
  position: absolute;

  // here the variants get pushed _under_ the button above,
  // so the shadow shines _through_ the button that sits one stacking context above
  top: calc(100% - #{$shadow-compensation});
  left: 0;
  max-width: 100vw;

  @include helper__greater($bp-768) {
    top: calc(100% - #{$shadow-compensation--desktop});
    left: auto;
    right: 0;
    width: $max-variants-width;
  }

  // to prevent the box-shadow from covering the button we have to push the shadow down by its size
  &::before {
    content: '';
    position: absolute;
    top: $shadow-size;
    left: 0;
    right: 0;
    bottom: 0;
    box-shadow: 0 0 $shadow-size rgba($color-black, 0.2);

    @include helper__greater($max-variants-width) {
      border-bottom-left-radius: $radius-default;
    }
  }
}

.nolte-product-reference__variants {
  background-color: $color-white;

  @include helper__greater($max-variants-width) {
    border-bottom-left-radius: $radius-default;
  }
}
</style>
