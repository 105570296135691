<template>
  <main class="permanentRedirect">
    <div v-if="sitecoreContext.pageState !== 'normal'">
      This route is a redirect to the page item "{{ route.fields.RedirectUrl.value.href }}".
    </div>
  </main>
</template>

<script>
import { mapState } from 'vuex';
import LayoutMixin from './LayoutMixin';

export default {
  name: 'Layout',
  mixins: [LayoutMixin],

  mounted() {
    // only redirect in live mode
    if (this.sitecoreContext?.pageState === 'normal') {
      this.$router.push(this.route?.fields?.RedirectUrl?.value?.href);
    }
  },
  computed: {
    ...mapState('jss', ['routeData', 'sitecoreContext']),
  },
};
</script>

<style lang="scss" scoped>
.permanentRedirect {
  max-width: $grid-width;
  margin-left: auto;
  margin-right: auto;
  padding-left: $unit-double;
  padding-right: $unit-double;
}
</style>
