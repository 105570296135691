export function highlight(words, query) {
  const iQuery = new RegExp(query, 'ig');
  return String(words)
    .replace(/<[^>]*>?/gm, '')
    .replace(iQuery, function (matchedTxt) {
      return "<span class='highlight'>" + matchedTxt + '</span>';
    });
}
export function createRouteplannerLink(lat, lng) {
  return `https://maps.google.com/?q=${lat},${lng}`;
}

export function createPhoneLink(phonenumber) {
  return (
    'tel:' +
    String(phonenumber)
      ?.replaceAll(' ', '')
      .replaceAll('-', '')
      .replaceAll('(', '')
      .replaceAll(')', '')
      .replaceAll('/', '')
  );
}

export function nl2br(str) {
  return str.replace(/(?:\r\n|\r|\n)/g, '<br>');
}
