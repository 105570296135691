<template>
  <NolteMediaDetail class="editorial-media-detail">
    <!--
      this placholder (nolte-media-detail_media) has:
      - a max length of 1 and
      - has just the ContentImage and EditorialVideo component allowed
    -->
    <template #media>
      <Placeholder name="nolte-media-detail_media" :rendering="rendering" />
    </template>
    <template #detail>
      <Placeholder name="nolte-media-detail_detail" :rendering="rendering" />
    </template>
  </NolteMediaDetail>
</template>

<script>
import NolteMediaDetail from '../nolte-ui/NolteMediaDetail';
import { Placeholder } from '@sitecore-jss/sitecore-jss-vue';

export default {
  name: 'EditorialMediaDetail',

  components: {
    NolteMediaDetail,
    Placeholder,
  },

  props: {
    rendering: {
      type: Object,
      default: undefined,
    },
  },
};
</script>
