<template>
  <div class="nolte-kitchen-talk-menu-categories">
    <ul class="nolte-kitchen-talk-menu-tabs">
      <li
        class="nolte-kitchen-talk-menu-tabs__tab"
        :class="{
          'nolte-kitchen-talk-menu-tabs__tab--selected': active,
        }"
        v-for="({ url, title, active }, index) in categories"
        :key="`nolte-kitchen-talk-menu-tabs-${index}`"
      >
        <ContentLink
          :editable="false"
          class="nolte-kitchen-talk-menu-categories-option__link"
          :field="{ value: { href: url, linktype: 'internal' } }"
        >
          <div class="nolte-kitchen-talk-menu-tabs__indicator">{{ title }}</div>
        </ContentLink>
      </li>
    </ul>
  </div>
</template>

<script>
import { touchHover } from '../directives';
import ContentLink from '@/components/ContentLink';

export default {
  name: 'NolteKitchenTalkMenuCategories',

  components: {
    ContentLink,
  },

  directives: {
    touchHover,
  },

  props: {
    categories: {
      type: Array,
      required: true,
    },
  },
};
</script>

<style lang="scss" scoped>
.nolte-kitchen-talk-menu-categories {
  @include helper__transition((margin-bottom, border-radius));
  position: relative;
  background-color: $color-white;
  border-radius: $radius-default;
}

.nolte-kitchen-talk-menu-tabs {
  position: relative;
  z-index: 2;
  display: inline-flex;
}

.nolte-kitchen-talk-menu-categories-option__link {
  @include helper__transition((padding, color));
  @include helper__button;
  @include text-h5;
  padding: $unit-base + $unit-half $unit-base;

  /**
   * Directive touchHover has the modifier "mobileFlag" which adds the event touchstart
   * to the element. After this event gets triggered, the data-attribute data-touch-hover--mobile
   * will set. This helps in combination with :not to prevent :hover from staying active after
   * the element got touched.
   */
  &:not([data-touch-hover--mobile]):hover,
  &[data-touch-hover] {
    color: $color-delta;
  }
}

.nolte-kitchen-talk-menu-tabs__tab:first-of-type .nolte-kitchen-talk-menu-categories-option__link {
  padding-left: $unit-base * 2;
}

.nolte-kitchen-talk-menu-tabs__tab:last-of-type .nolte-kitchen-talk-menu-categories-option__link {
  padding-right: $unit-base * 2;
}

.nolte-kitchen-talk-menu-tabs__indicator {
  position: relative;

  &::before {
    @include helper__transition((transform, background-color, bottom));
    content: '';
    position: absolute;
    background-color: $color-ironside-gray;
    height: $unit-half;
    width: 100%;
    left: 0;
    bottom: $unit-base * -1.5;
    // rotate(0.01deg) avoids jitter in Chrome
    // https://greensock.com/forums/topic/16385-chrome-bug-when-i-scale-an-element-with-background-image-the-image-flickers/
    transform: scaleY(0) rotate(0.01deg);
    // Fix for Chrome breaks Firefox. Need another solution to avoid jitter in Firefox
    @include helper__composited-layer;
    transform-origin: bottom;
  }
}

.nolte-kitchen-talk-menu-tabs__tab--selected {
  .nolte-kitchen-talk-menu-tabs__indicator::before {
    transform: scaleY(1);
  }
}
</style>
