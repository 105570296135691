<template>
  <NolteColumns>
    <template #column_1>
      <!--<component
        :is="component"
        v-for="(component, index) in column1Placeholder"
        :key="`column1_${index}`"
      />-->
      <sc-placeholder name="columns2__column1" :rendering="rendering" />
    </template>
    <template #column_2>
      <!--<component
        :is="component"
        v-for="(component, index) in column2Placeholder"
        :key="`column2_${index}`"
      />-->
      <sc-placeholder name="columns2__column2" :rendering="rendering" />
    </template>
  </NolteColumns>
</template>

<script>
import NolteColumns from '../nolte-ui/NolteColumns';
import { Placeholder } from '@sitecore-jss/sitecore-jss-vue';

export default {
  name: 'EditorialColumns2',

  components: {
    NolteColumns,
    ScPlaceholder: Placeholder,
  },

  props: {
    rendering: {
      type: Object,
      default: undefined,
    },
  },

  /* withPlaceholder: {
    placeholders: [
      {
        placeholder: 'columns2__column1',
        computedPropName: 'column1Placeholder',
      },
      {
        placeholder: 'columns2__column2',
        computedPropName: 'column2Placeholder',
      },
    ],
  },*/
};
</script>

<style lang="scss" scoped>
.nolte-columns {
  @include spacing;

  :deep() {
    .editorial-headline-wrapper,
    .editorial-text-multi-line-wrapper,
    .editorial-rich-text-wrapper,
    .editorial-button-wrapper {
      @include reset-spacing;
    }

    .editorial-text-multi-line-wrapper .editorial-text-multi-line {
      @include text-base;
    }
  }
}
</style>
