<template>
  <figure class="nolte-media-detail">
    <div class="nolte-media-detail__media-container">
      <div class="nolte-media-detail__media">
        <slot name="media" />
      </div>
    </div>
    <figcaption class="nolte-media-detail__detail-container">
      <slot name="detail" />
    </figcaption>
  </figure>
</template>

<script>
export default {
  name: 'NolteMediaDetail',
};
</script>

<style lang="scss" scoped>
@import './styles.scss';

.nolte-media-detail__media-container {
  @include media-container;
}

.nolte-media-detail__detail-container {
  @include detail-container;
}
</style>
