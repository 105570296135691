<template>
  <div class="nolte-grid">
    <slot />
  </div>
</template>

<script>
export default {
  name: 'NolteGrid',

  computed: {
    columnCount() {
      if (!this.$slots) {
        return;
      }

      return Object.keys(this.$slots).length;
    },
  },
};
</script>

<style lang="scss" scoped>
.nolte-grid {
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: auto auto;
  grid-gap: $unit-half;
  /* autoprefixer: ignore next */
  grid-auto-flow: dense;

  @include helper__greater($bp-768) {
    grid-template-columns: 1fr 1fr;
    grid-template-rows: auto auto;
  }

  @include helper__greater($bp-1024) {
    grid-template-columns: 1fr 1fr 1fr;
    grid-template-rows: auto auto;
  }
}
</style>
