<template>
  <main class="simple">
    <Placeholder name="jss-simple-header" :rendering="route" />
    <Placeholder name="jss-main" :rendering="route" />
    <Placeholder name="jss-dataprovider" :rendering="route" />
  </main>
</template>

<script>
import Placeholder from '@/components/Placeholder';
import LayoutMixin from './LayoutMixin';

export default {
  name: 'Simple',
  mixins: [LayoutMixin],

  components: {
    Placeholder,
  },
};
</script>
