<template>
  <EditorialKitchenTalkFeed :header="header" :article-teasers="newsTeasers" />
</template>

<script>
import { mapGetters } from 'vuex';
import EditorialKitchenTalkFeed from './EditorialKitchenTalkFeed';
import { imageHasSource } from './helper';

export default {
  name: 'EditorialNewsFeed',

  components: {
    EditorialKitchenTalkFeed,
  },

  computed: {
    ...mapGetters(['getTeaserFallbackImage']),

    fieldsData() {
      return this.fields?.data;
    },

    header() {
      return {
        image: this.imageOrDefault(this.fieldsData?.contextItem?.headerImage?.jss),
        headline: this.$t('newshub-headline'),
        copy: this.$t('newshub-copy'),
      };
    },

    newsTeasers() {
      return this.fieldsData?.contextItem?.news
        ?.filter(news => news.versions.length > 0)
        .map(news => this.createTeaser(news));
    },
  },

  methods: {
    imageHasSource,

    createTeaser(news) {
      return {
        headline: news.headline?.value,
        copy: news.teaserCopy?.value,
        image: this.imageOrDefault(news.teaserImage?.jss),
        link: news.link,
      };
    },

    imageOrDefault(image) {
      return this.imageHasSource(image) ? image : this.getTeaserFallbackImage?.jss;
    },
  },
};
</script>
