<template>
  <div class="editorial-video" @click="iframeVisibility = true">
    <div class="is-editing" v-if="isEditing">
      <span>Enter the Youtube video ID: </span>
      <sc-text :field="fields.videoId" />
    </div>

    <div v-if="videoId !== null" class="editorial-video__container">
      <EditorialImage
        class="editorial-video__poster-image"
        fit="cover"
        :media="posterImage"
        :editable="false"
      />
      <div class="editorial-video__play"></div>
      <iframe
        v-if="iframeVisibility && videoId"
        width="560"
        height="315"
        frameborder="0"
        allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
        allowfullscreen
        :src="videoUrl"
      />
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import { Text } from '@sitecore-jss/sitecore-jss-vue';
import EditorialImage from '@/components/EditorialImage';

// Inspired by https://github.com/paulirish/lite-youtube-embed

export default {
  name: 'EditorialVideo',

  components: {
    scText: Text,
    EditorialImage,
  },

  data() {
    return {
      iframeVisibility: false,
    };
  },

  computed: {
    ...mapGetters('jss', ['isEditing']),
    ...mapGetters(['isMobile']),

    videoId() {
      return this.fields?.videoId?.value;
    },

    posterImage() {
      return {
        src: this.isMobile
          ? `https://i.ytimg.com/vi/${this.videoId}/hqdefault.jpg`
          : `https://i.ytimg.com/vi/${this.videoId}/maxresdefault.jpg`,
        width: 16,
        height: 9,
      };
    },

    videoUrl() {
      return `https://www.youtube-nocookie.com/embed/${this.videoId}?autoplay=1&amp;color=white&rel=0`;
    },
  },

  watch: {
    videoId() {
      this.iframeVisibility = false;
    },
  },
};
</script>

<style lang="scss" scoped>
.editorial-video {
  margin: $unit-double 0;

  @include helper__greater($bp-768) {
    margin: $unit-double * 2 auto;
  }
}

.editorial-video__container {
  background-color: $color-black;
  position: relative;
  display: block;
  cursor: pointer;

  &::after {
    content: '';
    display: block;
    padding-bottom: calc(100% / (16 / 9));
    pointer-events: none;
  }
}

.editorial-video__poster-image {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.editorial-video__play {
  background-color: rgba($color-black, 0.4);
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;

  &::before {
    content: '';
    border-style: solid;
    border-width: 18px 0 18px 30px;
    border-color: transparent transparent transparent $color-aureolin;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate3d(-50%, -50%, 0);
  }
}

.editorial-video iframe {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
}

.is-editing {
  @include spacing;
  margin-bottom: $unit-half !important;
}
</style>
