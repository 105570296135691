<template>
  <div ref="container" class="editorial-neo-comparison" :class="{ isEditing }">
    <ContentAnchor
      :fields="{
        anchorTitle: datasource['anchorTitle'],
        anchorName: datasource['anchorName'],
      }"
    />

    <EditorialHeadline
      v-if="textHasValue(datasource['title']?.jss) || isEditing"
      :fields="{ headline: datasource['title'].jss }"
      class="title"
      ref="title"
      headline-level="h2"
    />

    <EditorialRichtext
      v-if="textHasValue(datasource['copy']?.jss) || isEditing"
      :fields="{ content: datasource['copy'].jss }"
      class="text"
      ref="text"
    />

    <div ref="comparison" class="comparison">
      <div class="comparison-both comparison-1">
        <EditorialImage
          v-if="imageHasSource(secondImage) || isEditing"
          :media="secondImage"
          :sources="{
            '(min-width: 769px)': '16:10',
          }"
          default-aspect-ratio="3:2"
          default-sizes="100vw"
          fit="cover"
          class="image"
        />

        <!-- tooltips -->
        <div
          v-for="(t, index) in tooltips"
          :key="index"
          class="comparison-hotspot"
          :class="{ 'comparison-hotspot--open': t.open, 'comparison-hotspot--low': t.top > 50 }"
          :style="{ top: `${t.top}%`, left: `${t.left}%` }"
        >
          <button class="comparison-hotspot__trigger" @click.stop="hotspotTriggerClick($event, t)">
            <IconNeoHotspot />
          </button>

          <div class="comparison-hotspot__layer" @click.stop>
            <sc-text v-if="t.title" :field="t.title" class="title" tag="h2" />
            <sc-text v-if="t.text" :field="t.text" class="text" tag="p" />
            <EditorialButton
              v-if="t.cta"
              :fields="{ link: t.cta, _extendedParams: buttonParams('yellow--plain') }"
              class="cta"
            />
          </div>
        </div>
        <!-- / tooltips -->
      </div>
      <div ref="overlay" class="comparison-both comparison-2">
        <EditorialImage
          v-if="imageHasSource(firstImage) || isEditing"
          :media="firstImage"
          :sources="{
            '(min-width: 769px)': '16:10',
          }"
          default-aspect-ratio="3:2"
          default-sizes="100vw"
          fit="cover"
          class="image"
        />
      </div>
      <div ref="divider" class="comparison-divider" @click.stop>
        <div class="comparison-divider__knob">
          <IconNeoSlider />
        </div>
      </div>
    </div>

    <EditorialHeadline
      v-if="textHasValue(datasource['productsTitle']?.jss) && isEditing"
      :fields="{ headline: datasource['productsTitle'].jss }"
      class="products-title--is-editing"
      headline-level="h3"
    />

    <div class="products" v-if="isMobile && variants.length">
      <NolteVariantGroup
        :variants="variants"
        :label="
          textHasValue(datasource['productsTitle']?.jss)
            ? datasource['productsTitle'].jss.value
            : undefined
        "
        type="scroller"
        class="products__scroller"
      />
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import NolteVariantGroup from '@/nolte-ui/NolteVariant/NolteVariantGroup';

import { Text } from '@sitecore-jss/sitecore-jss-vue';
import ContentAnchor from '@/components/ContentAnchor';
import EditorialButton from '@/components/EditorialButton';
import EditorialImage from '@/components/EditorialImage';
import EditorialHeadline from '@/components/EditorialHeadline';
import EditorialRichtext from '@/components/EditorialRichText';
import { textHasValue, imageHasSource } from './helper';
import IconNeoSlider from '@/assets/icons/IconNeoSlider.svg?inline';
import IconNeoHotspot from '@/assets/icons/IconNeoHotspot.svg?inline';
import eventBus from '@/lib/eventBus';

export default {
  name: 'EditorialNeoComparison',

  components: {
    ContentAnchor,
    NolteVariantGroup,
    EditorialButton,
    EditorialImage,
    EditorialHeadline,
    EditorialRichtext,
    IconNeoSlider,
    IconNeoHotspot,
    ScText: Text,
  },

  beforeUnmount() {
    eventBus.$emit('EditorialNeoComparison:beforeUnmount');
  },

  mounted() {
    this.initComparisonSlider(this.$refs.comparison, this.$refs.overlay, this.$refs.divider);

    // create tooltips
    if (this?.datasource?.neoImage?.jss?.value?.coordinates) {
      this.tooltips = this.datasource.neoImage.jss.value.coordinates.map(item => {
        return {
          title: item.fields.Headline,
          text: item.fields.Text,
          cta: item.fields.CTA,
          left: Math.round(item.left * 100),
          top: Math.round(item.top * 100),
          open: false,
        };
      });

      const closeAllTooltips = () => {
        this.tooltips.forEach(item => {
          item.open = false;
        });
      };
      document.body.addEventListener('click', closeAllTooltips);
      eventBus.$once('EditorialNeoComparison:beforeUnmount', () => {
        if (typeof window !== 'undefined') {
          window.removeEventListener('onConsentStatusChange', closeAllTooltips);
        }
      });
    }
  },

  data: () => ({
    tooltips: [],
  }),

  computed: {
    ...mapGetters('jss', ['isEditing']),
    ...mapGetters(['isMobile']),

    datasource() {
      return this.fields.data.datasource;
    },

    firstImage() {
      return this.datasource?.['image']?.jss;
    },

    secondImage() {
      return this.datasource?.['neoImage']?.jss?.value['Source Image'];
    },

    variants() {
      if (!this?.datasource?.productLinks?.targetItems) {
        return [];
      }
      return this.datasource.productLinks.targetItems.map(item => {
        // create variant url
        let url = item.Product?.url || item.Product?.parent?.url;
        url += '?guid=' + item.id;
        if (item.internalTags?.value?.length > 0) {
          url += '&id=' + item.internalTags?.value;
        }

        return {
          url,
          variantLabel: item.label?.value,
          internalTag: item.internalTags?.value,
          variantImage: item.previewImage?.jss?.value,
          showProductInformation: true,
          // we have to take a look at items upwards in the tree to get the product label and the
          // category label. But because of the variant group items we sometimes have to look at
          // the parent and sometimes at the parent.parent
          // Both paths are returned from graphQL but one of them will be null
          productLabel: item.Product?.label?.value || item.Product?.parent?.label?.value,
          categoryLabel:
            item?.Category?.parent?.label?.value || item?.Category?.parent?.parent?.label?.value,
        };
      });
    },
  },

  methods: {
    textHasValue,
    imageHasSource,

    hotspotTriggerClick(e, hotspotData) {
      hotspotData.open = !hotspotData.open;

      // calculate the left position of the layer when it is opened
      if (!hotspotData.open) return;

      const $layer = e.target.closest('button').nextSibling;
      $layer.style.left = 0;
      const layerCoords = $layer.getBoundingClientRect();
      const viewportWidth = document.documentElement.clientWidth;
      const marginToEdge = 10;

      if (layerCoords.x + layerCoords.width > viewportWidth - marginToEdge) {
        $layer.style.left =
          viewportWidth - (layerCoords.x + layerCoords.width) - marginToEdge + 'px';
      } else if (layerCoords.x < marginToEdge) {
        $layer.style.left = -layerCoords.x + marginToEdge + 'px';
      }
    },

    initComparisonSlider($container, $overlay, $divider) {
      let isClicked = false;

      /* Get the width and height of the img element */
      let w = $container.offsetWidth;

      /* Set the initial position to 50% */
      slide(w / 2);

      /* Execute a function when the mouse button is pressed: */
      $divider.addEventListener('mousedown', slideReady);
      /* Or touched (for touch screens: */
      $divider.addEventListener('touchstart', slideReady);

      if (typeof window !== 'undefined') {
        window.addEventListener('resize', updateContainerWidth);
        /* And another function when the mouse button is released: */
        window.addEventListener('mouseup', slideFinish);
        /* And released (for touch screens: */
        window.addEventListener('touchend', slideFinish);
      }

      eventBus.$on('EditorialNeoComparison:beforeUnmount', () => {
        $divider.removeEventListener('mousedown', slideReady);
        $divider.removeEventListener('touchstart', slideReady);
        if (typeof window !== 'undefined') {
          window.removeEventListener('mouseup', slideFinish);
          window.removeEventListener('touchend', slideFinish);
          window.removeEventListener('resize', updateContainerWidth);
        }
      });

      function updateContainerWidth() {
        w = $container.offsetWidth;
      }

      function slideReady(e) {
        /* Prevent any other actions that may occur when moving over the image: */
        e.preventDefault();
        /* The slider is now clicked and ready to move: */
        isClicked = true;
        if (typeof window !== 'undefined') {
          /* Execute a function when the slider is moved: */
          window.addEventListener('mousemove', slideMove);
          window.addEventListener('touchmove', slideMove);
        }
      }

      function slideFinish() {
        /* The slider is no longer clicked: */
        isClicked = false;
        if (typeof window !== 'undefined') {
          /* Remove the events listeners added in slideReady(): */
          window.removeEventListener('mousemove', slideMove);
          window.removeEventListener('touchmove', slideMove);
        }
      }

      function slideMove(e) {
        let pos;
        /* If the slider is no longer clicked, exit this function: */
        if (!isClicked) return false;
        /* Get the cursor's x position: */
        pos = getCursorPos(e);
        /* Prevent the slider from being positioned outside the image: */
        if (pos < 0) pos = 0;
        if (pos > w) pos = w;
        /* Execute a function that will resize the overlay image according to the cursor: */
        slide(pos);
      }

      function getCursorPos(e) {
        let x = 0;
        e = e.changedTouches ? e.changedTouches[0] : e;
        /* Get the x positions of the image: */
        const a = $overlay.getBoundingClientRect();
        /* Calculate the cursor's x coordinate, relative to the image: */
        x = e.pageX - a.left;
        /* Consider any page scrolling: */
        x = x - window.pageXOffset;
        return x;
      }

      function slide(x) {
        /* Set value as percentage so we don't have to adjust this on a window resize event */
        const dividerWidth = 4;
        const percent = (x / (w + dividerWidth)) * 100;
        /* Clip the overlay container: */
        $overlay.style.clipPath = `polygon(0 0, ${percent}% 0, ${percent}% 100%, 0 100%)`;
        /* Position the slider: */
        $divider.style.left = percent + '%';
      }
    },

    buttonParams(style) {
      return {
        ButtonType: {
          fields: {
            Type: {
              value: style,
            },
          },
        },
      };
    },
  },
};
</script>

<style lang="scss" scoped>
.editorial-neo-comparison {
  @include spacing;
  position: relative;
}

.title {
  :deep(.nolte-headline) {
    @include text-h1-detail;
  }
  padding: 0;
  margin-bottom: $unit-base;
}
.text {
  padding: 0;
  margin: 0 0 $unit-base * 3;
}

.comparison {
  position: relative;
}

.comparison-2 {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;

  .image {
    width: 100%;
  }
}

.comparison-divider {
  position: absolute;
  z-index: 1;
  top: 0;
  height: 100%;
  border-right: 4px solid $color-white;
  cursor: ew-resize;
}

.comparison-divider__knob {
  position: absolute;
  top: 50%;
  transform: translate(calc(-50% + 2px), -50%);
  width: $unit-quadruple;
  height: $unit-quadruple;
  content: '';

  svg {
    width: 100%;
    height: 100%;
  }
}

.comparison-hotspot {
  position: absolute;
  top: 50%;
  transform: translate(-50%, -50%);

  .comparison-hotspot__trigger {
    border: 0;
    padding: 0;
    width: $unit-quadruple;
    height: $unit-quadruple;
    border-radius: 50%;
    box-shadow: 0px 30px 50px #00000066;

    svg {
      @include helper__transition;
    }
  }

  .comparison-hotspot__layer {
    position: absolute;
    left: 0;
    top: $unit-quadruple + $unit-base;
    min-width: 270px;
    color: $color-tuatara;
    background: $color-white;
    border-radius: 5px;
    padding: $unit-double $unit-double;
    box-shadow: 0px 30px 50px #68666366;
    pointer-events: none;

    @include helper__transition((opacity, transform));
    opacity: 0;
    transform: translate(calc(-50% + #{$unit-double}), -$unit-double);
  }

  &.comparison-hotspot--open {
    // open hotspots will fly above both images
    z-index: 11;

    .comparison-hotspot__trigger svg {
      transform: rotate(45deg);
    }
    .comparison-hotspot__layer {
      pointer-events: auto;
      opacity: 1;
      transform: translate(calc(-50% + #{$unit-double}), 0);
    }
  }

  &.comparison-hotspot--low {
    .comparison-hotspot__layer {
      top: auto;
      bottom: $unit-quadruple + $unit-base;
      transform: translate(calc(-50% + #{$unit-double}), $unit-double);
    }

    &.comparison-hotspot--open .comparison-hotspot__layer {
      transform: translate(calc(-50% + #{$unit-double}), 0);
    }
  }

  .title {
    @include text-h1-detail;
    margin: 0 0 $unit-base 0;

    @include helper__greater($bp-768) {
      line-height: 1;
    }
  }

  .text {
    margin-bottom: 0;
  }

  .cta {
    margin: $unit-double 0 0;

    :deep(.editorial-button-wrapper) {
      margin: 0;
      padding: 0;
    }
    :deep(.nolte-button) {
      @include text-h5;
    }
  }
}

// mobile vp
@include helper__until($bp-768) {
  .editorial-neo-comparison {
    overflow: hidden;
  }

  .title {
    margin: 0 0 $unit-base;
  }

  .text {
    margin: 0 0 $unit-base;
  }

  .comparison {
    width: calc(100% + #{$unit-double});
    margin-left: -$unit-base;
  }

  .comparison-divider__knob {
    width: $unit-double + $unit-base;
    height: $unit-double + $unit-base;
  }

  .comparison-hotspot {
    display: none;
  }

  .products__title {
    margin: $unit-base 0;
    padding: 0;
  }

  .products__scroller {
    width: calc(100% + #{$unit-double});
    margin-left: -$unit-base;

    :deep() {
      .nolte-variant-group__label {
        @include text-h3;
        padding: $unit-base $unit-base 0;
      }
      .nolte-variant__label {
        color: $color-concret;
      }
      .nolte-variant__info {
        opacity: 1;
      }
    }
  }
}

.products-title--is-editing {
  :deep(.editorial-headline) {
    text-transform: 0;
  }
  @include helper__greater($bp-768) {
    padding: 0;
  }
}

:deep(.editorial-image) {
  width: 100%;
}

// for debugging
// :deep(.comparison-2 img) {
//   filter: grayscale(100%) blur(2px);
// }
</style>
