<template>
  <main class="news-hub">
    <Placeholder name="jss-news-hub-main" :rendering="route" />
    <Placeholder name="jss-dataprovider" :rendering="route" />
  </main>
</template>

<script>
import Placeholder from '@/components/Placeholder';
import LayoutMixin from './LayoutMixin';

export default {
  name: 'NewsHubPage',
  mixins: [LayoutMixin],
  components: {
    Placeholder,
  },
};
</script>
