<template>
  <v-field-template
    name="radiolist"
    :field="field"
    :hidden="hidden"
    :disabled="disabled"
    :errors="errors"
  >
    <template #field>
      <NolteFormWrapper :errors="errors" #default="{ hasError }">
        <NolteFormRadio
          v-for="(item, key) in field.model.items"
          :key="key"
          :name="field.valueField.name"
          :code="item.value"
          :checked="item.selected"
          :required="field.model.required"
          :disabled="disabled"
          v-model="value"
          :value="item.value"
          @blur="fieldBlur"
          :label="item.text"
          :invalid="hasError"
          @uncheck="uncheck"
        />
      </NolteFormWrapper>
    </template>
  </v-field-template>
</template>

<script>
import eventBus from '@/lib/eventBus';

import FieldMixin from '../structure/FieldMixin';
import FieldConditionMixin from '../structure/conditions/FieldConditionMixin';
import FieldValidationMixin from '../structure/validation/FieldValidationMixin';

import NolteFormRadio from '../../nolte-ui/NolteForm/NolteFormRadio';
import NolteFormWrapper from '../../nolte-ui/NolteForm/NolteFormWrapper';

export default {
  name: 'SitecoreFormsFieldsRadiolist',

  components: {
    NolteFormRadio,
    NolteFormWrapper,
  },

  mixins: [FieldMixin, FieldConditionMixin, FieldValidationMixin],

  data() {
    return {
      value: this.field.model.items.find(item => item.selected)
        ? this.field.model.items.find(item => item.selected).value
        : false,
    };
  },

  methods: {
    uncheck() {
      // Usually (standard HTML) we wouldn't send the value if no radio is selected. But usually it
      // is also not possible to uncheck a radio button. So we use the same mechanics Sitecore uses
      // for checkboxes. In case of an empty value we don't send nothing but an empty value to empty
      // the list.
      this.value = '';
      eventBus.$emit(this.formId + ':field-change', this);
    },
  },
};
</script>
