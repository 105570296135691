<template>
  <NolteMediaDetailGroup class="editorial-international-projects-showrooms">
    <NolteMediaDetail v-for="(showroom, index) in fields.showrooms" :key="index">
      <template #media>
        <EditorialImage
          v-if="showroom.fields && showroom.fields.image"
          :media="showroom.fields.image"
          default-aspect-ratio="3:2"
          default-sizes="850px"
          :editable="false"
        />
      </template>
      <template #detail>
        <NolteDetailShowroom
          v-if="showroom.fields && showroom.fields.headline && showroom.fields.copy"
          :headline="showroom.fields.headline.value"
          :copy="showroom.fields.copy.value"
          :address="address(showroom)"
        />
      </template>
    </NolteMediaDetail>
  </NolteMediaDetailGroup>
</template>

<script>
import NolteMediaDetailGroup from '../nolte-ui/NolteMediaDetail/NolteMediaDetailGroup';
import NolteMediaDetail from '../nolte-ui/NolteMediaDetail';
import NolteDetailShowroom from '../nolte-ui/NolteDetailShowroom';
import EditorialImage from '@/components/EditorialImage';

export default {
  name: 'EditorialInternationalProjectsShowroomsWorldwide',

  components: {
    NolteMediaDetailGroup,
    NolteMediaDetail,
    NolteDetailShowroom,
    EditorialImage,
  },

  methods: {
    address(showroom) {
      return {
        name: showroom?.fields?.addressName?.value,
        street: showroom?.fields?.addressStreet?.value,
        locality: showroom?.fields?.addressLocality?.value,
        zip: showroom?.fields?.addressZip?.value,
        country: showroom?.fields?.addressCountry?.value,
        phone: showroom?.fields?.addressPhone?.value,
        email: showroom?.fields?.addressEmail?.value,
      };
    },
  },
};
</script>
