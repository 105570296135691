<template>
  <div class="editorial-mnk-kitchenplan-download">
    <div v-if="kitchenplanDownloadUrl">
      <EditorialHeadline
        v-if="textHasValue(fields['Headline'])"
        :fields="{ headline: fields['Headline'] }"
        class="headline"
        headline-level="h2"
      />

      <EditorialTextMultiLine
        v-if="textHasValue(fields['Copy'])"
        :fields="{ copy: fields['Copy'] }"
        class="subline"
      />
    </div>

    <div v-if="kitchenplanDownloadUrl === false">
      <EditorialHeadline
        v-if="textHasValue(fields['No Plans Headline'])"
        :fields="{ headline: fields['No Plans Headline'] }"
        class="headline"
        headline-level="h2"
      />

      <EditorialTextMultiLine
        v-if="textHasValue(fields['No Plans Copy'])"
        :fields="{ copy: fields['No Plans Copy'] }"
        class="subline"
      />
    </div>

    <div class="buttons">
      <EditorialButton v-if="kitchenplanDownloadUrl" :fields="buttonDownload" />
      <EditorialButton v-if="linkHasValue(fields['Next Button'])" :fields="buttonNext" />
    </div>
  </div>
</template>
<script>
import { mapState } from 'vuex';
import { dataFetcher } from '@/dataFetcher';
import { textHasValue, linkHasValue } from './helper';

import EditorialHeadline from '@/components/EditorialHeadline';
import EditorialButton from './EditorialButton';
import EditorialTextMultiLine from './EditorialTextMultiLine';

export default {
  name: 'EditorialMNKKitchenPlanDownload',

  components: {
    EditorialHeadline,
    EditorialButton,
    EditorialTextMultiLine,
  },

  data: () => ({
    kitchenplanDownloadUrl: null,
  }),

  computed: {
    ...mapState('jss', ['sitecoreContext']),

    buttonDownload() {
      return {
        link: {
          value: {
            href: this.kitchenplanDownloadUrl,
            text: this.fields['Download Button Text']?.value,
            linktype: 'external',
            target: '_blank',
          },
        },
        _extendedParams: this.buttonParams('grey--plain'),
      };
    },

    buttonNext() {
      return {
        link: this.fields['Next Button'],
        _extendedParams: this.buttonParams('yellow--plain'),
      };
    },
  },

  methods: {
    textHasValue,
    linkHasValue,

    getKitchenplanDownloadUrl() {
      const payload = sessionStorage.getItem('dealerTransmission');
      // check if we have a valid sessionStorage item
      if (!payload) {
        this.kitchenplanDownloadUrl = false;
      } else {
        const planIds = JSON.parse(payload).planIds;

        // check if kitchenplans were selected by the user
        if (planIds !== '') {
          this.kitchenplanDownloadUrl =
            `${process.env.VUE_APP_API_BASE_URL}/Kitchenplanning/Download/${this.sitecoreContext.language}/${planIds}`.toLowerCase();
        } else {
          this.kitchenplanDownloadUrl = false;
        }
      }

      // The following would be the preferred solution but because of a bug that kills the session
      // we have to pass the planids on the FE via sessionStorage. At a later point this code
      // should get valid.

      // this.api('/Kitchenplanning/GetDownloadUrl').then(result => {
      //   if (result.data) {
      //     this.kitchenplanDownloadUrl = `${process.env.VUE_APP_API_BASE_URL}/Kitchenplanning/Download/${this.sitecoreContext.language}/${result.data}`.toLowerCase();
      //   } else {
      //     this.kitchenplanDownloadUrl = false;
      //   }
      // });
    },

    buttonParams(type) {
      return {
        ButtonType: {
          fields: {
            Type: {
              value: type,
            },
          },
        },
      };
    },

    api(node, data) {
      return dataFetcher(process.env.VUE_APP_API_BASE_URL + node, data).then(response => {
        if (response.status !== 200) {
          throw new Error('No HTTP 200');
        }
        return response;
      });
    },
  },

  mounted() {
    this.getKitchenplanDownloadUrl();
  },
};
</script>

<style lang="scss" scoped>
.editorial-mnk-kitchenplan-download {
  background: $color-white;

  // triggers the complete rendering of the background
  padding-top: 1px;
}

.headline {
  :deep() > * {
    @include helper__greater($bp-768) {
      max-width: none;
      font-size: 36px;
      line-height: (48 / 36);
    }
  }
}

.subline {
  margin: $unit-double auto;
}

.buttons {
  @include spacing;

  :deep() {
    .editorial-button-wrapper {
      margin: $unit-base 0;
    }
    .editorial-button-wrapper {
      padding: 0;
    }
    .editorial-button,
    .nolte-button {
      @include text-h5;
      min-width: 0;
      max-width: none;
      width: 100%;
      display: block;
    }
  }

  @include helper__greater($bp-768) {
    display: flex;
    justify-content: flex-end;

    .editorial-button-outer-wrapper {
      margin: 0 0 0 $unit-base;
    }
  }
}
</style>
