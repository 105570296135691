import 'url-search-params-polyfill';
import { useMeta } from 'vue-meta';

export default function ({ dataProviderGlobal, jss }, globalAnchorNavigationVisible) {
  const routeFields = jss?.routeData?.fields;

  const title = [
    dataProviderGlobal?.browserTitle?.prefix?.value,
    routeFields?.Title?.value,
    dataProviderGlobal?.browserTitle?.suffix?.value,
  ]
    .filter(Boolean)
    .join(' ');

  const head = {
    title,
    link: [
      { rel: 'canonical', href: jss?.sitecoreContext?.canonicalUrl },
      {
        rel: 'preconnect',
        href: 'https://nolte-prod-ebcwh6b9axcgebb3.z01.azurefd.net/',
      },
      {
        rel: 'preconnect',
        href: 'https://app.usercentrics.eu/',
      },
      {
        rel: 'preconnect',
        href: 'https://api.usercentrics.eu/',
      },
      {
        rel: 'preconnect',
        href: 'https://aggregator.service.usercentrics.eu/',
      },
    ],
    meta: [],
    htmlAttrs: {
      lang: jss?.sitecoreContext?.language,
      class: [
        globalAnchorNavigationVisible
          ? `template--${jss?.routeData?.templateName.toLowerCase()} anchor-navigation--visible`
          : `template--${jss?.routeData?.templateName.toLowerCase()}`,
      ],
    },
  };

  // add standard meta tags
  if (routeFields?.MetaDescription?.value) {
    head.meta.push({ name: 'description', content: routeFields.MetaDescription.value });
  }
  if (routeFields?.MetaKeywords?.value) {
    head.meta.push({ name: 'keywords', content: routeFields.MetaKeywords.value });
  }

  // add opengraph meta tags
  head.meta.push({ property: 'og:url', content: jss?.sitecoreContext?.canonicalUrl });
  if (routeFields?.OpenGraphType?.value) {
    head.meta.push({ property: 'og:type', content: routeFields.OpenGraphType.value });
  }
  if (routeFields?.OpenGraphSiteName?.value) {
    head.meta.push({ property: 'og:site_name', content: routeFields.OpenGraphSiteName.value });
  }
  if (routeFields?.OpenGraphTitle?.value) {
    head.meta.push({ property: 'og:title', content: routeFields.OpenGraphTitle.value });
  }
  if (routeFields?.OpenGraphImageUrl?.value?.src) {
    head.meta.push({ property: 'og:image', content: routeFields.OpenGraphImageUrl.value.src });
    head.meta.push({
      property: 'og:image:secure_url',
      content: routeFields.OpenGraphImageUrl.value.src,
    });
  }
  if (routeFields?.OpenGraphDescription?.value) {
    head.meta.push({ property: 'og:description', content: routeFields.OpenGraphDescription.value });
  }

  // add facebook specific opengraph meta tags
  if (routeFields?.OpenGraphAdmins?.value) {
    head.meta.push({ property: 'fb:admins', content: routeFields.OpenGraphAdmins.value });
  }
  if (routeFields?.OpenGraphAppId?.value) {
    head.meta.push({ property: 'fb:app_id', content: routeFields.OpenGraphAppId.value });
  }

  // add facebook specific domain verification
  if (dataProviderGlobal?.facebookDomainVerification?.page?.value !== '') {
    head.meta.push({
      property: 'facebook-domain-verification',
      content: dataProviderGlobal.facebookDomainVerification.page.value,
    });
  }
  // add pinterest specific domain verification
  if (dataProviderGlobal?.pinterestDomainVerification?.page?.value !== '') {
    head.meta.push({
      property: 'p:domain_verify',
      content: dataProviderGlobal.pinterestDomainVerification.page.value,
    });
  }

  // add user defined meta tags
  if (routeFields?.Attributes?.value) {
    const params = Array.from(new URLSearchParams(routeFields.Attributes.value).entries());
    const customMeta = params?.map(parts => {
      return { name: parts[0], content: parts[1] };
    });
    head.meta = head.meta.concat(customMeta);
  }

  // if route does not exist (404) routeLanguages is null
  if (!jss?.sitecoreContext?.versions) {
    return null;
  }

  // add href lang tags
  jss.sitecoreContext.versions.forEach(version => {
    // we want a trailing slash for home items
    if (
      jss?.sitecoreContext?.site?.siteLanguages
        ?.map(language => {
          return '/' + language.toLowerCase();
        })
        .includes(version.url)
    ) {
      version.fullUrl += '/';
    }

    const tag = {
      rel: 'alternate',
      hreflang: version.language,
      href: version.fullUrl,
    };
    head.link.push(tag);

    // set the x-default href lang to en-GB
    if (version.locale === 'en-GB') {
      head.link.push({
        ...tag,
        hreflang: 'x-default',
      });
    }
  });

  useMeta({
    title: head.title,
    htmlAttrs: head.htmlAttrs,
    meta: head.meta,
    link: head.link,
  });
  return head;
}
