import { createApp as createVueApp, h, createSSRApp } from 'vue';
import { SitecoreJssPlaceholderPlugin } from '@sitecore-jss/sitecore-jss-vue';
import {
  createMetaManager as createVueMetaManager,
  defaultConfig,
  deepestResolver,
} from 'vue-meta';
import AppRoot from './AppRoot';
import { createRouter } from './router';
import GraphQLClientFactory from './lib/GraphQLClientFactory';
import config from './temp/config';
import componentFactory from './temp/componentFactory';
import comspaceBootstrap from './comspace/Bootstrap';
import VueSocialSharing from 'vue-social-sharing';

const createMetaManager = (isSSR = false) =>
  createVueMetaManager(
    isSSR,
    {
      ...defaultConfig,
      meta: { tag: 'meta', nameless: true },
    },
    deepestResolver
  );

// createApp is invoked by both the main and SSR entry points, so the two entry points can use the same app creation process.
export function createApp(initialState, i18n, isSSR) {
  const router = createRouter(isSSR);
  const metaManager = createMetaManager(isSSR);
  const graphQLProvider = createGraphQLClient(initialState);

  const vueOptions = {
    router,
    render: () => h(AppRoot),
    i18n,
  };

  const app = isSSR ? createSSRApp(vueOptions) : createVueApp(vueOptions);

  const { store } = comspaceBootstrap(app, graphQLProvider);

  app.provide('apollo', graphQLProvider);

  app.use(router);
  //app.use(SitecoreJssStorePlugin);
  app.use(SitecoreJssPlaceholderPlugin, { componentFactory });
  app.use(i18n);
  app.use(metaManager);
  app.use(VueSocialSharing);
  
  // if there is an initial state defined, push it into the store, where it can be referenced by interested components.
  if (initialState) {
    //app.config.globalProperties.$jss.store.setSitecoreData(initialState);
    store.dispatch('jss/setSitecoreData', initialState);
  }

  return { app, router, graphQLProvider };
}

export function createGraphQLClient(initialState) {
  const endpointWithCreds = `${config.graphQLEndpoint}?sc_apikey=${config.sitecoreApiKey}`;

  const client =
    initialState && initialState.APOLLO_STATE
      ? GraphQLClientFactory(endpointWithCreds, false, initialState.APOLLO_STATE)
      : GraphQLClientFactory(endpointWithCreds, true);

  return client;
}
