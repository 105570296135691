<template>
  <div
    class="nolte-variant-group"
    :class="{
      'nolte-variant-group--grid': type === 'grid',
      'nolte-variant-group--scroller': type === 'scroller',
    }"
  >
    <h4 v-if="label" class="nolte-variant-group__label">
      {{ label }}
    </h4>

    <NolteScroller scroller-el="ul" class="nolte-variant-group__content" shadow-only="">
      <li
        class="nolte-variant-group__variant"
        v-for="(
          {
            variantImage,
            productLabel,
            internalTag,
            variantLabel,
            url,
            categoryLabel,
            showProductInformation,
          },
          index
        ) in variants"
        :key="`variant-${index}`"
      >
        <NolteVariant
          :image="variantImage"
          :internal-tag="internalTag"
          :label="variantLabel"
          :product-label="productLabel"
          :url="url"
          :active="activeItem && activeItem.internalTag === internalTag"
          :category-label="categoryLabel"
          :show-product-information="showProductInformation"
        />
      </li>
    </NolteScroller>
  </div>
</template>

<script>
import NolteVariant from './';
import NolteScroller from '../NolteScroller';

export default {
  name: 'NolteVariantGroup',

  components: {
    NolteVariant,
    NolteScroller,
  },

  props: {
    variants: {
      type: Array,
      required: true,
    },
    label: {
      type: String,
      default: undefined,
    },
    type: {
      type: String,
      default: 'grid',
      validator(value) {
        return ['grid', 'scroller'].includes(value);
      },
    },
    activeItem: {
      type: Object,
      default: undefined,
    },
  },
};
</script>

<style lang="scss" scoped>
$grid-gap: 5px;

.nolte-variant-group {
  width: 100%;

  // create a new stacking context that makes shadow appear behind content
  // https://developer.mozilla.org/en-US/docs/Web/CSS/CSS_Positioning/Understanding_z_index/The_stacking_context
  z-index: 0;
  position: relative;
}

.nolte-variant-group__content :deep(.viewport) {
  display: flex;
  justify-content: space-between;
  margin-right: -$grid-gap;
}

.nolte-variant-group__variant {
  padding-right: $grid-gap;
  padding-bottom: $unit-base;
  position: relative;

  &:last-of-type {
    margin-right: auto;
  }
}

.nolte-variant-group--scroller {
  .nolte-variant-group__content :deep(.viewport) {
    padding-top: $unit-base;
    padding-right: $unit-base;
    padding-left: $unit-base;

    @include helper__greater($bp-768) {
      padding: $unit-double $unit-double $unit-base;
    }
  }

  .nolte-variant-group__variant {
    flex: 0 0 auto;
    width: 140px;

    @include helper__greater($bp-768) {
      width: 235px;
    }
  }
}

.nolte-variant-group--grid {
  .nolte-variant-group__content {
    overflow: visible;
  }

  .nolte-variant-group__content :deep() {
    .viewport {
      flex-wrap: wrap;
      overflow: visible;
      // overwrite
      width: 100%;
    }
  }
}

.nolte-variant-group__variant {
  flex: 0 0 33.33%;

  @include helper__greater($bp-768) {
    flex-basis: 25%;
  }
}

.nolte-variant-group__label {
  @include text-caption--small;
  padding-bottom: $unit-base;
  padding-left: $grid-gap;

  @include helper__greater($bp-425) {
    @include text-caption;
  }
}

:deep(.swiper-slide) > * {
  margin: 0;
}
</style>
