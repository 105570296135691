const jssModule = {
  namespaced: true,

  state: {
    sitecoreContext: {
      pageEditing: false,
    },
    routeData: null,
  },

  getters: {
    isEditing(state) {
      return state.sitecoreContext?.pageEditing;
    },

    isPreview(state) {
      return state.sitecoreContext?.pageState === 'preview';
    },

    // checks if we are running in connected mode that doesn't use local mock data
    // that is not the same as this.$isServer which answers the question if the current page is SSR.
    isConnected(state) {
      return state.sitecoreContext?.site?.name !== 'JssDisconnectedLayoutService';
    },

    isNeoPage(state) {
      return state.routeData?.templateName === 'NeoPage';
    },

    currentLanguage({ sitecoreContext }) {
      return sitecoreContext?.language;
    },

    currentRouteLanguage({ sitecoreContext }) {
      if (!sitecoreContext?.versions) return null;
      return sitecoreContext.versions.find(
        ({ locale }) => locale.toLowerCase() === sitecoreContext.language.toLowerCase()
      );
    },
  },

  mutations: {
    setRouteData(state, routeData) {
      state.routeData = routeData;
    },

    setSitecoreContext(state, sitecoreContext) {
      state.sitecoreContext = sitecoreContext;
    },
  },

  actions: {
    setSitecoreData({ commit }, sitecoreData) {
      const route = sitecoreData?.sitecore?.route;
      const context = sitecoreData?.sitecore?.context || {};

      // Do not replace the original state object - the store and any components that use the store
      // need to share a reference to the same object in order for mutations to be observed.
      commit('setRouteData', route);
      commit('setSitecoreContext', {
        ...context,
        routeName: route?.name,
        itemId: route?.itemId,
      });

      // create the data of global data provider as own state in the parent vuex $store
      commit(
        'setGlobalData',
        sitecoreData.sitecore.route.placeholders['jss-dataprovider'].find(
          component => component.componentName === 'DataProviderGlobal'
        )?.fields?.data,
        { root: true }
      );
    },
  },
};

export default jssModule;
