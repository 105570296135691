<template>
  <div class="nolte-form-radio" :class="{ 'nolte-form-radio--invalid': invalid }">
    <input
      v-bind="$attrs"
      ref="input"
      type="radio"
      class="nolte-form-radio__radio"
      :id="id || randomId"
      :value="code"
      :checked="checked"
      v-on="listeners"
    />
    <label :for="id || randomId" @mousedown="radioMouseDown" @click="radioClick">
      {{ label }}
    </label>
  </div>
</template>

<script>
import '../keyboard-modality';

import { getRandomString } from '../helper';

export default {
  name: 'NolteFormRadio',

  inheritAttrs: false,

  props: {
    label: {
      type: String,
      default: '',
    },
    checked: {
      type: Boolean,
      default: false,
    },
    code: {
      type: String,
      default: '',
    },
    id: {
      type: String,
      default: undefined,
    },
    invalid: {
      type: Boolean,
    },
  },

  emits: ['input', 'change', 'uncheck', 'update:modelValue'],
  computed: {
    // ATTENTION: magic happening here!!! :D
    listeners() {
      return {
        ...this.$attrs,
        // we overwrite the change event from the component model...
        // with our own change method
        change: event => {
          this.$emit('update:modelValue', event.target.value);
          this.$emit('change', event.target.value);

          // IE 11 does not trigger the input event so we have to trigger it on change
          this.$emit('input', event.target.value);
        },
        // to enable v-model support
        // see: https://vuejs.org/v2/guide/components.html#Using-v-model-on-Components
        input: event => {
          this.$emit('update:modelValue', event.target.value);
          this.$emit('input', event.target.value);
        },
      };
    },

    randomId() {
      return getRandomString();
    },
  },

  methods: {
    // initUncheckableRadio
    radioMouseDown() {
      this.$refs.input.checkedOld = this.$refs.input.checked;
    },

    radioClick(e) {
      if (this.$refs.input.checkedOld) {
        // usually radio cannot be unchecked so the browsers do not respect this too
        // so we have to set the values and to trigger the events manually
        // this.selected = false;

        this.$emit('input', false);
        this.$emit('change', false);

        this.$refs.input.checked = false;

        // this does not work anymore => "TypeError: evt is not a function" when deselecting the radio box
        // const evt = document.createEvent('HTMLEvents');
        // evt('change', false, true);
        // this.$refs.input.dispatchEvent(evt);
        //
        // we use this event that is listened to in Radiolist to be able to edit the "changedFields"
        // object in Page.vue
        this.$emit('uncheck');

        e.preventDefault();
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.nolte-form-radio {
  display: block;
  margin-top: $unit-half;
  margin-bottom: $unit-half;
}

.nolte-form-radio__radio {
  @include helper__visually-hidden;
}

.nolte-form-radio__radio + label {
  cursor: pointer;
  white-space: nowrap;
  display: inline-flex;
  align-items: center;
  color: $color-ironside-gray;
  user-select: none;
}

.nolte-form-radio__radio + label::before {
  @include helper__transition((box-shadow, border-color));
  content: '';
  width: 26px;
  height: 26px;
  border-radius: 50%;
  border: 2px solid $color-athens-gray;
  background-color: $color-white;
  margin-right: $unit-half;
  margin-bottom: 3px;
}

.nolte-form-radio__radio:checked + label::before {
  box-shadow: inset 0 0 0 5px $color-citrine;
  border-color: $color-citrine;
}

.nolte-form-radio--invalid .nolte-form-radio__radio + label::before {
  border-color: $color-red-orange;
}

.nolte-form-radio__radio + label:hover::before {
  border-color: $color-tuatara;
}

// Style when focus by keyboard interaction
body[modality='keyboard'] .nolte-form-radio__radio:focus + label::before {
  border-color: $color-azure;
}
</style>
