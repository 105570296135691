<template>
  <v-field-template
    name="email"
    :field="field"
    :hidden="hidden"
    :disabled="disabled"
    :errors="errors"
  >
    <template #field>
      <div class="form-field-element-item">
        <NolteFormWrapper :errors="errors" #default="{ hasError }">
          <NolteFormInput
            type="email"
            autocomplete="on"
            :id="field.valueField.id"
            :name="field.valueField.name"
            :required="field.model.required"
            :minlength="field.model.minLength"
            :maxlength="field.model.maxLength"
            :placeholder="field.model.placeholderText"
            :disabled="disabled"
            v-model="value"
            :value="value"
            @blur="fieldBlur"
            :invalid="hasError"
          />
        </NolteFormWrapper>
      </div>

      <div class="form-field-element-item">
        <NolteFormWrapper :errors="errors" #default="{ hasError }">
          <NolteFormLabel
            :for="'id-' + field.model.itemId + '-confirm'"
            :label="field.model.confirmEmailLabel"
            :required="field.model.required"
          />
          <NolteFormInput
            type="email"
            :id="'id-' + field.model.itemId + '-confirm'"
            :name="field.valueField.name.replace(/\.Value$/, '.ConfirmEmail')"
            :placeholder="field.model.confirmEmailPlaceholder"
            :required="field.model.required"
            :minlength="field.model.minLength"
            :maxlength="field.model.maxLength"
            :disabled="disabled"
            :invalid="hasError"
          />
        </NolteFormWrapper>
      </div>
    </template>
    {{ id }}
  </v-field-template>
</template>

<script>
import FieldMixin from '../structure/FieldMixin';
import FieldConditionMixin from '../structure/conditions/FieldConditionMixin';
import FieldValidationMixin from '../structure/validation/FieldValidationMixin';

import NolteFormInput from '../../nolte-ui/NolteForm/NolteFormInput';
import NolteFormLabel from '../../nolte-ui/NolteForm/NolteFormLabel';
import NolteFormWrapper from '../../nolte-ui/NolteForm/NolteFormWrapper';

import { getStorage } from '@/useLocalStorage';

export default {
  name: 'SitecoreFormsFieldsEmailConfirmation',

  components: {
    NolteFormInput,
    NolteFormWrapper,
    NolteFormLabel,
  },

  mixins: [FieldMixin, FieldConditionMixin, FieldValidationMixin],

  // errors prop is defined in FieldValidationMixin

  mounted() {
    const savedValue = getStorage(this.field.valueField.name);
    this.value = savedValue ? savedValue : this.field.model.value;
  },

  data() {
    return {
      value: this.field.model.value,
    };
  },
};
</script>
