<template>
  <div class="editorial-mnk-account">
    <ContentPillLinks>
      <ContentLink
        :field="{ value: { text: $t('mnk-account-change-password') } }"
        @click="setChangePasswordFormVisible(true)"
      />
      <ContentLink :field="{ value: { text: $t('mnk-account-logout') } }" @click="logoutUser" />
    </ContentPillLinks>
    <GlobalForm :fields="formDefinitions.userInformation" v-if="formDefinitions.userInformation" />
    <div v-else>Menu data for MNK menu is missing.</div>

    <div class="additional-text-link">
      <button @click="setDeleteUserFormVisible(true)">
        {{ $t('mnk-account-delete-account') }}
      </button>
    </div>

    <ContentLightbox
      @open="setDeleteUserFormVisible(true)"
      @close="setDeleteUserFormVisible(false)"
      :visible="deleteUserFormVisible"
      overlay-class="overlay__deleteUser"
    >
      <GlobalForm :fields="formDefinitions.deleteUser" v-if="formDefinitions.deleteUser" />
      <div v-else>Menu data for MNK menu is missing.</div>
    </ContentLightbox>

    <ContentLightbox
      @open="setChangePasswordFormVisible(true)"
      @close="setChangePasswordFormVisible(false)"
      :visible="changePasswordFormVisible"
      overlay-class="overlay__changePassword"
    >
      <GlobalForm :fields="formDefinitions.changePassword" v-if="formDefinitions.changePassword" />
      <div v-else>Menu data for MNK menu is missing.</div>
    </ContentLightbox>

    <ContentLightbox
      @open="setUserInformationSuccessLightboxVisible(true)"
      @close="setUserInformationSuccessLightboxVisible(false)"
      :visible="userInformationSuccessLightboxVisible"
    >
      <NolteHeadline>
        {{ $t('mnk-account-save-changes-success-headline') }}
      </NolteHeadline>

      <p>
        {{ $t('mnk-account-save-changes-success-copy') }}
      </p>

      <div class="button-wrapper">
        <NolteButton type="yellow--plain" @click="setUserInformationSuccessLightboxVisible(false)">
          {{ $t('mnk-account-close-dialog') }}
        </NolteButton>
      </div>
    </ContentLightbox>

    <ContentLightbox
      @open="setChangePasswordSuccessLightboxVisible(true)"
      @close="setChangePasswordSuccessLightboxVisible(false)"
      :visible="changePasswordSuccessLightboxVisible"
    >
      <NolteHeadline>
        {{ $t('mnk-account-password-success-headline') }}
      </NolteHeadline>

      <p>
        {{ $t('mnk-account-password-success-copy') }}
      </p>

      <div class="button-wrapper">
        <NolteButton type="yellow--plain" @click="setChangePasswordSuccessLightboxVisible(false)">
          {{ $t('mnk-account-close-dialog') }}
        </NolteButton>
      </div>
    </ContentLightbox>
  </div>
</template>

<script>
import { mapState, mapGetters, mapActions, mapMutations } from 'vuex';
import { textHasValue } from './helper';

import GlobalForm from '@/components/GlobalForm';
import ContentLink from '@/components/ContentLink';
import ContentLightbox from '@/components/ContentLightbox';
import ContentPillLinks from '@/components/ContentPillLinks';
import NolteButton from '@/nolte-ui/NolteButton';
import NolteHeadline from '@/nolte-ui/NolteHeadline';
import eventBus from '@/lib/eventBus';

export default {
  name: 'EditorialMNKAccount',

  components: {
    ContentLink,
    ContentLightbox,
    ContentPillLinks,
    NolteButton,
    NolteHeadline,
    GlobalForm,
  },

  data: () => ({
    changePasswordFormVisible: false,
    deleteUserFormVisible: false,
    userInformationSuccessLightboxVisible: false,
    changePasswordSuccessLightboxVisible: false,
  }),

  computed: {
    ...mapState('jss', ['sitecoreContext', 'routeData']),
    ...mapGetters({ actionLinks: 'getMnkActionLinks' }),

    // get all form definitions in placeholder "jss-mnk-dialog"
    formDefinitions() {
      const mappings = {
        userInformation: '{355504F7-594B-4933-B2F3-86811AF49DD4}',
        changePassword: '{A006105D-FEB1-48CD-ABAA-8D1F7542775D}',
        deleteUser: '{832852C5-79BF-4007-A857-4A255CE4BE39}',
      };
      const forms = {};
      for (const mappingName in mappings) {
        let definition = false;
        try {
          definition = this.routeData?.placeholders['jss-mnk-dialog']?.find(
            item => item.dataSource === mappings[mappingName]
          ).fields;
        } catch {
          return false;
        }
        forms[mappingName] = definition;
      }
      return forms;
    },
  },

  methods: {
    ...mapActions(['checkUser']),
    ...mapActions(['logout']),
    ...mapMutations(['setSearchMnkMenuVisibility']),
    textHasValue,

    setDeleteUserFormVisible(status) {
      this.deleteUserFormVisible = status;
    },

    setChangePasswordFormVisible(status) {
      this.changePasswordFormVisible = status;
    },

    setUserInformationSuccessLightboxVisible(status) {
      this.userInformationSuccessLightboxVisible = status;
    },

    setChangePasswordSuccessLightboxVisible(status) {
      this.changePasswordSuccessLightboxVisible = status;
    },

    logoutUser() {
      // hide mnk navigation
      this.setSearchMnkMenuVisibility(false);

      // logout via api
      this.logout().then(() => {
        // redirect to logout success page
        // the query parameter "loggedout" is necessary to force a reload if we are already on the
        // "logout-success-page", because we need a fresh AntiForgery-Token from the
        // JSS Layout Service to be able to login again
        this.$router.push({
          path: this.actionLinks['logout-success-page'],
          query: { loggedout: true },
        });
      });
    },
  },

  mounted() {
    // show success message if changing user information was successful
    eventBus.$on(
      this.formDefinitions?.userInformation?.antiForgeryToken?.value + ':form-submitted-success',
      () => {
        this.checkUser();
        this.setUserInformationSuccessLightboxVisible(true);
      }
    );

    // show success message if changing user information was successful
    eventBus.$on(
      this.formDefinitions?.changePassword?.antiForgeryToken?.value + ':form-submitted-success',
      () => {
        this.setChangePasswordFormVisible(false);
        this.setChangePasswordSuccessLightboxVisible(true);
      }
    );
  },
};
</script>

<style lang="scss" scoped>
.editorial-mnk-account {
  background: $color-concret;
  padding-bottom: $unit-base;

  // triggers the complete rendering of the background
  padding-top: 1px;
}

.additional-text-link {
  @include spacing;

  button {
    border: 0;
    padding: 0;
    text-decoration: underline;
  }
}

// position of submit buttons
:deep(.form-field-type-submit .form-field-element) {
  text-align: left;
}

:deep(.form-field--text-small) {
  margin: 0;

  .copy__content {
    text-align: left;
    margin: 0;
    padding: 0;
  }
}

.overlay__changePassword :deep(),
.overlay__deleteUser :deep() {
  .global-form {
    margin: $unit-double;
    padding: 0;
    background: none;

    @include helper__greater($bp-768) {
      margin: $unit-double * 2;
    }
  }
  .customContent {
    padding: 0;
    overflow: auto;
  }
}
</style>
