<template>
  <div>
    <ContentLink
      :editable="false"
      class="nolte-button-image-pill"
      :field="{ value: { href: href, linktype: 'internal' } }"
    >
      <figure>
        <div class="nolte-button-image-pill__image-wrapper">
          <EditorialImage
            class="nolte-button-image-pill__image"
            :media="image"
            default-aspect-ratio="3:4"
            fit="cover"
            default-sizes="190px"
            :editable="false"
          />
        </div>
        <figcaption class="nolte-button-image-pill__label-wrapper">
          <span class="nolte-button-image-pill__label">{{ label }}</span>
        </figcaption>
      </figure>
    </ContentLink>
  </div>
</template>

<script>
import ContentLink from '@/components/ContentLink';
import EditorialImage from '@/components/EditorialImage';

export default {
  name: 'NolteButtonImagePill',

  components: {
    ContentLink,
    EditorialImage,
  },

  props: {
    label: {
      required: true,
      type: String,
    },
    image: {
      type: Object,
      required: true,
    },
    href: {
      type: String,
      required: true,
    },
  },
};
</script>

<style lang="scss" scoped>
.nolte-button-image-pill__image-wrapper {
  border-radius: $radius-default;
  overflow: hidden;
  width: 110px;
  @include helper__aspect-ratio(3/4);

  @include helper__greater($bp-768) {
    width: 130px;
  }

  @include helper__greater($bp-1024) {
    width: 190px;
  }

  &::before {
    @include helper__transition(opacity);
    content: '';
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background: linear-gradient(
      to top,
      rgba($color-tuatara-dark, 0.8),
      ease-in-out,
      rgba($color-tuatara-dark, 0.3)
    );
    z-index: 1;
  }

  :deep(.router-link-active) &::before {
    opacity: 0;
  }

  & > * {
    height: 100%;
  }
}

.nolte-button-image-pill__label-wrapper {
  text-align: center;
  margin-top: $unit-base;
  margin-bottom: $unit-base;
}

.nolte-button-image-pill__label {
  @include helper__transition(color);
  @include text-h5;
  position: relative;

  @include helper__greater($bp-768) {
    @include text-h4;
  }

  :deep(.router-link-active) & {
    color: $color-white;
  }

  :deep(.router-link-active) &::before {
    content: '';
    position: absolute;
    background-color: $color-aureolin;
    height: $unit-half;
    width: 100%;
    bottom: -$unit-base;
  }
}

.nolte-button-image-pill:hover .nolte-button-image-pill__image-wrapper::before {
  opacity: 0;
}

.nolte-button-image-pill:hover .nolte-button-image-pill__label {
  color: $color-white;
}
</style>
