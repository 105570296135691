<template>
  <nav
    class="nolte-catalog-menu"
    :class="{
      'nolte-catalog-menu--is-mobile': isMobile,
    }"
  >
    <component
      :is="isMobile && !categoriesOpen ? 'NolteScroller' : 'div'"
      :scroller-el="isMobile ? 'div' : undefined"
      :shadow-only="isMobile ? '' : undefined"
      class="nolte-catalog-menu__scroller"
    >
      <div
        class="nolte-catalog-menu__scroller-inner"
        :style="{ width: !categoriesOpen ? 'auto' : '100%' }"
      >
        <NolteCatalogMenuCategories
          v-model="categoriesOpen"
          :value="categoriesOpen"
          :categories="categories"
          :catalog-url="catalogUrl"
          :active-option-id="activeOptionId"
          class="nolte-catalog-menu__categories"
        />

        <div
          class="nolte-catalog-menu__inspiration-filter-wrapper"
          :class="{
            'nolte-catalog-menu__inspiration-filter--open': isInspirationFilterOpen,
          }"
        >
          <NolteCatalogMenuInspirationFilter
            v-if="!categoriesOpen"
            v-model="inspirationFilterOptionIndex"
            :inspiration-filter="inspirationFilter"
            :active-option-id="activeOptionId"
            :expanded-tabs="isMobile && isInspirationFilterOpen"
            class="nolte-catalog-menu__inspiration-filter"
            ref="nolteCatalogMenuInspirationFilter"
          />

          <div
            v-if="!isMobile"
            class="nolte-catalog-menu__inspiration-filter-options-wrapper"
            :style="{
              paddingTop: `${inspirationFilterHeight}px`,
              maxWidth: `calc(100vw - ${inspirationFilterOffsetLeft}px - 16px)`,
            }"
          >
            <NolteCatalogMenuInspirationFilterOptions
              :key="inspirationFilterOptionIndex"
              :catalog-url="catalogUrl"
              :active-option-id="activeOptionId"
              :children="activeInspirationFilter && activeInspirationFilter.children"
              @close="closeInspirationFilter(TIMING_CATALOG_MENU)"
              class="nolte-catalog-menu__inspiration-filter-options"
            />
          </div>
        </div>

        <NolteCatalogMenuNeo
          v-if="!categoriesOpen && neoFilters.length"
          :catalog-url="catalogUrl"
          :active-option-id="activeOptionId"
          :neo-filters="neoFilters"
          :neo-more-button="neoMoreButton"
        />
      </div>
    </component>

    <NolteCatalogMenuInspirationFilterOptions
      v-if="isMobile"
      :key="inspirationFilterOptionIndex"
      :catalog-url="catalogUrl"
      :active-option-id="activeOptionId"
      :children="activeInspirationFilter && activeInspirationFilter.children"
      @close="closeInspirationFilter(TIMING_CATALOG_MENU)"
      class="nolte-catalog-menu__inspiration-filter-options--mobile"
    />
  </nav>
</template>

<script>
import NolteCatalogMenuCategories from './NolteCatalogMenuCategories.vue';
import NolteCatalogMenuInspirationFilter from './NolteCatalogMenuInspirationFilter';
import NolteCatalogMenuNeo from './NolteCatalogMenuNeo';
import NolteCatalogMenuInspirationFilterOptions from './NolteCatalogMenuInspirationFilterOptions';
import NolteScroller from '../NolteScroller';
import { timingCatalogMenu } from '../../styles/variables.scss';
import eventBus from '@/lib/eventBus';

const TIMING_CATALOG_MENU = parseInt(timingCatalogMenu, 10);

export default {
  name: 'NolteCatalogMenu',

  components: {
    NolteCatalogMenuCategories,
    NolteCatalogMenuInspirationFilter,
    NolteCatalogMenuNeo,
    NolteCatalogMenuInspirationFilterOptions,
    NolteScroller,
  },

  props: {
    categories: {
      type: Array,
      required: true,
    },
    catalogUrl: {
      type: String,
      required: true,
    },
    inspirationFilter: {
      type: Array,
      required: true,
    },
    activeOptionId: {
      type: String,
      default: undefined,
    },
    isMobile: {
      type: Boolean,
      default: false,
    },
    neoFilters: {
      type: Array,
      required: true,
    },
    neoMoreButton: {
      type: Object,
      default: null,
    },
  },

  data() {
    return {
      categoriesOpen: false,
      inspirationFilterOptionIndex: undefined,
      inspirationFilterHeight: 0,
      inspirationFilterOffsetLeft: 0,
      TIMING_CATALOG_MENU,
      inspirationFilterTimeout: null,
    };
  },

  computed: {
    activeInspirationFilter() {
      let data = JSON.parse(JSON.stringify(this.inspirationFilter));
      return data[this.inspirationFilterOptionIndex];
    },

    isInspirationFilterOpen() {
      return this.inspirationFilterOptionIndex !== undefined;
    },
  },

  beforeUnmount() {
    eventBus.$emit('NolteCatalogMenu:beforeUnmount');
  },

  mounted() {
    document.addEventListener('scroll', this.handleScroll.bind(this));

    eventBus.$once('NolteCatalogMenu:beforeUnmount', () => {
      document.removeEventListener('scroll', this.handleScroll.bind(this));
    });
  },

  methods: {
    handleScroll() {
      requestAnimationFrame(() => {
        this.categoriesOpen = false;
        this.inspirationFilterOptionIndex = undefined;
      });
    },

    closeInspirationFilter(delay = undefined) {
      if (this.categoriesOpen) {
        this.inspirationFilterOptionIndex = undefined;
      }

      if (this.inspirationFilterTimeout) {
        clearTimeout(this.inspirationFilterTimeout);
      }

      if (typeof delay === 'number') {
        this.inspirationFilterTimeout = setTimeout(() => {
          // Skip closing when there is no option selected anymore
          if (this.activeOptionId !== undefined) {
            this.inspirationFilterOptionIndex = undefined;
          }
        }, delay);

        return;
      }

      this.inspirationFilterOptionIndex = undefined;
    },
  },

  watch: {
    inspirationFilterOptionIndex(inspirationFilterOptionIndex) {
      if (
        inspirationFilterOptionIndex !== undefined &&
        this.$refs?.nolteCatalogMenuInspirationFilter
      ) {
        const { height, left } =
          this.$refs?.nolteCatalogMenuInspirationFilter?.$el?.getBoundingClientRect();

        this.inspirationFilterHeight = height;
        this.inspirationFilterOffsetLeft = left;
      } else {
        this.inspirationFilterHeight = 0;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
@mixin box-shadow() {
  box-shadow: 0 25px 25px 0 rgba($color-black, 0.1);
}

.nolte-catalog-menu {
  position: relative;
  white-space: nowrap;
  z-index: 2;
}

.nolte-catalog-menu__scroller {
  margin-top: -50px;
  margin-bottom: -50px;
}

.nolte-catalog-menu__scroller-inner {
  padding: 50px $unit-base 50px;
  display: flex;
  // align-items: flex-start;

  @include helper__greater($bp-768) {
    padding-left: $unit-base * 5;
  }
}

.nolte-catalog-menu__categories {
  margin-right: $unit-half;
}

.nolte-catalog-menu__inspiration-filter-wrapper {
  position: relative;
}

.nolte-catalog-menu__inspiration-filter {
  position: relative;

  &::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    @include box-shadow;
    z-index: -1;
    border-radius: $radius-default;
  }

  .nolte-catalog-menu__inspiration-filter--open &::after {
    content: '';
    position: absolute;
    left: 0;
    bottom: 0;
    width: 100%;
    height: 1px;
    background-color: $color-athens-gray;
  }

  .nolte-catalog-menu__inspiration-filter--open &::before {
    content: none;
  }
}

.nolte-catalog-menu__inspiration-filter-options-wrapper {
  background-color: $color-white;
  position: absolute;
  left: 0;
  z-index: 1;
  min-width: 100%;
  max-width: 100vw;
  margin-top: $unit-half;

  &::after {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    @include box-shadow;
    z-index: -1;
    border-radius: $radius-default;
  }

  @include helper__until($bp-768) {
    // overwrite inline computed
    padding-top: 0 !important;
  }

  @include helper__greater($bp-768) {
    top: 0;
    padding-top: auto;
    margin-top: 0;
    border-radius: $radius-default;
  }

  .nolte-catalog-menu__inspiration-filter--open &::before {
    content: '';
    position: absolute;
    left: 0;
    width: 100%;
    height: 1px;
    background-color: $color-athens-gray;
    margin-top: -1px;
  }
}

.nolte-catalog-menu__inspiration-filter-options {
  display: flex;
  justify-content: center;
  border-bottom-left-radius: $radius-default;
  border-bottom-right-radius: $radius-default;
}

.nolte-catalog-menu__inspiration-filter-options--mobile {
  position: absolute;
  left: 0;
  width: 100vw;
  background-color: $color-white;
  margin-top: 8px;
}
</style>
