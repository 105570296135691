<template>
  <textarea
    class="nolte-form-textarea"
    :class="{ 'nolte-form-textarea--invalid': invalid }"
    v-bind="$attrs"
    v-on="listeners"
    :value="modelValue"
    data-lpignore="true"
  />
</template>

<script>
import '../keyboard-modality';

export default {
  name: 'NolteFormTextarea',

  inheritAttrs: false,

  props: {
    modelValue: {},
    invalid: {
      type: Boolean,
      default: false,
    },
  },

  emits: ['update:modelValue'],

  computed: {
    // ATTENTION: magic happening here!!! :D
    listeners() {
      return {
        ...this.$attrs,
        // we overwrite the change event from the component model...
        // with our own change method
        input: event => {
          this.$emit('update:modelValue', event.target.value);
        },
      };
    },
  },
};
</script>

<style lang="scss" scoped>
.nolte-form-textarea {
  @include helper__input;
  @include helper__transition(border-color);
  border: 2px solid $color-athens-gray;
  padding: $unit-half;
  margin-bottom: $unit-half;
  width: 100%;
  border-radius: $radius-form;
  background-color: $color-white;
  min-height: $unit-base * 3;

  &:hover {
    border-color: $color-tuatara;
  }

  // Style when focus by mouse interaction
  &:focus {
    border-color: $color-tuatara !important;
  }

  // Style when focus by keyboard interaction
  body[modality='keyboard'] &:focus {
    border-color: $color-azure !important;
  }
}

.nolte-form-textarea--invalid {
  border-color: $color-red-orange;
}
</style>
