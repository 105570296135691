<template>
  <div class="nolte-detail-planning-alternatives">
    <NolteHeadline class="nolte-detail-planning-alternatives__headline" level="h2">{{
      headline
    }}</NolteHeadline>
    <p v-if="copy" class="nolte-detail-planning-alternatives__copy">{{ copy }}</p>
    <div class="nolte-detail-planning-alternatives__icon">
      <component :is="iconShape" width="56" height="56" />
    </div>
  </div>
</template>

<script>
import NolteHeadline from '../NolteHeadline';
import IconShapeG from '../../assets/icons/catalog/IconShapeG.svg?inline';
import IconShapeIsland from '../../assets/icons/catalog/IconShapeIsland.svg?inline';
import IconShapeL from '../../assets/icons/catalog/IconShapeL.svg?inline';
import IconShapeLine from '../../assets/icons/catalog/IconShapeLine.svg?inline';
import IconShapeU from '../../assets/icons/catalog/IconShapeU.svg?inline';
import { capitalizeFirstLetter } from '../helper';

export default {
  name: 'NolteDetailPlanningAlternatives',

  components: {
    NolteHeadline,
    IconShapeG,
    IconShapeIsland,
    IconShapeL,
    IconShapeLine,
    IconShapeU,
  },

  props: {
    // TODO: For the future we should think about a fallback solution
    // where the headline should not be required anymore
    headline: {
      type: String,
      required: true,
    },
    copy: {
      type: String,
      default: undefined,
    },
    shapeId: {
      type: String,
      required: true,
      default: 'shapeLine',
      validator(shapeId) {
        return ['shapeG', 'shapeIsland', 'shapeL', 'shapeLine', 'shapeU'].includes(shapeId);
      },
    },
  },

  computed: {
    iconShape() {
      return `Icon${capitalizeFirstLetter(this.shapeId)}`;
    },
  },
};
</script>

<style lang="scss">
.nolte-detail-planning-alternatives {
  padding: $unit-base;
  display: grid;
  grid-gap: $unit-base;
  grid-template:
    'headline icon'
    'copy icon' /
    70%;

  > * {
    justify-self: flex-start;
    align-self: center;
  }

  @include helper__greater($bp-768) {
    padding: $unit-double;
  }
}

.nolte-detail-planning-alternatives__headline {
  grid-area: headline;

  // TODO: Define font size in typography.scss
  @include helper__until($bp-768) {
    font-size: 22px !important;
    line-height: (28 / 22) !important; // [Intended lineheight] / [font size]
  }

  @include helper__greater($bp-768) {
    font-size: 32px !important;
    line-height: (38 / 32) !important; // [Intended lineheight] / [font size]
  }
}

.nolte-detail-planning-alternatives__copy {
  grid-area: copy;
}

.nolte-detail-planning-alternatives__icon {
  -ms-grid-row-align: center;
  grid-area: icon;
  border: 1px solid $color-athens-gray;
  color: $color-ironside-gray;
  padding: $unit-half;
  border-radius: $radius-default;
  justify-self: flex-end;
}
</style>
