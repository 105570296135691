<template>
  <div class="editorial-neo-col-media-group">
    <ContentAnchor
      :fields="{
        anchorTitle: fields['Anchor Title'],
        anchorName: fields['Anchor Name'],
      }"
    />
    <div class="editorial-neo-col-media-group--flex">
      <!--<template v-for="(EditorialNeoLightColMedia, index) in colGroupPlaceholder">
        <component :key="index" :is="EditorialNeoLightColMedia" />
      </template>-->
      <sc-placeholder name="nolte-neo-light-col-group_default" :rendering="rendering" />
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import ContentAnchor from '@/components/ContentAnchor';
// import EditorialNeoLightColMedia from '../../components/Neo Light/EditorialNeoLightColMedia.vue';
import { Placeholder } from '@sitecore-jss/sitecore-jss-vue';

export default {
  name: 'EditorialNeoLightColGroup',

  components: {
    ContentAnchor,
    //EditorialNeoLightColMedia,
    ScPlaceholder: Placeholder,
  },

  props: {
    rendering: {
      type: Object,
      default: undefined,
    },
  },

  computed: {
    ...mapGetters('jss', ['isEditing']),
  },

  /*withPlaceholder: {
    placeholders: [
      {
        placeholder: 'nolte-neo-light-col-group_default',
        computedPropName: 'colGroupPlaceholder',
      },
    ],
  },*/
};
</script>

<style lang="scss" scoped>
.editorial-neo-col-media-group {
  @include spacing;

  &--flex {
    width: 100%;

    display: flex;
    flex-wrap: wrap;
    gap: $unit-quadruple $unit-base;

    @include helper__greater($bp-768) {
      gap: $unit-quadruple $unit-double;
    }

    @include helper__greater($bp-1280) {
      gap: $unit-quadruple $unit-quadruple;
    }
  }
}
</style>
