<template>
  <div>
    <ContentPillLinks>
      <ContentLink
        v-if="textHasValue(fields['Overview Data Button Link'])"
        :field="fields['Overview Data Button Link']"
      />
      <ContentLink
        v-if="textHasValue(fields['Overview Data Send to Retailer Link'])"
        :field="fields['Overview Data Send to Retailer Link']"
      />
    </ContentPillLinks>

    <div class="editorial-mnk-kitchenplans">
      <div v-if="plans === null"></div>
      <div class="no-plans" v-else-if="plans.length === 0">
        <EditorialHeadline
          v-if="textHasValue(fields['Overview No Plans Data Headline'])"
          :fields="{ headline: fields['Overview No Plans Data Headline'] }"
        />

        <EditorialRichtext
          v-if="textHasValue(fields['Overview No Plans Data Text'])"
          :fields="{ content: fields['Overview No Plans Data Text'] }"
        />

        <div class="button-wrapper" v-if="fields['Overview No Plans Data Link']?.value?.href">
          <NolteButton
            :link-url="fields['Overview No Plans Data Link'].value.href"
            type="yellow--plain"
          >
            {{ fields['Overview No Plans Data Link'].value.text }}
          </NolteButton>
        </div>
      </div>
      <table class="table" v-else>
        <thead>
          <tr>
            <th class="table-thumbnail">
              {{ $t('Nolte-Feature-KitchenPlanning-KitchenplanOverviewPreview') }}
            </th>
            <th class="table-name">
              {{ $t('Nolte-Feature-KitchenPlanning-KitchenplanOverviewPlanName') }}
            </th>
            <th class="table-last-change">
              {{ $t('Nolte-Feature-KitchenPlanning-KitchenplanOverviewLastChange') }}
            </th>
            <th class="table-to-planning">
              {{ $t('Nolte-Feature-KitchenPlanning-KitchenplanOverviewToKitchenplan') }}
            </th>
            <th class="table-download">
              {{ $t('Nolte-Feature-KitchenPlanning-KitchenplanOverviewDownload') }}
            </th>
            <th class="table-delete">
              {{ $t('Nolte-Feature-KitchenPlanning-KitchenplanOverviewDelete') }}
            </th>
            <th
              v-if="fields['Overview Social Media Sharing Kitchen Planner Link']?.value?.url"
              class="table-share"
            >
              {{ $t('Nolte-Feature-KitchenPlanning-KitchenplanOverviewSharing') }}
            </th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="plan in plans" :key="plan.id">
            <td class="table-thumbnail">
              <ContentLightbox overlay-class="overlay" :close-on-content-click="false">
                <img
                  :src="plan.thumbnail"
                  class="link"
                  :title="fields['Preview Kitchenplan Text'].value"
                />
                <template #content>
                  <NolteHeadline v-if="textHasValue(plan.title)">
                    {{ plan.title }}
                  </NolteHeadline>
                  <EditorialMNKKitchenPlansGallery :images="plan.galleryImages" />
                  <div class="button-wrapper">
                    <NolteButton
                      v-if="textHasValue(fields['Open Kitchenplan Text'])"
                      type="yellow--plain"
                      data-close
                      :link-url="plan.planningUrl"
                      target="_blank"
                    >
                      {{ fields['Open Kitchenplan Text'].value }}
                    </NolteButton>
                  </div>
                </template>
              </ContentLightbox>
            </td>
            <td class="table-name">
              {{ plan.title }}
            </td>
            <td class="table-last-change">
              {{ plan.lastChange }}
            </td>
            <td class="table-to-planning">
              <a
                :href="plan.planningUrl"
                :title="fields['Open Kitchenplan Text'].value"
                class="cta-link"
                target="_blank"
              >
                <IconMNKChecklist />
              </a>
            </td>
            <td class="table-download">
              <a
                :href="plan.downloadUrl"
                :title="fields['Download Kitchenplan Text'].value"
                class="cta-link"
                target="_blank"
                ><IconMNKDownload
              /></a>
            </td>
            <td class="table-delete">
              <ContentLightbox overlay-class="overlay">
                <button class="cta-link" :title="fields['Delete Kitchenplan Text'].value">
                  <IconMNKTrash />
                </button>

                <template #content>
                  <NolteHeadline v-if="textHasValue(fields['Overview Delete Data Heading'])">
                    {{ fields['Overview Delete Data Heading'].value }}
                  </NolteHeadline>

                  <div
                    v-if="textHasValue(fields['Overview Delete Data Text'])"
                    v-html="
                      fields['Overview Delete Data Text'].value.replace('$planName$', plan.title)
                    "
                  />

                  <NolteButton
                    v-if="textHasValue(fields['Overview Delete Data Button Text'])"
                    type="yellow--plain"
                    data-close
                    @click="removePlan($event, plan.id)"
                  >
                    {{ fields['Overview Delete Data Button Text'].value }}
                  </NolteButton>
                </template>
              </ContentLightbox>
            </td>
            <td v-if="fields['Overview Social Media Sharing Kitchen Planner Link']?.value?.url">
              <ContentLightbox overlay-class="overlay">
                <button
                  v-if="textHasValue(fields['Overview Social Media Sharing Button Title'])"
                  class="cta-link-share"
                  :title="fields['Overview Social Media Sharing Button Title'].value"
                >
                  <IconMNKShare />
                </button>

                <template #content>
                  <NolteHeadline v-if="textHasValue(fields['Overview Social Media Sharing Text'])">
                    {{ fields['Overview Social Media Sharing Text'].value }}
                  </NolteHeadline>
                  <div class="icons-container">
                    <ShareNetwork
                      v-for="network in socialNetworks"
                      :key="network.id"
                      :network="network.network"
                      :url="plan.sharedUrl"
                      :description="fields['Overview Social Media Sharing Description Text']?.value"
                      :title="fields['Overview Social Media Sharing Placeholder Text']?.value"
                      :hashtags="fields['Overview Social Media Sharing Hashtags']?.value"
                    >
                      <div class="icon-background" :style="{ background: network.color }">
                        <component :is="network.icon" class="icon-component" />
                      </div>
                    </ShareNetwork>
                  </div>
                  <NolteFormTextarea class="copy-text" :modelValue="plan.sharedUrl" />
                  <NolteButton
                    v-if="textHasValue(fields['Overview Social Media Sharing Copy'])"
                    type="yellow--plain"
                    data-close
                    @click="copy(plan.sharedUrl)"
                  >
                    {{ fields['Overview Social Media Sharing Copy'].value }}
                  </NolteButton>
                </template>
              </ContentLightbox>
            </td>
          </tr>
        </tbody>
      </table>

      <ContentLightbox
        @open="setDeleteFailedLightboxVisible(true)"
        @close="setDeleteFailedLightboxVisible(false)"
        :visible="deleteFailedLightboxVisible"
        overlay-class="overlay"
      >
        <NolteHeadline v-if="textHasValue(fields['Overview Delete Failed Data Heading'])">
          {{ fields['Overview Delete Failed Data Heading'].value }}
        </NolteHeadline>

        <div
          v-if="textHasValue(fields['Overview Delete Failed Data Text'])"
          v-html="fields['Overview Delete Failed Data Text'].value"
        />

        <NolteButton
          v-if="textHasValue(fields['Overview Delete Failed Data Button Text'])"
          type="yellow--plain"
          @click="setDeleteFailedLightboxVisible(false)"
        >
          {{ fields['Overview Delete Failed Data Button Text'].value }}
        </NolteButton>
      </ContentLightbox>

      <ContentLightbox
        @open="setDeleteSuccessLightboxVisible(true)"
        @close="setDeleteSuccessLightboxVisible(false)"
        :visible="deleteSuccessLightboxVisible"
        overlay-class="overlay"
      >
        <NolteHeadline v-if="textHasValue(fields['Overview Delete Success Data Heading'])">
          {{ fields['Overview Delete Success Data Heading'].value }}
        </NolteHeadline>

        <NolteButton
          v-if="textHasValue(fields['Overview Delete Success Data Button Text'])"
          type="yellow--plain"
          @click="setDeleteSuccessLightboxVisible(false)"
        >
          {{ fields['Overview Delete Success Data Button Text'].value }}
        </NolteButton>
      </ContentLightbox>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapState } from 'vuex';
import { dataFetcher } from '@/dataFetcher';
import { textHasValue } from './helper';

import IconMNKChecklist from '@/assets/icons/IconMNKChecklist.svg?inline';
import IconMNKDownload from '@/assets/icons/IconMNKDownload.svg?inline';
import IconMNKTrash from '@/assets/icons/IconMNKTrash.svg?inline';
import IconMNKShare from '@/assets/icons/IconMNKShare.svg?inline';
import IconFacebook from '@/assets/icons/IconFacebook.svg?inline';
import IconInstagram from '@/assets/icons/IconInstagram.svg?inline';
import IconPinterest from '@/assets/icons/IconPinterest.svg?inline';
import IconLinkedin from '@/assets/icons/IconLinkedin.svg?inline';
import IconTwitter from '@/assets/icons/IconTwitter.svg?inline';
import IconWhatsapp from '@/assets/icons/IconWhatsapp.svg?inline';
import IconMail from '@/assets/icons/IconMail.svg?inline';

import ContentPillLinks from '@/components/ContentPillLinks';
import ContentLink from '@/components/ContentLink';
import ContentLightbox from '@/components/ContentLightbox';
import EditorialHeadline from '@/components/EditorialHeadline';
import EditorialRichtext from '@/components/EditorialRichText';
import EditorialMNKKitchenPlansGallery from '@/components/EditorialMNKKitchenPlansGallery';
import NolteButton from '@/nolte-ui/NolteButton';
import NolteHeadline from '@/nolte-ui/NolteHeadline';
import NolteFormTextarea from '@/nolte-ui/NolteForm/NolteFormTextarea';

export default {
  name: 'EditorialMNKKitchenPlans',

  components: {
    IconMNKChecklist,
    IconMNKDownload,
    IconMNKTrash,
    IconMNKShare,
    IconFacebook,
    IconLinkedin,
    IconPinterest,
    IconMail,
    IconInstagram,
    IconTwitter,
    ContentPillLinks,
    ContentLink,
    ContentLightbox,
    EditorialHeadline,
    EditorialRichtext,
    EditorialMNKKitchenPlansGallery,
    NolteButton,
    NolteHeadline,
    NolteFormTextarea,
  },

  data: () => ({
    plans: null,
    deleteFailedLightboxVisible: false,
    deleteSuccessLightboxVisible: false,
    socialNetworks: [
      { id: '1', network: 'twitter', icon: <IconTwitter /> },
      { id: '2', network: 'facebook', icon: <IconFacebook /> },
      { id: '3', network: 'linkedIn', icon: <IconLinkedin /> },
      { id: '4', network: 'pinterest', icon: <IconPinterest /> },
      { id: '5', network: 'whatsapp', icon: <IconWhatsapp /> },
      { id: '6', network: 'email', icon: <IconMail /> },
    ],
  }),

  computed: {
    ...mapState('jss', ['sitecoreContext']),
    ...mapGetters('jss', ['isEditing']),
  },

  methods: {
    textHasValue,
    getMediaObj(imageUrl) {
      return {
        src: imageUrl,
      };
    },
    setDeleteFailedLightboxVisible(status) {
      this.deleteFailedLightboxVisible = status;
    },

    setDeleteSuccessLightboxVisible(status) {
      this.deleteSuccessLightboxVisible = status;
    },

    getPlans() {
      this.api('/Kitchenplanning/GetPlans').then(result => {
        this.plans = result.data.map(item => ({
          id: item.Item1.PlnId,
          title: item.Item1.Title,
          downloadUrl:
            `/Kitchenplanning/Download/${this.sitecoreContext.language}/${item.Item1.PlnId}`.toLowerCase(),
          planningUrl: item.Item2,
          lastChange: item.Item1.ModificationDateAsString,
          thumbnail: item.Item1.MediaData.PlanTopThumb,
          sharedUrl: this.fields['Overview Social Media Sharing Kitchen Planner Link']?.value?.url
            ? `${this.fields['Overview Social Media Sharing Kitchen Planner Link']?.value?.url}/share.html?plnID=${item.Item1.PlnId}&s=5&l=${this.sitecoreContext.language}`
            : '',
          galleryImages: [
            item.Item1.MediaData.PlanSnapshot,
            item.Item1.MediaData.Plan2D,
            item.Item1.MediaData.PlanTop,
          ],
        }));
      });
    },

    removePlan(e, id) {
      this.api(`/Kitchenplanning/Delete/${id}`, {})
        .then(response => {
          if (response.status !== 200) {
            throw new Error('Wrong HTTP code', id);
          }
          return response;
        })
        .then(() => {
          const row = e.target.closest('tr');
          row.classList.add('hidden');
          row.querySelector('td').addEventListener('transitionend', () => {
            row.remove();
          });
          this.setDeleteSuccessLightboxVisible(true);
        })
        .catch(() => {
          this.setDeleteFailedLightboxVisible(true);
        });
    },

    api(node, data) {
      return dataFetcher(process.env.VUE_APP_API_BASE_URL + node, data);
    },

    copy(url) {
      navigator.clipboard.writeText(url);
    },
  },

  mounted() {
    this.getPlans();
  },
};
</script>

<style lang="scss" scoped>
.editorial-mnk-kitchenplans {
  @include spacing;
  margin: 0 auto;
  padding-bottom: $unit-double * 2;

  @include helper__greater($bp-768) {
    margin: $unit-base auto 0;
    padding-bottom: $unit-double * 2;
  }
}
.no-plans {
  .editorial-headline-wrapper {
    padding: 0;
  }
  .editorial-rich-text-wrapper {
    padding: 0;
    margin-top: 0;
  }
}
p {
  margin: $unit-base 0;
}
.table {
  width: 100%;
  background: $color-white;
  border-radius: 8px;

  th {
    @include text-table-header;
    text-align: center;
    padding: $unit-base;
    white-space: nowrap;
    text-transform: none;
  }
  td {
    transition: all 0.5s;
    text-align: center;
    border-top: 2px solid $color-concret;
    padding: $unit-double $unit-base;
  }
  tr.hidden td {
    opacity: 0;
  }

  th.table-name,
  td.table-name {
    text-align: left;
    width: 95%;
  }
  td.table-thumbnail img {
    max-width: 2 * $unit-double;
    max-height: 2 * $unit-double;
    border: 2px solid $color-athens-gray;
  }
  td.table-last-change {
    white-space: nowrap;
  }

  .cta-link {
    @include helper__transition(color);
    border: 0;
    padding: 0;

    &:hover {
      color: $color-delta;
    }
    svg {
      height: 38px;
    }
  }

  .cta-link-share {
    @include helper__transition(color);
    border: 0;
    padding: 0;

    &:hover:deep(path) {
      fill: $color-delta;
    }
  }

  @include helper__until($bp-1024) {
    thead {
      display: none;
    }

    td {
      border: 0;
      padding: $unit-base + $unit-half $unit-base + $unit-half $unit-base + $unit-half 0;
      text-align: left;
    }
    tr {
      display: grid;
      border-top: 2px solid $color-concret;
      grid-template-columns: auto 1fr 1fr 1fr 10fr;
      grid-template-rows: auto auto auto;
      grid-template-areas:
        'thumbnail last-change last-change last-change last-change'
        'thumbnail name name name name'
        'thumbnail to-planning download delete .';
    }
    td.table-thumbnail {
      grid-area: thumbnail;
      padding-left: $unit-base + $unit-half;
    }
    td.table-name {
      grid-area: name;
      width: auto;
      padding-top: 0;
      padding-bottom: 0;
    }
    td.table-last-change {
      @include text-caption--small;
      grid-area: last-change;
      padding-bottom: $unit-half;
    }
    td.table-to-planning {
      grid-area: to-planning;
      padding-right: $unit-base;
    }
    td.table-download {
      grid-area: download;
      padding-right: $unit-base;
    }
    td.table-delete {
      grid-area: delete;
      padding-right: $unit-base;
    }
  }
  @include helper__until($bp-425) {
    td.table-to-planning {
      grid-area: to-planning;
      padding-right: $unit-half;
    }
    td.table-download {
      grid-area: download;
      padding-right: $unit-half;
    }
    td.table-delete {
      grid-area: delete;
      padding-right: $unit-half;
    }
  }
}

img.link {
  cursor: pointer;
}

.nolte-headline {
  margin: 0;
}

:deep(.slbCloseBtn) {
  color: rgba(0, 0, 0, 0.3);

  &:hover {
    color: rgba(0, 0, 0, 0.7);
  }
}

:deep(.slbContentOuter) {
  padding: 0;
}
:deep(.overlay) {
  .button-wrapper {
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .nolte-button__content {
    white-space: nowrap;
  }
}

:deep(.customContent) {
  max-width: 800px;

  @include helper__greater($bp-1280) {
    max-width: 800px;
  }

  //max-width: 1200px;
}

.icons-container {
  display: flex;
  align-items: center;
  gap: 15px;
  flex-wrap: wrap;
  margin-bottom: 16px;

  .icon-background {
    width: 50px;
    height: 50px;
    background-color: $color-ironside-gray;
    border-radius: 50%;
    display: inline-flex;
    justify-content: center;
    align-items: center;
    color: #fff;
    fill: #fff;

    &:hover,
    &:active {
      background-color: $color-delta !important;
    }
  }
}
</style>
