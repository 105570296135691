import eventBus from '@/lib/eventBus';

export default {
  // beforeUnmount() {
  //   // eventBus.$emit('FieldValidationMixin:beforeUnmount');
  // },

  mounted() {
    // the submit button does not have a value field and cannot be validated
    if (this.field.valueField) {
      eventBus.$emit(
        this.formId + ':validation:register-component',
        this.field.valueField.name,
        this
      );
    } else if (this.field.buttonField) {
      // also add the submit button so we are able to add global error messages to the button
      eventBus.$emit(
        this.formId + ':validation:register-component',
        this.field.buttonField.name,
        this
      );
    }

    // we want to check the field after first blur and on every change after that (e.g. every keystroke)

    eventBus.$on(this.formId + ':field-change', component => {
      //console.log('FieldValidationMixin eventBus.on: ' + this.formId + ':field-change');

      // we want to emit the event only once for the component that changed
      // as this is a mixin all field components would trigger the event if we wouldn't check that
      if (component !== this) return;

      // the user shouldn't see errors if he is entering the data for the first time
      // but after first blur we can check it on every keystroke
      if (!this.blurredAtLeastOnce) return;

      // validate field on client
      this.processClientsideValidation();
    });

    eventBus.$on(this.formId + ':field-blur', component => {
      //console.log('FieldValidationMixin eventBus.on: ' + this.formId + ':field-blur');

      // we want to emit the event only once for the component that changed
      // as this is a mixin all field components would trigger the event if we wouldn't check that
      if (component !== this) return;

      if (!this.blurredAtLeastOnce) {
        this.processClientsideValidation();
        this.blurredAtLeastOnce = true;
      }
    });
  },

  data() {
    return {
      errors: [],
      blurredAtLeastOnce: false,
      // returnen true oder die Fehlermeldung
      clientsideValidators: {
        // Email Validator (Fields: Email)
        '{9BAE3E9A-D89F-4F93-9577-68B5E9D44A38}': (value, validatorModel) => {
          const parameters = JSON.parse(validatorModel.parameters);
          const regex = new RegExp(parameters.regularExpression);
          const valid = regex.test(value);

          if (!valid) {
            return validatorModel.message.replace('{0}', this.field.model.title);
          }
          return true;
        },
        // Date Validator (Fields: Date)
        '{24A3A1C0-CCBF-4ABA-ADA4-B6874BD3B4FC}': (value, validatorModel) => {
          const valid =
            new Date(value).getTime() >= new Date(this.min).getTime() &&
            new Date(value).getTime() <= new Date(this.max).getTime();

          if (!valid) {
            let message = validatorModel.message;
            message = message.replace('{0}', this.field.model.title);
            message = message.replace('{1}', this.min);
            message = message.replace('{2}', this.max);
            return message;
          }
          return true;
        },
        // Number Validator (Fields: Number)
        '{163EFF9F-D173-485C-869F-41D3A46883BF}': (value, validatorModel) => {
          const valid = value >= this.field.model.min && value <= this.field.model.max;

          if (!valid) {
            let message = validatorModel.message;
            message = message.replace('{0}', this.field.model.title);
            message = message.replace('{1}', this.field.model.min);
            message = message.replace('{2}', this.field.model.max);
            return message;
          }
          return true;
        },
        // Number Step Validator (Fields: Number)
        '{A398667F-BD9F-4DDB-8C74-2501A738667D}': (value, validatorModel) => {
          // const valid = (value - this.field.model.min) % this.field.model.step === 0;
          // would be the correct implementation but this is wrong implemented by the backend validator shipped with Sitecore
          // so we use the same implementation as Sitecore that only is correct if min is 0
          const valid = value % this.field.model.step === 0;

          if (!valid) {
            let message = validatorModel.message;
            message = message.replace('{0}', this.field.model.title);
            message = message.replace('{1}', this.field.model.step);
            return message;
          }
          return true;
        },
        // Phone Number Validator (Fields: Telephone)
        '{1AA74652-2177-48C9-88F5-1214445FEA34}': (value, validatorModel) => {
          const parameters = JSON.parse(validatorModel.parameters);
          const regex = new RegExp(parameters.regularExpression);
          const valid = regex.test(value);

          if (!valid) {
            return validatorModel.message.replace('{0}', this.field.model.title);
          }
          return true;
        },
        // String Length Validator (Fields: Telephone)
        '{83E96D09-11C8-4132-8A8A-76D8DB8208D6}': (value, validatorModel) => {
          const valid =
            value.length >= this.field.model.minLength &&
            value.length <= this.field.model.maxLength;

          if (!valid) {
            let message = validatorModel.message;
            message = message.replace('{0}', this.field.model.title);
            message = message.replace('{1}', this.field.model.minLength);
            message = message.replace('{2}', this.field.model.maxLength);
            return message;
          }
          return true;
        },
        // File extension Validator (Fields: FileUpload)
        '{1DFE7E87-8411-4071-B945-23333CC7EE93}': (value, validatorModel) => {
          const validExtensions = this.field.model.allowedContentTypes
            .split(',')
            .map(item => item.trim().toLowerCase());
          const extension = value.replace(/.+\./, '').toLowerCase();
          const valid = validExtensions.filter(item => item === extension)[0];

          if (!valid) {
            return validatorModel.message.replace('{0}', this.field.model.allowedContentTypes);
          }
          return true;
        },
        // File size Validator (Fields: FileUpload)
        '{9B8C73AE-23C4-4DA5-ACD2-4475D828A83B}': (value, validatorModel) => {
          const filesize = this.$refs.input.$el.files[0].size;
          const maxFileSize = this.field.model.maxFileSize;
          const valid = filesize <= maxFileSize;

          if (!valid) {
            return validatorModel.message.replace('{0}', this.field.model.maxFileSize);
          }
          return true;
        },
        // Nin Validator (Fields: Nolte Identification Number (NIN))
        '{609A6D44-5198-435F-9823-AFC255CCD997}': () => {
          return true;
        },
      },
    };
  },

  methods: {
    processClientsideValidation() {
      this.errors = [];

      this.field.model.validationDataModels.forEach(validatorModel => {
        // do not use the validators if the field is empty
        // required check will be done server side as we wouldn't have a translations otherwise
        if (!this.value) return;

        // our validator method
        const validatorMethod = this.clientsideValidators[validatorModel.itemId];
        if (!validatorMethod) {
          console.log('Missing client side validator method for this validator:', validatorModel);
        }
        const result = validatorMethod(this.value, validatorModel);

        if (result !== true) {
          this.errors.push(result);
        }
      });
    },
  },
};
