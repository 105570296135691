// based on https://markus.oberlehner.net/blog/using-the-google-maps-api-with-vue/

const CALLBACK_NAME = 'gmapsCallback';

let initialized = typeof window === 'undefined' ? false : !!window.google;
let resolveInitPromise;
let rejectInitPromise;

// This promise handles the initialization
// status of the google maps script.
const initPromise = new Promise((resolve, reject) => {
  resolveInitPromise = resolve;
  rejectInitPromise = reject;
});

export default function init(googleMapsApiKey, libraries = '') {
  // server side -- never resolve this promise
  if (typeof window === 'undefined') {
    throw new Error('window undefined');
  }
  if (libraries === '') {
    throw new Error('libraries must be set');
  }
  // If Google Maps already is initialized
  // the `initPromise` should get resolved
  // eventually.
  if (initialized) return initPromise;

  initialized = true;
  // The callback function is called by
  // the Google Maps script if it is
  // successfully loaded.
  window[CALLBACK_NAME] = () => resolveInitPromise(window.google);

  // Usually we use the API key that is returned by the "Retailersearch/Options/" request
  // But for local development it is possible to overwrite it with an API key that has
  // the permissions to be called by localhost:3000 via .env
  // Get it here: https://console.cloud.google.com/google/maps-apis
  const apiKey = process.env.VUE_APP_GOOGLE_MAPS_API_KEY || googleMapsApiKey;

  // We inject a new script tag into
  // the `<head>` of our HTML to load
  // the Google Maps script.
  const script = document.createElement('script');
  script.async = true;
  script.defer = true;
  script.src = `https://maps.googleapis.com/maps/api/js?key=${apiKey}&libraries=${libraries}&callback=${CALLBACK_NAME}`;

  script.onerror = rejectInitPromise;
  document.querySelector('head').appendChild(script);

  return initPromise;
}
