import { dataFetcher } from '@/dataFetcher';
import { unifySitecoreId } from '@/components/helper';

const debug = false;

function api(node, data) {
  return dataFetcher(process.env.VUE_APP_API_BASE_URL + node, data).then(response => {
    if (response.status !== 200) {
      throw new Error('No HTTP 200');
    }
    return response;
  });
}

export default {
  namespaced: true,

  state: {
    isLoading: true,
    items: [],
    promises: [],
    itemAddedWithTimeout: false,
  },

  mutations: {
    setItems(state, items) {
      state.isLoading = false;
      state.items = items;
    },

    create(state, payload) {
      state.items.push(payload);
      if (debug) console.log('created local', payload);

      // we use this to trigger the animation in the GlobalHeader via a style class that is only set
      // for 500ms
      state.itemAddedWithTimeout = true;
      setTimeout(_ => {
        state.itemAddedWithTimeout = false;
      }, 1000);
    },

    delete(state, payload) {
      const { itemId, imageId } = payload;
      for (let i = 0; i < state.items.length; i++) {
        if (state.items[i].itemId === itemId && state.items[i].imageId === imageId) {
          if (debug) console.log('deleted local', payload);
          state.items.splice(i, 1);
        }
      }
    },
  },

  getters: {
    hasEntries(state) {
      return state.items.length > 0;
    },
  },

  actions: {
    async getItems({ state, commit }) {
      state.isLoading = true;
      return api('/Contacts/Moods/GetMoods')
        .then(result => {
          const data = result.data.map(item => {
            return {
              itemId: unifySitecoreId(item.itemId),
              imageId: unifySitecoreId(item.image.itemId),
            };
          });

          commit('setItems', data);
          return result.data;
        })
        .catch(e => {
          console.log(e);
        });
    },

    async createItem({ commit }, payload) {
      payload.itemId = unifySitecoreId(payload.itemId);
      payload.imageId = unifySitecoreId(payload.imageId);
      const { itemId, imageId } = payload;

      // we don't wait for the API and instantly change the active state
      // if the API does not respond correctly we will revert our action
      commit('create', payload);
      if (debug) console.log('create', payload);

      api('/Contacts/Moods/SetMood', {
        PageItemGuid: itemId,
        ImageMediaGuid: imageId,
      })
        .then(() => {
          if (debug) console.log('created remote', payload);
        })
        .catch(() => {
          if (debug) console.log('create not successful, reverting create', payload);
          commit('delete', payload);
        });
    },

    async deleteItem({ commit }, payload) {
      payload.itemId = unifySitecoreId(payload.itemId);
      payload.imageId = unifySitecoreId(payload.imageId);
      const { itemId, imageId } = payload;

      // we don't wait for the API and instantly change the active state
      // if the API does not respond correctly we will revert our action
      commit('delete', payload);
      if (debug) console.log('delete', payload);

      api('/Contacts/Moods/DeleteMood', {
        PageItemGuid: itemId,
        ImageMediaGuid: imageId,
      })
        .then(() => {
          if (debug) console.log('deleted remote', payload);
        })
        .catch(() => {
          if (debug) console.log('delete not successful, reverting delete', payload);
          commit('create', payload);
        });
    },

    async toggleItem({ dispatch, state }, payload) {
      payload.itemId = unifySitecoreId(payload.itemId);
      payload.imageId = unifySitecoreId(payload.imageId);
      const { itemId, imageId } = payload;

      if (state.items.find(item => item.itemId === itemId && item.imageId === imageId)) {
        dispatch('deleteItem', payload);
      } else {
        dispatch('createItem', payload);
      }
    },
  },
};
