<template>
  <NolteTip
    v-if="!isEditing"
    :headline="headline"
    :copy="copy"
    :link-url="linkUrl"
    :link-external="isExternal"
    :wistia-video-id="wistiaVideoId"
    :image="image"
    :design="design"
    :size="copyLength > CHARACTER_LENGTH_BREAKPOINT ? 'large' : undefined"
  />
  <div v-else-if="fieldsData" class="editorial-tip" :class="[`nolte-tip--design-${design}`]">
    <template v-if="!imageHasSource(image)">
      <p class="editorial-tip__video">Wistia Video Id: <sc-text :field="wistiaVideoId" /></p>
      <ContentVideoWistia v-if="textHasValue(wistiaVideoId)" :video-id="wistiaVideoId.value" />
    </template>
    <template v-if="!textHasValue(wistiaVideoId)">
      <EditorialImage
        class="editorial-tip__image"
        :media="image"
        default-sizes="1024px"
        fit="cover"
      />
      <h3 class="editorial-tip__headline"><sc-text :field="fieldsData.headline.jss" /></h3>
      <p class="editorial-tip__copy"><sc-text :field="fieldsData.copy.jss" /></p>
      <p class="editorial-tip__link"><sc-text :field="fieldsData.link.jss" /></p>
    </template>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import { Text } from '@sitecore-jss/sitecore-jss-vue';
import { textHasValue, imageHasSource } from '@/components/helper';
import ContentVideoWistia from '@/components/ContentVideoWistia';
import EditorialImage from '@/components/EditorialImage';

import NolteTip, { CHARACTER_LENGTH_BREAKPOINT } from '../nolte-ui/NolteTip';

export default {
  name: 'EditorialTip',

  components: {
    ScText: Text,
    NolteTip,
    ContentVideoWistia,
    EditorialImage,
  },

  data() {
    return {
      CHARACTER_LENGTH_BREAKPOINT,
    };
  },

  computed: {
    ...mapGetters('jss', ['isEditing']),

    fieldsData() {
      return this.fields?.data?.datasource;
    },

    headline() {
      return this.fieldsData?.headline?.jss?.value;
    },

    copy() {
      return this.fieldsData?.copy?.jss?.value;
    },

    copyLength() {
      return this.copy?.length;
    },

    image() {
      return this.fieldsData?.image?.jss;
    },

    wistiaVideoId() {
      return this.fieldsData?.wistiaVideoId?.jss;
    },

    design() {
      return this.fieldsData?.design?.jss?.fields?.Design?.value || 'yellow';
    },

    linkUrl() {
      return this.fieldsData?.link?.jss?.value?.href;
    },

    isExternal() {
      return this.fieldsData?.link?.jss?.value?.linktype === 'external';
    },
  },

  mounted() {
    console.log(this.wistiaVideoId);
    console.log(this.textHasValue(this.wistiaVideoId));
  },

  methods: {
    textHasValue,
    imageHasSource,
  },
};
</script>

<style lang="scss" scoped>
.editorial-tip {
  text-align: center;
  padding: $unit-base;
}

.editorial-tip__headline {
  @include text-h3;
}

.editorial-tip__copy {
  @include text-base;
  font-size: 20px;
  line-height: (28 / 20);
}

.editorial-tip__video {
  margin-bottom: $unit-half;
}

.editorial-tip__link {
  text-align: left;
}

.nolte-tip--design-yellow {
  color: $color-tuatara;
  background-color: $color-aureolin;
}

.nolte-tip--design-dark {
  color: $color-white;
  background-color: $color-tuatara-dark;
}
</style>
