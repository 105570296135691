<script>
import { mapGetters } from 'vuex';
import { h } from 'vue';

export default {
  name: 'CaptureError',

  data: () => ({
    error: undefined,
  }),

  errorCaptured(err, vm, info) {
    console.error('CapturedError', err);
    this.error = { err, vm, info };
    return false;
  },

  render() {
    // error in development environment
    if (this.error && this.showDetails) {
      const children = [
        h('h1', this.error.vm.$options.name),
        h('p', 'Additional Info: ' + this.error.info),
        h('pre', this.error.err.stack),
      ];
      return h('div', { class: 'captureErrorDev' }, children);
    }

    // error in live environment
    if (this.error && !this.showDetails) {
      const message = this.$t('component-global-error');
      return h('div', { class: 'captureErrorLive' }, message);
    }

    // no error
    // if we use this component in a placeholder as in the "layouts/*.vue" files we need a div
    // around the slot components otherwise the Experience Editor does not work anymore.
    if (this.$parent.$options.name === 'Placeholder') {
      return h('div', {}, this.$slots.default());
    } else {
      return this.$slots.default();
    }
  },

  computed: {
    ...mapGetters('jss', ['isEditing', 'isPreview']),

    showDetails() {
      return (
        this.isEditing ||
        this.isPreview ||
        (typeof window !== 'undefined' && window.location.hostname === 'localhost')
      );
    },
  },
};
</script>

<style lang="scss" scoped>
div.captureErrorDev {
  padding: 10px;
  margin: 0 auto;
  background: orange;
  color: $color-white;

  h1 {
    @include text-h1;
  }
}
div.captureErrorLive {
  padding: 10px;
  margin: 0 auto;
  background: $color-concret;
  color: $color-ironside-gray;
  text-align: center;
}
</style>
