<!-- data-lpignore on input elements prevent autofill of lastpass -->
<!-- v-on="listeners" -->
<template>
  <input
    v-bind="$attrs"
    class="nolte-form-input"
    :class="{ 'nolte-form-input--invalid': invalid }"
    :type="type"
    :value="modelValue"
    @input="$emit('update:modelValue', $event.target.value)"
    data-lpignore="true"
  />
</template>

<script>
import '../keyboard-modality';

export default {
  name: 'NolteFormInput',

  inheritAttrs: false,

  props: {
    modelValue: {},
    type: {
      type: String,
      default: 'text',
    },
    invalid: {
      type: Boolean,
      default: false,
    },
  },

  emits: ['update:modelValue'],

  computed: {
    // ATTENTION: magic happening here!!! :D
    listeners() {
      return {
        ...this.$attrs,
        // we overwrite the change event from the component model...
        // with our own change method
        input: event => {
          // console.log("this.emit eventtarget", event.target)
          // console.log('this.emit update:modelValue', event.target.value);
          this.$emit('update:modelValue', event.target.value);
        },
      };
    },
  },
};
</script>

<style lang="scss" scoped>
.nolte-form-input {
  @include helper__input;
  @include helper__transition(border-bottom-color);
  border-bottom: 2px solid $color-athens-gray;
  padding-left: $unit-half / 4;
  padding-right: $unit-half / 4;
  padding-top: $unit-half / 2;
  margin-bottom: $unit-half;
  width: 100%;
  vertical-align: top;

  &:hover {
    border-bottom-color: $color-tuatara;
  }

  // Style when focus by mouse interaction
  &:focus {
    border-bottom-color: $color-tuatara !important;
  }

  // Style when focus by keyboard interaction
  body[modality='keyboard'] &:focus {
    border-bottom-color: $color-azure !important;
  }

  // Use different font family for password field because
  // with Futura the dots doesnt look good
  &[type='password'] {
    font-family: Arial, Courier New, Georgia, Times New Roman, Verdana, sans-serif;
    letter-spacing: 2px;
  }

  &[disabled] {
    color: $color-delta;
  }
}

.nolte-form-input--invalid {
  border-bottom-color: $color-red-orange;
}
</style>
