<template>
  <div class="editorial-media-detail-outer-wrapper" :class="`editorial-component--${design}`">
    <div class="editorial-media-detail-wrapper">
      <div class="editorial-media-detail-header">
        <div class="editorial-media-detail-header__content">
          <!--<component
            :is="component"
            v-for="(component, index) in detailGroupHeaderPlaceholder"
            :key="`group_${index}`"
          />-->
          <Placeholder name="nolte-media-detail-group_header" :rendering="rendering" />
        </div>
      </div>
      <Placeholder
        v-if="!isEditing"
        name="nolte-media-detail-group_default"
        :rendering="rendering"
        #default="{ components }"
      >
        <NolteMediaDetailGroup :components="components" />
      </Placeholder>

      <div v-else class="editorial-media-detail-group">
        <!--
        When experience editor is active we want to render all tabs in a stack,
        to simplify editing.
        Note: additional props passed to the `<component />` here will not be forwarded to the actual Tab component.
        -->
        <!--<component v-for="(tab, index) in detailGroupPlaceholder" :is="tab" :key="`tab${index}`" />-->
        <Placeholder name="nolte-media-detail-group_default" :rendering="rendering" />
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import { Placeholder } from '@sitecore-jss/sitecore-jss-vue';

import NolteMediaDetailGroup from '../nolte-ui/NolteMediaDetail/NolteMediaDetailGroup';

export default {
  name: 'EditorialMediaDetailGroup',

  components: {
    NolteMediaDetailGroup,
    Placeholder,
  },
  props: {
    rendering: {
      type: Object,
      default: undefined,
    },
  },

  computed: {
    ...mapGetters('jss', ['isEditing']),

    design() {
      // returns default or dark
      return this.fields._extendedParams?.BackgroundDesign?.fields?.Type?.value || 'default';
    },
  },

  // If withPlaceholder is defined on a component instance, the SitecoreJssPlugin will
  // attempt to inject placeholder data for the requested placeholders as computed properties
  // on the component instance (in this case, `computed.tabsPlaceholder`, a.k.a. `this.tabsPlaceholder`).
  // This is another option compared to using the <Placeholder> component;
  // in this case, chosen because we primarily need access to the _data_ of the placeholder.
  /*withPlaceholder: {
    placeholders: [
      {
        placeholder: 'nolte-media-detail-group_default',
        computedPropName: 'detailGroupPlaceholder',
      },
      {
        placeholder: 'nolte-media-detail-group_header',
        computedPropName: 'detailGroupHeaderPlaceholder',
      },
    ],
  },*/
};
</script>

<style lang="scss" scoped>
.editorial-component--dark {
  overflow: auto;
  background: $color-concret;
  margin: $unit-double 0;

  @include helper__greater($bp-768) {
    margin: $unit-double * 2 auto;
  }
}

.editorial-media-detail-wrapper {
  overflow: hidden;
  margin: $unit-double auto;

  @include helper__greater($bp-768) {
    margin: $unit-double * 2 auto;
  }
}

.editorial-media-detail-header {
  @include helper__greater(850) {
    max-width: 850px;
  }

  margin: auto;

  &__content {
    padding-left: 0;

    :deep() {
      .editorial-headline-wrapper,
      .editorial-text-multi-line-wrapper {
        margin: $unit-double auto;
        padding-left: $unit-base;

        @include helper__greater($bp-768) {
          padding-left: 0;
          padding-left: $unit-double;
        }
        @include helper__greater(850) {
          padding-left: 0;
          padding-left: $unit-base;
        }
        @include helper__greater($bp-1024) {
          padding-left: 0;
        }
      }
    }
  }
}
</style>
