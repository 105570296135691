export default function (id, lang) {
  (function (d, s, i, l) {
    const j = d.createElement(s);
    j.type = 'text/javascript';
    j.async = true;
    j.src = `https://squarelovin.com/squarelovin.js?stream=${i}&t=teaser&l=${l}&u=${location.href}`;

    const sc = d.getElementsByTagName(s)[0];
    sc.parentNode.insertBefore(j, sc);
  })(document, 'script', id, lang);
}
