<template>
  <div class="editorial-mnk-stage">
    <div class="editorial-mnk-stage__inner">
      <div class="editorial-mnk-stage__inner__left">
        <ContentLink
          v-if="isAuthenticated && mnkRootItem && mnkRootItem.href !== $route.path"
          :editable="false"
          class="editorial-mnk-stage__breadcrumb"
          :field="{
            value: {
              text: $t('mnk-stage-homelabel'),
              href: mnkRootItem.href,
              linktype: 'internal',
            },
          }"
        />
        <div v-else-if="mnkRootItem" class="editorial-mnk-stage__overline">
          {{ $t('mnk-stage-homelabel') }}
        </div>
        <sc-text
          v-if="textHasValue(datasource?.headline?.jss) && headlinePlaceholdersReplaced"
          class="editorial-mnk-stage__headline"
          :field="personalizedHeadline"
          tag="h1"
        />
      </div>
      <div
        class="editorial-mnk-stage__inner__right"
        v-if="textHasValue(datasource?.copy?.jss) || linkHasValue(datasource?.link?.jss)"
      >
        <sc-text class="editorial-mnk-stage__copy" :field="datasource.copy.jss" tag="p" />
        <EditorialButton
          v-if="linkHasValue(datasource?.link?.jss) && datasource?.showMnkMenu?.jss?.value === true"
          class="editorial-mnk-stage__link"
          :fields="{ link: datasource.link.jss, _extendedParams: buttonParams }"
          @click="setSearchMnkMenuVisibility(true)"
        />
        <EditorialButton
          v-else-if="linkHasValue(datasource?.link?.jss) || isEditing"
          class="editorial-mnk-stage__link"
          :fields="{ link: datasource.link.jss, _extendedParams: buttonParams }"
        />
      </div>
    </div>
  </div>
</template>

<script>
import { Text } from '@sitecore-jss/sitecore-jss-vue';
import { textHasValue, linkHasValue } from './helper';

import { mapState, mapMutations, mapGetters } from 'vuex';

import ContentLink from '@/components/ContentLink';
import EditorialButton from './EditorialButton';

export default {
  name: 'EditorialMNKStage',

  data() {
    return {
      buttonParams: {
        ButtonType: {
          fields: {
            Type: {
              value: 'yellow--plain',
            },
          },
        },
      },
    };
  },

  components: {
    ScText: Text,
    ContentLink,
    EditorialButton,
  },

  methods: {
    textHasValue,
    linkHasValue,
    ...mapMutations(['setSearchMnkMenuVisibility']),
  },

  computed: {
    ...mapGetters('jss', ['isEditing']),
    ...mapState('jss', ['sitecoreContext']),
    ...mapState(['user']),

    datasource() {
      return this.fields?.data?.datasource;
    },

    mnkRootItem() {
      return this.sitecoreContext.site?.login || null;
    },

    isAuthenticated() {
      return this.user !== null;
    },

    personalizedHeadline() {
      const headline = this.datasource?.headline?.jss;
      if (!this.user) return headline;

      for (let key in this.user) {
        headline.value = headline.value.replace(`$${key}$`, this.user[key]);
      }
      return headline;
    },

    headlinePlaceholdersReplaced() {
      // only show the headline if it does not contain placeholders
      return !this.personalizedHeadline.value.match(/\$[a-z]+\$/i);
    },
  },
};
</script>

<style lang="scss" scoped>
.editorial-mnk-stage {
  @include spacing;

  @include helper__greater($bp-768) {
    margin-top: $unit-double * 4;
  }
}
.editorial-mnk-stage__inner {
  display: flex;
  flex-direction: column;

  @include helper__greater($bp-1024) {
    flex-direction: row;
  }
}

.editorial-mnk-stage__inner__left {
  margin-bottom: 0 !important;
  min-width: 60%;

  @include helper__greater($bp-1024) {
    margin-right: $unit-base * 2;
  }
}

.editorial-mnk-stage__inner__right {
  margin-top: $unit-double !important;
}

.editorial-mnk-stage__breadcrumb {
  @include breadcrumb;
}

.editorial-mnk-stage__overline {
  @include text-h3;
}

.editorial-mnk-stage__headline {
  @include mnk-text-h1;
}

.editorial-mnk-stage__copy {
  @include text-intro;
}

.editorial-mnk-stage__link {
  :deep(.editorial-button-wrapper) {
    margin: $unit-double 0;
    padding: 0;
  }
  :deep(.nolte-button--yellow--plain) {
    font-size: 13px;
  }
}
</style>
