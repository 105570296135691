<template>
  <!--
    The link to an secured page is empty if the user is not logged in.
    For this reason "linkHasValue(link)"" hides secured pages.
  -->
  <div class="editorial-mnk-teaser" v-if="linkHasValue(link) || isEditing">
    <ContentLink :field="link">
      <div class="image-wrapper">
        <EditorialImage
          v-if="imageHasSource(image) || isEditing"
          :media="image"
          default-aspect-ratio="3:2"
          default-sizes="250px"
          fit="cover"
          :editable="false"
        />
      </div>
      <div class="content-wrapper">
        <sc-text
          v-if="textHasValue(headline) || isEditing"
          class="headline"
          :field="headline"
          tag="h3"
        />
        <sc-text v-if="textHasValue(copy) || isEditing" class="copy" :field="copy" tag="p" />
      </div>
    </ContentLink>
  </div>
</template>

<script>
import { textHasValue, imageHasSource, linkHasValue } from './helper.js';
import { mapGetters } from 'vuex';
import { Text } from '@sitecore-jss/sitecore-jss-vue';
import EditorialImage from '@/components/EditorialImage';
import ContentLink from '@/components/ContentLink';

export default {
  name: 'EditorialMNKTeaser',

  components: {
    ScText: Text,
    EditorialImage,
    ContentLink,
  },

  computed: {
    ...mapGetters('jss', ['isEditing']),

    link() {
      return this.fields?.link;
    },
    image() {
      return this.fields?.image;
    },
    headline() {
      return this.fields?.headline;
    },
    copy() {
      return this.fields?.copy;
    },
  },

  methods: {
    textHasValue,
    imageHasSource,
    linkHasValue,
  },
};
</script>

<style lang="scss" scoped>
$border-radius-card: $radius-default;

.editorial-mnk-teaser {
  position: relative;
  margin-right: $unit-base;
  background: $color-white;
  border-radius: $border-radius-card;
  box-shadow: 0 0 50px rgba($color-black, 0.15);
  width: 215px;

  @include helper__greater($bp-768) {
    margin-right: $unit-double;
  }
}

.headline {
  @include text-h4;
  margin-top: $unit-base * 1.5;
  margin-bottom: $unit-half;
}

.image-wrapper {
  border-top-left-radius: $radius-default;
  border-top-right-radius: $radius-default;
  overflow: hidden;

  > div {
    display: block;
  }
}

.content-wrapper {
  padding: 0 $unit-base $unit-base;
}
</style>
