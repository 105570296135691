<template>
  <meta
    v-if="visitorIdentificationTimestamp"
    name="VIcurrentDateTime"
    :content="visitorIdentificationTimestamp"
  />
</template>

<script>
import { mapState } from 'vuex';

/**
  VisitorIdentification is necessary for Sitecore Analytics to determine if the visitor is a robot.
  If Sitecore XP (with xConnect/xDB) is used, this is required or else analytics will not be collected for the JSS app.
  For XM (CMS-only) apps, this should be removed.

  VI detection only runs once for a given analytics ID, so this is not a recurring operation once cookies are established.
 */

let emittedVI = false;

function emitVIScript(visitorIdentificationTimestamp) {
  if (!emittedVI && typeof document !== 'undefined' && visitorIdentificationTimestamp) {
    emittedVI = true;
    const script = document.createElement('script');
    script.src = `/layouts/system/VisitorIdentification.js`;
    script.type = 'text/javascript';
    document.head.appendChild(script);
  }
}

export default {
  name: 'VisitorIdentification',

  computed: {
    ...mapState('jss', {
      // since sitecoreContext gets updated with every route change the timestamp should always be correct
      visitorIdentificationTimestamp: ({ sitecoreContext }) =>
        sitecoreContext.visitorIdentificationTimestamp,
    }),
  },

  watch: {
    visitorIdentificationTimestamp: {
      immediate: true,
      handler(timestamp) {
        emitVIScript(timestamp);
      },
    },
  },
};
</script>
