<template>
  <sc-link v-if="isEditing && editable" :field="field" />

  <router-link
    v-else-if="isInternalLink && field.value && field.value.href"
    v-bind="routerLinkParams"
    :class="{ inactive: inactive }"
  >
    <slot>{{ field.value.text }}</slot>
  </router-link>

  <a
    v-else-if="field.value && field.value.href"
    v-bind="routerLinkParams"
    @click="click($event)"
    v-on="$attrs"
    :class="{ inactive: inactive }"
  >
    <slot>{{ field.value.text }}</slot>
  </a>
  <div v-else>
    <slot>{{ field.value.text }}</slot>
  </div>
</template>

<script>
import { Link } from '@sitecore-jss/sitecore-jss-vue';
import { mapGetters } from 'vuex';

export default {
  name: 'ContentLink',

  components: {
    ScLink: Link,
  },

  data: () => ({
    inactive: true,
  }),

  props: {
    /**
     * A JSS link object.
     */
    field: {
      type: Object,
      default: () => {},
    },

    /**
     * Defines if the link is editable in the Experience Editor.
     */
    editable: {
      type: Boolean,
      default: true,
    },

    /**
     * Pass this if you have an internal link but don't want to render a router-link
     * This is necessary if you want to pass events along with ContentLink as router-link does not
     * allow to use passed events via v-on="$attrs" (or direct events as @click)
     */
    noRouterLink: {
      type: Boolean,
      default: false,
    },
  },

  computed: {
    ...mapGetters('jss', ['isEditing']),

    isInternalLink() {
      return (
        !this.field?.value?.href?.startsWith('mailto:') && // an email link is not internal
        this.field?.value?.href?.charAt(0) !== '#' && // a hash link is not internal
        this.field?.value?.linktype === 'internal' &&
        this.field?.value?.target !== '_blank' &&
        !this.field?.value?.href?.match(/^https?:\/\//) &&
        !this.noRouterLink
      );
    },

    routerLinkParams() {
      let link = {
        target: this.field?.value?.target,
        title: this.field?.value?.title,
        class: this.field?.value?.class,
        style: this.field?.value?.style,
        to: '',
        href: '',
      };

      if (this.isInternalLink) {
        const queryParams = {};

        let paramString = this.field?.value?.href?.split('?')[1];
        let queryString = new URLSearchParams(paramString);

        if (queryString) {
          for (let param of queryString.entries()) {
            queryParams[param[0]] = param[1];
          }
        }

        link.to = {
          path: this.field?.value?.href,
          query: queryParams,
        };
        if (this.field?.value?.querystring) {
          link.to.path += '?' + this.field?.value?.querystring;
        }
        if (this.field?.value?.anchor) {
          link.to.hash = this.field?.value?.anchor;
        }
        link.href = this.field?.value?.href;
      } else {
        link.href = this.field?.value?.href;
        if (this.field?.value?.querystring) {
          link.href += '?' + this.field?.value?.querystring;
        }
        if (this.field?.value?.anchor && this.field?.value?.linktype !== 'anchor') {
          link.href += '#' + this.field?.value?.anchor;
        }
      }

      return link;
    },
  },

  methods: {
    /**
     * The onclick handler for clicks on the sc-link component.
     * @param e {event} The native click event object
     */
    click(e) {
      if (this.field?.value?.linktype === 'javascript') {
        // the link construct of SC is broken
        // https://sitecore.stackexchange.com/questions/22838/general-link-insert-javascript-contains-return-false-causes-error
        // so we have to execute the code ourself
        e.preventDefault();
        eval(this.field?.value?.url?.replace(/^javascript:/, ''));
      }
    },
  },

  mounted() {
    this.inactive = false;
  },
};
</script>

<style lang="scss" scoped>
.inactive {
  pointer-events: none;
}
</style>
