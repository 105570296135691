<template>
  <main class="product-detail">
    <Placeholder name="jss-product-detail-main" :rendering="route" />
    <Placeholder name="jss-dataprovider" :rendering="route" />
  </main>
</template>

<script>
import Placeholder from '@/components/Placeholder';
import LayoutMixin from './LayoutMixin';

export default {
  name: 'ProductDetail',

  mixins: [LayoutMixin],

  components: {
    Placeholder,
  },
};
</script>
