<template>
  <v-select
    class="nolte-form-dropdown"
    :class="{ 'nolte-form-dropdown--invalid': invalid }"
    v-bind="$attrs"
    v-on="listeners"
    :components="{ OpenIndicator }"
    :reduce="reduce"
    @search:blur="listeners.blur || function () {}"
  >
    <template v-slot:no-options></template>
  </v-select>
</template>

<script>
import vSelect from 'vue-select';
import { shallowRef } from 'vue';
import '../keyboard-modality';
import IconArrowDown from '../../assets/icons/IconArrowDown.svg?inline';

const Icon = shallowRef(IconArrowDown);

export default {
  name: 'NolteFormDropdown',

  inheritAttrs: false,

  components: {
    'v-select': vSelect,
  },

  props: {
    invalid: {
      type: Boolean,
      default: false,
    },
    reduce: {
      type: Function,
      default: option => option.value,
    },
  },

  emits: ['update:modelValue'],
  data() {
    return {
      OpenIndicator: Icon,
    };
  },
  computed: {
    // ATTENTION: magic happening here!!! :D

    listeners() {
      return {
        ...this.$attrs,
        // we overwrite the change event from the component model...
        // with our own change method
        'option:selected': value => {
          if (value === null) value = '';
          this.$emit('update:modelValue', value);
        },
      };
    },
  },
};
</script>

<style lang="scss">
@import 'vue-select/src/scss/vue-select.scss';
</style>

<style lang="scss" scoped>
.nolte-form-dropdown {
  margin-bottom: $unit-half;

  // vue-select dropdown is already styled
  // but we dont want to use this style at all
  // so we override parts of it.
  :deep() {
    .vs__dropdown-toggle,
    .vs__dropdown-menu {
      @include helper__transition;
      background-color: $color-white;
      border: 2px solid $color-athens-gray;
      border-radius: $radius-form;
      width: 100%;
      cursor: pointer;
      text-align: left;
    }

    .vs__dropdown-menu {
      border-top-left-radius: 0;
      border-top-right-radius: 0;
      margin-top: -2px;
      padding-top: 0px;
      border-top: 0;
      padding-bottom: 0;
      padding-left: 0;
      box-shadow: inset 0 2px $color-athens-gray;
      // border-top: 2px solid $color-athens-gray !important;
    }

    .vs__dropdown-toggle {
      padding: 10px 3px 12px 0px;
    }

    .vs__dropdown-menu::after {
      content: '';
      display: block;
      // box-shadow: 0 5px 20px 5px rgba($color-ironside-gray, 0.1);
      position: absolute;
      top: 0;
      width: 100%;
    }

    .vs__dropdown-option,
    .vs__dropdown-option--highlight {
      color: $color-ironside-gray;
      @include helper__transition;
    }

    .vs__dropdown-option {
      padding: 8px 16px;

      &:hover {
        background-color: $color-concret;
      }
    }

    .vs__dropdown-option--selected {
      background-color: $color-aureolin;

      &:hover,
      &.vs__dropdown-option--highlight {
        background-color: $color-citrine;
      }
    }

    .vs__dropdown-option--highlight {
      background-color: $color-concret;
    }

    .vs__selected {
      line-height: 1.3em;
    }

    .vs__search {
      cursor: pointer;
    }

    .vs__search::placeholder {
      color: $color-delta;
    }

    .vs__open-indicator {
      @include helper__transition(transform);
    }

    .vs__actions {
      padding-top: 0;
    }
  }

  &:hover :deep() {
    .vs__dropdown-toggle,
    .vs__dropdown-menu {
      border-color: $color-tuatara;
    }
  }

  body[modality='keyboard'] &.vs--open :deep() {
    .vs__dropdown-toggle,
    .vs__dropdown-menu {
      border-color: $color-azure;
    }
  }

  &.vs--open :deep() {
    .vs__dropdown-toggle,
    .vs__dropdown-menu {
      border-color: $color-tuatara;
    }

    .vs__dropdown-toggle {
      border-bottom-left-radius: 3px;
      border-bottom-right-radius: 3px;
    }
  }

  &:not(.vs--single) :deep(.vs__selected) {
    background-color: $color-aureolin;
    border: 0;
    border-radius: $radius-form;
    padding: 0 7px;
  }
}

.nolte-form-dropdown--invalid {
  :deep() {
    .vs__dropdown-toggle,
    .vs__dropdown-menu {
      border-color: $color-red-orange;
    }
  }
}
</style>
