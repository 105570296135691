<template>
  <figure class="nolte-media-detail">
    <div v-if="isEditing" class="nolte-media-detail__media-container">
      <div class="nolte-media-detail__media">
        <EditorialImage
          :media="fields.media"
          default-aspect-ratio="3:2"
          default-sizes="1024px"
          :editable="true"
        />
      </div>
    </div>
    <figcaption class="nolte-media-detail__detail-container">
      <div v-if="!isEditing" class="nolte-detail-planning-alternatives">
        <NolteHeadline
          v-if="headline"
          class="nolte-detail-planning-alternatives__headline"
          level="h2"
        >
          {{ headline }}
        </NolteHeadline>
        <p v-if="copy" class="nolte-detail-planning-alternatives__copy">
          {{ copy }}
        </p>
        <div class="nolte-detail-planning-alternatives__icon">
          <component :is="iconShape" width="56" height="56" />
        </div>
      </div>
      <div v-else class="editorial-detail-planning-alternatives">
        <p><sc-text :field="fields.headline" /></p>
        <p><sc-text :field="fields.copy" /></p>
        <p>Selected shape ID: {{ shapeId }}</p>
      </div>
    </figcaption>
  </figure>
</template>

<script>
import { mapGetters } from 'vuex';
import { Text } from '@sitecore-jss/sitecore-jss-vue';
import EditorialImage from './EditorialImage';
import NolteHeadline from '../nolte-ui/NolteHeadline';
import IconShapeG from '../assets/icons/catalog/IconShapeG.svg?inline';
import IconShapeIsland from '../assets/icons/catalog/IconShapeIsland.svg?inline';
import IconShapeL from '../assets/icons/catalog/IconShapeL.svg?inline';
import IconShapeLine from '../assets/icons/catalog/IconShapeLine.svg?inline';
import IconShapeU from '../assets/icons/catalog/IconShapeU.svg?inline';
import { capitalizeFirstLetter } from '../nolte-ui/helper';

export default {
  name: 'EditorialPlanningAlternative',

  components: {
    ScText: Text,
    EditorialImage,
    NolteHeadline,
    IconShapeG,
    IconShapeIsland,
    IconShapeL,
    IconShapeLine,
    IconShapeU,
  },

  props: {
    rendering: {
      type: Object,
      default: undefined,
    },
  },

  computed: {
    ...mapGetters('jss', ['isEditing']),
    headline() {
      return this.rendering?.fields?.headline?.value;
    },
    copy() {
      return this.rendering?.fields?.copy?.value;
    },
    shapeId() {
      return this.rendering?.fields?.shapeId?.fields?.Icon?.value || 'shapeL';
    },
    iconShape() {
      return `Icon${capitalizeFirstLetter(this.shapeId)}`;
    },
  },
};
</script>

<style lang="scss" scoped>
@import '../nolte-ui/NolteMediaDetail/styles.scss';

.nolte-media-detail__media-container {
  @include media-container;
}

.nolte-media-detail__detail-container {
  @include detail-container;
}

.nolte-detail-planning-alternatives {
  padding: $unit-base;
  display: grid;
  grid-gap: $unit-base;
  grid-template:
    'headline icon'
    'copy icon' /
    70%;

  > * {
    justify-self: flex-start;
    align-self: center;
  }

  @include helper__greater($bp-768) {
    padding: $unit-double;
  }
}

.nolte-detail-planning-alternatives__headline {
  grid-area: headline;

  // TODO: Define font size in typography.scss
  @include helper__until($bp-768) {
    font-size: 22px !important;
    line-height: (28 / 22) !important; // [Intended lineheight] / [font size]
  }

  @include helper__greater($bp-768) {
    font-size: 32px !important;
    line-height: (38 / 32) !important; // [Intended lineheight] / [font size]
  }
}

.nolte-detail-planning-alternatives__copy {
  grid-area: copy;
}

.nolte-detail-planning-alternatives__icon {
  -ms-grid-row-align: center;
  grid-area: icon;
  border: 1px solid $color-athens-gray;
  color: $color-ironside-gray;
  padding: $unit-half;
  border-radius: $radius-default;
  justify-self: flex-end;
}
</style>
