<template>
  <v-field-template
    name="checkbox"
    :field="field"
    :hidden="hidden"
    :disabled="disabled"
    :errors="errors"
  >
    <template #field>
      <NolteFormWrapper :errors="errors" #default="{ hasError }">
        <NolteFormCheckbox
          v-if="field.valueField && field.model"
          :id="field.valueField.id"
          :name="field.valueField.name"
          :checked="field.model.value"
          :required="field.model.required"
          :disabled="disabled"
          v-model="value"
          @blur="fieldBlur"
          :invalid="hasError"
        />
      </NolteFormWrapper>
    </template>
  </v-field-template>
</template>

<script>
import FieldMixin from '../structure/FieldMixin';
import FieldConditionMixin from '../structure/conditions/FieldConditionMixin';
import FieldValidationMixin from '../structure/validation/FieldValidationMixin';

import NolteFormCheckbox from '../../nolte-ui/NolteForm/NolteFormCheckbox';
import NolteFormWrapper from '../../nolte-ui/NolteForm/NolteFormWrapper';

export default {
  name: 'SitecoreFormsFieldsCheckbox',

  components: {
    NolteFormCheckbox,
    NolteFormWrapper,
  },

  mixins: [FieldMixin, FieldConditionMixin, FieldValidationMixin],

  // errors prop is defined in FieldValidationMixin

  data() {
    return {
      value: this.field.model.value,
    };
  },
};
</script>
<style lang="scss" scoped>
.form-field {
  flex-direction: row !important;
}

:deep() {
  .form-field-label {
    order: 2;
    padding-top: 4px;
  }
  .nolte-form-checkbox {
    margin-top: 0;
  }
}
</style>
