import { createStore } from 'vuex';
import initStore from '../store';
import initMixin from './Mixin';

// this is the bootstrap file for our custom extensions of the default jss setup
export default function (app) {
  // make store accessible in Vue components via this.$store
  const init = initStore(app);
  const store = createStore(init);
  app.use(store);
  //app.prototype.$store = store;

  // creates a global mixin to fix sitecore bugs and register fields globally as props
  app.mixin(initMixin());

  // make Vue globally accessible to be able to inject development helpers
  //if (typeof window !== 'undefined' && window.VueHelpers) {
  //  window.VueHelpers(Vue);
  //}

  return { store };
}
