import { AxiosDataFetcher } from '@sitecore-jss/sitecore-jss';
import { RestLayoutService } from '@sitecore-jss/sitecore-jss-vue';
import config from '../temp/config';

const isServer = () => !(typeof window !== 'undefined' && window.document);

const getQueryString = params =>
  Object.keys(params)
    .map(k => `${encodeURIComponent(k)}=${encodeURIComponent(String(params[k]))}`)
    .join('&');

const resolveUrl = (urlBase, params = {}) => {
  if (!urlBase) {
    throw new RangeError('url must be a non-empty string');
  }

  if (isServer()) {
    const url = new URL(urlBase);
    for (const key in params) {
      if ({}.hasOwnProperty.call(params, key)) {
        url.searchParams.append(key, String(params[key]));
      }
    }
    return url.toString();
  }

  const qs = getQueryString(params);
  return urlBase.indexOf('?') !== -1 ? `${urlBase}&${qs}` : `${urlBase}?${qs}`;
};
function getUrlQueryParams(searchQuery) {
  if (!searchQuery.includes('?')) {
    return;
  }
  let params = new URLSearchParams(searchQuery.split('?')[1]);
  const obj = {};
  for (const [key, value] of params) {
    obj[key] = value;
  }
  return obj;
}

const dataFetcher = (url, data) => {
  const querystringParams = getUrlQueryParams(window.location.pathname + window.location.search);
  return new AxiosDataFetcher().fetch(resolveUrl(url, querystringParams), data);
};

export class LayoutServiceFactory {
  create() {
    return new RestLayoutService({
      apiHost: config.sitecoreApiHost,
      apiKey: config.sitecoreApiKey,
      siteName: config.jssAppName,
      configurationName: 'jss',
      dataFetcherResolver: () => dataFetcher,
    });
  }
}

export const layoutServiceFactory = new LayoutServiceFactory();
