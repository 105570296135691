import throttle from 'lodash.throttle';

import jssModule from './jss';
import moodboardModule from './moodboard';
import processNavigationItems from './processNavigationItems';
import processMetaData from './processMetaData';
import map from './map';

import { bp768 } from '../styles/variables.scss';
import { dataFetcher } from '@/dataFetcher';

// this is global store for the complete app
// eslint-disable-next-line no-unused-vars
export default function Store(Vue, app) {
  return {
    modules: {
      jss: jssModule,
      moodboard: moodboardModule,
      map: map,
    },

    state: {
      dataProviderGlobal: null,

      windowWidth: typeof window !== 'undefined' ? window.innerWidth : 0,
      windowHeight: typeof window !== 'undefined' ? window.innerHeight : 0,

      /**
       * State which, when not undefined, overrides the sticking state in GlobalHeader.
       * Necessary to get rid of the shadow when sticky menu is visible on top.
       */
      headerStickingOverride: undefined,
      referrer: undefined,

      searchBarVisible: false,
      footerVisible: true,
      mnkMenuVisible: false,
      mnkProgressbarVisible: true,
      anchorNavigationVisible: false,
      invalidSession: false,
      user: null,
    },

    mutations: {
      setUser(state, data) {
        state.user = data;
      },
      setGlobalData(state, data) {
        state.dataProviderGlobal = data;
      },

      setWindowSize(state, { width, height }) {
        state.windowWidth = width;
        state.windowHeight = height;
      },

      setHeaderStickingOverride(state, headerStickingOverride) {
        state.headerStickingOverride = headerStickingOverride;
      },
      setReferrer(state, referrer) {
        state.referrer = referrer;
      },
      setSearchBarVisibility(state, open) {
        // do not allow to open in editing mode
        if (this.getters['jss/isEditing']) return;

        state.searchBarVisible = open;
      },
      setFooterVisibility(state, open) {
        // do not allow to open in editing mode
        if (this.getters['jss/isEditing']) return;

        state.footerVisible = open;
      },
      setSearchMnkMenuVisibility(state, open) {
        // do not allow to open in editing mode
        if (this.getters['jss/isEditing']) return;

        // the timeout is necessary otherwise we would get the wrong state of "open"
        // in the document.body click handler in GlobalHeaderMNKMenu
        setTimeout(() => {
          state.mnkMenuVisible = open;
        });
      },
      setMnkProgressbarVisibility(state, open) {
        setTimeout(() => {
          state.mnkProgressbarVisible = open;
        });
      },
      setAnchorNavigationVisible(state, open) {
        state.anchorNavigationVisible = open;
      },
      setInvalidSession(state, value) {
        state.invalidSession = value;
      },
    },

    getters: {
      getPage404Path(state) {
        if (!state.dataProviderGlobal) return [];
        return state.dataProviderGlobal.page404?.page?.editable?.match(/href="(.+?)"/)[1];
      },
      getGoogleTagManagerId(state) {
        if (!state.dataProviderGlobal) return [];
        return state.dataProviderGlobal.gtm?.id?.value;
      },
      getUserCentricsId(state) {
        if (!state.dataProviderGlobal) return [];
        return state.dataProviderGlobal.userCentrics?.id?.value;
      },
      getUserCentricsGoogleMapsServiceId(state) {
        if (!state.dataProviderGlobal) return [];
        return state.dataProviderGlobal.userCentrics?.googleMapServiceID?.value;
      },
      getTeaserFallbackImage(state) {
        if (!state.dataProviderGlobal) return [];
        return state.dataProviderGlobal.feedSettings?.teaserFallbackImage;
      },
      // used to disable the filters in "Nolte Spa"
      getShowFiltersInFeed(state) {
        if (!state.dataProviderGlobal) return [];
        return state.dataProviderGlobal.feedSettings?.showFilters?.boolValue;
      },
      getRouteMetaData(state) {
        if (!state.dataProviderGlobal) return [];
        return processMetaData(state, state.anchorNavigationVisible);
      },
      getHeaderNav(state) {
        if (!state.dataProviderGlobal) return [];
        return processNavigationItems(state.dataProviderGlobal.headerNav?.children);
      },
      getHeaderMetaNav(state) {
        if (!state.dataProviderGlobal) return [];
        return state.dataProviderGlobal.headerMetaNav?.children?.map(item => {
          return { ...item.Link?.jss?.value, icon: item.Icon?.jss?.fields?.Type?.value };
        });
      },
      getHeaderMnkNav(state) {
        if (!state.dataProviderGlobal) return [];
        return processNavigationItems(state.dataProviderGlobal.headerMnkNav?.children);
      },
      getFooterSocialMediaBar(state) {
        if (!state.dataProviderGlobal) return [];
        return state.dataProviderGlobal.footerSocialMediaBar?.children?.map(item => {
          return { ...item.Link?.jss, icon: item.Icon?.value };
        });
      },
      getFooterNav(state) {
        if (!state.dataProviderGlobal) return [];
        return state.dataProviderGlobal.footerNav?.children;
      },
      getFooterMetaNav(state) {
        if (!state.dataProviderGlobal) return [];
        return state.dataProviderGlobal.footerMetaNav?.children?.map(item => item.field?.jss);
      },
      getInstagramSettings(state) {
        if (!state.dataProviderGlobal) return [];
        return state.dataProviderGlobal.instagramId;
      },
      isMobile(state) {
        return state.windowWidth <= parseInt(bp768, 10);
      },
      isDesktop(state) {
        return state.windowWidth > parseInt(bp768, 10);
      },
      getSearchBarVisibility(state) {
        return state.searchBarVisible;
      },
      getFooterVisibility(state) {
        return state.footerVisible;
      },
      getMnkMenuVisibility(state) {
        return state.mnkMenuVisible;
      },
      getMnkProgressbarVisibility(state) {
        return state.mnkProgressbarVisible;
      },
      getMnkActionLinks(state) {
        if (!state.dataProviderGlobal) return [];

        const links = {};
        state.dataProviderGlobal.MnkActionLinks?.children.forEach(item => {
          links[item.name] = item.Link.jss.value.href;
        });
        return links;
      },
      getShowMoodboardSetting(state) {
        if (!state.dataProviderGlobal) return false;
        return state.dataProviderGlobal.showMoodboard?.setting?.value ?? false;
      },
      getBannerData(state) {
        if (!state.dataProviderGlobal) return [];
        return state.dataProviderGlobal.banner;
      },
      getInvalidSession(state) {
        return state.invalidSession;
      },
    },
    actions: {
      updateWindowSize: throttle(({ commit }) => {
        commit('setWindowSize', {
          width: typeof window !== 'undefined' ? window.innerWidth : 0,
          height: typeof window !== 'undefined' ? window.innerHeight : 0,
        });
      }, 250),

      updateReferrer({ commit }, referrer) {
        commit('setReferrer', referrer);
      },

      // USER ACTIONS
      async checkUser({ commit }) {
        const result = await dataFetcher(process.env.VUE_APP_API_BASE_URL + '/sso/user', {});
        if (result.data.isAuthenticated) {
          commit('setUser', result.data);
        } else {
          commit('setUser', null);
        }
        return result.data;
      },

      logout({ commit }) {
        return new Promise(resolve => {
          dataFetcher(process.env.VUE_APP_API_BASE_URL + '/sso/logout', {}).then(resolve);
          commit('setUser', null);
        });
      },
    },
  };
}
