<template>
  <div class="editorial-mnk-checklist">
    <ContentPillLinks>
      <ContentLink
        v-if="linkHasValue(fields['Overview Data Send to Retailer Link'])"
        :field="fields['Overview Data Send to Retailer Link']"
      />
    </ContentPillLinks>
    <GlobalForm :fields="formDefinitions.checklist" v-if="formDefinitions.checklist" />
    <div v-else>Data for MNK ChecklistForm is missing.</div>
  </div>
</template>

<script>
import { mapState } from 'vuex';
import { linkHasValue } from './helper';
import eventBus from '@/lib/eventBus';

import GlobalForm from '@/components/GlobalForm';
import smoothscroll from 'smoothscroll-polyfill';
import 'formdata-polyfill';

import ContentPillLinks from '@/components/ContentPillLinks';
import ContentLink from '@/components/ContentLink';

export default {
  name: 'EditorialMNKChecklistForm',

  components: {
    GlobalForm,
    ContentPillLinks,
    ContentLink,
  },

  computed: {
    ...mapState('jss', ['routeData']),

    // get all form definitions in placeholder "jss-mnk-dialog"
    formDefinitions() {
      const mappings = {
        checklist: '{3C1171A0-FADF-4D10-9C15-47833AB29D1E}',
      };
      const forms = {};
      for (const mappingName in mappings) {
        let definition = false;
        try {
          definition = this.routeData?.placeholders['jss-mnk-dialog']?.find(
            item => item.dataSource === mappings[mappingName]
          ).fields;
        } catch {
          return false;
        }
        forms[mappingName] = definition;
      }
      return forms;
    },

    isCollapse() {
      return this.field?.model?.cssClass?.includes('section-collapse');
    },
  },

  mounted() {
    // expand all sections on failure so the page is able to scroll to the first error
    eventBus.$on(
      this.formDefinitions?.checklist?.antiForgeryToken?.value + ':form-submitted-failure',
      () => {
        console.log(
          'MNKChecklistForm EventBus.on:form-submitted-failure',
          this.formDefinitions?.checklist?.antiForgeryToken?.value + ':form-submitted-failure'
        );
        this.expandAllSections();
      }
    );

    eventBus.$on(
      this.formDefinitions?.checklist?.antiForgeryToken?.value + ':form-submitted-success',
      ({ rerenderForm }) => {
        console.log(
          'MNKChecklistForm EventBus.on:form-submitted-success',
          this.formDefinitions?.checklist?.antiForgeryToken?.value + ':form-submitted-success'
        );
        if (rerenderForm) {
          this.$router.push({
            path: this.fields['Overview Data Send to Retailer Link'].value.href,
          });
        }
      }
    );

    setTimeout(() => {
      this.initSections();
      this.initProgressbar();
    });

    smoothscroll.polyfill();
  },

  beforeUnmount() {
    eventBus.$emit('EditorialMNKChecklistForm:beforeUnmount');
  },

  methods: {
    linkHasValue,

    initSections() {
      const $sections = [].slice.call(this.$el.querySelectorAll('.section-step'));

      // eslint-disable-next-line no-unused-vars
      $sections.forEach(($section, index) => {
        if ($section.classList.contains('section-step--open')) {
          this.expandSection($section, true);
        } else {
          this.collapseSection($section);
        }

        // init prev and next buttons
        const $previousButtons = $section.querySelectorAll('.section-button-prev');
        $previousButtons.forEach($button => {
          $button.addEventListener('click', e => {
            e.preventDefault();
            if (index == 0) return;
            this.expandSection($sections[index - 1]);
          });
        });

        const $nextButtons = $section.querySelectorAll('.section-button-next');
        $nextButtons.forEach($button => {
          $button.addEventListener('click', e => {
            e.preventDefault();
            if (index > $sections.length - 1) return;
            this.expandSection($sections[index + 1]);

            // fire the submit manually
            eventBus.$emit(
              this.formDefinitions?.checklist?.antiForgeryToken?.value + ':form-submit',
              false
            );
          });
        });
      });

      this.$el.addEventListener('click', e => {
        if (!e.target.matches('.copy__content')) return;

        this.toggleSection(e.target.closest('.section-step'));
      });

      // Allow to animate the height of the ".form-field"s via CSS transition.
      const $items = [].slice.call(this.$el.querySelectorAll('.section-step__content'));
      $items.forEach($item => {
        $item.style.height = $item.scrollHeight + 'px';

        // We use a observer to check when a field is getting hidden. In this case we set the height
        const observer = new MutationObserver(mutations => {
          mutations.forEach(mutation => {
            mutation.target.style.height = mutation.target.scrollHeight + 'px';
          });
        });
        observer.observe($item, { attributes: true });

        eventBus.$once('EditorialMNKChecklistForm:beforeUnmount', () => {
          // Mutation Observer does not have Method unobserve(), but disconnect()!
          // observer.unobserve();
          observer.disconnect();
        });
      });

      $items.forEach($item => {
        $item.style.transition = 'height 0.3s';
      });
    },

    toggleSection($section) {
      if ($section.classList.contains('section-step--open')) {
        this.collapseSection($section);
      } else {
        this.expandSection($section);
      }
    },

    collapseSection($section) {
      $section.classList.remove('section-step--open');
      $section
        .querySelector('.section-step__content')
        .classList.add('section-step__content--hidden');
    },

    expandSection($section, preventScrollingIntoViewport, keepOtherSectionsOpen) {
      // close all other sections if we expand a one
      if (!keepOtherSectionsOpen) {
        [].forEach.call(this.$el.querySelectorAll('.section-step'), $sec => {
          $sec.classList.remove('section-step--open');
        });

        [].forEach.call(this.$el.querySelectorAll('.section-step__content'), $sec => {
          $sec.classList.add('section-step__content--hidden');
        });
      }

      // expand the wanted section
      $section.classList.add('section-step--open');
      $section
        .querySelector('.section-step__content')
        .classList.remove('section-step__content--hidden');

      // scroll current $section into viewport
      if (typeof window !== 'undefined' && !preventScrollingIntoViewport) {
        setTimeout(() => {
          window.scroll({
            top:
              $section.offsetTop -
              document.body.scrollTop -
              document.querySelector('header').getBoundingClientRect().height - // header height
              150, // just some whitespace
            behavior: 'smooth',
          });
        }, 300);
      }
    },

    expandAllSections() {
      const $sections = [].slice.call(this.$el.querySelectorAll('.section-step'));
      $sections.forEach($section => {
        this.expandSection($section, true, true);
      });
    },

    initProgressbar() {
      const $inputs = [].slice.call(this.$el.querySelectorAll('input,textarea'));

      // add change event listener to all inputs ot update the progressbar
      [].forEach.call($inputs, $input => {
        $input.addEventListener('change', _ => {
          this.updateProgressbar();
        });
      });

      this.updateProgressbar();
    },

    updateProgressbar() {
      const $form = this.$el.querySelector('form');
      const formData = new FormData($form);

      // search all options the user has set (the ones starting with "Q")
      const userFormData = Array.from(formData)
        .filter(item => item[0].match(/.Value/) && item[1] !== '')
        .map(item => item[0]);

      // now we take the last answered section and all sections before and
      // add "section-option--done" to them
      const lastAnswered = userFormData[userFormData.length - 1];
      const $sectionProgressOptions = [].slice.call(
        this.$el.querySelectorAll('.section-progress-option')
      );

      let latestIndex = 0;
      $sectionProgressOptions.forEach(($option, index) => {
        if ($option.querySelector(`[name="${lastAnswered}"]`)) {
          latestIndex = index;
        }
        $option.classList.remove('section-option--done');
        $option.classList.remove('section-option--next');
      });

      $sectionProgressOptions.forEach(($option, index) => {
        // special case: if latestIndex is 0 there was no option selected yet
        // if we wouldn't check this the first headline would get this class and so the first
        // section would look as it was answered.
        if (latestIndex !== 0 && index <= latestIndex) {
          $option.classList.add('section-option--done');
        }

        if (index <= latestIndex + 1) {
          $option.classList.add('section-option--next');
        }

        // if we answered the last question of a section highlight the first one of the next section
        if (
          index === latestIndex + 1 &&
          $option.classList.contains('form-buttons') &&
          $sectionProgressOptions[latestIndex + 3]
        ) {
          $sectionProgressOptions[latestIndex + 2].classList.add('section-option--next');
          $sectionProgressOptions[latestIndex + 3].classList.add('section-option--next');
        }
      });
    },
  },
};
</script>

<style lang="scss">
// the following resets all widths for the form so we get a full width form
.mnk-default .editorial-mnk-checklist .global-form {
  max-width: none;
  padding: 0;
  margin-top: 0;

  .form-fields {
    max-width: none;
  }
  .section-step > .form-fields {
    @include spacing;
    padding: 0 $unit-base 0 $unit-double;
    margin: 0 auto !important;
  }
}
</style>

<style lang="scss" scoped>
.editorial-mnk-checklist {
  overflow: auto;
}

:deep() {
  .section-title .copy__content {
    @include text-h3;
    padding: 0 $unit-double + $unit-base 0 0;
    text-align: left;
    color: $color-ironside-gray;
    text-transform: none;
    letter-spacing: 0 !important;

    @include helper__greater($bp-768) {
      font-size: 40px;
      line-height: (40 / 40);
    }
    @include helper__until($bp-768) {
      font-size: 30px;
      line-height: (30 / 30);
    }
  }

  .section-step {
    overflow-y: hidden;
    background: $color-concret;
  }

  .form-buttons .form-fields {
    display: flex;
    justify-content: flex-end;

    .form-field-type-submit {
      min-height: 0;
    }

    @include helper__until($bp-768) {
      .form-field {
        width: 50%;
      }
      .form-field:last-child .nolte-form-wrapper {
        margin: 0;
      }
      .form-field-element {
        display: block;
      }
      .nolte-button {
        display: block;
        width: 100%;
        margin: 0 !important;
      }
    }
  }

  // --------------- Styling of accordion
  .section-step {
    @include helper__transition(background-color);
  }

  .section-step--open {
    background: $color-white;
  }

  .section-step__content .form-fields .section-option:first-child .form-field-label {
    padding-top: $unit-double;
    @include helper__greater($bp-768) {
      padding-top: 50px;
    }
  }

  .section-step__content--hidden {
    height: 0 !important;
    overflow: hidden;
  }

  .section-title .copy__content {
    cursor: pointer;

    &:after {
      position: absolute;
      top: $unit-base;
      right: 0;
      width: 40px;
      height: 40px;
      content: '';
      @include helper__transition(transform);
      background: url(../assets/icons/IconArrowLeftFixedColor.svg) no-repeat center center;

      @include helper__until($bp-768) {
        height: 30px;
      }
    }
  }

  .section-step--open {
    .section-title .copy__content {
      &:after {
        transform: rotate(-90deg);
      }
    }
  }
  // /--------------- Styling of accordion

  // --------------- Styling of vertical progressbar
  .section-progress-option {
    &.section-progress-option {
      padding-left: $unit-double + $unit-base;
      position: relative;
      margin-top: 0;
      margin-bottom: 0;
    }
  }

  // --- line styling
  .section-progress-option {
    &:before {
      @include helper__transition(border-left-color, 1000);
      position: absolute;
      left: 0;
      height: 100%;
      content: '';
      border-left: 8px solid $color-athens-gray;
    }
    &.section-option--next:before {
      border-left-color: $color-aureolin;
    }
    &.section-option--done.section-title:before {
      border-left: 8px solid $color-ironside-gray;
    }
  }
  .section-step--open .section-title.section-option--done:before {
    border-left: 8px solid $color-aureolin;
  }
  // /--- line styling

  // --- checkmark styling
  .section-progress-option {
    &.section-option {
      margin-bottom: 33px;
    }
    &:after {
      content: '';
      width: 38px;
      height: 38px;
      background: url(../assets/icons/IconEllipse.svg) no-repeat 0 0;
      position: absolute;
      left: -15px;
      bottom: -33px;
    }
    &.section-option:after {
      @include helper__transition(transform, $transition-duration-default * 2);
    }
    &.section-option--done:after {
      transform: rotate(360deg);
      background-position: bottom;
    }
  }
  .section-step--open .section-option--done:after {
    background-position: center;
  }
  .section-step--open .section-title {
    margin: 0;

    &:after {
      display: none;
    }
  }
  .section-step--open .form-buttons {
    &:after {
      display: none;
    }
  }
  // /--- checkmark styling

  // --- offset to have an overlapping checkmark between the sections
  .section-step:not(:nth-child(1)) {
    margin-top: -19px;
  }
  .section-step:not(:nth-child(1)) .section-title {
    margin-top: 19px !important;
  }
  .section-step:nth-child(1) .section-title .copy__content {
    margin-top: $unit-base + 19px !important;
    &:after {
      top: $unit-base + 19px;
    }
  }
  .section-step--open + .section-step {
    margin-top: 0;
    .section-title {
      margin-top: 0 !important;
    }
    .section-title .copy__content {
      margin-top: $unit-base + 19px !important;
      &:after {
        top: $unit-base + 19px;
      }
    }
  }
  // /--- offset to have an overlapping checkmark between the sections

  // /--------------- Styling of vertical progressbar

  // --------------- Restyling of labels
  .nolte-form-label {
    font-family: $font-futura-pt-book;
    font-size: 18px;
    font-weight: 100;
    margin: 0 0 $unit-half 0;

    @include helper__greater($bp-1024) {
      @include text-intro;
      margin: 0;
    }
  }
  // /--------------- Restyling of label sizes

  // --------------- Restyling of radios and checkboxes
  .form-field-type-checkboxlist,
  .form-field-type-radiolist {
    label::before {
      display: none;
    }

    .nolte-form-checkbox,
    .nolte-form-radio {
      display: inline-block;
      margin: 0 $unit-base $unit-base 0;
    }

    .nolte-form-radio__radio + label,
    .nolte-form-checkbox__checkbox + label {
      @include text-button;
      @include text-h5;
      @include helper__transition(border);

      background: $color-concret;
      padding: $unit-base;
      border-radius: $unit-half;
      color: $color-ironside-gray;
      border: 2px solid $color-concret;
      white-space: normal;

      &:hover {
        border-color: darken($color-concret, 10%);
      }
    }

    .nolte-form-radio__radio:checked + label,
    .nolte-form-checkbox__checkbox:checked + label {
      @include helper__transition((background-color, border-color));
      background: $color-aureolin;
      border-color: $color-aureolin;

      &:hover {
        border-color: darken($color-aureolin, 10%);
      }

      &:after {
        display: none;
      }
    }
  }
  // /--------------- Restyling of radios and checkboxes
}
</style>
