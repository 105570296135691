<script>
/*
DataProviderGlobal Component

This component is only intended to query additional data globally and make it available to the app
without the need to write backend code to get site wide data. The data is then available via the
jss module of the vuex store in the state "routeData".

Don't create a create() method here to pass the data to the store because it wouldn't be available
in the SSR mode as this code would be executed when the other components were already rendered.

This is useful for content that needs to be available globally, but cannot be retrieved
using an Integrated GraphQL query. In this website, for example, the contents of the navigation
are fetched by this component. Since the header is not in a Sitecore placeholder, no iGQL query
can be bound to a component. With this solution the contents can be made available.
*/

export default {
  name: 'DataProviderGlobal',

  render() {
    return null;
  },
};
</script>
