<template>
  <div class="editorial-video">
    <div class="is-editing" v-if="isEditing">
      <span>Enter the Wistia video ID: </span>
      <sc-text :field="fields.videoId" />
    </div>

    <ContentVideoWistia :video-id="videoId" />
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import { Text } from '@sitecore-jss/sitecore-jss-vue';
import ContentVideoWistia from '@/components/ContentVideoWistia';

export default {
  name: 'EditorialVideoWistia',

  components: {
    scText: Text,
    ContentVideoWistia,
  },

  computed: {
    ...mapGetters('jss', ['isEditing']),

    videoId() {
      return this.fields?.videoId?.value;
    },
  },
};
</script>

<style lang="scss" scoped>
.editorial-video {
  margin: $unit-double 0;

  @include helper__greater($bp-768) {
    margin: $unit-double * 2 auto;
  }
}

.is-editing {
  @include spacing;
  margin-bottom: $unit-half !important;
}
</style>
