<template>
  <div class="editorial-mnk-teaser-group">
    <NolteScroller class="scroller" :bounce-edges="true" shadow-only="">
      <!--<component v-for="(item, index) in groupPlaceholder" :is="item" :key="`item${index}`" />-->
      <sc-placeholder name="nolte-mnk-teaser-group" :rendering="rendering" />
    </NolteScroller>
  </div>
</template>

<script>
import NolteScroller from '@/nolte-ui/NolteScroller';
import { Placeholder } from '@sitecore-jss/sitecore-jss-vue';

export default {
  name: 'EditorialMNKTeaserGroup',

  components: {
    NolteScroller,
    ScPlaceholder: Placeholder,
  },

  props: {
    rendering: {
      type: Object,
      default: undefined,
    },
  },

  /*withPlaceholder: {
    placeholders: [
      {
        placeholder: 'nolte-mnk-teaser-group',
        computedPropName: 'groupPlaceholder',
      },
    ],
  },*/
};
</script>

<style lang="scss" scoped>
.editorial-mnk-teaser-group {
  overflow: hidden;

  padding: 0 0 $unit-double;
  @include helper__greater($bp-768) {
    padding: 0 0 $unit-double * 2;
  }
}

.scroller {
  @include spacing;
  margin: 0 auto !important;
  z-index: 0;
  position: relative;
  overflow: visible;
}
</style>
