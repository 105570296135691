<template>
  <transition name="fade">
    <div v-if="open" class="global-header-search">
      <div ref="canvas" class="global-header-search__canvas">
        <button class="global-header-search__close" @click="setSearchBarVisibility(false)">
          <IconArrowLeft />
        </button>
        <div class="global-header-search__input-container">
          <NolteFormInput
            ref="input"
            type="text"
            name="q"
            v-model="q"
            :placeholder="$t('search-input-placeholder-text')"
            autocomplete="off"
            class="global-header-search__input"
            @input="getSuggestionsDebounced"
            @click="activeItemIndex = -1"
          />
          <ul class="global-header-search__suggestions">
            <li
              v-for="(item, index) in suggestions"
              :key="item.value.id"
              @click="setSearchBarVisibility(false)"
              :class="{ hover: index == activeItemIndex }"
            >
              <ContentLink :field="item">
                <span>{{ item.value.text }}</span>
              </ContentLink>
            </li>
          </ul>
          <transition name="fade">
            <button class="global-header-search__empty" @click="empty" v-if="q.length > 0">
              <IconInputCross />
            </button>
          </transition>
          <button
            class="global-header-search__search"
            :class="{ 'global-header-search__search--active': q.length >= options.inputMinLength }"
            @click="search"
          >
            <IconInputSearch />
          </button>
        </div>
      </div>
    </div>
  </transition>
</template>

<script>
import { fetchResults } from '@/components/Search/utils';
import { mapGetters, mapState, mapMutations } from 'vuex';
import NolteFormInput from '@/nolte-ui/NolteForm/NolteFormInput';
import IconArrowLeft from '@/assets/icons/IconArrowLeft.svg?inline';
import IconInputCross from '@/assets/icons/IconInputCross.svg?inline';
import IconInputSearch from '@/assets/icons/IconInputSearch.svg?inline';
import ContentLink from '@/components/ContentLink';
import debounce from 'debounce';

const keys = {
  27: 'escape',
  38: 'up',
  40: 'down',
  13: 'enter',
};

export default {
  name: 'GlobalHeaderSearch',

  components: {
    NolteFormInput,
    IconArrowLeft,
    IconInputCross,
    IconInputSearch,
    ContentLink,
  },

  data: () => ({
    q: '',
    suggestions: [],
    activeItemIndex: null,
    options: {
      inputMinLength: 3,
      suggestionsLimit: 10,
      batchSize: 10,
    },
  }),

  computed: {
    ...mapState('jss', ['sitecoreContext']),
    ...mapGetters({ open: 'getSearchBarVisibility' }),
  },

  props: {
    endpoint: {
      type: String,
      required: true,
    },
  },

  watch: {
    open(isOpen) {
      if (isOpen) {
        this.q = '';
        this.suggestions = [];
        setTimeout(() => {
          this.activeItemIndex = -1;

          // usually the setting of this.activeItemIndex = -1 should trigger this.focusInput as the
          // variable is watched. But if the focus is on the input field, we close and reopen the
          // search component the watcher is not triggered as the value of activeItemIndex does
          // actually not change
          this.focusInput();

          // we cannot use simply dynamic classes as this doesn't work in conjunction with transitions
          // so we have to add and remove the classes manually
          this.$refs.canvas.classList.add('global-header-search__canvas--visible');
        }, 100);

        // capture the keyboard events if the component is open
        document.addEventListener('keydown', this.onkey);
      } else {
        this.$refs.canvas.classList.remove('global-header-search__canvas--visible');

        // do not capture the keyboard events if the component is closed
        document.removeEventListener('keydown', this.onkey);
      }
    },

    // renders the currently active item
    activeItemIndex(activeItemIndex) {
      if (activeItemIndex === -1) {
        this.focusInput();
      } else {
        this.blurInput();
      }
    },
  },

  methods: {
    ...mapMutations(['setSearchBarVisibility']),
    debounce,

    onkey(e) {
      const key = keys[e.keyCode] || e.keyCode;
      if (key === 'escape') {
        this.setSearchBarVisibility(false);
        e.preventDefault();
      }
      if (key === 'up') {
        this.activeItemIndex--;
        e.preventDefault();
      }
      if (key === 'down') {
        this.activeItemIndex++;
        e.preventDefault();
      }

      if (this.activeItemIndex < -1) {
        this.activeItemIndex = this.suggestions.length - 1;
      }
      if (this.activeItemIndex > this.suggestions.length - 1) {
        this.activeItemIndex = -1;
      }

      if (key === 'enter') {
        if (this.activeItemIndex === -1) {
          // redirect to search page
          this.search();
        } else {
          // redirect to suggestion
          this.openSuggestion();
        }
        e.preventDefault();
      }
    },

    search() {
      this.$router.push({ path: this.endpoint, query: { q: this.q } });
      this.setSearchBarVisibility(false);
    },

    openSuggestion() {
      this.$router.push(this.suggestions[this.activeItemIndex].value.href);
      this.setSearchBarVisibility(false);
    },

    empty() {
      this.q = '';
      this.suggestions = [];
      this.activeItemIndex = -1;
    },

    // should not be called directly
    // set this.activeItemIndex = -1 instead as this variable is watched
    focusInput() {
      const $input = this.$refs.input.$el;
      $input.focus();

      // set the cursor to the end of the input field
      const len = this.q.length * 2;
      // Timeout seems to be required for Blink
      setTimeout(function () {
        $input.setSelectionRange(len, len);
      }, 1);
    },

    blurInput() {
      this.$refs.input.$el.blur();
    },

    getSuggestionsDebounced: debounce(function () {
      this.getSuggestions();
    }, 300),

    getSuggestions() {
      if (this.q.length < this.options.inputMinLength) {
        this.suggestions = [];
        return;
      }

      const { language, searchScope, itemId } = this.sitecoreContext;
      const searchParams = {
        term: this.q,
        offset: '0',
        batchSize: this.options.batchSize.toString(),
        language,
        searchScope,
        itemId,
      };

      fetchResults(searchParams, result => {
        this.suggestions = result?.Results.map(item => {
          return {
            value: {
              id: item.Id,
              text: item.Headline || item.Url, // use url as fallback for an empty Headline
              href: item.Url,
              linktype: 'internal',
            },
          };
        });
      });
    },
  },
};
</script>

<style lang="scss" scoped>
button {
  border: 0;
  padding: 0;
}

.global-header-search {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: $color-white;

  z-index: 80; // higher than logo and burger menu

  @include helper__greater($bp-768) {
    z-index: 40;
  }
}

.global-header-search__canvas {
  display: flex;
  align-items: flex-start;
  background: $color-white;
  transition: transform 0.5s;
  transform: translateY(-100%);
  height: 100vh;
  overflow: auto;
  padding: $unit-double $unit-base 0;

  @include helper__greater($bp-768) {
    padding: $unit-double 112px 0 150px;
  }

  @include helper__greater($bp-1280) {
    padding: $unit-double $unit-double 0 150px;
  }
}

.global-header-search__canvas--visible {
  transform: translateY(0);
}

.global-header-search__input-container {
  width: 100%;
  position: relative;
}

.global-header-search__input {
  @include text-copy;
  padding: 0 100px $unit-half $unit-half;
  margin-bottom: 0;

  &::-ms-clear {
    display: none;
  }
}

.global-header-search__close {
  margin: 0 $unit-base 0 0;
}

.global-header-search__empty {
  position: absolute;
  right: $unit-double * 2;
  top: 0;
}

.global-header-search__search {
  position: absolute;
  right: $unit-base;
  top: 0;

  :deep(circle),
  :deep(path) {
    stroke: $color-athens-gray;
    transition: stroke 0.5s;
  }

  &--active {
    :deep(circle),
    :deep(path) {
      stroke: $color-ironside-gray;
    }
  }
}

.global-header-search__suggestions li {
  &.hover a {
    background-color: $color-aureolin;
  }
}

.global-header-search__suggestions a {
  @include text-copy;
  @include helper__transition(background-color);
  display: block;
  padding: $unit-half;
  position: relative;

  display: table;
  table-layout: fixed;
  width: 100%;

  &:hover {
    background-color: $color-concret;
  }

  span {
    display: table-cell;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
    padding: 0 $unit-double 0 0;
    background: url(../assets/icons/IconExternal.svg) no-repeat center right;
  }
}

.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.5s;
}
.fade-enter, .fade-leave-to /* .fade-leave-active below version 2.1.8 */ {
  opacity: 0;
}
</style>
