<template>
  <NolteDealerFinder
    v-if="mapsConsent"
    :site-country-code="siteCountryCode"
    :site-language-code="siteLanguageCode"
  />

  <div class="editorial-dealer-finder__no-map" v-else>
    <template v-if="mapsConsent === false">
      <div class="editorial-dealer-finder__no-map-content">
        <IconCross class="editorial-dealer-finder__no-map-icon" />
        <p class="editorial-dealer-finder__no-map-text">{{ noConsentText }}</p>
      </div>
      <div class="editorial-dealer-finder__no-map-content">
        <input
          type="checkbox"
          id="dealer-finder__checkbox"
          @click="toggleGoogleMaps"
          class="editorial-dealer-finder__checkbox"
        />
        <label for="dealer-finder__checkbox" class="editorial-dealer-finder__no-map-text">
          {{ $t('dealer-finder-google-maps-checkbox') }}
        </label>
      </div>
    </template>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import eventBus from '@/lib/eventBus';

const NolteDealerFinder = () =>
  import(/* webpackChunkName: 'NolteDealerFinder' */ '../nolte-ui/NolteDealerFinder');
const IconCross = () =>
  import(/* webpackChunkName: 'NolteDealerFinder' */ '../assets/icons/IconCross.svg?inline');

export default {
  name: 'EditorialDealerFinder',
  components: {
    NolteDealerFinder,
    IconCross,
  },

  data() {
    return {
      mapsConsent: undefined,
      checked: false,
    };
  },

  computed: {
    ...mapGetters(['getUserCentricsId', 'getUserCentricsGoogleMapsServiceId']),
    ...mapGetters('jss', ['currentRouteLanguage']),

    siteLanguageCode() {
      return this.currentRouteLanguage?.language;
    },

    siteCountryCode() {
      // set the fallback to GB if we are using the system language
      return this.currentRouteLanguage?.locale?.split('-')[1] || 'GB';
    },

    noConsentText() {
      return this.$t('dealer-finder-no-consent-text');
    },
  },

  beforeUnmount() {
    eventBus.$emit('EditorialDealerFinder:beforeUnmount');
  },

  mounted() {
    if (typeof window !== 'undefined') {
      // check for the user consent
      // this code will check for the consent if we came from any other SPA page ...
      if (window.UC_UI) {
        const service = window.UC_UI.getServicesBaseInfo().find(
          service => service.id === this.getUserCentricsGoogleMapsServiceId
        );
        if (service) {
          this.mapsConsent = service.consent?.status;
        }
      }

      // ... and this code will be triggered on SSR because does window.UC_UI not exist yet, but
      // UC will triggering this event on load
      window.addEventListener('onConsentStatusChange', this.consentHandler);

      eventBus.$once('EditorialDealerFinder:beforeUnmount', () => {
        window.removeEventListener('onConsentStatusChange', this.consentHandler);
      });
    }
  },

  methods: {
    consentHandler(event) {
      this.mapsConsent = event.detail['Google Maps'];
    },

    toggleGoogleMaps() {
      if (
        typeof window !== 'undefined' &&
        window.UC_UI &&
        this.getUserCentricsGoogleMapsServiceId
      ) {
        if (!this.mapsConsent) {
          this.checked = false;
        }

        this.checked = !this.checked;

        if (this.checked) {
          window.UC_UI.acceptService(this.getUserCentricsGoogleMapsServiceId);
          setTimeout(() => {
            this.mapsConsent = true;
          }, 500);
        }
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.editorial-dealer-finder__no-map {
  @include spacing;
  @include text-intro;
}

.editorial-dealer-finder__no-map-content {
  display: flex;
  flex-flow: row;
}

.editorial-dealer-finder__no-map-icon {
  margin-right: $unit-base;
  max-width: max-content;
  overflow: visible;
  flex: 0 0 auto;
}

.editorial-dealer-finder__no-map-text {
  padding: $unit-half 0;
}

.editorial-dealer-finder__checkbox {
  @include helper__visually-hidden;
}

.editorial-dealer-finder__checkbox + label {
  padding-left: $unit-half;
  cursor: pointer;
  position: relative;
  display: inline-flex;
  align-items: center;
  color: $color-ironside-gray;
  user-select: none;
}

.editorial-dealer-finder__checkbox + label::before {
  @include helper__transition((background-color, border-color));
  content: '';
  width: 26px;
  height: 26px;
  border-radius: 3px;
  border: 2px solid $color-athens-gray;
  background-color: $color-white;
  margin-right: 29px;
  margin-bottom: 3px;
}

.editorial-dealer-finder__checkbox + label:hover::before {
  border-color: $color-tuatara;
}

// Style when focus by keyboard interaction
body[modality='keyboard'] .editorial-dealer-finder__checkbox:focus + label::before {
  border-color: $color-azure;
}

.editorial-dealer-finder__checkbox:checked + label::before {
  background-color: $color-aureolin;
  border-color: $color-citrine;
}
.editorial-dealer-finder__checkbox + label::after {
  @include helper__transition((opacity, transform));
  @include helper__composited-layer;
  content: '';
  height: 5px;
  width: 9px;
  border-left: 2px solid;
  border-bottom: 2px solid;
  transform: rotate(-45deg) scale(0.7);
  left: 17px;
  top: 20px;
  position: absolute;
  color: $color-tuatara;
  opacity: 0;
}

.editorial-dealer-finder__checkbox:checked + label::after {
  opacity: 1;
  transform: rotate(-45deg) scale(1);
}
</style>
