<template>
  <div ref="container" class="editorial-neo-stage" :class="{ 'has-buttons': hasButtons }">
    <ContentAnchor
      :class="{ anchor: !isEditing }"
      :fields="{
        anchorTitle: fields['Anchor Title'],
        anchorName: fields['Anchor Name'],
      }"
    />
    <div v-if="isEditing">
      <span>Enter the Wistia video ID: </span>
      <sc-text :field="fields['Wistia Video Id']" />
    </div>
    <div class="media-container">
      <ContentVideoWistia
        v-if="textHasValue(fields['Wistia Video Id']) && videoDoesExist && !isEditing"
        :video-id="fields['Wistia Video Id'].value"
        end-video-behavior="loop"
        :fullscreen-button="false"
        :settings-control="false"
        :auto-play="videoAutoplay"
        :muted="videoMuted"
        :playbar="videoPlaybutton"
        :volume-control="!videoMuted"
        :small-play-button="videoPlaybutton"
        :fit="isMobile ? 'fill' : 'cover'"
        class="video"
        @error="videoDoesExist = false"
      />
      <EditorialImage
        v-else-if="imageHasSource(fields['Fallback Image']) || isEditing"
        :media="fields['Fallback Image']"
        :sources="{
          '(min-width: 769px)': '16:10',
        }"
        default-aspect-ratio="3:4"
        default-sizes="100vw"
        fit="cover"
        class="fallback-image"
      />
    </div>

    <div class="content-container">
      <div class="copy">
        <EditorialImage
          v-if="imageHasSource(fields['Logo']) || isEditing"
          :media="fields['Logo']"
          :sources="{
            '(min-width: 769px)': '16:10',
          }"
          default-aspect-ratio="3:4"
          default-sizes="100vw"
          fit="cover"
          class="logo"
        />

        <div class="ctas" v-if="hasButtons">
          <EditorialButton
            v-if="linkHasValue(fields['CTA 1']) || isEditing"
            class="cta1"
            :fields="{
              link: fields['CTA 1'],
            }"
          />

          <EditorialButton
            v-if="linkHasValue(fields['CTA 2']) || isEditing"
            class="cta2"
            :fields="{
              link: fields['CTA 2'],
              _extendedParams: buttonParams('yellow'),
            }"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
// TODO: object-fit: cover; für das Video funktioniert nicht
// TODO: mobile
import { mapGetters } from 'vuex';
import EditorialImage from '@/components/EditorialImage';
import EditorialButton from '@/components/EditorialButton';
import ContentAnchor from '@/components/ContentAnchor';
import ContentVideoWistia from '@/components/ContentVideoWistia';
import { textHasValue, linkHasValue, imageHasSource } from './helper';
import { Text } from '@sitecore-jss/sitecore-jss-vue';

export default {
  name: 'EditorialNeoStage',

  components: {
    EditorialImage,
    EditorialButton,
    ContentAnchor,
    ContentVideoWistia,
    ScText: Text,
  },

  data: () => ({
    videoDoesExist: true,
  }),

  computed: {
    ...mapGetters('jss', ['isEditing']),
    ...mapGetters(['isMobile']),

    showInAnchorNavigation() {
      return this.fields && this.fields['Show Anchor in Navigation']?.value ? true : false;
    },
    videoAutoplay() {
      return !!Number(this.fields?._extendedParams?.['Autoplay']?.value);
    },
    videoMuted() {
      return (
        !!Number(this.fields?._extendedParams?.['Autoplay']?.value) ||
        !!Number(this.fields?._extendedParams?.['Muted']?.value)
      );
    },
    videoPlaybutton() {
      return !!Number(this.fields?._extendedParams?.['Playbutton']?.value);
    },
    hasButtons() {
      return (
        !this.videoPlaybutton &&
        (linkHasValue(this.fields['CTA 2']) ||
          linkHasValue(this.fields['CTA 1']) ||
          imageHasSource(this.fields['Logo']))
      );
    },
  },

  methods: {
    textHasValue,
    linkHasValue,
    imageHasSource,

    buttonParams(style) {
      return {
        ButtonType: {
          fields: {
            Type: {
              value: style,
            },
          },
        },
      };
    },
  },
};
</script>

<style lang="scss" scoped>
.editorial-neo-stage {
  position: relative;
  width: 100%;

  @include helper__greater($bp-768) {
    @include fullHeightWithoutHeader;
  }

  overflow: hidden;

  @include helper__until($bp-768) {
    background: $color-black;
  }
}

// we have to offset the anchor to an Y scroll position equal or lower than 0 to be sure the
// page scrolls to the upper edge (to overwrite the scroll-padding-top setting)
.anchor {
  position: absolute;
  top: -100px;
}

.media-container {
  position: relative;

  @include helper__greater($bp-768) {
    height: 100%;
  }
  &::after {
    pointer-events: none;
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;

    background: linear-gradient(0deg, #000000ff 0%, #00000000 6%);
  }
}

.video {
  height: 100%;
}

.fallback-image {
  position: relative;
  width: 100%;
  height: 100%;
}

.ctas {
  @include helper__greater($bp-768) {
    display: flex;
    justify-content: center;
  }

  :deep(.editorial-button-wrapper) {
    margin: $unit-base 0;
    padding: 0 $unit-base;

    a {
      display: block;
    }
  }
}

.content-container {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 500;
}

.copy {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.logo {
  width: 80vw;
  height: auto;

  @include helper__greater($bp-768) {
    width: 600px;
  }
}

.anchor-navigation--visible {
  .editorial-neo-stage {
    @include helper__greater($bp-768) {
      @include fullHeightWithoutAnchorNavigation;
    }
  }
}
.editorial-neo-stage.has-buttons {
  :deep .content-video-wistia__play {
    display: none !important;
  }
}
</style>
