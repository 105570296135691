<template>
  <div>
    <ul class="editorial-map-results__result-list">
      <li
        v-for="(result, index) in results"
        :key="`result_${index}`"
        class="result__item"
        :class="{ active: activeResultItem === result.identifier }"
        @click="selectRetailer(result)"
        :ref="`retailerResultItem__${result.identifier}`"
        :id="`retailerResultItem__${result.identifier}`"
      >
        <div class="divider" />
        <div class="result__inner">
          <div class="address-wrapper">
            <h4 class="headline">{{ result.name1 }} {{ result.name2 }}</h4>
            <span>{{ result.street }}</span>
            <span>{{ result.zipcode }} {{ result.city }}</span>
            <span class="hide-mobile">{{ result.phone }}</span>
            <span class="hide-mobile"
              ><a :href="'mailto:' + result.email">{{ result.email }}</a></span
            >
          </div>
          <div class="label-wrapper">
            <NolteLabel class="label" />
            <NolteNeoLabel v-if="result.isNeo" class="label neo" />
          </div>
          <div class="routeplanner-wrapper hide-mobile" v-if="result.lat && result.lng">
            <a :href="routeplannerLink(result)" target="_blank" class="button--yellow">
              {{ $t('editorial-map-overlay-route-planner') }}
            </a>
          </div>
          <div class="cta-wrapper mobile-only">
            <div class="cta-item" v-if="result.phone">
              <a :href="phoneLink(result)" target="_blank">
                <IconPhone class="icon--yellow" />
              </a>
            </div>
            <div class="cta-item" v-if="result.email">
              <a :href="'mailto: ' + result.email + ''" target="_blank">
                <IconMail class="icon--yellow" />
              </a>
            </div>
            <div class="cta-item" v-if="result.lat && result.lng">
              <a :href="routeplannerLink(result)" target="_blank">
                <IconRoute class="icon--yellow" />
              </a>
            </div>
          </div>
        </div>
      </li>
    </ul>
  </div>
</template>

<script>
import { mapActions } from 'vuex';
import NolteLabel from '../../assets/images/nolte-label.svg?inline';
import NolteNeoLabel from '../../assets/images/nolte-neo-label.svg?inline';
import smoothscroll from 'smoothscroll-polyfill';
import { createPhoneLink, createRouteplannerLink } from './utils';

import IconMail from '../../assets/icons/IconMail.svg?inline';
import IconPhone from '../../assets/icons/IconPhone.svg?inline';
import IconRoute from '../../assets/icons/IconRoute.svg?inline';

export default {
  name: 'ResultListRetailers',

  components: { NolteLabel, NolteNeoLabel, IconMail, IconPhone, IconRoute },

  props: {
    isMobile: undefined,
    results: undefined,
    scrollToRetailer: undefined,
  },
  data() {
    return {
      activeResultItem: null,
      selectedRetailer: null,
    };
  },
  computed: {
    doScrollToRetailer() {
      return this.scrollToRetailer;
    },
  },
  methods: {
    ...mapActions('map', {
      setLocation: 'setLocation',
    }),
    routeplannerLink(retailer) {
      if (retailer.lat && retailer.lng) {
        return createRouteplannerLink(retailer.lat, retailer.lng);
      }
    },
    phoneLink(retailer) {
      if (retailer.phone) {
        return createPhoneLink(retailer.phone);
      }
    },
    selectRetailer(retailer) {
      if (retailer) {
        this.selectedRetailer = retailer;
        this.activeResultItem = retailer.identifier;
        let location = {
          longitude: retailer.lng,
          latitude: retailer.lat,
          isShowroom: false,
          isValid: true,
          id: retailer.identifier,
          zoom: true,
        };

        this.setLocation(location);
        this.scrollToRetailerInList(retailer.identifier);
      }
    },
    scrollToRetailerInList(id) {
      this.activeResultItem = id;
      if (this.$refs[`retailerResultItem__${id}`] !== undefined) {
        const currentRetailerListEl = this.$refs[`retailerResultItem__${id}`][0];
        if (currentRetailerListEl) {
          if (this.isMobile) {
            currentRetailerListEl.scrollIntoView();
          } else {
            currentRetailerListEl.scrollIntoView({ behavior: 'smooth' });
          }
        }
      }
    },
  },
  mounted() {
    smoothscroll.polyfill();

    this.$watch(
      'doScrollToRetailer',
      doScrollToRetailer => {
        if (doScrollToRetailer) {
          let activeRetailer = this.results.find(item => item.identifier === doScrollToRetailer);
          this.selectRetailer(activeRetailer);
          this.scrollToRetailerInList(doScrollToRetailer);
        }
      },
      { immediate: true }
    );
  },
};
</script>

<style lang="scss" scoped>
.editorial-map-results__result-list {
  background: $color-concret;
  overflow: hidden;
}

.result__item {
  cursor: pointer;
  border-left: $unit-half solid $color-concret;
  background: $color-concret;

  &.active,
  &:hover {
    background: $color-athens-gray;
    border-left: $unit-half solid $color-aureolin;
  }
}

.result__inner {
  padding: $unit-base $unit-half !important;

  @include helper__greater($bp-1024) {
    padding: $unit-base $unit-double !important;
  }
}

.divider {
  border-top: 1px solid #a4a19d;
  margin: 0;
  padding: 0;
  margin-left: -8px;
  margin-right: 47px;
}

.address-wrapper {
  display: flex;
  flex-direction: column;
  @include text-base;
  color: $color-ironside-gray;

  .headline {
    @include text-h4;
    margin: 0 0 $unit-half;
  }
  a {
    text-decoration: underline;
  }
}

.button--yellow {
  @include text-h5;
  @include helper__transition((color, border-color, background-color));
  border-radius: $radius-form;
  padding: $unit-half $unit-base;

  border: 2px solid $color-aureolin;
  background-color: $color-aureolin;

  &:hover {
    border: 2px solid $color-citrine;
  }
}

.label-wrapper {
  margin: $unit-half 0;
  @include helper__greater($bp-1024) {
    margin: $unit-base 0;
  }
  display: flex;
  align-items: center;
  align-content: flex-start;
}

.routeplanner-wrapper {
  margin-bottom: $unit-half;
}

.label {
  width: auto;
  height: 32px;
  margin-right: $unit-half;

  &.not-active {
    filter: opacity(0.2);
  }

  &.neo {
    margin-top: -5px;
  }
}

.cta-wrapper {
  display: flex;
  align-items: flex-start;
  align-content: flex-start;
}
.cta-item {
  padding: $unit-half $unit-base;
  background-color: $color-aureolin;
  border-radius: 10px;
  padding: 4px 12px;
  margin-right: 16px;
}
.icon--yellow {
  color: $color-ironside-gray;
  margin: 0 auto;
  background-color: $color-aureolin;
  width: auto;
  height: auto;
}

.mobile-only {
  @include helper__greater($bp-1024) {
    display: none;
  }
}
.hide-mobile {
  @include helper__until($bp-1024) {
    display: none;
  }
}
</style>
