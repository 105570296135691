<template>
  <li
    class="nolte-catalog-menu-category-pill"
    :class="{ 'nolte-catalog-menu-category-pill--active': active }"
  >
    <ContentLink
      :editable="false"
      class="nolte-catalog-menu-category-pill__link"
      :field="{ value: { text: label, href: url, linktype: 'internal', title: title } }"
      @click="$emit('click')"
    />
  </li>
</template>

<script>
import ContentLink from '@/components/ContentLink';

export default {
  name: 'NolteCatalogMenuCategoryPill',

  components: {
    ContentLink,
  },

  props: {
    url: {
      type: String,
      required: true,
    },
    label: {
      type: String,
      required: true,
    },
    title: {
      type: String,
      default: '',
    },
    active: {
      type: Boolean,
      default: false,
    },
  },
};
</script>

<style lang="scss" scoped>
$additional-space: $unit-half / 2;
$extended-padding-horizontal: $unit-base + $additional-space;
$extended-padding-vertical: $unit-base + $additional-space + $unit-half / 2;

.nolte-catalog-menu-category-pill {
  &:last-of-type {
    padding-right: $additional-space;
  }
}

.nolte-catalog-menu-category-pill__link {
  padding: $extended-padding-vertical $extended-padding-horizontal;
  display: block;
  position: relative;
  white-space: nowrap;
  z-index: 0;
  @include text-h5;

  &::before {
    @include helper__transition((background-color, box-shadow));
    content: '';
    position: absolute;
    left: $additional-space;
    right: $additional-space;
    top: $additional-space * 2;
    bottom: $additional-space * 2;
    background-color: $color-concret;
    border-radius: $radius-default;
    z-index: -1;
  }
}

.nolte-catalog-menu-category-pill--active .nolte-catalog-menu-category-pill__link::before {
  background-color: $color-aureolin;
}

.nolte-catalog-menu-category-pill:hover .nolte-catalog-menu-category-pill__link::before {
  box-shadow: inset 0 0 0 1px rgba($color-black, 0.1);
}
</style>
