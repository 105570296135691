// Polyfill for IE11 to support event.composedPath()
// Source: https://stackoverflow.com/questions/39245488/event-path-undefined-with-firefox-and-vue-js/57554722#57554722

if (typeof window !== 'undefined' && typeof document !== 'undefined') {
  (function (e, d, w) {
    if (!e.composedPath) {
      e.composedPath = function () {
        if (this.path) {
          return this.path;
        }
        let target = this.target;

        this.path = [];
        while (target.parentNode !== null) {
          this.path.push(target);
          target = target.parentNode;
        }
        this.path.push(d, w);
        return this.path;
      };
    }
  })(Event.prototype, document, window);
}
