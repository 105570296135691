<template>
  <v-field-template
    name="nin"
    :field="field"
    :hidden="hidden"
    :disabled="disabled"
    :errors="errors"
  >
    <template #field>
      <NolteFormWrapper :errors="errors" #default="{ hasError }">
        <NolteFormCheckbox
          :id="field.valueField.id + '-checkbox'"
          :name="field.valueField.name.replace(/\.Value$/, '.CheckboxValue')"
          v-model="field.model.checkboxValue"
          :label="field.model.checkboxTitle"
          @change="toggleFormFieldNin($event, field.model.checkboxValue)"
          :checked="field.model.checkboxValue"
        />
        <div class="form-field-nin" ref="formFieldNinDiv">
          <v-label v-if="field.valueField" :field="field" />
          <ContentLightbox v-if="hasLightboxData" class="help">
            ?
            <template #content>
              <NolteHeadline v-if="field.model.headline !== ''" level="h3">
                {{ field.model.headline }}
              </NolteHeadline>
              <p v-if="field.model.copy !== ''">{{ field.model.copy }}</p>
            </template>
          </ContentLightbox>
          <NolteFormInput
            type="text"
            :id="field.valueField.id"
            :name="field.valueField.name"
            :placeholder="field.model.placeholderText"
            :required="field.model.required"
            :minlength="field.model.minLength"
            :maxlength="field.model.maxLength"
            :disabled="disabled"
            v-model="value"
            :value="value"
            @blur="fieldBlur"
            :invalid="hasError"
          />
        </div>
      </NolteFormWrapper>
    </template>
    <template #label> </template>
  </v-field-template>
</template>

<script>
import FieldMixin from '../structure/FieldMixin';
import FieldConditionMixin from '../structure/conditions/FieldConditionMixin';
import FieldValidationMixin from '../structure/validation/FieldValidationMixin';

import NolteFormInput from '../../nolte-ui/NolteForm/NolteFormInput';
import NolteFormWrapper from '../../nolte-ui/NolteForm/NolteFormWrapper';
import NolteHeadline from '../../nolte-ui/NolteHeadline';
import ContentLightbox from '@/components/ContentLightbox';
import NolteFormCheckbox from '../../nolte-ui/NolteForm/NolteFormCheckbox';

import { getStorage } from '@/useLocalStorage';

export default {
  name: 'SitecoreFormsFieldsNin',

  components: {
    NolteFormInput,
    NolteFormWrapper,
    ContentLightbox,
    NolteHeadline,
    NolteFormCheckbox,
  },

  mixins: [FieldMixin, FieldConditionMixin, FieldValidationMixin],

  // errors prop is defined in FieldValidationMixin
  data() {
    return {
      value: this.field.model.value,
    };
  },

  computed: {
    hasLightboxData() {
      return this.field.model.headline !== '' || this.field.model.copy !== '';
    },
  },

  mounted() {
    const savedValue = getStorage(this.field.valueField.name);
    this.value = savedValue ? savedValue : this.field.model.value;

    const savedCheckboxValue = getStorage(
      this.field.valueField.name.replace(/\.Value$/, '.CheckboxValue')
    );

    if (savedCheckboxValue === 'true' || savedValue) {
      this.field.model.checkboxValue = true;
    }

    setTimeout(() => {
      // Allow to animate the height of the ".form-field"s via CSS transition.
      const items = [].slice.call(document.querySelectorAll('.form-field-nin'));
      items.forEach(item => {
        item.style.height = item.clientHeight + 'px';
        if (!this.field.model.checkboxValue) {
          item.classList.add('form-item-is-hidden');
        }
        item.closest('.form-field').style.height = 'auto';
      });
      // Add the transition in the next event cycle. This way we don't have an animation on mount.
      setTimeout(() => {
        items.forEach(item => {
          item.style.transition = 'height 0.3s';
        });
      });
    });
  },
  methods: {
    toggleFormFieldNin(state, value) {
      if (!state || value === true) {
        this.$refs.formFieldNinDiv.classList.add('form-item-is-hidden');
      } else {
        this.$refs.formFieldNinDiv.classList.remove('form-item-is-hidden');
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.nolte-form-label {
  display: inline;
  margin: 0 $unit-base 0 0;
}
:deep(.form-field-label) {
  display: none;
}
:deep(label) {
  @include text-form-title;
  margin-left: $unit-half / 4;
  color: $color-ironside-gray;
  padding-bottom: $unit-half;
  font-weight: 100;
}
:deep(.nolte-form-checkbox__checkbox) + label {
  display: block;
  white-space: normal;
  padding-top: 4px;
  padding-left: 36px;
}
:deep(.nolte-form-checkbox__checkbox) + label:before {
  position: absolute;
  top: 0;
  left: 0;
}
.help {
  @include text-form-title;
  display: inline-block;
  width: 24px;
  height: 24px;
  line-height: 24px;
  border-radius: 50%;
  margin: 0;
  color: $color-ironside-gray;
  background: $color-aureolin;
  text-align: center;
  box-shadow: 1px 1px 5px rgba($color-black, 0.25);
  cursor: pointer;
}
</style>
