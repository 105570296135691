<template>
  <div
    :class="[
      'form-field',
      'form-field-type-' + name,
      {
        'form-field-is-required': field.model.required,
        'form-field-is-disabled': disabled,
        'form-item-is-hidden': hidden,
        'form-field-has-errors': errors && errors.length,
      },
      field.model.cssClass,
    ]"
  >
    <!-- a copy field e.g. does not have the information for hidden fields -->
    <v-hidden v-if="field.indexField" :field="field.indexField" />
    <v-hidden v-if="field.fieldIdField" :field="field.fieldIdField" />

    <div class="form-field-label">
      <!-- a copy field e.g. does not have the information for a label -->
      <slot name="label">
        <v-label v-if="field.valueField" :field="field" />
      </slot>
    </div>

    <div class="form-field-element">
      <slot name="field" />
    </div>
  </div>
</template>

<script>
import VLabel from '../fields/Label';
import VHidden from '../fields/Hidden';

export default {
  name: 'SitecoreFormsStructureFieldTemplate',

  components: {
    VLabel,
    VHidden,
  },

  props: {
    name: {
      type: String,
      required: true,
    },
    field: {
      type: Object,
      required: true,
    },
    disabled: {
      type: Boolean,
    },
    hidden: {
      type: Boolean,
    },
    errors: {
      type: Array,
      default: undefined,
    },
  },
};
</script>
