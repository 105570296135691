<template>
  <div class="jss-loading" v-if="visible">
    <!-- By Sam Herbert (@sherb), for everyone. More @ http://goo.gl/7AJzbL -->
    <svg
      width="44"
      height="44"
      viewBox="0 0 44 44"
      xmlns="http://www.w3.org/2000/svg"
      stroke="#373737"
    >
      <g fill="none" fill-rule="evenodd" stroke-width="2">
        <circle cx="22" cy="22" r="1">
          <animate
            attributeName="r"
            begin="0s"
            dur="1.8s"
            values="1; 20"
            calcMode="spline"
            keyTimes="0; 1"
            keySplines="0.165, 0.84, 0.44, 1"
            repeatCount="indefinite"
          />
          <animate
            attributeName="stroke-opacity"
            begin="0s"
            dur="1.8s"
            values="1; 0"
            calcMode="spline"
            keyTimes="0; 1"
            keySplines="0.3, 0.61, 0.355, 1"
            repeatCount="indefinite"
          />
        </circle>
        <circle cx="22" cy="22" r="1">
          <animate
            attributeName="r"
            begin="-0.9s"
            dur="1.8s"
            values="1; 20"
            calcMode="spline"
            keyTimes="0; 1"
            keySplines="0.165, 0.84, 0.44, 1"
            repeatCount="indefinite"
          />
          <animate
            attributeName="stroke-opacity"
            begin="-0.9s"
            dur="1.8s"
            values="1; 0"
            calcMode="spline"
            keyTimes="0; 1"
            keySplines="0.3, 0.61, 0.355, 1"
            repeatCount="indefinite"
          />
        </circle>
      </g>
    </svg>
  </div>
</template>

<script>
export default {
  data() {
    return {
      visible: false,
    };
  },

  props: {
    delay: {
      type: Number,
      default: 100,
    },
  },

  created() {
    // Wait {this.delay}ms before making the loading graphic visible.
    // If whatever other async operation completes within that timeframe,
    // then whichever parent component invoked the loading component will
    // presumably re-render without the loading component.
    // This helps prevent the loading graphic from too briefly "flashing" on the screen
    // causing an undesirable flicker for users.
    setTimeout(() => {
      this.visible = true;
    }, this.delay);
  },
};
</script>

<style scoped>
.jss-loading {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100vw;
  height: 100vh;
  text-align: center;
  position: absolute;
  top: 0;
  z-index: 999;
}
svg {
  width: 20vw;
  height: 20vw;
}
</style>
