<template>
  <div class="header-submenu">
    <ul class="submenu-list">
      <li class="submenu-item" v-for="(item, index) in links" :key="index">
        <ContentLink
          class="submenu-link"
          :editable="false"
          :field="{ value: item }"
          @click="$emit('toggle-overlay')"
        >
          <!-- uses the name of the SVG imports below and is defined by items in /sitecore/content/Nolte JSS/Corporate Website/Data/Params/Header/Submenu Icons/ -->
          <span class="submenu-icon">
            <component :is="item.navigationIcon" />
          </span>

          <span class="submenu-label">{{ item.text }}</span>
        </ContentLink>
      </li>
    </ul>
  </div>
</template>
<script>
import ContentLink from './ContentLink';

import IconDownload from '../assets/icons/IconDownload.svg?inline';
import IconContact from '../assets/icons/IconContact.svg?inline';
import IconShowroom from '../assets/icons/IconShowroom.svg?inline';
import IconCleaningSupplies from '../assets/icons/IconCleaningSupplies.svg?inline';
import IconSustainability from '../assets/icons/IconSustainability.svg?inline';
import IconWhyNolteKitchen from '../assets/icons/IconWhyNolteKitchen.svg?inline';

export default {
  name: 'HeaderSubmenu',

  components: {
    IconDownload,
    IconContact,
    IconShowroom,
    IconCleaningSupplies,
    IconSustainability,
    IconWhyNolteKitchen,
    ContentLink,
  },

  props: {
    links: {
      type: Array,
      required: true,
    },
  },
};
</script>

<style lang="scss" scoped>
.submenu-list {
  max-width: $grid-width;
  margin-left: auto;
  margin-right: auto;
  height: inherit;

  @include helper__until($bp-1280) {
    display: block;
    width: 100%;
    padding-top: $unit-base * 3;
    padding-bottom: $unit-base * 3;
    box-shadow: inset 0 18px 23px -20px rgba($color-black, 0.1);
  }

  @include helper__greater($bp-1280) {
    width: 70%;
    display: flex;
  }
}

.submenu-item {
  flex: 1 auto;

  & + & {
    margin-top: 24px;
  }

  @include helper__greater($bp-1280) {
    margin-top: 24px;
  }
}

.submenu-link {
  @include text-navigation-sub-menu;
  @include helper__transition(color);
  text-transform: capitalize;
  color: $color-ironside-gray;
  display: flex;
  align-items: center;

  @include helper__until($bp-1280) {
    flex-direction: row;
    justify-content: flex-start;
    font-size: 18px;
    padding-left: $unit-base * 3;
    padding-right: $unit-base * 3;
  }

  @include helper__greater($bp-1280) {
    flex-direction: column;
    justify-content: space-between;
    height: 100%;
  }

  &:hover {
    color: $color-delta;
  }
}

.submenu-icon {
  height: 100%;

  svg {
    @include helper__until($bp-1280) {
      width: 50px !important;
      height: 50px !important;
      margin-right: $unit-double;
    }

    @include helper__greater($bp-1280) {
      height: 130px;
      margin-top: $unit-double;
    }
  }
}

.submenu-label {
  @include helper__greater($bp-1280) {
    min-height: $unit-base * 6;
    flex-grow: 0;
    display: flex;
    align-items: center;
  }
}
</style>
