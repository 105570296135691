<template>
  <button
    v-if="!linkUrl || disabled"
    class="nolte-button nolte-button--button"
    :class="buttonClasses"
    :type="formSubmit ? 'submit' : 'button'"
    :disabled="disabled"
    @click="$emit('click')"
    v-ripple.mouseenter.500="rippleColor"
    :key="type"
  >
    <span class="nolte-button__inner">
      <span v-if="$slots.icon && iconAlign === 'left'" class="nolte-button__icon--left">
        <slot name="icon" />
      </span>
      <span class="nolte-button__content"><slot /></span>
      <span v-if="$slots.icon && iconAlign === 'right'" class="nolte-button__icon--right">
        <slot name="icon" />
      </span>
    </span>
  </button>

  <ContentLink
    v-else
    :editable="false"
    class="nolte-button"
    :class="buttonClasses"
    v-ripple.mouseenter.500="rippleColor"
    :key="type"
    :field="{
      value: { href: linkUrl, linktype: external ? 'external' : 'internal', target: target },
    }"
  >
    <span class="nolte-button__inner">
      <span v-if="$slots.icon && iconAlign === 'left'" class="nolte-button__icon--left">
        <slot name="icon" />
      </span>
      <span class="nolte-button__content"><slot /></span>
      <span v-if="$slots.icon && iconAlign === 'right'" class="nolte-button__icon--right">
        <slot name="icon" />
      </span>
    </span>
  </ContentLink>
</template>

<script>
import Ripple from 'vue-ripple-directive';
import ContentLink from '@/components/ContentLink';

Ripple.zIndex = 2;

import { colorCitrine } from '../../styles/variables.scss';
import { hexToRgb } from '../helper';

const types = [
  'yellow',
  'yellow--shadow',
  'yellow--plain',
  'grey--plain',
  'grey--outline',
  'white',
  'white--shadow',
  'white--outline',
  'outline-bright',
  'outline-dark',
  'link-bright',
  'link-dark',
];

const iconAligns = ['left', 'right'];

export default {
  name: 'NolteButton',

  components: {
    ContentLink,
  },

  directives: {
    ripple: Ripple,
  },

  props: {
    linkUrl: {
      type: String,
      default: '',
    },
    target: {
      type: String,
      default: '',
    },
    external: {
      type: Boolean,
      default: false,
    },
    formSubmit: {
      type: Boolean,
      default: false,
    },
    type: {
      type: String,
      default: 'yellow',
      validator(type) {
        return types.includes(type);
      },
    },
    iconAlign: {
      type: String,
      default: 'left',
      validator(align) {
        return iconAligns.includes(align);
      },
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    fullyDisabled: {
      type: Boolean,
      default: false,
    },
  },

  emits: ['click'],

  computed: {
    buttonClasses() {
      if (this.fullyDisabled) {
        return [
          'nolte-button--grey--plain',
          'nolte-button--disabled',
          {
            'nolte-button--icon': this.$slots.icon,
          },
        ];
      } else {
        return [
          `nolte-button--${this.type}`,
          {
            'nolte-button--disabled': this.disabled,
            'nolte-button--icon': this.$slots.icon,
          },
        ];
      }
    },

    rippleColor() {
      // only allow mouseenter ripple for yellow cta buttons
      if (!['yellow', 'yellow--shadow'].includes(this.type)) {
        return 'none';
      }

      const color = colorCitrine;
      const opacity = 0.5;
      const rgb = hexToRgb(color);
      if (!rgb) {
        return 'none';
      }

      const { r, g, b } = rgb;
      return `rgba(${r}, ${g}, ${b}, ${opacity})`;
    },
  },
};
</script>

<style lang="scss" scoped>
// must be defined before .nolte-button to enable overwriting
.nolte-button--button {
  @include helper__button;
}

.nolte-button {
  @include text-button;
  word-wrap: normal;
  @include helper__transition((color, border-color, background-color, opacity));
  color: $color-ironside-gray;
  display: inline-block;
  padding: 0;

  // Style when focus by keyboard interaction
  body[modality='keyboard'] &:focus {
    border-color: $color-azure !important;
  }
}

.nolte-button__inner {
  display: flex;
  justify-content: center;
  z-index: 3;
  position: relative;
}

.nolte-button__icon--left {
  margin-right: 4px;

  :deep() > * {
    vertical-align: text-bottom;
  }
}

.nolte-button__icon--right {
  margin-left: 4px;
}

.nolte-button--yellow,
.nolte-button--yellow--plain,
.nolte-button--yellow--shadow,
.nolte-button--grey--plain,
.nolte-button--grey--outline,
.nolte-button--white,
.nolte-button--white--outline,
.nolte-button--white--shadow,
.nolte-button--outline-bright,
.nolte-button--outline-dark {
  border: 2px solid $color-athens-gray;
  border-radius: $radius-form;
  padding: $unit-base;
  min-width: 200px;
  max-width: 320px;

  @include helper__greater($bp-320) {
    padding: $unit-base $unit-double;
  }
}

.nolte-button--link-bright,
.nolte-button--link-dark {
  position: relative;
  padding-bottom: 2px;

  &::before {
    @include helper__transition(background-color);
    content: '';
    display: block;
    position: absolute;
    width: 100%;
    height: 2px;
    bottom: 0px;
    background-color: currentColor;
  }

  &:hover::before {
    background-color: $color-citrine;
  }

  &::after {
    @include helper__transition(transform);
    content: '';
    display: block;
    position: absolute;
    width: 100%;
    height: 4px;
    bottom: -2px;
    background-color: $color-citrine;
    transform: scaleX(0);
    transform-origin: right;
  }

  &:active::after {
    transform: scaleX(1);
    transform-origin: left;
  }

  // Style when focus by keyboard interaction
  body[modality='keyboard'] &:focus::before,
  body[modality='keyboard'] &:focus::after {
    background-color: $color-azure;
  }
}

.nolte-button--yellow,
.nolte-button--yellow--plain,
.nolte-button--yellow--shadow {
  background-color: $color-aureolin;
  border-color: $color-citrine;

  &:hover {
    border-color: darken($color-citrine, 5%);
  }

  &.nolte-button--disabled {
    color: $color-citrine;
  }
}

.nolte-button--yellow--plain {
  @include text-h5;
  border-color: $color-aureolin;

  &.nolte-button--disabled {
    opacity: 0.5;
    color: $color-ironside-gray;
  }
}

.nolte-button--grey--plain {
  @include text-h5;
  background-color: $color-concret;
  border-color: $color-concret;

  &:hover {
    border-color: darken($color-athens-gray, 5%);
  }

  &.nolte-button--disabled {
    opacity: 0.5;
    color: $color-ironside-gray;
  }
}

.nolte-button--grey--outline {
  @include text-h5;
  border-color: $color-athens-gray;

  &:hover {
    border-color: $color-delta;
  }

  &.nolte-button--disabled {
    opacity: 0.5;
    color: $color-ironside-gray;
  }
}

.nolte-button--white,
.nolte-button--white--shadow {
  background-color: $color-white;

  &:hover {
    border-color: $color-ironside-gray;
  }

  &.nolte-button--disabled {
    color: $color-delta;
  }
}

.nolte-button--white,
.nolte-button--white--outline {
  border-color: $color-concret;

  span {
    color: $color-concret;
  }

  &:hover {
    background-color: rgba($color-concret, 0.2);
  }

  &.nolte-button--disabled {
    color: $color-delta;
  }
}

.nolte-button--outline-bright {
  border-color: $color-ironside-gray;

  &:hover {
    background-color: rgba($color-concret, 0.7);
  }

  &.nolte-button--disabled {
    border-color: $color-delta;
    color: $color-delta;
  }
}

.nolte-button--outline-dark {
  border-color: $color-delta;
  color: $color-delta;

  &:hover {
    background-color: rgba($color-concret, 0.7);
  }

  &.nolte-button--disabled {
    border-color: $color-ironside-gray;
    color: $color-ironside-gray;
  }
}

.nolte-button--link-bright {
  color: $color-ironside-gray;

  &.nolte-button--disabled {
    color: $color-delta;
  }
}

.nolte-button--link-dark {
  color: $color-delta;

  &.nolte-button--disabled {
    color: $color-ironside-gray;
  }
}

.nolte-button--yellow--shadow {
  box-shadow: 0px 30px 50px -10px rgba($color-ironside-gray, 0.6),
    0px 30px 50px -10px rgba($color-aureolin, 0.2);
}

.nolte-button--white--shadow {
  box-shadow: 0px 30px 50px -10px rgba($color-ironside-gray, 0.6);
}

.nolte-button--disabled {
  pointer-events: none;
}

.nolte-button--icon {
  text-align: left;
}
</style>
