<template>
  <main class="mnk-default">
    <Placeholder name="jss-mnk-stage" :rendering="route" />
    <!--
      The :key forces the re-rendering if there is new data from the layout service.
      Needed if you change the login status of the user.
     -->
    <Placeholder name="jss-mnk-main" :rendering="route" :key="Date.now()" />
    <Placeholder name="jss-dataprovider" :rendering="route" />
  </main>
</template>

<script>
import Placeholder from '@/components/Placeholder';
import LayoutMixin from './LayoutMixin';

export default {
  name: 'MNKDefault',
  mixins: [LayoutMixin],

  components: {
    Placeholder,
  },
};
</script>

<style lang="scss" scoped>
.mnk-default {
  background-color: $color-concret;
  background-image: url('../assets/catalog_hub_mobile_background.svg');
  background-size: 100%;
  background-position: top center;
  background-repeat: no-repeat;

  @include helper__greater($bp-768) {
    background-image: url('../assets/catalog_hub_background.svg');
  }
}
</style>
