<template>
  <div class="mnk-menu-loggedout">
    <GlobalForm :fields="loginFormDefinition" v-if="loginFormDefinition" />
    <div v-else>Menu data for MNK menu is missing.</div>

    <div class="button-seperator">
      {{ $t('mnk-or') }}
    </div>

    <NolteButton type="grey--plain" @click="registerUser">
      {{ $t('mnk-request-new-registration') }}
    </NolteButton>

    <p class="lost-password" v-html="lostPassword" />
  </div>
</template>

<script>
import { mapState, mapGetters, mapMutations } from 'vuex';

import NolteButton from '@/nolte-ui/NolteButton';
import GlobalForm from '@/components/GlobalForm';

export default {
  name: 'GlobalHeaderMNKMenuLoggedOut',

  components: {
    NolteButton,
    GlobalForm,
  },

  computed: {
    ...mapState('jss', ['sitecoreContext', 'routeData']),
    ...mapGetters({ actionLinks: 'getMnkActionLinks' }),

    mnkRootItem() {
      return this.sitecoreContext.site?.login || null;
    },

    loginFormDefinition() {
      if (!this.routeData?.placeholders['jss-mnk-menu'][0]) {
        return false;
      }
      return this.routeData.placeholders['jss-mnk-menu'][0].fields;
    },

    lostPassword() {
      return this.$t('mnk-request-new-password').replace(
        /###(.+?)###/,
        `<a href="${this.actionLinks['request-new-password']}">$1</a>`
      );
    },

    items() {
      // the passed children complemented by the root item of MNK
      if (!this.mnkRootItem) return [];
      if (!this.children) return [];
      return [{ ...this.mnkRootItem, linktype: 'internal' }, ...this.children];
    },
  },

  methods: {
    ...mapMutations(['setSearchMnkMenuVisibility']),

    registerUser() {
      this.setSearchMnkMenuVisibility(false);
      this.$router.push(this.actionLinks['request-new-registration']);
    },
  },

  mounted() {
    // link the "forgot password" text
    this.$el.querySelector('.lost-password').addEventListener('click', e => {
      e.preventDefault();
      if (e.target.matches('a')) {
        this.setSearchMnkMenuVisibility(false);
        this.$router.push(e.target.getAttribute('href'));
      }
    });
  },
};
</script>

<style lang="scss" scoped>
.mnk-menu-loggedout {
  margin: $header-height-mobile + $unit-base $unit-double;

  @include helper__greater($bp-320) {
    margin: $unit-double * 2;
  }
}

:deep(button) {
  width: 100%;
}

// overwrite GlobalForm specific elements
.mnk-menu-loggedout :deep(.global-form) {
  margin: 0;
  padding: 0;
  background: none;

  .form-field-type-copy .copy__content {
    @include text-h3;
    padding: 0;
    text-align: left;
    color: $color-ironside-gray;
  }

  .form-field-type-submit {
    height: auto !important;
    min-height: 0;
    margin-bottom: 0;

    button {
      box-shadow: none;
    }
  }

  .form-field-type-submit .nolte-form-wrapper__error-msg {
    margin: 0;
    min-height: 0;

    > div {
      margin: $unit-half 0 $unit-base;
    }
  }
}

.button-seperator {
  text-align: center;
  margin: $unit-half 0;
}

.lost-password {
  margin: $unit-base 0 0 0;

  :deep(a) {
    text-decoration: underline !important;
  }
}
</style>
