import eventBus from '@/lib/eventBus';

export default {
  data() {
    return {
      conditionOperators: {
        '{1D38B217-A2EE-4E7B-B6ED-13E751462FEB}': 'condition-is-equal-to',
        '{49F47E77-E8C5-46F9-BF39-78D6B0D40B48}': 'condition-is-not-equal-to',
        '{BF8935A6-1976-43A0-ABA5-D0BC128A76EA}': 'condition-contains',
        '{45AAB0FB-775B-40F5-B3B8-7CAE3ABBF643}': 'condition-does-not-contain',
        '{FD10F291-3C2E-4AE7-8A67-2F8271CB3DF2}': 'condition-starts-with',
        '{6B92597D-F2E0-47D3-A40D-59AFB37EEDE5}': 'condition-does-not-start-with',
        '{D375ED5B-E156-4A2B-9F91-DFD5B03F0D78}': 'condition-ends-with',
        '{F3AC7A1A-3458-4385-BB65-860315313DB3}': 'condition-does-not-end-with',
        '{61FF63A0-375C-47BD-9986-1F81BD12BBBB}': 'condition-is-greater-than',
        '{062C6ED9-EA6E-4A88-AE54-C88E2147971D}': 'condition-is-greater-than-or-equal-to',
        '{8FE41E53-AD87-4D24-B50F-EA0F6BDF739F}': 'condition-is-less-than',
        '{88AC1C6B-BAFE-40A7-BB75-E304C8EC29DD}': 'condition-is-less-than-or-equal-to',
      },
      conditionFunctions: {
        'condition-is-equal-to': (fieldValue, compareValue) => {
          // convert array and boolean to single string value
          if (typeof fieldValue === 'boolean' || Array.isArray(fieldValue)) {
            fieldValue = fieldValue.toString();
          }

          return fieldValue === compareValue;
        },
        'condition-is-not-equal-to': (fieldValue, compareValue) => {
          // convert array to single value
          if (typeof fieldValue === 'boolean' || Array.isArray(fieldValue)) {
            fieldValue = fieldValue.toString();
          }

          return fieldValue !== compareValue;
        },
        'condition-contains': (fieldValue, compareValue) => {
          return fieldValue.includes(compareValue);
        },
        'condition-does-not-contain': (fieldValue, compareValue) => {
          return !fieldValue.includes(compareValue);
        },
        'condition-starts-with': (fieldValue, compareValue) => {
          return fieldValue.startsWith(compareValue);
        },
        'condition-does-not-start-with': (fieldValue, compareValue) => {
          return !fieldValue.startsWith(compareValue);
        },
        'condition-ends-with': (fieldValue, compareValue) => {
          return fieldValue.endsWith(compareValue);
        },
        'condition-does-not-end-with': (fieldValue, compareValue) => {
          return !fieldValue.endsWith(compareValue);
        },
        'condition-is-greater-than': (fieldValue, compareValue) => {
          // the date construction also works with number fields
          return new Date(fieldValue).getTime() > new Date(compareValue).getTime();
        },
        'condition-is-greater-than-or-equal-to': (fieldValue, compareValue) => {
          // the date construction also works with number fields
          return new Date(fieldValue).getTime() >= new Date(compareValue).getTime();
        },
        'condition-is-less-than': (fieldValue, compareValue) => {
          // the date construction also works with number fields
          return new Date(fieldValue).getTime() < new Date(compareValue).getTime();
        },
        'condition-is-less-than-or-equal-to': (fieldValue, compareValue) => {
          // the date construction also works with number fields
          return new Date(fieldValue).getTime() <= new Date(compareValue).getTime();
        },
      },
      actionTypes: {
        '{5744A87E-E32C-42CC-862F-96842A0202BB}': 'action-enable',
        '{C698C993-549E-486A-A09C-BB8D830DA958}': 'action-disable',
        '{AAE07A52-46A4-49EF-98B0-C2595BAC2382}': 'action-show',
        '{7F58C8DD-D7C0-4FB7-BB44-8EC6B5E1C3D9}': 'action-hide',
        '{4E448D57-BA06-42DC-9519-6BCD102CB332}': 'action-go-to-page',
      },
      conditionComponents: {},
    };
  },

  beforeUnmount() {
    eventBus.$emit('PageConditionMixin:beforeUnmount');
  },

  created() {
    // all field components send their instance if they are mounted so we have an overview of all field components
    eventBus.$on(this.formId + ':conditions:register-component', (fieldKey, component) => {
      //console.log('PageCondition eventBus.on: ' + this.formId + ':conditions:register-component');
      this.conditionComponents[fieldKey] = component;
    });
  },

  mounted() {
    // we generate one list of all conditions because they are not field specific
    // so you are able to put your condition on any field (seems not to be a good design decision from SC)
    const conditionSettings = this.extractConditionSettings(this.page.fields);

    // process them initially ...
    this.processConditions(conditionSettings);

    // ... and when a field requests to trigger it
    eventBus.$on(this.formId + ':conditions:process', () => {
      //console.log('PageConditionMixin eventBus.on: ' + this.formId + ':conditions:process');
      this.processConditions(conditionSettings);
    });
  },

  methods: {
    extractConditionSettings(fields) {
      let conditionSettings = [];

      fields.forEach(field => {
        conditionSettings = [
          ...conditionSettings,
          ...field.model.conditionSettings.fieldConditions,
        ];

        if (field.fields) {
          const conditionSettings2 = this.extractConditionSettings(field.fields);
          conditionSettings = [...conditionSettings, ...conditionSettings2];
        }
      });

      return conditionSettings;
    },

    processConditions(conditionSettings) {
      // iterate all condition sets
      conditionSettings.forEach(setting => {
        // interate all conditions of a set
        // if all checks return true we can process the action
        let checkResult = true;
        setting.conditions.forEach(condition => {
          const $component = this.conditionComponents[condition.fieldId];
          const operator = this.conditionOperators[condition.operatorId];
          const compareValue = condition?.value;
          const fieldValue = $component?.value;

          const check = this.conditionFunctions[operator](fieldValue, compareValue);
          if (check === false) {
            checkResult = false;
          }
        });

        // if the conditions succeded all we should now process the actions
        if (checkResult) {
          this.processActions(setting.actions);
        }
      });
    },

    processActions(actions) {
      actions.forEach(action => {
        const $component = this.conditionComponents[action.fieldId];
        const actionType = this.actionTypes[action.actionTypeId];

        if ($component !== null && actionType !== null) {
          if (actionType === 'action-enable') {
            $component.disabled = false;
          } else if (actionType === 'action-disable') {
            $component.disabled = true;
          } else if (actionType === 'action-show') {
            $component.hidden = false;
          } else if (actionType === 'action-hide') {
            $component.hidden = true;
          }
        }
      });
    },
  },
};
