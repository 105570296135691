<template>
  <nav
    class="nolte-kitchen-talk-menu"
    :class="{
      'nolte-kitchen-talk-menu--is-mobile': isMobile,
    }"
  >
    <component
      :is="isMobile ? 'NolteScroller' : 'div'"
      :scroller-el="isMobile ? 'div' : undefined"
      :shadow-only="isMobile ? '' : undefined"
      class="nolte-kitchen-talk-menu__scroller"
    >
      <div class="nolte-kitchen-talk-menu__scroller-inner">
        <div class="nolte-kitchen-talk-menu__categories-wrapper">
          <NolteKitchenTalkMenuCategories
            v-model="categoriesOptionIndex"
            :categories="categories"
            class="nolte-kitchen-talk-menu__categories"
            ref="nolteKitchenTalkCategories"
          />
        </div>
      </div>
    </component>
  </nav>
</template>

<script>
import NolteKitchenTalkMenuCategories from './NolteKitchenTalkMenuCategories';
import NolteScroller from '../NolteScroller';
import eventBus from '@/lib/eventBus';

export default {
  name: 'NolteKitchenTalkMenu',

  components: {
    NolteKitchenTalkMenuCategories,
    NolteScroller,
  },

  props: {
    categories: {
      type: Array,
      required: true,
    },
    isMobile: {
      type: Boolean,
      default: false,
    },
  },

  data() {
    return {
      categoriesOptionIndex: undefined,
      categoriesHeight: 0,
      categoriesOffsetLeft: 0,
    };
  },

  beforeUnmount() {
    eventBus.$emit('hook:beforeUnmount');
  },

  mounted() {
    document.addEventListener('scroll', this.handleScroll.bind(this));

    eventBus.$once('hook:beforeUnmount', () => {
      document.removeEventListener('scroll', this.handleScroll.bind(this));
    });
  },

  methods: {
    handleScroll() {
      requestAnimationFrame(() => {
        this.categoriesOptionIndex = undefined;
      });
    },
  },

  watch: {
    categoriesOptionIndex(categoriesOptionIndex) {
      if (categoriesOptionIndex !== undefined && this.$refs?.nolteKitchenTalkCategories) {
        const { height, left } =
          this.$refs?.nolteKitchenTalkCategories?.$el?.getBoundingClientRect();

        this.categoriesHeight = height;
        this.categoriesOffsetLeft = left;
      } else {
        this.categoriesHeight = 0;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
@mixin box-shadow() {
  box-shadow: 0 25px 25px 0 rgba($color-black, 0.1);
}

.nolte-kitchen-talk-menu {
  position: relative;
  white-space: nowrap;
  z-index: 2;
}

.nolte-kitchen-talk-menu__scroller-inner {
  padding: 0 $unit-base;
  display: flex;
  align-items: flex-start;
  width: auto;

  @include helper__greater($bp-768) {
    padding-left: $unit-base * 5;
  }
}

.nolte-kitchen-talk-menu__categories-wrapper {
  position: relative;
}

.nolte-kitchen-talk-menu__categories {
  position: relative;

  &::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    @include box-shadow;
    z-index: -1;
    border-radius: $radius-default;
  }
}
</style>
