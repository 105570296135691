<template>
  <span
    v-if="lightboxImages.length"
    class="content-lightbox__link"
    :title="lightboxTitle"
    @click.prevent="openImage"
  >
    <slot />
  </span>

  <span v-else-if="$slots.content" @click="openContent">
    <slot />

    <div class="slbElement slbContentEl" ref="content-lightbox__content" :class="overlayClass">
      <div class="slbOverlay" />

      <div class="slbWrapOuter" @click.stop="closeContent">
        <div class="slbWrap">
          <div class="slbContentOuter">
            <div class="slbContent" @click.stop>
              <div v-if="!closeOnContentClick" class="customContent">
                <slot name="content" :close="closeContent" v-if="isVisible" />
              </div>
              <div v-else class="customContent" @click="closeObserver($event)">
                <slot name="content" :close="closeContent" v-if="isVisible" />
              </div>
            </div>
            <button type="button" class="slbCloseBtn" @click="closeContent">×</button>
          </div>
        </div>
      </div>
    </div>
  </span>

  <div
    v-else
    class="slbElement slbContentEl"
    :class="[visible ? 'slbContentEl--visible' : '', overlayClass]"
    ref="content-lightbox__content"
  >
    <div class="slbOverlay" />

    <div class="slbWrapOuter" @click.stop="$emit('close')">
      <div class="slbWrap">
        <div class="slbContentOuter">
          <div class="slbContent" @click.stop>
            <div class="customContent">
              <slot />
            </div>
          </div>
          <button type="button" class="slbCloseBtn" @click="$emit('close')">×</button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { imageHasSource, generateScaling } from '@/components/helper';
import { mapGetters } from 'vuex';
import SimpleLightbox from 'simple-lightbox';
import 'simple-lightbox/dist/simpleLightbox.min.css'; // http://dbrekalo.github.io/simpleLightbox/

/*
This component can handle three possibilites:

Images Galleries (uses simpleLightbox)
======================================

Prop "images" is an array of URL strings or JSS image objects

<ContentLightbox :images="[image-url1, image-url2]">
  <div>I am the trigger</div>
<ContentLightbox>


Custom Content (uses styling of simpleLightbox)
===============================================

The "simpleLightbox" expects an HTML string to be passed for custom content that is cloned and
injected into the DOM. But then Vue does not work anymore within the HTML.
Thus we use a custom implementation for custom content where we don't move the content but we keep
and use all styles of simpleLightbox.

<ContentLightbox>
  <div>I am the trigger</div>

  <template #content>
    <div>I am the Lightbox content</div>

    // use the data-close attribute for custom close buttons
    <div data-close>I can close the Lightbox</div>
  </template>
<ContentLightbox>


Custom Content without trigger (uses styling of simpleLightbox)
===============================================================

<ContentLightbox
  @open="setVisible(true)"
  @close="setVisible(false)"
  :visible="visible"
>
  <div>I am the trigger</div>

  <template #content>
    <div>I am the Lightbox content</div>

    <div @click="setVisible(false)">I can close the Lightbox</div>
  </template>
<ContentLightbox>
*/

export default {
  name: 'ContentLightbox',
  data() {
    return {
      isVisible: {
        type: Boolean,
        default: false,
      },
    };
  },
  props: {
    // an array of SC image objects or plain image URLs
    images: {
      type: Array,
      default: null,
    },
    visible: {
      type: Boolean,
      default: false,
    },
    overlayClass: {
      type: String,
      default: '',
    },
    closeOnContentClick: {
      type: Boolean,
      default: false,
    },
  },

  computed: {
    ...mapGetters(['isMobile']),

    lightboxImages() {
      if (!this.images) return [];
      const images = [];

      this.images.forEach(img => {
        if (imageHasSource(img)) {
          images.push(this.generateScaling(img.value.src, this.isMobile ? 1024 : 1920, true));
        } else if (typeof img === 'string' && img.match(/^https?:\/\//)) {
          images.push(img);
        }
      });
      return images;
    },

    lightboxTitle() {
      return '';
      // comment in if you want to show the image title
      // return this.image.value?.alt;
    },
  },

  methods: {
    imageHasSource,
    generateScaling,

    openImage() {
      SimpleLightbox.open({
        items: this.lightboxImages,
        loadingCaption: '',
      });
    },

    openContent() {
      this.$refs['content-lightbox__content'].classList.add('slbContentEl--visible');
      // eslint-disable-next-line
      this.isVisible = true;
    },

    closeContent() {
      this.$refs['content-lightbox__content'].classList.remove('slbContentEl--visible');
      // eslint-disable-next-line
      this.isVisible = false;
    },

    // checks if an item with attribute "data-close" was clicked inside the overlay
    // then it closes the overlay
    closeObserver(e) {
      if (
        e.target.dataset.close !== undefined ||
        e.target.closest('[data-close]').dataset.close !== undefined
      ) {
        this.closeContent();
      }
    },
  },
  mounted() {
    this.isVisible = this.visible;
  },
};
</script>

<style lang="scss">
.content-lightbox__link {
  cursor: url('../assets/icons/IconZoom.svg'), zoom-in;
}

.slbContentEl {
  display: none;
  &--visible {
    display: block;
  }
}

.slbContentEl {
  .customContent {
    background: $color-white;
    max-width: 800px;
    padding: $unit-double;

    h1,
    h2,
    h3,
    h4,
    p {
      margin: $unit-base 0;
    }
  }
}
</style>
