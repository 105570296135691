<template>
  <main class="category">
    <Placeholder name="jss-category-header" :rendering="route" />
    <Placeholder name="jss-category-main" :rendering="route" />
    <Placeholder name="jss-dataprovider" :rendering="route" />
  </main>
</template>

<script>
import Placeholder from '@/components/Placeholder';
import LayoutMixin from './LayoutMixin';

export default {
  name: 'CategoryDetail',

  mixins: [LayoutMixin],

  components: {
    Placeholder,
  },
};
</script>

<style lang="scss" scoped>
.category {
  background-color: $color-concret;
}

.introduction {
  display: flex;
  padding-top: $unit-double;
  padding-bottom: $unit-double;
}

.headline {
  width: 25%;
  @include text-h2;
  color: $color-ironside-gray;
}

.copy {
  flex: 1 0 auto;
  @include text-copy;
  color: $color-ironside-gray;
  max-width: 400px;
  margin-right: auto;
}
</style>
