<template>
  <div class="editorial-neo-col-media">
    <ContentLightbox :images="[fields.image]">
      <EditorialImage
        :media="fields.image"
        default-aspect-ratio="3:2"
        default-sizes="(min-width: 1280px) 500px, (min-width: 768px) 1024px, 100vw"
      />
    </ContentLightbox>

    <sc-text
      v-if="fields.headline || isEditing"
      class="headline"
      :field="fields.headline"
      tag="h2"
    />

    <sc-text v-if="fields.copy || isEditing" class="copy" :field="fields.copy" tag="p" />

    <EditorialButton
      v-if="linkHasValue(fields['CTA']) || isEditing"
      class="cta"
      :fields="{
        link: fields['CTA'],
      }"
    />
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import { Text } from '@sitecore-jss/sitecore-jss-vue';
import { textHasValue, imageHasSource, linkHasValue } from '@/components/helper';

import EditorialImage from '@/components/EditorialImage';
import EditorialButton from '@/components/EditorialButton';
import ContentLightbox from '@/components/ContentLightbox';

export default {
  name: 'EditorialNeoColMedia',

  components: {
    ScText: Text,
    EditorialImage,
    EditorialButton,
    ContentLightbox,
  },

  computed: {
    ...mapGetters('jss', ['isEditing']),
  },

  methods: {
    textHasValue,
    imageHasSource,
    linkHasValue,
  },
};
</script>

<style lang="scss" scoped>
.editorial-neo-col-media {
  flex: 0 0 100%;
  display: flex;
  flex-direction: column;
  box-sizing: border-box;

  @include helper__greater($bp-768) {
    flex: 0 0 calc((100% / 2) - 1 / 2 * #{$unit-double});
  }

  @include helper__greater($bp-1280) {
    flex: 0 0 calc((100% / 3) - 2 / 3 * #{$unit-quadruple});
  }
}

.headline {
  @include text-h3;
  padding: $unit-base $unit-base 0;

  @include helper__greater($bp-768) {
    font-size: 28px;
  }
}
.copy {
  padding: $unit-base $unit-base 0;
}
.cta {
  margin-top: auto;
  padding: $unit-base $unit-base 0;

  :deep() {
    .editorial-button-wrapper {
      margin: $unit-base 0 0 0;
      padding: 0;
    }
  }
}
</style>
