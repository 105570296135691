<template>
  <main class="catalog">
    <Placeholder name="jss-catalog-header" :rendering="route" />
    <Placeholder name="jss-catalog-main" :rendering="route" />
    <Placeholder name="jss-dataprovider" :rendering="route" />
  </main>
</template>

<script>
import Placeholder from '@/components/Placeholder';
import LayoutMixin from './LayoutMixin';

export default {
  name: 'Catalog',

  mixins: [LayoutMixin],

  components: {
    Placeholder,
  },
};
</script>

<style lang="scss" scoped>
.catalog {
  background-color: $color-concret;
}
</style>
