<template>
  <v-field-template
    name="passwordconfirm"
    :field="field"
    :hidden="hidden"
    :disabled="disabled"
    :errors="errors"
  >
    <template #field>
      <div class="form-field-element-item">
        <NolteFormWrapper :errors="errors" #default="{ hasError }">
          <NolteFormInput
            type="password"
            :id="field.valueField.id"
            :name="field.valueField.name"
            :placeholder="field.model.placeholderText"
            :required="field.model.required"
            :minlength="field.model.minLength"
            :maxlength="field.model.maxLength"
            :disabled="disabled"
            autocomplete="new-password"
            v-model="value"
            @blur="fieldBlur"
            :invalid="hasError"
          />
        </NolteFormWrapper>
      </div>

      <div class="form-field-element-item">
        <NolteFormWrapper :errors="errors" #default="{ hasError }">
          <NolteFormLabel
            :for="'id-' + field.model.itemId + '-confirm'"
            :label="field.model.confirmPasswordLabel"
            :required="field.model.required"
          />

          <NolteFormInput
            type="password"
            :id="'id-' + field.model.itemId + '-confirm'"
            :name="field.valueField.name.replace(/\.Value$/, '.ConfirmPassword')"
            :placeholder="field.model.confirmPasswordPlaceholder"
            :required="field.model.required"
            :disabled="disabled"
            autocomplete="new-password"
            :invalid="hasError"
          />
        </NolteFormWrapper>
      </div>
    </template>
  </v-field-template>
</template>

<script>
import FieldMixin from '../structure/FieldMixin';
import FieldConditionMixin from '../structure/conditions/FieldConditionMixin';
import FieldValidationMixin from '../structure/validation/FieldValidationMixin';

import NolteFormInput from '../../nolte-ui/NolteForm/NolteFormInput';
import NolteFormLabel from '../../nolte-ui/NolteForm/NolteFormLabel';
import NolteFormWrapper from '../../nolte-ui/NolteForm/NolteFormWrapper';

export default {
  name: 'SitecoreFormsFieldsPasswordconfirm',

  components: {
    NolteFormInput,
    NolteFormLabel,
    NolteFormWrapper,
  },

  mixins: [FieldMixin, FieldConditionMixin, FieldValidationMixin],

  // errors prop is defined in FieldValidationMixin
  data() {
    return {
      value: this.field.model.value,
    };
  },
};
</script>
