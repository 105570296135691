<template>
  <NolteHighlightGroup class="editorial-highlight-group" :class="{ stack: isEditing }">
    <!--<component v-for="(tab, index) in groupPlaceholder" :is="tab" :key="`tab${index}`" />-->
    <sc-placeholder name="nolte-highlight-group_default" :rendering="rendering" />
  </NolteHighlightGroup>
</template>

<script>
import { mapGetters } from 'vuex';
import NolteHighlightGroup from '../nolte-ui/NolteHighlightGroup';
import { Placeholder } from '@sitecore-jss/sitecore-jss-vue';

export default {
  name: 'EditorialHighlightGroup',

  components: {
    NolteHighlightGroup,
    ScPlaceholder: Placeholder,
  },

  props: {
    rendering: {
      type: Object,
      default: undefined,
    },
  },

  computed: {
    ...mapGetters('jss', ['isEditing']),
  },

  /*withPlaceholder: {
    placeholders: [
      {
        placeholder: 'nolte-highlight-group_default',
        computedPropName: 'groupPlaceholder',
      },
    ],
  },*/
};
</script>

<style lang="scss" scoped>
.editorial-highlight-group.stack {
  max-width: 100%;

  &:deep(.swiper-slide) {
    max-width: 100%;
    flex-direction: column;
  }
}
</style>
