<template>
  <ul
    class="nolte-catalog-menu-tabs"
    :class="{
      'nolte-catalog-menu-tabs--selected': selectedIndex !== undefined,
      'nolte-catalog-menu-tabs--expanded': expanded,
    }"
  >
    <li
      class="nolte-catalog-menu-tabs__tab"
      :class="{
        'nolte-catalog-menu-tabs__tab--selected': index === selectedIndex,
      }"
      v-for="({ label, title }, index) in links"
      :key="`nolte-catalog-menu-tabs-${index}`"
    >
      <button
        type="button"
        class="nolte-catalog-menu-tabs__button"
        :title="title"
        @click="$emit('select', index)"
        v-touch-hover.mobileFlag
      >
        <div class="nolte-catalog-menu-tabs__indicator">{{ label }}</div>
      </button>
    </li>
  </ul>
</template>

<script>
import { touchHover } from '../directives';

export default {
  name: 'NolteCatalogMenuTabs',

  directives: {
    touchHover,
  },

  props: {
    links: {
      type: Array,
      required: true,
    },
    selectedIndex: {
      type: Number,
      default: undefined,
    },
    expanded: {
      type: Boolean,
      default: false,
    },
  },
};
</script>

<style lang="scss" scoped>
.nolte-catalog-menu-tabs {
  display: inline-flex;
}

.nolte-catalog-menu-tabs__button {
  @include helper__transition((padding, color));
  @include helper__button;
  @include text-h5;
  padding: $unit-base + $unit-half $unit-base;

  /**
   * Directive touchHover has the modifier "mobileFlag" which adds the event touchstart
   * to the element. After this event gets triggered, the data-attribute data-touch-hover--mobile
   * will set. This helps in combination with :not to prevent :hover from staying active after
   * the element got touched.
   */
  &:not([data-touch-hover--mobile]):hover,
  &[data-touch-hover] {
    color: $color-delta;
  }
}

.nolte-catalog-menu-tabs--expanded .nolte-catalog-menu-tabs__button {
  padding-bottom: $unit-base * 2;
}

.nolte-catalog-menu-tabs__tab:first-of-type .nolte-catalog-menu-tabs__button {
  padding-left: $unit-base * 2;
}

.nolte-catalog-menu-tabs__tab:last-of-type .nolte-catalog-menu-tabs__button {
  padding-right: $unit-base * 2;
}

.nolte-catalog-menu-tabs__indicator {
  position: relative;

  &::before {
    @include helper__transition((transform, background-color, bottom));
    content: '';
    position: absolute;
    background-color: $color-ironside-gray;
    height: $unit-half;
    width: 100%;
    left: 0;
    bottom: $unit-base * -1.5;
    // rotate(0.01deg) avoids jitter in Chrome
    // https://greensock.com/forums/topic/16385-chrome-bug-when-i-scale-an-element-with-background-image-the-image-flickers/
    transform: scaleY(0) rotate(0.01deg);
    // Fix for Chrome breaks Firefox. Need another solution to avoid jitter in Firefox
    @include helper__composited-layer;
    transform-origin: bottom;
  }
}

.nolte-catalog-menu-tabs--expanded .nolte-catalog-menu-tabs__indicator::before {
  bottom: $unit-base * -2;
}

.nolte-catalog-menu-tabs__tab--selected {
  .nolte-catalog-menu-tabs__indicator::before {
    transform: scaleY(1);
  }
}
</style>
