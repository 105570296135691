<template>
  <div class="mnk-kitchenplans-gallery">
    <button
      v-if="isSlider"
      class="mnk-kitchenplans-gallery__swiper-navigation-left"
      ref="swiperNavigationLeft"
    >
      <IconArrowLeft />
    </button>
    <div class="mnk-kitchenplans-gallery__swiper" v-if="isSlider">
      <div class="swiper-container" ref="swiperContainer">
        <div class="swiper-wrapper">
          <div v-for="(image, index) in images" :key="`image_${index}`" class="swiper-slide">
            <EditorialImage
              class="swiper-slide__image"
              :media="{ src: image }"
              :editable="false"
              :keep-query-parameters="true"
            />
          </div>
        </div>
      </div>
    </div>
    <button
      v-if="isSlider"
      class="mnk-kitchenplans-gallery__swiper-navigation-right"
      ref="swiperNavigationRight"
    >
      <IconArrowRight />
    </button>
  </div>
</template>

<script>
import Swiper from 'swiper';
import { unitHalf } from '@/styles/variables.scss';
import IconArrowLeft from '@/assets/icons/IconArrowLeft.svg?inline';
import IconArrowRight from '@/assets/icons/IconArrowRight.svg?inline';
import EditorialImage from '@/components/EditorialImage';
import eventBus from '@/lib/eventBus';

export default {
  name: 'EditorialMNKKitchenPlansGallery',
  props: {
    images: {
      type: Array,
      default: null,
    },
  },

  components: {
    IconArrowLeft,
    IconArrowRight,
    EditorialImage,
  },

  data() {
    return {
      swiperInitialized: false,
    };
  },

  computed: {
    isSlider() {
      return this.images.length > 1;
    },
  },

  mounted() {
    // Register watcher dynamically in mounted because before
    // this.$refs is not fulfilled
    this.$watch(
      'isSlider',
      isSlider => {
        if (isSlider) {
          this.initSwiper();
        }
      },
      { immediate: true }
    );
  },

  beforeUnmount() {
    eventBus.$emit('EditorialMNKKitchenPlansGallery:beforeUnmount');
  },

  methods: {
    initSwiper() {
      if (this.swiperInitialized) {
        return;
      }

      this.swiperInitialized = true;

      const swiper = new Swiper(this.$refs?.swiperContainer, {
        grabCursor: true,
        spaceBetween: parseInt(unitHalf, 10),
        pagination: {
          el: this.$refs?.swiperPagination,
          clickable: true,
        },
        navigation: {
          prevEl: this.$refs?.swiperNavigationLeft,
          nextEl: this.$refs?.swiperNavigationRight,
        },
        dynamicBullets: false,
        iOSEdgeSwipeDetection: true,
        loop: true,
        keyboard: true,
      });

      eventBus.$once('EditorialMNKKitchenPlansGallery:beforeUnmount', () => {
        swiper.destroy();
      });
    },
  },
};
</script>

<style lang="scss" scoped>
@import '~swiper/swiper.scss';
@import '../styles/swiper-styles.scss';

.mnk-kitchenplans-gallery {
  position: relative;
  z-index: 0;
  @include spacing;

  display: flex;
  align-items: center;
  justify-content: center;
}

.mnk-kitchenplans-gallery__swiper {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  position: relative;
  z-index: -1;
}

.mnk-kitchenplans-gallery__swiper-navigation-left,
.mnk-kitchenplans-gallery__swiper-navigation-right {
  @include helper__button;
  @include helper__transition(color);
  width: $unit-base * 4;
  height: $unit-base * 4;
  background-color: $color-white;
  border-radius: 50%;
  color: $color-delta;
  flex-shrink: 0;
  margin-left: (-1) * $unit-double;
  margin-right: (-1) * $unit-double;
  position: relative;
  z-index: 1;
  box-shadow: 0 0 50px rgba($color-black, 0.2);

  &:hover {
    color: $color-ironside-gray;
  }

  @include helper__until($bp-1024) {
    display: none;
  }
}

.mnk-kitchenplans-gallery__detail-container2 {
  @include detail-container;
  z-index: 1;
}

.swiper-container {
  position: relative;
  width: 100%;

  .swiper-slide {
    @include helper__aspect-ratio(16/10);

    &::after {
      width: 100vw;
    }

    & > * {
      height: 100%;
    }
  }
}

:deep(.editorial-image--fit-cover img) {
  object-fit: contain;
  font-family: 'object-fit: contain;';
}
</style>
