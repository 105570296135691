<template>
  <div class="editorial-mnk-moodboard">
    <ContentPillLinks v-if="moodboardHasEntries && isAuthenticated">
      <ContentLink
        v-if="linkHasValue(fields['Overview Data Send to Retailer Link'])"
        :field="fields['Overview Data Send to Retailer Link']"
      />
    </ContentPillLinks>
    <div v-if="feed.length > 0">
      <NolteGrid>
        <NolteGridCell
          v-for="(component, index) in feedLimited"
          :key="`feed-item_${index}`"
          :type="component.aspectRatio.attribute"
        >
          <ContentFavorite :item-id="component.itemId" :image-id="component.image.itemId">
            <NolteTeaser
              :link-url="component.link"
              :image="component.image"
              :headline="component.headline"
              :copy="component.copy"
              :aspect-ratio="component.aspectRatio"
            />
          </ContentFavorite>
        </NolteGridCell>
      </NolteGrid>

      <div class="editorial-mnk-moodboard__button">
        <NolteButton type="yellow--shadow" v-if="feed.length > visible" @click="loadNextBatch">
          <template #icon><IconArrowDown /></template>
          {{ $t('cataloghub-discover-more') }}
        </NolteButton>
      </div>
    </div>
  </div>
</template>

<script>
import { linkHasValue } from './helper';
import { mapGetters, mapActions, mapState } from 'vuex';
import { textHasValue, processGrid } from './helper';
import NolteGrid from '../nolte-ui/NolteGrid';
import NolteGridCell from '../nolte-ui/NolteGrid/NolteGridCell';
import NolteTeaser from '../nolte-ui/NolteTeaser';
import NolteButton from '../nolte-ui/NolteButton';
import IconArrowDown from '../assets/icons/IconArrowDown.svg?inline';
import ContentFavorite from '@/components/ContentFavorite.vue';
import ContentPillLinks from '@/components/ContentPillLinks';
import ContentLink from '@/components/ContentLink';

// TODO: Komponente "EditorialMNKMoodboard" muss erstellt werden
//        injection aus store/jss.js entfernen
// TODO: created wird im Moment doppelt aufgerufen, warum?
//       Das liegt am :key in MNKDefaultPage, welches wiederum eingebaut wurde, damit sich die Teaser auf der MNK-Startseite bei Logout aktualisieren
// TODO: GraphQL für EditorialFeed habe ich selbst angepasst
//       siehe src\constants\graphql\EditorialFeed.apollo.graphql
// TODO: Globales Ausschalten von ContentFavorite ermöglichen (siehe this.enabled)

export default {
  name: 'EditorialMNKMoodboard',

  components: {
    NolteGrid,
    NolteGridCell,
    NolteTeaser,
    NolteButton,
    IconArrowDown,
    ContentFavorite,
    ContentPillLinks,
    ContentLink,
  },

  data: () => ({
    feed: [],
    batchSize: 10,
    visible: 0,
  }),

  created() {
    // request the initial state of the data
    if (!this.isEditing) {
      this.getMoodboardDataIfNotDoneAlready().then(data => {
        this.feed = this.processRawDataForFeed(data);
      });

      // Show the next {batchSize} items
      this.loadNextBatch();
    }
  },

  computed: {
    ...mapGetters('jss', ['isEditing']),
    ...mapGetters(['getTeaserFallbackImage']),
    ...mapGetters('moodboard', { moodboardHasEntries: 'hasEntries' }),
    ...mapState(['user']),

    feedLimited() {
      return this.feed.slice(0, this.visible);
    },

    isAuthenticated() {
      return this.user !== null;
    },
  },

  methods: {
    ...mapActions('moodboard', { getMoodboardDataIfNotDoneAlready: 'getItems' }),

    processGrid,
    textHasValue,
    linkHasValue,

    debug(debugData) {
      // for debugging purposes we duplicate the data
      return debugData.concat(debugData);
    },

    loadNextBatch() {
      this.visible += this.batchSize;
    },

    processRawDataForFeed(rawData) {
      let processedData = [];

      rawData?.forEach(item => {
        processedData.push({
          type: 'Teaser',
          itemId: item.itemId,
          created: item.created,
          image: item.image,
          headline: item.source === 'mood' ? this.$t('cataloghub-planning-example') : item.category,
          copy: item.label,
          link: item.link,
        });
      });

      // sort by creation date
      processedData.sort((a, b) => {
        return a.created.localeCompare(b.created);
      });

      return this.processGrid(processedData);
    },
  },
};
</script>

<style lang="scss" scoped>
.editorial-mnk-moodboard {
  margin-bottom: $unit-base * 5;

  &:last-of-type {
    margin-bottom: 0;
  }

  // Fixes this behaviour in Chrome: https://support.google.com/chrome/thread/62861011?hl=en
  overflow-anchor: none;
}

.editorial-mnk-moodboard__button {
  padding: $unit-double * 2 0;
  text-align: center;
}
</style>

<style lang="scss">
.mnk-default {
  @include helper__greater($bp-768) {
    min-height: 100vh;
  }
}
</style>
