<template>
  <v-field-template
    name="listbox"
    :field="field"
    :hidden="hidden"
    :disabled="disabled"
    :errors="errors"
  >
    <template #field>
      <select
        :id="field.valueField.id"
        :name="field.valueField.name"
        :required="field.model.required"
        :size="field.model.rows"
        :multiple="field.model.multipleSelection"
        :disabled="disabled"
        v-model="value"
        @blur="fieldBlur"
      >
        <option v-if="field.model.showEmptyItem"></option>
        <option
          v-for="(item, index) in field.model.items"
          :key="index"
          :value="item.value"
          :selected="item.selected"
        >
          {{ item.text }}
        </option>
      </select>
    </template>
  </v-field-template>
</template>

<script>
import FieldMixin from '../structure/FieldMixin';
import FieldConditionMixin from '../structure/conditions/FieldConditionMixin';
import FieldValidationMixin from '../structure/validation/FieldValidationMixin';

export default {
  name: 'SitecoreFormsFieldsListbox',
  mixins: [FieldMixin, FieldConditionMixin, FieldValidationMixin],
  data() {
    return {
      value: this.field.model.multipleSelection
        ? this.field.model.items.find(item => item.selected)
          ? this.field.model.items.filter(item => item.selected).map(item => item.value)
          : []
        : this.field.model.items.find(item => item.selected)
        ? this.field.model.items.find(item => item.selected).value
        : '',
    };
  },
};
</script>
