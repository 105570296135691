<template>
  <div class="editorial-international-project-header">
    <div class="header-image-container">
      <EditorialImage
        class="header-image"
        :media="headerImage"
        :sources="{
          '(min-width: 769px)': '21:9',
        }"
        default-aspect-ratio="16:10"
      />
    </div>

    <section class="introduction" v-if="datasource && region">
      <div class="introduction__container">
        <div class="introduction__header">
          <ContentLink
            :editable="false"
            class="breadcrumb"
            :field="{ value: { text: region.text, href: region.href, linktype: 'internal' } }"
          />
          <EditorialHeadline
            class="headline"
            :fields="{ headline: datasource.headline }"
            headline-level="h2"
          />
          <sc-text class="location" :field="datasource.location" tag="p" />
        </div>
        <sc-text class="copy" :field="datasource.copy" tag="p" />
      </div>
    </section>
  </div>
</template>

<script>
import { Text } from '@sitecore-jss/sitecore-jss-vue';

import { flattenGraphqlFields } from './helper';

import EditorialHeadline from './EditorialHeadline';
import EditorialImage from '@/components/EditorialImage';
import ContentLink from '@/components/ContentLink';

export default {
  name: 'EditorialInternationalProjectHeader',

  components: {
    ScText: Text,
    EditorialHeadline,
    EditorialImage,
    ContentLink,
  },

  computed: {
    region() {
      // simulate a link field
      return {
        href: this.fields?.data?.project?.parent?.url,
        text: this.fields?.data?.project?.parent?.navigationTitle?.value,
      };
    },

    datasource() {
      return flattenGraphqlFields(this.fields?.data?.datasource?.fields);
    },

    headerImage() {
      // check if a headerImage was passed via fields otherwise use the headerImage of the route definition
      return this.datasource?.headerImage?.value?.src
        ? this.datasource?.headerImage
        : this.fields?.data?.headerImage?.headerImage?.jss;
    },
  },
};
</script>

<style lang="scss" scoped>
.header-image-container {
  position: relative;
  @include helper__aspect-ratio(16 / 10);

  @include helper__greater($bp-768) {
    @include helper__aspect-ratio(21 / 9);
  }
}

.header-image {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
}

.introduction {
  @include spacing;
  @include helper__greater($bp-768) {
    margin: $unit-double * 2 auto $unit-double * 4;
  }
}

.introduction__header {
  margin-bottom: $unit-half * 3;

  @include helper__greater($bp-768) {
    margin-bottom: $unit-base;
  }
}

.breadcrumb {
  @include breadcrumb;
}

.headline {
  @include text-hero;
}

.location {
  @include text-h3;
}

.copy {
  @include text-intro;
  // max-width: 55ch;
  // As seen in comment 1 in https://caniuse.com/#feat=ch-unit IE 11 does not support ch correctly
  max-width: 560px;
}

:deep(.editorial-headline-wrapper) {
  @include reset-spacing;
  margin: $unit-half 0 $unit-base 0;

  @include helper__greater($bp-768) {
    margin-bottom: $unit-half 0 $unit-half * 3 0;
  }
}
</style>
