import { mapGetters } from 'vuex';
import { useMeta } from 'vue-meta';

const layoutMixin = {
  props: {
    route: {
      type: Object,
      default: () => ({}),
    },
  },

  //...mapGetters({ metaInfo: 'getRouteMetaData' }),
  // metaInfo: {
  //   title: 'Default App Title',
  //   titleTemplate: '%s | vue-meta Example App',
  // },
  computed: {
    ...mapGetters(['getRouteMetaData']),
    title() {
      return this.getRouteMetaData?.title;
    },
    htmlAttrs() {
      return this.getRouteMetaData?.htmlAttrs;
    },
    meta() {
      return this.getRouteMetaData?.meta;
    },
    link() {
      return this.getRouteMetaData?.link;
    },
  },
  created() {
    useMeta({
      title: this.title,
      htmlAttrs: this.htmlAttrs,
      meta: this.meta,
      link: this.link,
    });
  },
};

export default layoutMixin;
