<template>
  <NolteProductDetail
    :header-image="headerImage"
    :header-media-id="headerMediaId"
    :page-item-id="pageItemId"
    :category="formatCategory(productCategory)"
    :title="productLabel"
    :description="productDescription"
    :active-variant="activeVariant"
    :variant-groups="variantGroups"
    :product-variant-dimensions-label="$t('product-variants-dimensions-label')"
    :highlights="highlights"
    :images="galleryImages"
    :details-headline="$t('product-detail-details-headline')"
    :details="productDetails"
    :downloads="downloads"
    :related-products-headline="$t('product-detail-related-products-headline')"
    :related-products="relatedProducts"
    :is-neo="isNeo"
  />
</template>

<script>
import { mapState } from 'vuex';

import NolteProductDetail from '../nolte-ui/NolteProductDetail';

const formatCategory = ({ url, label }) => ({ url, label: label?.value || undefined });

const formatActiveVariant = activeVariant => ({
  image: {
    ...activeVariant?.productImage?.jss,
    alt: `${activeVariant?.label?.value}, ${activeVariant?.internalTags?.value}`,
  },
  internalTag: activeVariant?.internalTags?.value,
  productLabel: activeVariant?.label?.value,
  dimensionValues: activeVariant?.productDimensionValues?.targetItems?.length
    ? activeVariant?.productDimensionValues?.targetItems?.map(({ image, value, unit }) => ({
        variantImage: image.jss?.value,
        variantLabel: `${value?.value} ${unit?.value}`,
      }))
    : null,
});

export default {
  name: 'EditorialProductDetail',

  components: {
    NolteProductDetail,
  },

  computed: {
    ...mapState('jss', ['routeData']),

    fieldsData() {
      return this.fields?.data;
    },

    pageItemId() {
      return this.fieldsData?.product?.pageItemId;
    },

    headerMediaId() {
      const mediaId = this.fieldsData?.product?.headerImage?.value?.match(/\{[a-f0-9-]+\}/i);
      return mediaId !== null ? mediaId[0].replace(/[^a-z0-9_-]/gi, '') : '';
    },

    headerImage() {
      return this.fieldsData?.product?.headerImage?.jss;
    },

    productLabel() {
      return this.fieldsData?.product?.label?.value;
    },

    productDescription() {
      return this.fieldsData?.product?.description?.value;
    },

    productCategory() {
      return this.fieldsData?.product?.category;
    },

    productDetails() {
      return this.fieldsData?.product?.details?.value;
    },

    variantGroups() {
      const productVariants = this.fieldsData?.productVariants?.variants;
      if (productVariants?.length) {
        return [{ variants: this.mapVariants(productVariants) }];
      }
      if (this.fieldsData?.productVariantsGrouped?.groups?.length) {
        return this.fieldsData?.productVariantsGrouped?.groups?.map(({ label, variants }) => ({
          label: label.value,
          variants: this.mapVariants(variants),
        }));
      }
      return [];
    },

    activeVariant() {
      const { activeVariantGuid, activeVariantId } = this.fieldsData;
      if (activeVariantGuid) {
        return formatActiveVariant(activeVariantGuid);
      }

      return activeVariantId.results?.items?.[0]?.item
        ? formatActiveVariant(activeVariantId?.results?.items[0]?.item)
        : null;
    },

    highlights() {
      const highlights = this.fieldsData?.product?.highlights?.targetItems;
      if (!highlights.length) {
        return;
      }
      return highlights.map(({ image, headline, copy }) => ({
        image: image.jss,
        headline: headline.value,
        copy: copy.value,
      }));
    },

    galleryImages() {
      const galleryImages = this.fieldsData?.product?.galleryImages?.targetItems;
      if (!galleryImages.length) {
        return;
      }
      return galleryImages.map(({ image }) => image.jss);
    },

    downloads() {
      const downloads = this.fieldsData?.product?.downloads?.targetItems;
      return downloads.length ? downloads : undefined;
    },

    relatedProducts() {
      const related = this.fieldsData?.product?.relatedProducts?.targetItems;
      // adjust data for EditorialTeaser
      return related.length
        ? related.map(item => ({
            headline: {
              value:
                item.template?.id === '096018C08E9B563FB662D9FD9373ADCC' // MoodDetail
                  ? this.$t('cataloghub-planning-example')
                  : item.category?.label?.value,
            },
            copy: item.label,
            link: { value: { href: item.url } },
            image: { value: item.teaserImage?.jss?.value },
          }))
        : [];
    },

    isNeo() {
      return this.fieldsData?.product?.isNeo;
    },
  },

  methods: {
    formatCategory,

    getVariantUrl(variantGuid, internalTag) {
      let variantUrl = this.fieldsData?.product?.url + '?guid=' + variantGuid;
      if (internalTag?.length > 0) {
        variantUrl += '&id=' + internalTag;
      }
      return variantUrl;
    },

    mapVariants(variants) {
      return variants.map(({ previewImage, label, internalTags, id }) => ({
        variantImage: previewImage.jss?.value,
        variantLabel: label.value,
        internalTag: internalTags.value,
        url: this.getVariantUrl(id, internalTags.value),
      }));
    },
  },
};
</script>
