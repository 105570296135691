<template>
  <div class="nolte-highlight-group">
    <NolteScroller class="nolte-highlight-group__scroller" :bounce-edges="true" shadow-only="">
      <slot />
    </NolteScroller>
  </div>
</template>

<script>
import NolteScroller from '../NolteScroller';

export default {
  name: 'NolteHighlightGroup',

  components: { NolteScroller },
};
</script>

<style lang="scss" scoped>
.nolte-highlight-group {
  overflow: hidden;
  background-color: $color-concret;

  padding: $unit-double 0;
  @include helper__greater($bp-768) {
    padding: $unit-double * 2 0;
  }
}

.nolte-highlight-group__scroller {
  @include spacing;
  margin: 0 auto !important;
  z-index: 0;
  position: relative;
  overflow: visible;

  :deep(img) {
    height: 100%;
  }
}

.nolte-highlight-group :deep(.viewport) {
  > .nolte-highlight {
    margin-right: $unit-base;

    @include helper__greater($bp-768) {
      margin-right: $unit-double;
    }

    &:last-child {
      margin: 0;
    }
  }
}
</style>
