<template>
  <div class="catalog-header" :class="{ 'catalog-header--active-option': activeOption.id }">
    <div class="catalog-header__inner">
      <div class="catalog-header__head">
        <div class="catalog-header__headline-wrapper">
          <template v-if="activeOption.parent">
            <NolteHeadline>{{ activeOption.parent }}</NolteHeadline>
            <NolteHeadline level="h1" class="catalog-header__headline">{{
              activeOption.label
            }}</NolteHeadline>
          </template>
          <template v-else>
            <NolteHeadline level="h1" class="catalog-header__headline catalog-header__primary">{{
              $t('cataloghub-headline')
            }}</NolteHeadline>
          </template>
        </div>
        <p class="catalog-header__copy">
          {{ catalogCopytext }}
        </p>
      </div>

      <NolteCatalogMenu
        class="catalog-header__menu"
        :catalog-url="catalogUrl"
        :categories="categories"
        :inspiration-filter="inspirationFilter"
        :active-option-id="activeOption.id"
        :is-mobile="isMobile"
        :neo-more-button="neoMoreButton"
        :neo-filters="neoFilters"
        ref="catalogMenu"
      />

      <NolteCatalogMenuSticky
        :catalog-url="catalogUrl"
        :categories="categories"
        :inspiration-filter="inspirationFilter"
        :active-option-id="activeOption.id"
        :is-mobile="isMobile"
        :show-if-possible="isMainCatalogMenuHidden"
        :neo-more-button="neoMoreButton"
        :neo-filters="neoFilters"
      />
    </div>
  </div>
</template>

<script>
import 'intersection-observer';
import { mapGetters, mapState } from 'vuex';
import NolteCatalogMenu from '../nolte-ui/NolteCatalogMenu';
import NolteHeadline from '../nolte-ui/NolteHeadline';
import NolteCatalogMenuSticky from '../nolte-ui/NolteCatalogMenu/NolteCatalogMenuSticky';
import intersectionObserverHelper from '../nolte-ui/NolteStickyMenu/helper.js';
import eventBus from '../lib/eventBus';

export default {
  name: 'NolteCatalogHeader',

  components: {
    NolteCatalogMenu,
    NolteHeadline,
    NolteCatalogMenuSticky,
  },

  data() {
    return {
      activeOption: {},
      isMainCatalogMenuHidden: false,
      neoIsActive: false,
    };
  },

  computed: {
    ...mapGetters(['isMobile', 'getShowFiltersInFeed']),
    ...mapState('jss', ['sitecoreContext']),

    neoMoreButton() {
      return this?.fields?.data?.datasource?.fields?.find(
        item => item.name === 'Find More Neo Link'
      )?.jss;
    },

    catalogUrl() {
      let url = this.fields?.data?.catalogUrl?.parent?.url || this.fields?.data?.catalogUrl?.url;

      return url.replace(/^.+?[//][^/]+/, '');
      //return url.replace(/^.+?[\/\/][^\/]+/, '');
    },

    catalogCopytext() {
      return this.fields?.data?.catalogUrl?.description?.value || this.$t('cataloghub-copy');
    },

    categories() {
      const categories =
        this.fields?.data?.categories?.parent?.children || this.fields?.data?.categories?.children;
      return categories.map(item => {
        item.label = item.Label?.value;
        item.title = item.Description?.value;
        return item;
      });
    },

    inspirationFilter() {
      if (!this.getShowFiltersInFeed) {
        return [];
      }
      // with the filter command we filter null values that could occur if some inspiration filters
      // are missing in the graphQL data
      const moods = [
        this.fields?.data?.inspiration_shapes,
        this.fields?.data?.inspiration_handles,
        this.fields?.data?.inspiration_designs,
        this.fields?.data?.inspiration_rooms,
      ].filter(item => item);

      moods.map(mood => {
        mood.label = mood.Label?.value;
        mood.children.map(item => {
          item.url = `${this.catalogUrl}?${mood.GetParameter?.value}=${item.Slug?.value}`;
          item.label = item.Label?.value;
          item.title = item.Description?.value;
          item.iconId = item.Icon?.value;
          return item;
        });
        return mood;
      });
      return moods;
    },

    neoFilters() {
      const neo = this.fields?.data?.isNeo;
      if (!neo) {
        return [];
      }
      const filters = neo.children.map(item => {
        item.url = `${this.catalogUrl}?${neo.GetParameter?.value}=${item.Slug?.value}`;
        item.label = item.Label?.value;
        item.title = item.Description?.value;
        item.parent = neo.Label?.value;
        return item;
      });
      return filters;
    },
  },

  watch: {
    $route: {
      immediate: true,
      handler(to) {
        // [].concat is required to get rid of nested array
        // Without it would be `inspirationFilterOptions = [[{ ... }, { ... }, { ... }], [{ ... }, { ... }, { ... }]. [{ ... }, { ... }, { ... }]]`
        // With it is `inspirationFilterOptions = [{ ... }, { ... }, { ... }, { ... }, { ... }, { ... }. { ... }, { ... }, { ... }]`
        const inspirationFilter = this.inspirationFilter;
        const inspirationFilterOptions = [].concat(
          ...inspirationFilter.map(({ label, children }) =>
            children.map(child => ({ ...child, parent: label }))
          )
        );

        // Get array of all available options
        // Input are categories (Object) and inspirationFilter (Array of Objects)
        const categories = this.categories;
        const availableOptions = [
          // Spread all children of categories
          // Before, map each child and add label of parent
          ...categories.map(category => ({ ...category, parent: this.$t('cataloghub-products') })),
          ...inspirationFilterOptions,
        ];

        if (Object.keys(to.query).length === 0) {
          // Get active option for categories
          this.activeOption = availableOptions.find(({ url }) => url === to.path) || {};
        } else {
          // Get active option for inspirations
          this.activeOption = inspirationFilterOptions.find(({ url }) => url === to.fullPath) || {};
          if (!this.activeOption.url) {
            this.activeOption = this.neoFilters.find(({ url }) => url === to.fullPath) || {};
          }
        }

        if (this.isMainCatalogMenuHidden) {
          if (typeof window !== 'undefined') {
            window.scrollTo(0, 0);
          }
        }
      },
    },
  },

  mounted() {
    intersectionObserverHelper.call(
      this,
      { rootMargin: `${this.isMobile ? '-100px' : '-170px'} 0px 0px 0px` },
      this.$refs?.catalogMenu?.$el,
      this.intersectionObserverCallback
    );
  },

  beforeUnmount() {
    eventBus.$emit('intersectionObserverHelper:beforeUnmount');
  },
  methods: {
    intersectionObserverCallback(entries) {
      entries.forEach(entry => {
        this.isMainCatalogMenuHidden = !entry.isIntersecting;
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.catalog-header {
  background-image: url('../assets/catalog_hub_mobile_background.svg');
  background-size: cover;
  background-repeat: no-repeat;

  @include helper__greater($bp-768) {
    background-position: 0 15%;
    background-image: url('../assets/catalog_hub_background.svg');
  }
}

.catalog-header__inner {
  max-width: $grid-width;
  padding: $unit-double 0;
  margin: 0 auto;
  justify-content: center;

  @include helper__greater($bp-768) {
    padding-top: $unit-base * 3;
    padding-bottom: $unit-base * 3;
  }
}

.catalog-header__headline {
  @include text-hero;
  text-transform: none;
}

.catalog-header__copy {
  @include text-intro;
  flex: 0 1 40%;
}

.catalog-header__head {
  padding-left: $unit-base;
  padding-right: $unit-base;

  @include helper__greater($bp-768) {
    padding-left: $unit-base * 5;
    padding-right: $unit-base * 5;
  }

  @include helper__greater($bp-1280) {
    display: flex;
    flex-direction: row;
    align-items: center;
  }
}

.catalog-header__headline-wrapper {
  flex: 0 0 60%;
  position: relative;
  margin-bottom: $unit-base;

  display: flex;
  flex-direction: column;
  justify-content: center;

  @include helper__greater($bp-1280) {
    padding-right: $unit-double;
    min-height: 180px;
  }
}

.catalog-header__menu {
  margin-top: $unit-double;
}

.catalog-header--active-option .catalog-header__primary {
  user-select: none;
  pointer-events: none;
}
</style>
