<template>
  <div>
    <div v-for="(result, index) in results" :key="`result_${index}`">
      <div class="divider" />
      <div class="editorial-map-showroom-wrapper">
        <div class="address-wrapper">
          <h4 class="headline">{{ result.addressName.value }}</h4>
          <span>{{ result.addressStreet.value }}</span
          ><span>{{ result.addressZip.value }} {{ result.addressLocality.value }}</span
          ><span
            ><a :href="phoneLink(result.addressPhone.value)" target="_blank">{{
              result.addressPhone.value
            }}</a></span
          ><span
            ><a :href="'mailto:' + result.addressEmail.value" class="email">{{
              result.addressEmail.value
            }}</a></span
          >
        </div>
        <div class="address-wrapper openinghours-wrapper" v-if="result.openinghours.value">
          <h4 class="headline">{{ $t('editorial-map-overlay-opening-hours') }}</h4>
          <div v-html="doNl2br(result.openinghours.value)" />
        </div>
        <div class="label-wrapper">
          <NolteLabel class="label" />
          <NolteNeoLabel class="label neo" />
        </div>
        <div class="routeplanner-wrapper">
          <a
            :href="routeplannerLink(result.addressLat.value, result.addressLng.value)"
            target="_blank"
            class="button--yellow"
            >{{ $t('editorial-map-overlay-route-planner') }}</a
          >
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import NolteLabel from '../../assets/images/nolte-label.svg?inline';
import NolteNeoLabel from '../../assets/images/nolte-neo-label.svg?inline';
import { createPhoneLink, createRouteplannerLink, nl2br } from './utils';

export default {
  name: 'ResultListRetailers',

  components: { NolteLabel, NolteNeoLabel },

  props: {
    results: undefined,
  },
  data() {
    return {};
  },

  methods: {
    routeplannerLink(lat, lng) {
      return createRouteplannerLink(lat, lng);
    },
    phoneLink(phonenumber) {
      return createPhoneLink(phonenumber);
    },
    doNl2br(txt) {
      return nl2br(txt);
    },
  },
};
</script>

<style lang="scss" scoped>
.editorial-map-showroom-wrapper {
  width: 100%;
  @include spacing;
  padding-bottom: $unit-base !important;
  margin-top: 0 !important;
  margin-bottom: 0 !important;
  * {
    color: #707070 !important;
  }
}

.label-wrapper {
  margin: $unit-base 0;
  display: flex;
  align-items: center;
  align-content: flex-start;
}

.routeplanner-wrapper {
  margin-top: $unit-double;
}

.openinghours-wrapper {
  margin-top: $unit-double;
}

.label {
  width: auto;
  height: 32px;
  margin-right: $unit-half;

  &.neo {
    margin-top: -5px;
  }
}
.divider {
  border-top: 1px solid #a4a19d;
  margin: 0 0 $unit-base 0;
  padding: 0;
  margin-left: 0px;
  margin-right: 0px;
  width: 100%;

  @include helper__greater($bp-1024) {
    width: 432px;
  }
}

.address-wrapper {
  display: flex;
  flex-direction: column;
  @include text-base;
  color: $color-ironside-gray;

  .headline {
    @include text-h4;
    margin: 0 0 $unit-half;
  }
  a.email {
    text-decoration: underline;
  }
}

.button--yellow {
  @include text-h5;
  @include helper__transition((color, border-color, background-color));
  border-radius: $radius-form;
  padding: $unit-half $unit-base;

  border: 2px solid $color-aureolin;
  background-color: $color-aureolin;

  &:hover {
    border: 2px solid $color-citrine;
  }
}

.routeplanner-wrapper {
  margin-bottom: $unit-half;
}

.label {
  width: auto;
  height: 32px;
  margin-right: $unit-half;

  &.not-active {
    filter: opacity(0.2);
  }

  &.neo {
    margin-top: -5px;
  }
}
</style>
