<template>
  <v-field-template
    name="fileupload"
    :field="field"
    :hidden="hidden"
    :disabled="disabled"
    :errors="errors"
  >
    <template #field>
      <NolteFormWrapper :errors="errors" #default="{ hasError }">
        <div class="wrapper--position">
          <IconPaperclip class="paperclip" />
          <NolteFormInput
            ref="input"
            type="file"
            :id="field.valueField.id"
            :name="field.valueField.name"
            :accept="accept"
            :required="field.model.required"
            :disabled="disabled"
            v-model="value"
            :value="value"
            @blur="fieldBlur"
            @change="fieldChange"
            :invalid="hasError"
          />
          <v-label ref="label" v-if="field.valueField" :field="field" />
          <div class="remove" :class="{ 'remove--hidden': fieldEmpty }" @click="remove">
            <IconInputCross />
          </div>
        </div>
      </NolteFormWrapper>
    </template>

    <template #label>
      <span></span>
    </template>
  </v-field-template>
</template>

<script>
import { mapGetters } from 'vuex';
import { humanFileSize } from '@/components/helper';
import IconPaperclip from '@/assets/icons/IconPaperclip.svg?inline';
import IconInputCross from '@/assets/icons/IconInputCross.svg?inline';

import FieldMixin from '../structure/FieldMixin';
import FieldConditionMixin from '../structure/conditions/FieldConditionMixin';
import FieldValidationMixin from '../structure/validation/FieldValidationMixin';

import NolteFormInput from '../../nolte-ui/NolteForm/NolteFormInput';
import NolteFormWrapper from '../../nolte-ui/NolteForm/NolteFormWrapper';

export default {
  name: 'SitecoreFormsFieldsFileUpload',

  components: {
    IconPaperclip,
    IconInputCross,
    NolteFormInput,
    NolteFormWrapper,
  },

  mixins: [FieldMixin, FieldConditionMixin, FieldValidationMixin],

  // errors prop is defined in FieldValidationMixin

  data() {
    return {
      value: this.field.model.value,
      originalLabel: '',
    };
  },

  computed: {
    ...mapGetters('jss', ['currentLanguage']),

    accept() {
      return this.field.model.allowedContentTypes
        .split(',')
        .map(item => {
          return '.' + item.trim().toLowerCase();
        })
        .join(',');
    },

    fieldEmpty() {
      return this.value === null || this.value === '';
    },
  },

  mounted() {
    this.originalLabel = this.$refs.label.$el.textContent;

    this.field.valueField.name = this.field.valueField.name.replace('.Value', '.File');
  },

  methods: {
    fieldChange() {
      const input = this.$refs.input.$el;
      const label = input.nextElementSibling;
      const filename = input.value.split('\\').pop();
      if (filename) {
        const filesize = humanFileSize(input.files[0].size, this.currentLanguage);
        label.textContent = `${filename} (${filesize})`;
      } else {
        label.textContent = this.originalLabel;
      }
    },

    remove() {
      // check for all browser including IE11
      this.$refs.input.$el.value = '';
      this.fieldChange();
      this.value = '';
    },
  },
};
</script>

<style lang="scss" scoped>
.wrapper--position {
  position: relative;

  &:hover label:after {
    border-bottom-color: $color-tuatara;
  }
  // Style when focus by mouse interaction
  &:focus label:after {
    border-bottom-color: $color-tuatara !important;
  }
  // Style when focus by keyboard interaction
  body[modality='keyboard'] &:focus label:after {
    border-bottom-color: $color-azure !important;
  }
}

input[type='file'] {
  width: 0.1px;
  height: 0.1px;
  opacity: 0;
  overflow: hidden;
  position: absolute;
  z-index: -1;
}

input[type='file'] + label {
  padding: $unit-half / 2 36px $unit-half 36px;
  margin-bottom: $unit-half;
  vertical-align: top;
  cursor: pointer;

  // shorten functionality
  width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;

  &:after {
    @include helper__transition(border-bottom-color);
    content: '';
    display: block;
    border-bottom: 2px solid $color-athens-gray;
    padding: 0 $unit-double $unit-half 0;
    width: calc(100% + 34px);
  }
}

.paperclip {
  position: absolute;
  top: 0;
  left: 0;
  color: $color-ironside-gray;
  width: 34px;
  height: 34px;
  pointer-events: none;
}

.remove {
  @include helper__transition(opacity);
  position: absolute;
  top: 0;
  right: 0;
  width: 32px;
  height: 32px;
  text-align: center;
  cursor: pointer;
  opacity: 1s;

  &:hover {
    color: $color-azure;
  }

  &--hidden {
    opacity: 0;
    pointer-events: none;
  }
}
</style>
