const debug = false;

export default {
  namespaced: true,
  state: {
    allShowrooms: [],
    filteredShowrooms: [],
    filteredRetailers: [],
    searchLocationisVisible: false,
    allCountries: [],
    searchModel: [],
    location: {},
    googleMapsApiKey: '',
    fetchNewData: false,
  },

  mutations: {
    updateSearchModel(state, payload) {
      if (debug) {
        console.log('updateSearchModel:' + JSON.stringify(payload));
      }
      if (payload != null && payload != undefined) {
        state.searchModel = payload;
      }
    },
    // TODO: obsolete, remove this
    setCountryCodeX_LOE(state, payload) {
      if (debug) {
        console.log('setCountryCode:' + payload);
      }
      if (payload != null && payload != undefined) {
        const newSearchmodel = {
          query: '',
          country: { code: payload, label: '' },
        };
        state.searchModel = newSearchmodel;
      }
    },
    setSearchLocationVisibility(state, payload) {
      if (payload != null && payload != undefined) {
        state.searchLocationisVisible = payload;
      }
    },
    setLocation(state, payload) {
      if (payload != null && payload != undefined) {
        // check if name has been set
        let previousName = state.location.name;
        state.location = payload;
        if (!state.location.name) {
          state.location.name = previousName;
        }
      }
    },
    setFetchNewData(state, payload) {
      if (debug) {
        console.log('mutation setFetchNewData: ' + payload);
      }
      if (payload != null && payload != undefined) {
        state.fetchNewData = payload;
      }
    },
    setFilteredShowrooms(state, payload) {
      if (payload != null && payload != undefined) {
        state.filteredShowrooms = payload;
      }
    },
    setFilteredRetailers(state, payload) {
      if (payload != null && payload != undefined) {
        state.filteredRetailers = payload;
      }
    },
    setShowrooms(state, payload) {
      if (payload != null && payload != undefined) {
        if (debug) {
          console.log('setShowrooms: ' + payload);
        }
        state.allShowrooms = payload;
      }
    },
    setCountries(state, payload) {
      if (payload != null && payload != undefined) {
        let countries = new Array();
        for (let i = 0; i < payload.length; i++) {
          countries[i] = {
            label: payload[i].name.value,
            value: payload[i].acronym.value,
            id: payload[i].id,
          };
        }
        state.allCountries = countries;
        if (debug) {
          console.log('setCountries');
        }
      }
    },
    setGoogleMapsApiKey(state, payload) {
      if (payload != null && payload != undefined) {
        state.googleMapsApiKey = payload;
      }
    },
  },

  getters: {
    searchLocationIsVisible(state) {
      return state.searchLocationisVisible;
    },
    getSearchModel(state) {
      return state.searchModel;
    },
    getFetchNewData(state) {
      return state.fetchNewData;
    },
    currentLocation(state) {
      return state.location;
    },
    getAllCountries(state) {
      return state.allCountries;
    },
    getAllShowrooms(state) {
      return state.allShowrooms;
    },
    getFilteredShowrooms(state) {
      return state.filteredShowrooms;
    },
    getGoogleMapsApiKey(state) {
      return state.googleMapsApiKey;
    },
  },

  actions: {
    updateSearchModel({ commit }, payload) {
      commit('updateSearchModel', payload);
    },
    setCountry({ commit }, payload) {
      commit('setCountry', payload);
    },
    setSearchLocationVisibility({ commit }, payload) {
      commit('setSearchLocationVisibility', payload);
    },
    setLocation({ commit }, payload) {
      if (debug) {
        console.log('setLocation: ' + JSON.stringify(payload));
      }
      commit('setLocation', payload);
    },
    setFetchNewData({ commit }, payload) {
      if (debug) {
        console.log('action setFetchNewData: ' + JSON.stringify(payload));
      }
      commit('setFetchNewData', payload);
    },
    setFilteredShowrooms({ commit }, payload) {
      if (debug) {
        console.log('setFilteredShowrooms: ' + JSON.stringify(payload));
      }
      commit('setFilteredShowrooms', payload);
    },
    setCountries({ commit }, payload) {
      commit('setCountries', payload);
    },
    setShowrooms({ commit }, payload) {
      commit('setShowrooms', payload);
    },
    setGoogleMapsApiKey({ commit }, payload) {
      commit('setGoogleMapsApiKey', payload);
    },
  },
};
