<template>
  <div class="editorial-feed">
    <NolteGrid>
      <NolteGridCell
        v-for="(component, index) in feed"
        :key="`feed-item_${index}`"
        :type="component.aspectRatio.attribute"
      >
        <ContentFavorite
          v-if="component.type === 'Teaser'"
          :item-id="component.id"
          :image-id="component.imageId"
        >
          <NolteTeaser
            :link-url="component.link"
            :image="component.image"
            :headline="component.headline"
            :copy="component.copy"
            :aspect-ratio="component.aspectRatio"
            headline-level="h2"
          />
          <ContentNeoOverlay :is-neo="component.isNeo" />
        </ContentFavorite>
        <NolteTip
          v-else-if="component.type === 'Tip'"
          :headline="component.headline"
          :copy="component.copy"
          :link-url="component.link"
          :wistia-video-id="component.wistiaVideoId"
          :image="component.image"
          :design="component.design"
          :size="component.copy.length > 80 ? 'large' : undefined"
        />
      </NolteGridCell>
    </NolteGrid>

    <div class="editorial-feed__button">
      <NolteButton type="yellow--shadow" v-if="feedQueue.length" @click="loadNextBatch">
        <template #icon><IconArrowDown /></template>
        {{ $t('cataloghub-discover-more') }}
      </NolteButton>
    </div>

    <ul class="editorial-feed__seo">
      <li v-for="(item, index) in seoData" :key="index">
        <a :href="item.url" v-if="textHasValue(item.label)">{{ item.label.value }}</a>
      </li>
    </ul>
  </div>
</template>

<script>
import queryMore from '../constants/graphql/EditorialFeed.apollo.graphql';
import { mapGetters, mapState } from 'vuex';
import NolteGrid from '../nolte-ui/NolteGrid';
import NolteGridCell from '../nolte-ui/NolteGrid/NolteGridCell';
import NolteTeaser from '../nolte-ui/NolteTeaser';
import NolteTip from '../nolte-ui/NolteTip';
import NolteButton from '../nolte-ui/NolteButton';
import { textHasValue, processGrid, resolveQuery, shuffleArray } from './helper';
import IconArrowDown from '../assets/icons/IconArrowDown.svg?inline';
import { feedData } from '@/constants/experience-editor-mocking-data/feed';
import ContentFavorite from '@/components/ContentFavorite.vue';
import ContentNeoOverlay from '@/components/ContentNeoOverlay';

export default {
  name: 'EditorialFeed',

  components: {
    NolteGrid,
    NolteGridCell,
    NolteTeaser,
    NolteTip,
    NolteButton,
    IconArrowDown,
    ContentFavorite,
    ContentNeoOverlay,
  },
  emits: ['click'],

  data() {
    return {
      feed: [],
      feedQueue: [],
      resolvedQuery: undefined,
      batchSize: {
        products: 4,
        moods: 4,
        tips: 2,
      },
      // You have to define the schema of batch size here and in GraphQL queries.
      // It represents the schema for CatalogHub (hub), CatalogHub with set Get Parameter (mood) and CategoryDetail (category) pages.
      batchSizeSchema: {
        hub: {
          products: 4,
          moods: 4,
          tips: 2,
        },
        mood: {
          products: 0,
          moods: 10,
          tips: 0,
        },
        category: {
          products: 10,
          moods: 0,
          tips: 0,
        },
        product: {
          products: 10,
          moods: 0,
          tips: 0,
        },
      },
      // It respresents the value of each particular type of item which are currently loaded from queue to feed (displayed on the site)
      // It determines the Apollo-GraphQL query offset (e.g. '$offsetMoods' in query)
      loadedItems: {
        products: 0,
        moods: 0,
        tips: 0,
      },
      // Represents the Key:Value pair of the GET-Parameter
      inspirationFilter: {
        key: '',
        value: '',
      },
      // Represents the Key:Value pair of the GET-Parameter
      productFilter: {
        key: '',
        value: '',
      },
    };
  },
  inject: ['apollo'],
  created() {
    if (!this.isEditing) {
      this.resolvedQuery = resolveQuery(
        queryMore,
        /\$contextSiteRootPath/g,
        this.sitecoreContext?.site?.rootPath
      );
      const pageEnvironment = this.resolvePageEnviroment();
      // Create items that come from integrated GraphQL request
      this.feedQueue = this.processRawDataForFeed(this.fields?.data);

      this.prepareGraphQLRequest(pageEnvironment);

      // Load the next items and put them into the queue
      this.loadNextBatch();
    } else {
      const loadedFeedData = feedData.map(teaser => this.createTeaserData(teaser));
      this.feed = this.processGrid(loadedFeedData, false);
    }
  },

  methods: {
    textHasValue,
    processGrid,

    processRawDataForFeed(rawData) {
      const processedTips = [];
      let processedData = [];
      let oldRandomIndex;

      // Set teasers from ProductDetail
      rawData?.products?.results?.items?.forEach(({ productDetail }) => {
        productDetail = Object.assign([], productDetail);
        productDetail.source = 'products';
        const teaserData = this.createTeaserData(productDetail);
        processedData.push(teaserData);
      });

      // Set teasers from MoodDetail (exists only in CatalogHub page context)
      rawData?.moods?.results?.items.forEach(({ moodDetail }) => {
        moodDetail = Object.assign([], moodDetail);
        moodDetail.source = 'moods';
        const teaserData = this.createTeaserData(moodDetail);
        processedData.push(teaserData);
      });

      // Set Tips (exists only in CatalogHub page context and when Get-Parameter is not set in the URL)
      rawData?.tips?.results?.items?.forEach(({ tipDetail }) => {
        const tipData = this.formatTip(tipDetail);
        processedTips.push(tipData);
      });

      // Shuffle Teasers
      processedData = shuffleArray(processedData);

      // Add tips to feed
      for (let i = 0; i < processedTips?.length; i++) {
        // if we have more data (moods / products) than tips then we can provide custom sorting of tips
        if (processedData.length > processedTips.length) {
          // generate a random number with max penultimate index, so we need to substract 1 from array length
          let randomIndex = Math.floor(Math.random() * (processedData.length - 1));

          // if random index is not the same as or direct sibling of old random index
          if (
            randomIndex != oldRandomIndex &&
            randomIndex != oldRandomIndex - 1 &&
            randomIndex != oldRandomIndex + 1
          ) {
            processedData.splice(randomIndex, 0, processedTips[i]);
            oldRandomIndex = randomIndex;
          } else {
            i--; // reset loop to generate a new random number
          }
        } else {
          // otherwise just add tips as they are
          processedData.push(processedTips[i]);
        }
      }
      return this.processGrid(processedData);
    },

    createTeaserData(object) {
      const {
        id,
        source,
        category,
        label,
        link,
        headerImage,
        teaserImage,
        isNeo,
        galleryImages: { targetItems },
      } = object;

      // Use header image if teaser image was not maintained
      let image = teaserImage;
      if (!image.jss.value.src) {
        image = headerImage;
      }

      // Create an image pool and then take one of the images to represent the current product or mood
      const imagePool = targetItems
        .filter(item => item.showInHub.boolValue && item.image.jss.value.src)
        .map(item => item.image); // Add galleryImages to pool
      // Add teaserImage to pool
      if (image.jss.value.src) {
        imagePool.push(image);
      }

      let poolImage = this.getTeaserFallbackImage;
      if (imagePool.length) {
        // Choose one of the images as image for the current teaser
        const randomIndex = Math.floor(Math.random() * imagePool.length);
        poolImage = Object.assign([], imagePool[randomIndex]);
      }

      // It is not possible to get the media-id of the image directly via graphQL
      // so we have to parse it from the value which is an HTML string
      const mediaId = poolImage.value?.match(/\{[a-f0-9-]+\}/i);
      if (mediaId) {
        poolImage.id = mediaId !== null ? mediaId[0] : '';
      }

      return this.formatTeaser(id, source, label, link, poolImage, category, isNeo);
    },

    formatTeaser(id, source, label, link, image, category, isNeo) {
      return {
        id: id,
        type: 'Teaser',
        imageId: image?.id,
        image: image?.jss,
        headline:
          source === 'moods' && this.$t
            ? this.$t('cataloghub-planning-example')
            : category?.label?.value,
        copy: label?.value,
        link,
        isNeo,
      };
    },

    formatTip(tipDetail) {
      return {
        type: 'Tip',
        image: tipDetail?.image?.jss,
        headline: tipDetail?.label?.value,
        copy: tipDetail?.description?.value,
        link: tipDetail?.link?.jss?.value?.href,
        wistiaVideoId: tipDetail?.wistiaVideoId?.jss,
        design: tipDetail?.design?.targetItem?.design?.value,
      };
    },

    async loadNextBatch() {
      this.feed = this.feed.concat(this.feedQueue); // populate feed with the data coming from Integrated GraphQL
      this.feedQueue = [];
      for (const key in this.batchSize) {
        this.loadedItems[key] += this.batchSize[key]; // Update the loaded items counter
      }

      if (this.isConnected) {
        const newData = await this.retrieveNewData(); // retrieve new data using Apollo-GraphQL
        this.feedQueue = this.processRawDataForFeed(newData); // Add new data to queue, to improve user experience and to show 'load more' button
      }
    },

    prepareGraphQLRequest(pageEnvironment) {
      // Define batch size depending on the page type
      this.batchSize = this.batchSizeSchema[pageEnvironment.type];

      // Set filters depending on pageEnvironment.type
      this.productFilter = { key: '_templateName', value: 'ProductDetail' };
      this.inspirationFilter = { key: '_templateName', value: 'MoodDetail' };

      if (pageEnvironment.type === 'mood') {
        this.inspirationFilter.key = pageEnvironment.key;
        this.inspirationFilter.value = pageEnvironment.value;
      } else if (pageEnvironment.type === 'product') {
        this.productFilter.key = pageEnvironment.key;
        this.productFilter.value = pageEnvironment.value;
      }
    },

    async loadNewBatch() {
      const pageEnvironment = this.resolvePageEnviroment();

      this.prepareGraphQLRequest(pageEnvironment);

      // clear counter used for Apollo-GraphQL query
      this.loadedItems = {
        products: 0,
        moods: 0,
        tips: 0,
      };

      if (this.isConnected) {
        const newData = await this.retrieveNewData();
        this.feedQueue = this.processRawDataForFeed(newData); // we need just to populate the queue, loadNextBatch() will do the rest for us
      }
      // clear feed
      this.feed.splice(0, this.feed.length);
      this.loadNextBatch();
    },

    async retrieveNewData() {
      // to show all products
      //   productFilterName: "_templateName",
      //   productFilterValue: "ProductDetail",
      // to show neo products only
      //   productFilterName: "neo_product_sm",
      //   productFilterValue: "9e80003c963f4fe999d76b75e8e84a25",  # lowercase!
      // to show no products
      //   productFilterName: "neo_product_sm",
      //   productFilterValue: "null",

      try {
        const newData = await this.apollo.query({
          query: this.resolvedQuery,
          fetchPolicy: 'network-only',
          variables: {
            contextItem: this.fields?.data?.contextItem?.path,
            language: this.sitecoreContext?.language,
            offsetProducts: this.loadedItems?.products.toString(),
            offsetMoods: this.loadedItems?.moods.toString(),
            offsetTips: this.loadedItems?.tips.toString(),
            inspirationName: this.inspirationFilter?.key,
            inspirationValue: this.inspirationFilter?.value,
            productFilterName: this.productFilter?.key,
            productFilterValue: this.productFilter?.value,
            batchSizeProducts: this.batchSize?.products,
            batchSizeMoods: this.batchSize?.moods,
            batchSizeTips: this.batchSize?.tips,
            sessionIdRandomSort: this.sitecoreContext?.sessionSortOrder,
          },
        });
        return newData.data;
      } catch (e) {
        console.log(
          'GraphQL error (query EditorialFeedMore) in EditorialFeed (NORES-384)',
          e.message
        );
      }
    },

    resolvePageEnviroment() {
      // check if we are on CategoryDetail page because we don't need to show any moods and tips there
      if (this.routeData.templateName === 'CategoryDetail') {
        return {
          type: 'category',
        };
      } else if (!Object.keys(this.$route.query).length) {
        return {
          type: 'hub',
          key: '_templatename',
          value: 'MoodDetail',
        };
      }

      // getParamKey: Get the GET-Parameter key as string
      // Possible values: "handle_types_sm", "design", "shape_id", "kitchen_rooms_sm"
      const getParamKey = Object.keys(this.$route.query)[0];
      const getParamValue = this.$route.query[getParamKey];
      const handleTypes = this.fieldData.inspirationTypes;
      const designs = this.fieldData.inspirationDesigns;
      const shapeIds = this.fieldData.inspirationShapeIds;
      const rooms = this.fieldData.inspirationRooms;
      const neo = this.fieldData.isNeo;
      let inspirationFilter;
      let productFilter;

      // Check if the Get-Parameter key is valid
      // and find the filter category (inspiration moods filter) respresenting the Get-Parameter key
      switch (getParamKey) {
        case handleTypes.getParameter.value: // handle_types_sm
          inspirationFilter = handleTypes;
          break;
        case designs.getParameter.value: // design_sm
          inspirationFilter = designs;
          break;
        case shapeIds.getParameter.value: // shape_id_sm
          inspirationFilter = shapeIds;
          break;
        case rooms.getParameter.value: // kitchen_rooms_sm
          inspirationFilter = rooms;
          break;
        case neo.getParameter.value: // neo_product_sm
          productFilter = neo;
          break;
      }

      // If a filter is applied check if the Get-Parameter value is valid
      if (inspirationFilter) {
        // The inspirationFilter variable contains all possible filter values
        // e.g. filter 'design' contains: 'Glas', 'Holz', etc..
        const result = inspirationFilter.children.filter(obj => {
          return obj.slug.value === getParamValue;
        });

        // If the filter matches a value, then we have a valid Get-Parameter
        // and the page environment is a CatalogHub page with set Get-Parameter
        if (result.length !== 0) {
          return {
            type: 'mood',
            key: inspirationFilter.getParameter.value,
            value: result[0].id.toString().toLowerCase(), // the ID needs to be in lowercase
          };
        }
      }

      // do the same as above for the neo product filter
      if (productFilter) {
        const result = productFilter.children.filter(obj => {
          return obj.slug.value === getParamValue;
        });

        if (result.length !== 0) {
          return {
            type: 'product',
            key: productFilter.getParameter.value,
            value: result[0].id.toString().toLowerCase(),
          };
        }
      }

      // If the value is not valid (result.length is 0)
      // Show all moods by searching for '_templatename' and 'MoodDetail'
      return {
        type: 'hub',
        key: '_templatename',
        value: 'MoodDetail',
      };
    },
  },

  computed: {
    fieldData() {
      return this.fields?.data;
    },
    seoData() {
      // if no products or moods were found ".items" is an empty array so we don't have to use the
      // optional chaining operator
      const products = this.fieldData?.seoAllProducts?.results?.items;
      const moods = this.fieldData?.seoAllMoods?.results?.items;
      const data = products?.concat(moods)?.map(item => item?.detail);
      return data;
    },
    ...mapGetters('jss', ['isEditing', 'isConnected']),
    ...mapGetters(['getTeaserFallbackImage']),
    ...mapState('jss', ['routeData', 'sitecoreContext']),
  },
  watch: {
    $route() {
      this.loadNewBatch();
    },
  },
};
</script>

<style lang="scss" scoped>
.editorial-feed {
  margin-bottom: $unit-base * 5;

  &:last-of-type {
    margin-bottom: 0;
  }

  // Fixes this behaviour in Chrome: https://support.google.com/chrome/thread/62861011?hl=en
  overflow-anchor: none;
}

.editorial-feed__button {
  padding: $unit-double * 2 0;
  text-align: center;
}

.editorial-feed__seo {
  display: none;
}
</style>
