import 'intersection-observer';
import eventBus from '@/lib/eventBus';

export default function (options, element, callback) {
  const observer = new IntersectionObserver(callback, {
    rootMargin: '-170px',
    threshold: [0, 1],
    ...options,
  });

  observer.observe(element);

  eventBus.$once('intersectionObserverHelper:beforeUnmount', () => {
    if (element) {
      observer.unobserve(element);
    }
  });
}
