<template>
  <div class="editorial-search-results">
    <route-loading v-if="loading && !isEditing" />
    <div v-else>
      <SearchResultsHeader :search-details="searchDetails" />
      <SearchResultList :search-results="results" />
      <div class="editorial-search-results__button-container">
        <NolteButton
          class="editorial-search-results__button"
          type="yellow--shadow"
          v-if="resultsQueue.length"
          @click="loadNextBatch"
        >
          <template #icon><IconArrowDown /></template>
          {{ $t('search-load-more') }}
        </NolteButton>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState, mapMutations, mapGetters } from 'vuex';
import { fetchResults } from './utils';
import { searchResultsData } from '@/constants/experience-editor-mocking-data/searchResults';

import RouteLoading from '@/router/RouteLoading';
import SearchResultsHeader from './SearchResultsHeader';
import SearchResultList from './SearchResultList';
import NolteButton from '@/nolte-ui/NolteButton';
import IconArrowDown from '../../assets/icons/IconArrowDown.svg?inline';

export default {
  name: 'EditorialSearchResults',
  components: {
    RouteLoading,
    SearchResultsHeader,
    SearchResultList,
    NolteButton,
    IconArrowDown,
  },

  data: () => ({
    term: undefined,
    loading: true,
    count: 0,
    results: [],
    resultsQueue: [],
    batchSize: 14,
    loadedBatches: 0,
  }),

  computed: {
    ...mapMutations({ open: 'setSearchBarVisibility' }),
    ...mapState('jss', ['sitecoreContext']),
    ...mapGetters('jss', ['isEditing']),

    searchDetails() {
      return { count: this.count, term: this.term };
    },
  },

  mounted() {
    if (this.isEditing) {
      // mocking data for experience editor
      this.count = searchResultsData.Count;
      this.term = 'Nolte';
      this.results = searchResultsData.Results;
      return;
    }

    if (!this.$route.query['q']) {
      this.setSearchBarVisibility(true);
      return;
    }

    this.loadNewBatch();
  },

  methods: {
    ...mapMutations(['setSearchBarVisibility']),

    async loadNextBatch() {
      this.keepScrollPosition();
      this.results = this.results.concat(this.resultsQueue);

      const { language, searchScope, itemId } = this.sitecoreContext;
      const searchParams = {
        term: this.term,
        offset: (this.batchSize * this.loadedBatches).toString(),
        batchSize: this.batchSize.toString(),
        language,
        searchScope,
        itemId,
      };

      await fetchResults(searchParams, result => {
        this.resultsQueue = result?.Results;
        this.loadedBatches++;
      });
    },

    loadNewBatch() {
      this.loading = true;
      this.results = this.results.splice(0, this.results.length);

      this.term = this.$route.query['q'];
      if (this.term) {
        const { language, searchScope, itemId } = this.sitecoreContext;
        const searchParams = {
          term: this.term,
          offset: '0',
          batchSize: (this.batchSize * 2).toString(),
          language,
          searchScope,
          itemId,
        };
        fetchResults(searchParams, result => {
          this.count = result?.Count;
          const resultList = result?.Results;
          this.results = resultList.splice(0, this.batchSize);
          this.resultsQueue = resultList.splice(0, resultList.length);
          this.loadedBatches = 2;
        });
      }

      this.loading = false;
    },

    keepScrollPosition() {
      const scrollTop = document.documentElement.scrollTop;
      setTimeout(() => {
        document.documentElement.scrollTop = scrollTop;
      });
    },
  },

  watch: {
    $route(newRoute) {
      if (newRoute.query.q) {
        this.loadNewBatch();
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.editorial-search-results {
  background: $color-concret;
  padding-bottom: $unit-double * 2;
}
.editorial-search-results__button-container {
  text-align: center;
}
.editorial-search-results__button {
  margin: $unit-double * 2 0;
}
:deep(.jss-loading) {
  z-index: 30;
}
</style>
