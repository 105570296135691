<template>
  <NolteFormLabel
    :for="field.valueField.id"
    :class="field.model.labelCssClass"
    :label="field.model.title"
    :required="field.model.required"
    :raw="raw"
  />
</template>

<script>
import NolteFormLabel from '../../nolte-ui/NolteForm/NolteFormLabel';

export default {
  name: 'SitecoreFormsFieldsLabel',

  components: {
    NolteFormLabel,
  },

  props: {
    field: {
      type: Object,
      default: () => {},
    },
    raw: {
      type: Boolean,
      default: false,
    },
  },
};
</script>
