<template>
  <div class="nolte-detail-simple">
    <NolteHeadline class="nolte-detail-simple__headline" level="h2">{{ headline }}</NolteHeadline>
    <p v-if="copy" class="nolte-detail-simple__copy">{{ copy }}</p>
    <div v-if="linkHasValue(link) && linkTitle" class="nolte-detail-simple__link">
      <NolteButton
        type="link-bright"
        :external="isExternalLink"
        :link-url="linkUrl"
        :target="linkTarget"
        >{{ linkTitle }}</NolteButton
      >
    </div>
  </div>
</template>

<script>
import NolteButton from '../NolteButton';
import NolteHeadline from '../NolteHeadline';

import { linkHasValue } from '../../components/helper';

export default {
  name: 'NolteDetailSimple',

  components: {
    NolteButton,
    NolteHeadline,
  },

  props: {
    headline: {
      type: String,
      required: true,
    },
    copy: {
      type: String,
      default: undefined,
    },
    link: {
      type: Object,
      default: undefined,
    },
  },

  computed: {
    linkTitle() {
      return this.link?.value?.text;
    },

    linkUrl() {
      return this.link?.value?.href;
    },

    linkTarget() {
      return this.link?.value?.target;
    },

    isExternalLink() {
      return this.link?.value?.linktype === 'external';
    },
  },

  methods: {
    linkHasValue,
  },
};
</script>

<style lang="scss">
.nolte-detail-simple {
  padding: $unit-base;

  @include helper__greater($bp-768) {
    padding: $unit-double;
  }
}

.nolte-detail-simple__headline {
  // TODO: Define font size in typography.scss
  // Overriding NolteHeadline styles
  @include helper__until($bp-768) {
    font-size: 22px !important;
    line-height: (28 / 22) !important; // [Intended lineheight] / [font size]
  }

  @include helper__greater($bp-768) {
    font-size: 32px !important;
    line-height: (38 / 32) !important; // [Intended lineheight] / [font size]
  }
}

.nolte-detail-simple__copy {
  margin-top: $unit-base;
}

.nolte-detail-simple__link {
  margin-top: $unit-base;
}
</style>
