<template>
  <v-field-template
    name="dropdownlist"
    :field="field"
    :hidden="hidden"
    :disabled="disabled"
    :errors="errors"
  >
    <template #field>
      <NolteFormWrapper :errors="errors" #default="{ hasError }">
        <NolteFormDropdown
          :options="options"
          :id="field.valueField.id"
          :name="field.valueField.name"
          :required="field.model.required"
          :disabled="disabled"
          v-model="value"
          :value="value"
          @blur="fieldBlur"
          :clearable="field.model.showEmptyItem"
          :invalid="hasError"
        />
      </NolteFormWrapper>
    </template>
  </v-field-template>
</template>

<script>
import FieldMixin from '../structure/FieldMixin';
import FieldConditionMixin from '../structure/conditions/FieldConditionMixin';
import FieldValidationMixin from '../structure/validation/FieldValidationMixin';

import NolteFormDropdown from '../../nolte-ui/NolteForm/NolteFormDropdown';
import NolteFormWrapper from '../../nolte-ui/NolteForm/NolteFormWrapper';

import { getStorage } from '@/useLocalStorage';

export default {
  name: 'SitecoreFormsFieldsDropdownlist',

  components: {
    NolteFormDropdown,
    NolteFormWrapper,
  },

  mixins: [FieldMixin, FieldConditionMixin, FieldValidationMixin],

  mounted() {
    const savedValue = getStorage(this.field.valueField.name);
    if (savedValue) {
      this.value = savedValue;
    } else return;
  },

  // errors prop is defined in FieldValidationMixin

  data() {
    return {
      value: this.field.model.items.find(item => item.selected)
        ? this.field.model.items.filter(item => item.selected)[0].value
        : '',
    };
  },

  computed: {
    options() {
      return this.field.model.items.map(({ text, value }) => ({
        value,
        label: text,
      }));
    },
  },
};
</script>
