<template>
  <div v-if="hasId" class="squarelovin-container">
    <div class="squarelovin-container__limiter">
      <div :id="squarelovinIdforDataElement"></div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import loadSquarelovin from '../squarelovin.js';
import eventBus from '@/lib/eventBus';

export default {
  name: 'EditorialInstagram',
  lazy: true,

  data() {
    return {
      observer: {},
    };
  },
  computed: {
    ...mapGetters(['getInstagramSettings']),
    ...mapGetters('jss', ['currentRouteLanguage']),

    squarelovinIdforDataElement() {
      return `sqln-${this.getInstagramSettings?.page?.value}`;
    },

    hasId() {
      return this.getInstagramSettings?.page?.value !== '';
    },
  },

  mounted() {
    if (!this.$isServer && this.hasId) {
      loadSquarelovin(
        this.getInstagramSettings?.page?.value,
        this.currentRouteLanguage?.language?.split('-')[0]
      );
    }

    /**
     * The squarelovin script contains a strange scroll logic that causes the page to scroll from top to bottom when closing the popup.
     * This script also attaches some attributes to the body tag ('sq-scroll') when a user clicks on an instagram photo.
     * Our trick is to change css scroll behavior style to 'auto' when this script adds it to the body tag.
     * Then when the user closes the popup with the close button the css scroll behavior is reset.
     */

    const observer = new MutationObserver(mutations => {
      mutations.forEach(mutation => {
        // The attribute that is added by squarelovin is 'sq-scroll'.
        // The mutation is also called up when the user closes the popup. So we have to distinguish between the initial mutation
        // called by 'sq-scroll' and the one that is called when the user closes the popup.
        if (mutation.attributeName === 'sq-scroll' && mutation.oldValue === null) {
          // The overlay is added by script which is an popup.
          const overlay = document.querySelector('.squarelovin-overlay');

          document.querySelector('html').style.scrollBehavior = 'auto';

          // Reset the scroll behavior when the user clicks on the close button inside the popup.
          overlay.querySelector('.squarelovin-overlay-close-btn').addEventListener('click', () => {
            document.querySelector('html').style.scrollBehavior = 'smooth';
          });
        }
      });
    });
    observer?.observe(document.body, {
      attributes: true,
      attributeOldValue: true,
    });

    eventBus.$once('EditorialInstagram:beforeUnmount', () => {
      // Mutation Observer does not have Method unobserve(), but disconnect()!
      // observer.unobserve();
      observer.disconnect();
    });
  },

  beforeUnmount() {
    eventBus.$emit('EditorialInstagram:beforeUnmount');
  },
};
</script>

<style lang="scss" scoped>
.squarelovin-container {
  position: relative;
  margin: $unit-double * 2 auto;
  max-width: calc(850px + 2 * 128px);
}

// extend hidden area to be able to show the arrows
.squarelovin-container__limiter {
  overflow: hidden;
}
:deep(.squarelovin-widget-container) {
  overflow: visible;
  margin: 0 87px;

  @include helper__until($bp-1024) {
    margin: 0 30px;
  }
}

:deep(.squarelovin-head-container) {
  display: block;
  max-width: calc(850px + 32px);
  margin: 0 auto;
  padding: 0 $unit-base;
}

:deep(.squarelovin-headline) {
  @include text-h2;
  text-align: left !important;
}

:deep(.squarelovin-subtext) {
  text-align: left !important;
}

:deep(.squarelovin-productstream-arrow-margin) {
  margin-right: 134px !important;
}

:deep(.squarelovin-arrow-left),
:deep(.squarelovin-arrow-right) {
  @include helper__button;
  @include helper__transition(color);
  width: $unit-base * 4;
  height: $unit-base * 4;
  background: $color-white no-repeat center center;
  border-radius: 50%;
  margin: -32px $unit-double 0;
  text-indent: -999em;

  &:hover {
    color: $color-ironside-gray;
  }

  &::before {
    content: '';
    position: absolute;
    top: $unit-base;
    right: $unit-half;
    bottom: 0;
    left: $unit-half;
    border-radius: 50%;
    z-index: -1;
    box-shadow: 0 0 50px rgba($color-black, 0.2);
  }

  @include helper__until($bp-1024) {
    width: $unit-base * 3;
    height: $unit-base * 3;
  }
}

:deep(.squarelovin-arrow-left) {
  left: -87px !important;
  color: $color-delta !important;
  background-image: url('../assets/icons/IconArrowLeftBg.svg');

  @include helper__until($bp-1024) {
    left: -50px !important;
  }
}

:deep(.squarelovin-arrow-right) {
  right: -87px !important;
  color: $color-delta !important;
  background-image: url('../assets/icons/IconArrowRightBg.svg');

  @include helper__until($bp-1024) {
    right: -50px !important;
  }
}

:deep(.squarelovin-hover-posted-by) {
  strong {
    @include text-h3;
  }
}
</style>
