<template>
  <div class="content-pill-links">
    <div class="content-pill-links__centered">
      <NolteScroller class="content-pill-links__scroller" shadow-only="">
        <div class="content-pill-links__container">
          <div class="content-pill-links__inner">
            <slot />
          </div>
        </div>
      </NolteScroller>
    </div>
  </div>
</template>

<script>
/* eslint-disable */
import NolteScroller from '../nolte-ui/NolteScroller';

export default {
  name: 'ContentPillLinks',

  components: {
    NolteScroller,
  },
};
</script>

<style lang="scss" scoped>
.content-pill-links {
  max-width: 100%;
  overflow: hidden;
}

.content-pill-links__centered {
  @include spacing;

  @include helper__greater($bp-768) {
    margin-top: $unit-double;
  }
}

.content-pill-links__scroller {
  overflow: inherit;
}

.content-pill-links__container {
  display: inline-block;
  background: $color-white;
  border-radius: $unit-half;
  box-shadow: 0 25px 25px 0 rgba(0, 0, 0, 0.1);
  padding: 0 $unit-base;
}

.content-pill-links__inner {
  display: flex;

  :deep() {
    a,
    button,
    div {
      @include helper__transition((padding, color));
      @include helper__button;
      @include text-h5;
      padding: $unit-base + $unit-half $unit-base;
      // margin: 0 0 0 $unit-base;
      white-space: nowrap;

      &:hover {
        color: $color-delta;
      }
    }
  }
}
</style>
