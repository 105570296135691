<template>
  <main class="neo-default">
    <!--
      The :key forces the re-rendering if there is new data from the layout service.
      Needed if you change the login status of the user.
     -->
    <Placeholder name="jss-simple-header" :rendering="route" />
    <Placeholder name="jss-main" :rendering="route" :key="Date.now()" />
    <Placeholder name="jss-dataprovider" :rendering="route" />
  </main>
</template>

<script>
import Placeholder from '@/components/Placeholder';
import LayoutMixin from './LayoutMixin';

export default {
  name: 'Neo',
  mixins: [LayoutMixin],

  components: {
    Placeholder,
  },
};
</script>

<style lang="scss" scoped>
.neo-default {
  padding-top: 0;
}
</style>
