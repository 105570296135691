<template>
  <main class="international-projects">
    <Placeholder name="jss-international-projects-header" :rendering="route" />
    <Placeholder name="jss-international-projects-main" :rendering="route" />
    <Placeholder name="jss-dataprovider" :rendering="route" />
  </main>
</template>

<script>
import Placeholder from '@/components/Placeholder';
import LayoutMixin from './LayoutMixin';

export default {
  name: 'InternationalProjects',
  mixins: [LayoutMixin],

  components: {
    Placeholder,
  },
};
</script>
