<template>
  <ScPlaceholder :name="name" :rendering="rendering" #default="{ components }">
    <CaptureError>
      <component v-for="(component, index) in components" :is="component" :key="index" />
    </CaptureError>
  </ScPlaceholder>
</template>

<script>
import { Placeholder } from '@sitecore-jss/sitecore-jss-vue';
import CaptureError from '@/components/CaptureError';

export default {
  name: 'Placeholder',

  props: {
    name: {
      type: String,
      required: true,
    },
    rendering: {
      type: Object,
      required: true,
    },
  },

  components: {
    ScPlaceholder: Placeholder,
    CaptureError,
  },
};
</script>
