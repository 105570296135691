import eventBus from '@/lib/eventBus';

export default {
  mounted() {
    eventBus.$emit(
      this.formId + ':conditions:register-component',
      this.field.model.conditionSettings.fieldKey,
      this
    );

    eventBus.$on(this.formId + ':field-change', component => {
      //console.log('FieldConditionMixin EventBus.on: ' + this.formId + ':field-change');
      // we want to emit the event only once for the component that changed
      // as this is a mixin all field components would trigger the event if we wouldn't check that
      if (component !== this) return;

      eventBus.$emit(this.formId + ':conditions:process');
    });
  },

  data() {
    return {
      hidden: false,
      disabled: false,
    };
  },
};
