<template>
  <!-- mind vcard classes -->
  <div class="nolte-address vcard">
    <div class="nolte-address__name fn">{{ name }}</div>
    <div class="adr">
      <span class="street-address">{{ street }}</span
      ><br />
      <span class="postal-code">{{ zip }}</span> <span class="locality">{{ locality }}</span>
      <br />
      <span v-if="country" class="country-name">{{ country }}</span>
    </div>
    <a v-if="phone" class="tel" :href="`tel:${phone}`">{{ phone }}</a>
    <div v-if="email">
      <a class="email" :href="`mailto:${email}`">{{ email }}</a>
    </div>
  </div>
</template>

<script>
export default {
  name: 'NolteAddress',

  props: {
    name: {
      type: String,
      required: true,
    },
    street: {
      type: String,
      required: true,
    },
    locality: {
      type: String,
      required: true,
    },
    zip: {
      type: String,
      required: true,
    },
    country: {
      type: String,
      default: undefined,
    },
    phone: {
      type: String,
      default: undefined,
    },
    email: {
      type: String,
      default: undefined,
    },
  },
};
</script>

<style lang="scss" scoped>
.country-name {
  @include helper__visually-hidden;
}

.nolte-address__name {
  @include text-h4;
  margin-bottom: $unit-half;
}
</style>
