<template>
  <div class="editorial-search-results-header">
    <div class="editorial-search-results-header__content">
      <EditorialHeadline
        class="editorial-search-results-header__copy"
        :fields="{ headline: { value: copy } }"
        headline-level="h3"
      />
      <EditorialHeadline
        class="editorial-search-results-header__headline"
        :fields="headline"
        headline-level="h1"
      />
    </div>
  </div>
</template>

<script>
import EditorialHeadline from '@/components/EditorialHeadline';

export default {
  name: 'SearchResultsHeader',

  components: {
    EditorialHeadline,
  },

  props: {
    searchDetails: {
      type: Object,
      default: undefined,
      validator(details) {
        const keys = Object.keys(details);
        return keys.includes('count') && keys.includes('term');
      },
    },
  },

  computed: {
    headline() {
      return { headline: { value: `»${this.searchDetails.term}«` } };
    },

    copy() {
      return `${this.searchDetails.count} ${this.$t('search-header-copy-text')}`;
    },
  },
};
</script>

<style lang="scss" scoped>
.editorial-search-results-header {
  background: url('../../assets/catalog_hub_mobile_background.svg') no-repeat 0 15% / cover;

  @include helper__greater($bp-768) {
    background-image: url('../../assets/catalog_hub_background.svg');
  }
}

.editorial-search-results-header__content {
  overflow: auto;
}

.editorial-search-results-header__copy {
  margin-bottom: 0;

  @include helper__until($bp-768) {
    margin-top: $unit-half * 3;
  }
}

.editorial-search-results-header__headline {
  margin-top: 0;

  @include helper__until($bp-768) {
    margin-bottom: $unit-half * 3;
  }
}
</style>
