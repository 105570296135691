<template>
  <section class="nolte-details">
    <slot />
  </section>
</template>

<script>
export default {
  name: 'NolteDetails',
};
</script>

<style lang="scss" scoped>
@mixin roundedBorderLine($position: 'top') {
  position: relative;

  $pseudoclass: 'before';

  @if $position == 'bottom' {
    $pseudoclass: 'after';
  }

  &::#{$pseudoclass} {
    position: absolute;
    content: '';
    height: 4px;
    width: 100%;
    background-color: $color-athens-gray;
    border-radius: 2px;
    left: 0;

    @if $position == 'top' {
      top: -2px;
    } @else if $position == 'bottom' {
      bottom: -2px;
    }
  }
}
.nolte-details {
  @include roundedBorderLine('top');
  @include roundedBorderLine('bottom');
  @include text-caption;

  :deep() {
    ul {
      padding-top: $unit-base * 1.5;
      padding-bottom: $unit-base * 1.5;
      padding-left: $unit-double;
    }

    li {
      list-style-type: disc;
      padding-left: $unit-base;
    }

    li + li {
      margin-top: $unit-base * 1.5;
    }

    p {
      margin-top: $unit-base * 1.5;
      padding-bottom: $unit-base * 1.5;
    }

    ul + p,
    p + ul {
      @include roundedBorderLine;
      padding-top: $unit-base * 1.5;
      margin-top: 0; // overwrite
    }
  }
}
</style>
