<template>
  <NolteDetailShowroom v-if="!isEditing" :headline="headline" :copy="copy" :address="address" />

  <div v-else class="editorial-detail-showroom">
    <p>{{ getFieldDisplayName(fields.headline) }}: <sc-text :field="fields.headline" /></p>
    <p>{{ getFieldDisplayName(fields.copy) }}: <sc-text :field="fields.copy" /></p>
    <p>{{ getFieldDisplayName(fields.addressName) }}: <sc-text :field="fields.addressName" /></p>
    <p>
      {{ getFieldDisplayName(fields.addressStreet) }}: <sc-text :field="fields.addressStreet" />
    </p>
    <p>{{ getFieldDisplayName(fields.addressZip) }}: <sc-text :field="fields.addressZip" /></p>
    <p>
      {{ getFieldDisplayName(fields.addressLocality) }}: <sc-text :field="fields.addressLocality" />
    </p>
    <p>
      {{ getFieldDisplayName(fields.addressCountry) }}: <sc-text :field="fields.addressCountry" />
    </p>
    <p>{{ getFieldDisplayName(fields.addressPhone) }}: <sc-text :field="fields.addressPhone" /></p>
    <p>{{ getFieldDisplayName(fields.addressEmail) }}: <sc-text :field="fields.addressEmail" /></p>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import { Text } from '@sitecore-jss/sitecore-jss-vue';
import { getFieldDisplayName } from './helper';

import NolteDetailShowroom from '../nolte-ui/NolteDetailShowroom';

export default {
  name: 'EditorialDetailShowrooms',

  components: {
    ScText: Text,
    NolteDetailShowroom,
  },

  computed: {
    ...mapGetters('jss', ['isEditing']),

    headline() {
      return this.fields?.headline?.value;
    },

    copy() {
      return this.fields?.copy?.value;
    },

    address() {
      return {
        name: this.fields?.addressName?.value,
        street: this.fields?.addressStreet?.value,
        zip: this.fields?.addressZip?.value,
        locality: this.fields?.addressLocality?.value,
        country: this.fields?.addressCountry?.value,
        phone: this.fields?.addressPhone?.value,
        email: this.fields?.addressEmail?.value,
      };
    },
  },

  methods: {
    getFieldDisplayName,
  },
};
</script>
