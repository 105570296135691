<template>
  <label class="nolte-form-label" :bind="$attrs">
    <span v-if="raw" v-html="label" />
    <template v-else>{{ label }}</template>
    <template v-if="required">&nbsp;*</template>
  </label>
</template>

<script>
export default {
  name: 'NolteFormLabel',

  props: {
    label: {
      type: String,
      default: undefined,
    },

    required: {
      type: Boolean,
      default: false,
    },

    raw: {
      type: Boolean,
      default: false,
    },
  },
};
</script>

<style lang="scss" scoped>
.nolte-form-label {
  @include text-form-title;
  display: block;
  margin-left: $unit-half / 4;
  color: $color-ironside-gray;
  padding-bottom: $unit-half;
  font-weight: 100;
}
:deep(a) {
  text-decoration: underline;
}
</style>
