<template>
  <EditorialKitchenTalkFeed :header="header" :article-teasers="articleTeasers" />
</template>

<script>
import { mapGetters } from 'vuex';
import EditorialKitchenTalkFeed from './EditorialKitchenTalkFeed';
import { imageHasSource } from './helper';

export default {
  name: 'EditorialKitchenTalkAllFeed',

  components: {
    EditorialKitchenTalkFeed,
  },

  computed: {
    ...mapGetters(['getTeaserFallbackImage']),

    fieldsData() {
      return this.fields?.data;
    },

    header() {
      return {
        image: this.imageOrDefault(this.fieldsData?.route?.headerImage?.jss),
        headline: this.fieldsData?.route?.label?.value,
        copy: this.fieldsData?.route?.description?.value,
        categories: this.fieldsData?.route?.root?.categories?.map(category => {
          return {
            title: category.title?.value,
            url: category.url,
            active: this.isActive(category.url),
          };
        }),
      };
    },
    articleTeasers() {
      let articles = [];
      this.fieldsData?.route?.root?.categories?.forEach(category =>
        category.articles?.forEach(article => {
          articles.push(this.createTeaser(article));
        })
      );
      return articles;
    },
  },

  methods: {
    imageHasSource,

    createTeaser(article) {
      return {
        headline: article.headline?.value,
        copy: article.teaserCopy?.value,
        image: this.imageOrDefault(article.teaserImage?.jss),
        link: article.link,
        tags: article.sxaTags?.targetItems?.map(tag => {
          return tag.Title?.value;
        }),
      };
    },

    isActive(url) {
      return this.$route.path === url;
    },

    imageOrDefault(image) {
      return this.imageHasSource(image) ? image : this.getTeaserFallbackImage.jss;
    },
  },
};
</script>
