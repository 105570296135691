<template>
  <NolteCollapsable class="editorial-collapsable" :initial-open="isEditing">
    <template #button="{ toggleCollapsable, open }">
      <div class="editorial-collapsable__button">
        <NolteButton @click.passive="toggleCollapsable" :type="collapsableType">
          <!--<template name="icon">-->
          <IconArrowDown
            class="editorial-collapsable__button-arrow"
            :class="{ 'editorial-collapsable__button-arrow--open': open }"
          />
          <!--</template>-->
          <template v-if="!open && textHasValue(fields.buttonLabel)">
            {{ fields.buttonLabel.value }}
          </template>
          <template v-if="open && textHasValue(fields.buttonLabelOpen)">
            {{ fields.buttonLabelOpen.value }}
          </template>
        </NolteButton>
      </div>
    </template>
    <sc-placeholder name="nolte-collapsable_default" :rendering="rendering" />
    <!--<template>
      <component
        :is="component"
        v-for="(component, index) in defaultPlaceholder"
        :key="`default_${index}`"
      />
    </template>-->
  </NolteCollapsable>
</template>

<script>
import { mapGetters } from 'vuex';
import { textHasValue } from './helper';
//import { Text } from '@sitecore-jss/sitecore-jss-vue';

import IconArrowDown from '../assets/icons/IconArrowDown.svg?inline';

import NolteButton from '../nolte-ui/NolteButton';
import NolteCollapsable from '../nolte-ui/NolteCollapsable';

import { Placeholder } from '@sitecore-jss/sitecore-jss-vue';

export default {
  name: 'EditorialCollapsable',
  components: {
    IconArrowDown,
    //ScText: Text,
    NolteButton,
    NolteCollapsable,
    ScPlaceholder: Placeholder,
  },

  props: {
    rendering: {
      type: Object,
      default: undefined,
    },
  },

  computed: {
    ...mapGetters('jss', ['isEditing']),
    collapsableType() {
      return this.fields?._extendedParams?.CollapsableType?.fields?.Type?.value || 'white';
    },
  },

  methods: {
    textHasValue,
  },

  // If withPlaceholder is defined on a component instance, the SitecoreJssPlugin will
  // attempt to inject placeholder data for the requested placeholders as computed properties
  // on the component instance (in this case, `computed.tabsPlaceholder`, a.k.a. `this.tabsPlaceholder`).
  // This is another option compared to using the <Placeholder> component;
  // in this case, chosen because we primarily need access to the _data_ of the placeholder.
  /*withPlaceholder: {
    placeholders: [
      {
        placeholder: 'nolte-collapsable_default',
        computedPropName: 'defaultPlaceholder',
      },
    ],
  },*/
};
</script>

<style lang="scss" scoped>
.editorial-collapsable {
  margin: $unit-double 0;

  @include helper__greater($bp-768) {
    margin: $unit-double * 2 0;
  }
}

.editorial-collapsable__button {
  @include spacing;
  margin: 0 auto !important;
}

.editorial-collapsable__button-arrow {
  @include helper__transition(transform);
}

.editorial-collapsable__button-arrow--open {
  transform: rotate(90deg);
}

.editorial-collapsable :deep() {
  .editorial-rich-text-wrapper {
    @include spacing;
  }
}
</style>
