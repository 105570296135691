export default function (id, lang) {
  // CustomEvent Polyfill
  // this polyfill is placed here as it was only needed for user centrics and the npm integration
  // via 'custom-event' and 'custom-event-polyfill' did not work at the time of integration.
  (function () {
    if (typeof window.CustomEvent === 'function') return false;

    function CustomEvent(event, params) {
      params = params || { bubbles: false, cancelable: false, detail: null };
      const evt = document.createEvent('CustomEvent');
      evt.initCustomEvent(event, params.bubbles, params.cancelable, params.detail);
      return evt;
    }

    window.CustomEvent = CustomEvent;
  })();

  (function (d, s, i) {
    const j = d.createElement(s);
    j.type = 'text/javascript';
    j.async = false;
    j.defer = true;
    j.src = `https://app.usercentrics.eu/browser-ui/latest/bundle.js`;
    j.id = 'usercentrics-cmp';
    j.setAttribute('data-settings-id', `${i}`);

    const sc = d.getElementsByTagName(s)[0];
    sc.parentNode.insertBefore(j, sc);
  })(document, 'script', id, lang);
}
