import { createRouter as createVueRouter, createWebHistory, createMemoryHistory } from 'vue-router';

import RouteHandler from './RouteHandler.vue';

// support languages in the URL prefix
// e.g. /da-DK/path, or /en/path, or /path
export const routePatterns = [
  '/:lang([a-z]{2}-[A-Z]{2})/:sitecoreRoute(.*)*',
  '/:lang([a-z]{2})/:sitecoreRoute(.*)*',
  '/:sitecoreRoute(.*)*',
];

export function createRouter(isSSR) {
  // create an instance of vue-router and configure routes to use the RouteHandler component
  return createVueRouter({
    history: isSSR ? createMemoryHistory() : createWebHistory(),
    routes: routePatterns.map(routePattern => {
      return {
        path: routePattern,
        component: RouteHandler,
        props: route => ({
          route,
        }),
      };
    }),
    scrollBehavior(to, from) {
      // do nothing if paths are the same and only the hash changed
      if (from.path === to.path && from.hash !== to.hash) {
        return false;
      }

      // this exception prevents the product detail page from scrolling to top when clicking on a
      // product variant (those links should be the only ones with "guid" and "id" as query parameters)
      if (to.query.guid && to.query.id) {
        return false;
      }

      // jump to the hash if the path has changed too
      if (to.hash) {
        return new Promise(resolve => {
          setTimeout(() => {
            let target = document.querySelector(to.hash);
            resolve({ selector: target ? to.hash : `a[name="${to.hash.substring(1)}"]` });
          }, 1500);
        });
      }

      return { left: 0, top: 0 };
    },
  });
}
