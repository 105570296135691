<template>
  <div class="editorial-media-wrapper" :class="`editorial-component--${design}`">
    <figure class="editorial-media">
      <div class="details" v-if="fields.headline || fields.copy">
        <sc-text v-if="fields.headline" class="headline" :field="fields.headline" tag="h2" />
        <sc-text v-if="fields.copy" class="copy" :field="fields.copy" tag="p" />
      </div>
      <div class="media">
        <div class="is-editing" v-if="isEditing && !imageHasSource(fields.image)">
          <span>Enter the Wistia video ID: </span>
          <sc-text :field="fields.wistiaVideoId" />
        </div>
        <ContentVideoWistia
          v-if="textHasValue(fields.wistiaVideoId)"
          :video-id="fields.wistiaVideoId.value"
        />
        <EditorialImage
          v-else
          :media="fields.image"
          default-aspect-ratio="3:2"
          default-sizes="(min-width: 1024px) 1024px, (min-width: 768px) 500px, 100vw"
        />
      </div>
    </figure>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import { Text } from '@sitecore-jss/sitecore-jss-vue';
import { textHasValue, imageHasSource } from '@/components/helper';

import EditorialImage from '@/components/EditorialImage';
import ContentVideoWistia from '@/components/ContentVideoWistia';

export default {
  name: 'EditorialMedia',

  components: {
    EditorialImage,
    ScText: Text,
    ContentVideoWistia,
  },

  computed: {
    ...mapGetters('jss', ['isEditing']),

    design() {
      // returns default or dark
      return this.fields._extendedParams?.BackgroundDesign?.fields?.Type?.value || 'default';
    },
  },

  methods: {
    textHasValue,
    imageHasSource,
  },
};
</script>

<style lang="scss" scoped>
.editorial-component--dark {
  overflow: auto;
  background: $color-concret;
  margin: $unit-double 0;

  @include helper__greater($bp-768) {
    margin: $unit-double * 2 auto;
  }
}

.editorial-media {
  @include spacing;

  @include helper__greater($bp-768) {
    display: flex;
    align-items: center;
  }
}

.media {
  width: 100%;
  max-width: 100%;
  margin-bottom: $unit-base;

  @include helper__greater-until($bp-768, $bp-1024) {
    flex: 0 1 50%;
  }

  @include helper__greater($bp-1024) {
    flex: 0 1 62%;
  }
}

.details {
  @include helper__greater-until($bp-768, $bp-1024) {
    flex: 0 1 50%;
    padding: 0 $unit-double 0 0;
  }

  @include helper__greater($bp-1024) {
    flex: 0 1 38%;
    padding: 0 $unit-double * 2 0 0;
  }
}

.headline {
  @include text-h2;
  margin-bottom: $unit-base;

  @include helper__greater($bp-768) {
    font-size: 40px;
    line-height: (48 / 40);
  }
}

.copy {
  @include text-copy;
  margin-bottom: $unit-base;
}

.is-editing {
  margin-bottom: $unit-half !important;
}
</style>
