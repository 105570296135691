<template>
  <div class="nolte-catalog-menu-neo" :class="{ 'nolte-catalog-menu-neo--active': isActive }">
    <ul class="buttons">
      <li>
        <ContentLink :editable="false" :field="{ value: { href, linktype: 'internal' } }">
          <div class="indicator">
            <IconNeo />
          </div>
        </ContentLink>
      </li>
      <li class="more-button" v-if="isMoreButtonValid">
        <div ref="widthAnchor" class="width-anchor">
          <ContentLink :editable="false" :field="neoMoreButton" />
        </div>
      </li>
    </ul>
  </div>
</template>

<script>
// TODOCE: "Mehr erfahren"-Button kommt noch nicht von shared dev
import vClickOutside from 'click-outside-vue3';

import { linkHasValue } from '@/components/helper';
import { touchHover } from '../directives';
import { mapGetters } from 'vuex';
import ContentLink from '@/components/ContentLink';
import IconNeo from '@/assets/icons/IconNeo.svg?inline';

export default {
  name: 'NolteCatalogMenuCategories',

  components: {
    IconNeo,
    ContentLink,
  },

  directives: {
    clickOutside: vClickOutside.directive,
    touchHover,
  },

  props: {
    catalogUrl: {
      type: String,
      required: true,
    },
    activeOptionId: {
      type: String,
      default: undefined,
    },
    neoFilters: {
      type: Array,
      required: true,
    },
    neoMoreButton: {
      type: Object,
      default: null,
    },
  },

  data() {
    return {
      categoriesTimeout: null,
    };
  },

  computed: {
    ...mapGetters(['isMobile']),

    isActive() {
      return this.neoFilters[0].id == this.activeOptionId;
    },
    isMoreButtonValid() {
      return this.neoMoreButton && this.linkHasValue(this.neoMoreButton);
    },
    href() {
      return this.isActive ? this.catalogUrl : this.neoFilters[0].url;
    },
  },

  mounted() {
    if (this.isMoreButtonValid && !this.isMobile) {
      this.setScrollWidth();
    }
  },

  watch: {
    isActive() {
      if (this.isMoreButtonValid && !this.isMobile) {
        this.setScrollWidth();
      }
    },
  },

  methods: {
    linkHasValue,

    setScrollWidth() {
      const $anchor = this.$refs.widthAnchor;
      if (this.isActive) {
        $anchor.style.width = $anchor.scrollWidth + 32 + 'px';
      } else {
        $anchor.style.width = null;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
// white background
.nolte-catalog-menu-neo {
  max-width: 100%;
  background-color: $color-white;
  border-radius: $radius-default;
  position: relative;
  margin-left: $unit-half;

  // shadow
  &::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    box-shadow: 0 25px 25px 0 rgba($color-black, 0.1);
    z-index: -1;
    border-radius: $radius-default;
  }
}

.buttons {
  display: inline-flex;
  overflow: hidden;
  height: 100%;

  a {
    @include helper__transition(color);
    @include text-h5;
    display: block;
    padding: $unit-base + $unit-half $unit-double;
    cursor: pointer;
    user-select: none;
    border: 0;
    height: 100%;
    box-sizing: border-box;

    svg {
      @include helper__transition;
      color: #7c7c7b;
      width: 64px;
      height: 10px;
    }

    /**
    * Directive touchHover has the modifier "mobileFlag" which adds the event touchstart
    * to the element. After this event gets triggered, the data-attribute data-touch-hover--mobile
    * will set. This helps in combination with :not to prevent :hover from staying active after
    * the element got touched.
    */
    &:not([data-touch-hover--mobile]):hover,
    &[data-touch-hover] {
      color: $color-delta;
      svg {
        color: $color-delta;
      }
    }
  }

  > li:first-child a {
    padding: $unit-half $unit-double $unit-half $unit-double;
  }
  > li.more-button a {
    padding: $unit-base + $unit-half $unit-double $unit-base + $unit-half 0;
  }
}

// button animation in
@include helper__greater($bp-768) {
  .buttons {
    // width-anchor
    > li.more-button .width-anchor {
      display: block;
      position: relative;
      @include helper__transition;
      width: 0;
    }
  }
}
// /button animation in

.indicator {
  position: relative;
  height: 100%;
  display: flex;
  align-items: center;

  &::before {
    @include helper__transition(transform);
    content: '';
    position: absolute;
    background-color: $color-aureolin;
    height: $unit-half;
    width: 100%;
    left: 0;
    bottom: -$unit-half;
    // rotate(0.01deg) avoids jitter in Chrome
    // https://greensock.com/forums/topic/16385-chrome-bug-when-i-scale-an-element-with-background-image-the-image-flickers/
    transform: scaleY(0) rotate(0.01deg);
    // Fix for Chrome breaks Firefox. Need another solution to avoid jitter in Firefox
    @include helper__composited-layer;
    transform-origin: bottom;
  }
}

.nolte-catalog-menu-neo--active {
  .indicator::before {
    transform: scaleY(1);
  }
}
</style>
