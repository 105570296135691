// recursive function to simplify main navigation items
export default function processNavigationItems(items) {
  if (!items) {
    return false;
  }
  const mainNavigationItemId = '{D063E9D1-C7B5-4B1E-B31E-69886C9C59F5}';

  const simplifiedItems = items
    .filter(item => {
      // filter items that are not available in the current language
      if (item.versions?.length === 0) return false;

      // filter the page data items
      if (item.template?.name === 'Page Data') return false;

      // filter items that should not be shown in the main navigation
      return item.NavigationFilter?.value.indexOf(mainNavigationItemId) === -1;
    })
    .map(item => {
      let mainitem;

      if (item.template?.name === 'PermanentRedirect') {
        // if we have a permanent redirect we can use the complete link definition
        mainitem = item.RedirectUrl.jss.value;
        mainitem.href_original = item.url;
        mainitem.text = item.NavigationTitle.value;
      } else {
        // otherwise the item is dynamic and we have to rebuild a link construct
        mainitem = {
          href: item.url,
          text: item.NavigationTitle.value,
          linktype: 'internal',
          target: null,
          navigationIcon: item.NavigationIcon?.targetItem?.type?.value,
        };
      }

      // add children only for items that should be shown in the subnavigation
      if (item.showChildrenAsSubnavigation?.value === '1' && item.children) {
        mainitem.children = processNavigationItems(item.children);
      }

      return mainitem;
    });

  return simplifiedItems;
}
