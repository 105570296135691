<template>
  <NolteTeaser
    v-if="!isEditing"
    :image="imageHasSource(fields.image) ? fields.image : getTeaserFallbackImage.jss"
    :headline="textHasValue(fields.headline) ? fields.headline.value : ''"
    :copy="textHasValue(fields.copy) ? fields.copy.value : ''"
    :link-url="linkHasValue(fields.link) ? fields.link.value.href : ''"
    :link-title="linkHasValue(fields.link) ? fields.link.value.text : ''"
    :link-external="isExternal"
    :target="linkHasValue(fields.link) ? fields.link.value.target : ''"
    :aspect-ratio="aspectRatio"
  />
  <div v-else class="editorial-teaser">
    <div class="editorial-teaser__image">
      <EditorialImage
        :media="fields.image"
        fit="cover"
        default-sizes="1024px"
        :default-aspect-ratio="aspectRatio"
      />
    </div>
    <div class="editorial-teaser__description">
      <h3 class="editorial-teaser__headline"><sc-text :field="fields.headline" /></h3>
      <p class="editorial-teaser__copy"><sc-text :field="fields.copy" /></p>
    </div>
    <sc-text :field="fields.link" />
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import { Text } from '@sitecore-jss/sitecore-jss-vue';
import EditorialImage from '@/components/EditorialImage';
import NolteTeaser from '../nolte-ui/NolteTeaser';
import { textHasValue, imageHasSource, linkHasValue } from '@/components/helper';

export default {
  name: 'EditorialTeaser',

  components: {
    ScText: Text,
    NolteTeaser,
    EditorialImage,
  },

  props: {
    editable: {
      type: Boolean,
      default: true,
    },
  },

  computed: {
    ...mapGetters('jss', ['isEditing']),
    ...mapGetters(['getTeaserFallbackImage']),

    preferedSize() {
      return this.fields?._extendedParams?.PreferedSize?.fields?.Size?.value || 'default';
    },

    isExternal() {
      return this.fields?.link?.value?.linktype === 'external';
    },

    aspectRatio() {
      const imageAspectRatio = this.fields?.image?.value?.width / this.fields?.image?.value?.height;
      return {
        value: imageAspectRatio >= 1 ? '3:2' : '3:4',
      };
    },
  },

  methods: {
    imageHasSource,
    textHasValue,
    linkHasValue,
  },
};
</script>

<style lang="scss" scoped>
.editorial-teaser {
  position: relative;
  cursor: pointer;
  overflow: hidden;
  border-radius: $radius-default;
  background-color: $color-white;
}

.editorial-teaser__description {
  padding: 1.5 * $unit-base $unit-base;
}

.editorial-teaser__headline {
  @include text-h3;
}

.editorial-teaser__copy {
  @include text-copy;
  margin-top: $unit-half;
  color: $color-delta;
}
</style>
