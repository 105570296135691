<template>
  <div class="global-mnk-progressbar">
    <div class="global-mnk-progressbar__inner">
      <div
        class="global-mnk-progressbar__step"
        v-for="{ id, label, href, isActive, isFinished } in steps"
        :key="id"
      >
        <h3>
          <ContentLink
            v-if="isActive || isFinished"
            :editable="false"
            :field="{
              value: { text: label, href: href, linktype: 'internal' },
            }"
          />
          <span v-else>
            {{ label }}
          </span>
        </h3>
        <div :class="['global-mnk-progressbar__trail', isActive || isFinished ? 'active' : '']">
          <div class="trail__inner" />
          <ActiveSymbol v-if="isFinished" class="icon" />
          <InactiveSymbol v-else class="icon" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex';
import Ellipse from '../assets/icons/Ellipse.svg?inline';
import EllipseArrow from '../assets/icons/EllipseArrow.svg?inline';
import ContentLink from '@/components/ContentLink';

export default {
  name: 'GlobalMNKProgressbar',

  components: {
    ActiveSymbol: EllipseArrow,
    InactiveSymbol: Ellipse,
    ContentLink,
  },

  methods: {
    getStepHref(step) {
      return step?.link?.jss?.value?.href;
    },

    getStepLabel(step) {
      return step?.link?.jss?.value?.text;
    },
  },

  mounted() {
    if (!this.$isServer && this.$router.referer) {
      const fullPath = this.$router.referer.fullPath;

      if (this.steps[0].isActive === true && fullPath !== '/') {
        localStorage.setItem('progressbarReferrer', fullPath);
      }
    }
  },

  computed: {
    ...mapState('jss', ['sitecoreContext']),

    datasource() {
      return this.fields?.data?.datasource;
    },

    steps() {
      const steps = this.fields?.data?.datasource?.children;
      const activeStepId =
        steps?.findIndex(step => this.getStepHref(step) === this.fields?.data?.contextItem.url) ??
        -Infinity;

      return steps?.slice(0, -1).map((step, id) => {
        return {
          id,
          label: this.getStepLabel(step),
          href: this.getStepHref(step),
          isActive: id == activeStepId,
          isFinished: id < activeStepId,
        };
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.global-mnk-progressbar {
  flex-basis: 100%;
  max-width: 1200px;
  display: flex;
  align-items: flex-end;

  @include helper__until($bp-1024) {
    align-items: center;
    justify-content: center;
  }

  @include helper__greater($bp-1024) {
    padding-bottom: $unit-half;
  }
}

.global-mnk-progressbar__inner {
  display: flex;
}
.global-mnk-progressbar__step {
  display: flex;
  flex-direction: column;
  h3 {
    font-weight: 700;
    font-size: 0.8em;
    padding-right: $unit-base + 38px;
    line-height: 0;
    text-transform: uppercase;
    @include helper__until($bp-1024) {
      display: none;
    }
  }
  @include helper__until($bp-1024) {
    &:first-child {
      .global-mnk-progressbar__trail {
        .trail__inner {
          display: none;
        }
      }
    }
  }
  &:not(:first-child) {
    h3 {
      padding-left: $unit-base;
    }
  }
}
.global-mnk-progressbar__trail {
  display: flex;
  align-items: center;
  color: #dedede;
  .trail__inner {
    height: $unit-half;
    background-color: #dedede;
    width: 100%;
    min-width: $unit-double;
  }
  &.active {
    color: $color-aureolin;
    .trail__inner {
      background-color: $color-aureolin;
    }
  }
}
.icon {
  flex: 0 0 auto;
  max-width: 38px;
}
</style>
