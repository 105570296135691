import eventBus from '@/lib/eventBus';

export default {
  mounted() {
    eventBus.$emit(
      this.formId + ':register-component',
      this.field.model.conditionSettings.fieldKey,
      this
    );
  },
};
