import { gridAspectRatios } from '../nolte-ui/NolteGrid/NolteGridCell';
import { CHARACTER_LENGTH_BREAKPOINT } from '../nolte-ui/NolteTip';

const { ASPECT_RATIO_3_2, ASPECT_RATIO_3_2_LARGE, ASPECT_RATIO_3_4 } = gridAspectRatios;

export function populateAspectRatio(component) {
  let aspectRatio = ASPECT_RATIO_3_2;
  const imageAspectRatio = component.image?.value?.width / component.image?.value?.height;

  switch (true) {
    case component.type === 'Teaser' || component.type === 'EditorialTeaser':
      if (imageAspectRatio >= 1) {
        if (component.preferedSize === 'large') {
          aspectRatio = ASPECT_RATIO_3_2_LARGE;
          break;
        }
        aspectRatio = ASPECT_RATIO_3_2;
        break;
      }
      aspectRatio = ASPECT_RATIO_3_4;
      break;
    case component.type === 'Tip' ||
      (component.type === 'EditorialTip' && component.wistiaVideoId?.value === ''):
      aspectRatio =
        component.copy?.length > CHARACTER_LENGTH_BREAKPOINT ? ASPECT_RATIO_3_4 : ASPECT_RATIO_3_2;
      break;
    default:
      aspectRatio = ASPECT_RATIO_3_2;
  }
  component.aspectRatio = aspectRatio;
  return component;
}

export function processGrid(gridCells) {
  const processedGrid = [];
  if (gridCells) {
    // randomly place large images
    while (gridCells.length) {
      const component = gridCells[0];
      if (gridCells.length % 7 === 0) {
        component.preferedSize = 'large';
      }
      populateAspectRatio(component);
      processedGrid.push(component);
      gridCells.splice(0, 1);
    }
  }
  return processedGrid;
}

// Shuffle function based on Fisher-Yates algorithm
export function shuffleArray(array) {
  if (array) {
    for (let i = array.length - 1; i > 0; i--) {
      let j = Math.floor(Math.random() * (i + 1)); // random index from 0 to i

      // swap elements array[i] and array[j] using "destructuring assignment" syntax
      // same can be written as:
      // let t = array[i]; array[i] = array[j]; array[j] = t
      [array[i], array[j]] = [array[j], array[i]];
    }
  }
  return array;
}

export function textHasValue(text) {
  return text && text.value !== '';
}

export function linkHasValue(link) {
  return link && link.value && link.value.href && link.value.href !== '';
}

export function imageHasSource(image) {
  return image?.value?.src !== undefined && image?.value?.class !== 'scEmptyImage';
}

// json data from an integrated graphql request does not come in the same format as fields from the layout service
// so use this function to correct the format of the fields object
export function flattenGraphqlFields(fields) {
  return fields.reduce((acc, { name, jss }) => {
    acc[name] = jss;
    return acc;
  }, {});
}

export function resolveQuery(query, regEx, value) {
  return JSON.parse(JSON.stringify(query).replace(regEx, value));
}

export function getFieldDisplayName(field) {
  const matches = field?.editable?.match(/"displayName":".*",/);
  return matches && matches.length ? matches[0].replace(/"|,|:|displayName/g, '') : '';
}

export function generateScaling(src, scaling, omitWidth, keepQueryParameters) {
  let jssSrc = src
    // We have to rewrite all urls to use the jssmedia handler. Sitecore itself does it only for
    // images used with sc-image but not for images that are fetched via graphQL.
    .replace(new RegExp('(/[-~]{1})/media/', 'i'), '$1/jssmedia/');

  const isRelative = jssSrc.charAt(0) === '/';
  // URL needs a domain for relative URLs. For absolute URLs the domain parameter is ignored.
  const url = new URL(jssSrc, 'https://dummy.domain');

  // we don't want to use any of the parameters that Sitecore appends
  // except the 'u' parameter when image ratio is used
  // and the 'sc_revision' parameter of the default src if no image ratio of the cropping module is used

  // these are the cache buster parameters
  if (!keepQueryParameters) {
    Array.from(url.searchParams.entries()).map(item => {
      if (item[0] !== 'u' && item[0] !== 'cb') {
        url.searchParams.delete(item[0]);
      }
    });
  }

  // add our own parameters
  url.searchParams.append('mw', scaling);
  url.searchParams.append('as', 0);
  jssSrc = isRelative ? url.pathname + url.search : url.href;

  if (!omitWidth) {
    jssSrc += ` ${scaling}w`;
  }

  return jssSrc;
}

/*
Sometimes we get Sitecore IDs in different flavors, e.g.
  "E6FB968E5A5A4553BF0F97138AF17AC2"
  "{13ABD7C8-5D13-434F-98DA-2AC8FEDC0CA2}"
  "e6fb968e-5a5a-4553-bf0f-97138af17ac2"
The backend is able to work with all those formats but in the FE it can be necessary to have
always the same format.
*/
export function unifySitecoreId(id) {
  return id.replace(/[^a-f0-9]/gi, '').toUpperCase();
}

// localized (for decimal separator) file size
export function humanFileSize(bytes, locale) {
  const i = Math.floor(Math.log(bytes) / Math.log(1000));
  const num = (bytes / Math.pow(1000, i)).toFixed(1) * 1;
  return num.toLocaleString(locale) + ' ' + ['B', 'kB', 'MB', 'GB', 'TB'][i];
}
