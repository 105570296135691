<template>
  <v-field-template name="copy" :field="field" :hidden="hidden">
    <template #field>
      <v-dynamic-html-tag
        class="copy__content"
        :tag="field.model.htmlTag"
        :text="field.model.text"
      />
    </template>
  </v-field-template>
</template>

<script>
import VDynamicHtmlTag from './DynamicHtmlTag';
import FieldMixin from '../structure/FieldMixin';
import FieldConditionMixin from '../structure/conditions/FieldConditionMixin';
import FieldValidationMixin from '../structure/validation/FieldValidationMixin';

export default {
  name: 'SitecoreFormsFieldsCopy',

  mixins: [FieldMixin, FieldConditionMixin, FieldValidationMixin],

  components: {
    VDynamicHtmlTag,
  },
};
</script>

<style lang="scss" scoped>
.form-field--text-small .copy__content {
  font-size: $unit-base;
}

.copy__content {
  @include text-intro;
  text-align: center;
  padding: $unit-base;
  margin-top: $unit-base;
  margin-bottom: $unit-base;
}
</style>

<style lang="scss">
// special styles for forms in lightboxes (used in MNK)
.customContent .form-field-type-copy--headline .copy__content {
  @include text-h3;
  padding: 0;
  text-align: left;
  color: $color-ironside-gray;
}
.customContent .form-field-type-copy--small .copy__content {
  @include text-base;
  padding: 0;
  text-align: left;
  color: $color-ironside-gray;
}
</style>
