<template>
  <div class="nolte-kitchen-talk-feed-header">
    <EditorialImage
      class="nolte-kitchen-talk-feed-header__image"
      :media="header.image"
      fit="cover"
      default-sizes="1000px"
      default-aspect-ratio="1:1"
      :sources="{
        '(min-width: 600px)': '16:10',
      }"
      :editable="false"
    />

    <div class="nolte-kitchen-talk-feed-header__overlay"></div>

    <div class="nolte-kitchen-talk-feed-header__content-wrapper">
      <div class="nolte-kitchen-talk-feed-header__head">
        <div class="nolte-kitchen-talk-feed-header__headline-wrapper">
          <NolteHeadline
            class="nolte-kitchen-talk-feed-header__headline catalog-header__primary"
            :level="'h1'"
          >
            {{ header.headline }}
          </NolteHeadline>
        </div>
        <p class="nolte-kitchen-talk-feed-header__copy">
          {{ header.copy }}
        </p>
      </div>

      <NolteKitchenTalkMenu
        v-if="header.categories"
        class="nolte-kitchen-talk-feed-header__menu"
        :categories="header.categories"
        :is-mobile="isMobile"
        ref="kitchenTalkMenu"
      />

      <NolteKitchenTalkMenuSticky
        v-if="header.categories"
        :categories="header.categories"
        :is-mobile="isMobile"
        :show-if-possible="isKitchenTalkMenuHidden"
      />
    </div>
  </div>
</template>

<script>
import 'intersection-observer';
import { mapGetters, mapState } from 'vuex';
import EditorialImage from './EditorialImage';
import NolteHeadline from '../nolte-ui/NolteHeadline';
import NolteKitchenTalkMenu from '../nolte-ui/NolteKitchenTalkMenu';
import NolteKitchenTalkMenuSticky from '../nolte-ui/NolteKitchenTalkMenu/NolteKitchenTalkMenuSticky';
import intersectionObserverHelper from '../nolte-ui/NolteStickyMenu/helper.js';
import eventBus from '@/lib/eventBus';

export default {
  name: 'EditorialKitchenTalkFeedHeader',

  components: {
    NolteKitchenTalkMenu,
    NolteHeadline,
    NolteKitchenTalkMenuSticky,
    EditorialImage,
  },

  props: {
    header: {
      type: Object,
      required: true,
    },
  },

  data() {
    return {
      isKitchenTalkMenuHidden: false,
    };
  },

  computed: {
    ...mapGetters(['isMobile']),
    ...mapState('jss', ['sitecoreContext']),
  },

  mounted() {
    if (this.$refs?.kitchenTalkMenu) {
      intersectionObserverHelper.call(
        this,
        { rootMargin: `${this.isMobile ? '-100px' : '-170px'} 0px 0px 0px` },
        this.$refs?.kitchenTalkMenu?.$el,
        this.intersectionObserverCallback
      );
    }
  },
  beforeUnmount() {
    eventBus.$emit('intersectionObserverHelper:beforeUnmount');
  },
  methods: {
    intersectionObserverCallback(entries) {
      entries.forEach(entry => {
        this.isKitchenTalkMenuHidden = !entry.isIntersecting;
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.nolte-kitchen-talk-feed-header {
  display: block;
  position: relative;
  @include helper__aspect-ratio(16 / 10);

  @include helper__until($bp-600) {
    @include helper__aspect-ratio(1);
  }
}

.nolte-kitchen-talk-feed-header__image {
  height: 100%;
  width: 100%;

  .editorial-kitchen-talk-feed-feed & {
    display: inline;
  }
}

.nolte-kitchen-talk-feed-header__overlay {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  background-color: rgba($color-black, 0.6);
  pointer-events: none;
}

.nolte-kitchen-talk-feed-header__content-wrapper {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  padding-bottom: $unit-double * 2;

  @include helper__until($bp-600) {
    padding-bottom: $unit-double;
  }
}

.nolte-kitchen-talk-feed-header__headline {
  @include text-header-hero;
  line-height: 1.1 !important;
  margin-bottom: $unit-base;
}

.nolte-kitchen-talk-feed-header__copy {
  @include text-intro;
}

.nolte-kitchen-talk-feed-header__head {
  color: $color-white;
  padding-left: $unit-base;
  padding-right: $unit-double;

  @include helper__greater($bp-768) {
    padding-left: 80px;
  }
}

.nolte-kitchen-talk-feed-header__menu {
  margin-top: $unit-double;
}
</style>
