<template>
  <div class="form-fields">
    <template v-for="field in fields">
      <component
        v-if="fieldComponent[field.model.fieldTypeItemId]"
        :is="fieldComponent[field.model.fieldTypeItemId]"
        :field="field"
        :key="field.model.itemId"
        :form-id="formId"
        @submit="$emit(formId + ':submit')"
      />
      <div v-else :key="field.model.itemId">
        Missing component definition for field:
        <pre>
          {{ field.model }}
        </pre>
      </div>
    </template>
  </div>
</template>

<script>
import VCheckbox from '../fields/Checkbox';
import VCheckboxlist from '../fields/Checkboxlist';
import VCopy from '../fields/Copy';
import VDate from '../fields/Date';
import VDropdownlist from '../fields/Dropdownlist';
import VEmail from '../fields/Email';
import VEmailconfirmation from '../fields/EmailConfirmation.vue';
import VHtmltext from '../fields/Htmltext';
import VHtmlcheckbox from '../fields/Htmlcheckbox';
import VListbox from '../fields/Listbox';
import VNin from '../fields/Nin';
import VNumber from '../fields/Number';
import VMultiplelinetext from '../fields/Multiplelinetext';
import VPassword from '../fields/Password';
import VPasswordconfirm from '../fields/Passwordconfirm';
import VRadiolist from '../fields/Radiolist';
import VSinglelinetext from '../fields/Singlelinetext';
import VFileupload from '../fields/FileUpload';
import VSection from './Section';
import VSubmit from '../fields/Submit';
import VTelephone from '../fields/Telephone';

// to decide which fields component we have to show we have to check the templateId
// field.model.fieldTypeItemId: Vue-Component-Name
const fieldComponent = {
  '{447AA745-6D29-4B65-A5A3-8173AA8AF548}': 'v-section',
  '{4DA85E8A-3B48-4BC6-9565-8C1F5F36DD1B}': 'v-checkbox',
  '{D86A361A-D4FF-46B2-9E97-A37FC5B1FE1A}': 'v-checkboxlist',
  '{983BFA5F-C6B6-4AEE-A3BB-46B95D52E7DF}': 'v-copy',
  '{38137D30-7B2A-47D5-BBD8-133252C01B28}': 'v-date',
  '{E0CFADEE-1AC0-471D-A820-2E70D1547B4B}': 'v-dropdownlist',
  '{CAC81126-52E6-4E68-99A8-CE1EC4024590}': 'v-dropdownlist',
  '{04C39CAC-8976-4910-BE0D-879ED3368429}': 'v-email',
  '{72B22999-8509-49A1-BD0C-04D68A9131CD}': 'v-emailconfirmation',
  '{14D4EF6F-1917-4998-BA69-CC3EC31693ED}': 'v-htmltext',
  '{E262D760-7394-4ADC-9EEC-4F8CE1D7266B}': 'v-htmlcheckbox',
  '{1D445447-B313-45E6-972F-90115E343BF6}': 'v-nin',
  '{222A2121-D370-4C6F-80A3-03C930B718BF}': 'v-listbox',
  '{A296A1C1-0DA0-4493-A92E-B8191F43AEC6}': 'v-multiplelinetext',
  '{5B153FC0-FC3F-474F-8CB8-233FB1BEF292}': 'v-number',
  '{668A1C37-9D6B-483B-B7C1-340C92D04BA4}': 'v-password',
  '{6293530F-36A1-4CA6-A2E6-C59C9343F096}': 'v-passwordconfirm',
  '{EDBD38A8-1AE9-42EC-8CCD-F5B0E2998B4F}': 'v-radiolist',
  '{4EE89EA7-CEFE-4C8E-8532-467EF64591FC}': 'v-singlelinetext',
  '{7CE25CAB-EF3A-4F73-AB13-D33BDC1E4EE2}': 'v-submit',
  '{DF74F55B-47E6-4D1C-92F8-B0D46A7B2704}': 'v-telephone',
  '{687A7B09-47E3-4FAA-AB2D-A4BF83DB0F1B}': 'v-fileupload',
};

export default {
  name: 'SitecoreFormsStructureFields',

  components: {
    // default bundled components
    VCheckbox,
    VCheckboxlist,
    VCopy,
    VDate,
    VDropdownlist,
    VEmail,
    VEmailconfirmation,
    VFileupload,
    VHtmltext,
    VHtmlcheckbox,
    VListbox,
    VNin,
    VNumber,
    VMultiplelinetext,
    VPassword,
    VPasswordconfirm,
    VRadiolist,
    VSinglelinetext,
    VSection,
    VSubmit,
    VTelephone,
  },

  props: {
    fields: {
      type: Array,
      default: () => [],
    },
    formId: {
      type: String,
      default: () => '',
    },
  },

  data() {
    return {
      fieldComponent,
    };
  },

  beforeCreate: function () {
    this.$options.components.VSection = require('./Section').default;
  },
};
</script>
