<template>
  <div ref="container" class="editorial-neo-fullscreen" :class="{ isEditing }">
    <ContentAnchor
      :fields="{
        anchorTitle: fields['Anchor Title'],
        anchorName: fields['Anchor Name'],
      }"
    /><EditorialImage
      v-if="imageHasSource(fields['Image']) || isEditing"
      :media="fields['Image']"
      :sources="{
        '(min-width: 769px)': '16:10',
      }"
      default-aspect-ratio="3:4"
      default-sizes="100vw"
      fit="cover"
      class="image"
    />

    <div class="copy" :class="`copy--${alignment}`">
      <div>
        <EditorialHeadline
          v-if="textHasValue(fields['Title']) || isEditing"
          :fields="{ headline: fields['Title'] }"
          class="title"
          ref="title"
          headline-level="h2"
        />

        <EditorialRichtext
          v-if="textHasValue(fields['Text']) || isEditing"
          :fields="{ content: fields['Text'] }"
          class="text"
          ref="text"
        />
      </div>
    </div>

    <EditorialImage
      v-if="isEditing"
      :media="fields['Image']"
      :sources="{
        '(min-width: 769px)': '16:10',
      }"
      default-aspect-ratio="3:4"
      default-sizes="100vw"
      fit="cover"
      class="isEditing--image"
    />
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import EditorialImage from '@/components/EditorialImage';
import EditorialHeadline from '@/components/EditorialHeadline';
import EditorialRichtext from '@/components/EditorialRichText';
import ContentAnchor from '@/components/ContentAnchor';

import { textHasValue, imageHasSource } from './helper';
import 'intersection-observer';
import eventBus from '@/lib/eventBus';

export default {
  name: 'EditorialNeoFullscreen',

  components: {
    EditorialImage,
    EditorialHeadline,
    EditorialRichtext,
    ContentAnchor,
  },

  mounted() {
    this.animateOnScroll(this.$refs?.title?.$el);
    this.animateOnScroll(this.$refs?.text?.$el);
  },

  beforeUnmount() {
    eventBus.$emit('EditorialNeoFullscreen:beforeUnmount');
  },

  computed: {
    ...mapGetters('jss', ['isEditing']),

    alignment() {
      return this.fields?._extendedParams?.['RightAlign']?.value ? 'right' : 'left';
    },

    showInAnchorNavigation() {
      return this.fields && this.fields['Show Anchor in Navigation']?.value ? true : false;
    },
  },

  methods: {
    textHasValue,
    imageHasSource,

    animateOnScroll($el) {
      if (!$el) return;
      $el.classList.add('animate-on-scroll');
      const observer = new IntersectionObserver(
        entries => {
          entries.forEach(entry => {
            if (entry.isIntersecting) {
              $el.classList.add('animate-on-scroll--in');
            }
          });
        },
        {
          rootMargin: '0px 0px -100px 0px',
        }
      );
      observer.observe($el);

      eventBus.$once('EditorialNeoFullscreen:beforeUnmount', () => {
        // target element was missing!
        // observer.unobserve();
        if ($el) {
          observer.unobserve($el);
        }
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.editorial-neo-fullscreen {
  position: relative;
  width: 100%;
  @include fullHeightWithoutHeader(true);
  overflow: hidden;
  background: $color-black;
}

.image {
  position: absolute;
  width: 100%;

  @include helper__greater($bp-768) {
    height: 100%;
  }

  @include helper__until($bp-768) {
    &::after {
      pointer-events: none;
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background: linear-gradient(0deg, #000000ff 0%, #00000000 50%);
    }
  }
}

.copy {
  position: relative;
  top: 0;
  left: 0;
  width: 100%;
  @include fullHeightWithoutHeader(true);
  padding: $unit-double 0;
  display: flex;
  align-items: center;

  &::before {
    content: '';
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    pointer-events: none;
  }

  &--right::before,
  &--left::before {
    background: #00000066;
  }

  @include helper__greater($bp-600) {
    padding: 0;
    &--right::before {
      background: linear-gradient(270deg, #000000cc 0%, #00000000 50%);
    }
    &--left::before {
      background: linear-gradient(90deg, #000000cc 0%, #00000000 50%);
    }
  }

  @include helper__greater($bp-600) {
    > div {
      width: 50%;
    }

    &--right {
      > div {
        margin: 0 8.3% 0 auto;
      }
    }

    &--left {
      > div {
        margin: 0 auto 0 8.3%;
      }
    }
  }

  @include helper__greater($bp-1440) {
    > div {
      width: 25%;
    }

    &--right {
      > div {
        margin: 0 8.3% 0 auto;
      }
    }

    &--left {
      > div {
        margin: 0 auto 0 8.3%;
      }
    }
  }
}

.title {
  margin-top: 0;
  :deep(.nolte-headline) {
    @include text-h1-detail;
  }
}

.text {
  margin: 0;
  :deep(.editorial-text-multi-line) {
    @include text-base;
  }
}

.copy--right {
  .animate-on-scroll {
    transform: translateX(200px);
  }
}
.copy--left {
  .animate-on-scroll {
    transform: translateX(-200px);
  }
}
.copy--right .animate-on-scroll,
.copy--left .animate-on-scroll {
  transition: all 0.5s;
  opacity: 0;

  &--in {
    opacity: 1;
    transform: translateX(0);
  }
}

// for experience editor
.editorial-neo-fullscreen.isEditing {
  // removes the animation to have clean dotted borders in the experience editor
  .copy--right .animate-on-scroll,
  .copy--left .animate-on-scroll {
    transition: none;
    opacity: 1;
    transform: translateX(0);
  }

  .isEditing--image {
    position: absolute;
    right: $unit-double;
    bottom: $unit-double;
    width: 200px;
    outline: 1px solid #ffffff66;
  }
}

.anchor-navigation--visible {
  .editorial-neo-fullscreen {
    @include fullHeightWithoutAnchorNavigation(true);

    .copy {
      @include fullHeightWithoutAnchorNavigation(true);
    }
  }
}
</style>
