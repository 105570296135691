<template>
  <v-field-template
    name="multiplelinetext"
    :field="field"
    :hidden="hidden"
    :disabled="disabled"
    :errors="errors"
  >
    <template #field>
      <NolteFormWrapper :errors="errors" #default="{ hasError }">
        <NolteFormTextarea
          :id="field.valueField.id"
          :name="field.valueField.name"
          :placeholder="field.model.placeholderText"
          :rows="field.model.rows"
          :required="field.model.required"
          :minlength="field.model.minLength"
          :maxlength="field.model.maxLength"
          :disabled="disabled"
          v-model="value"
          @blur="fieldBlur"
          :invalid="hasError"
        />
      </NolteFormWrapper>
    </template>
  </v-field-template>
</template>

<script>
import FieldMixin from '../structure/FieldMixin';
import FieldConditionMixin from '../structure/conditions/FieldConditionMixin';
import FieldValidationMixin from '../structure/validation/FieldValidationMixin';

import NolteFormTextarea from '../../nolte-ui/NolteForm/NolteFormTextarea';
import NolteFormWrapper from '../../nolte-ui/NolteForm/NolteFormWrapper';

import { getStorage } from '@/useLocalStorage';

export default {
  name: 'SitecoreFormsFieldsMultiplelinetext',

  components: {
    NolteFormTextarea,
    NolteFormWrapper,
  },

  mixins: [FieldMixin, FieldConditionMixin, FieldValidationMixin],

  mounted() {
    const savedValue = getStorage(this.field.valueField.name);
    if (savedValue) {
      this.value = savedValue;
    } else return;
  },

  data() {
    return {
      value: this.field.model.value,
    };
  },
};
</script>
