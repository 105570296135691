<template>
  <v-field-template name="htmltext" :field="field" :hidden="hidden">
    <template #field>
      <v-dynamic-html-tag
        class="copy__content"
        :tag="field.model.htmlTag"
        :text="field.model.text"
        :raw="true"
      />
    </template>
  </v-field-template>
</template>

<script>
import VDynamicHtmlTag from './DynamicHtmlTag';
import FieldMixin from '../structure/FieldMixin';
import FieldConditionMixin from '../structure/conditions/FieldConditionMixin';
import FieldValidationMixin from '../structure/validation/FieldValidationMixin';

export default {
  name: 'SitecoreFormsFieldsHtmlText',
  mixins: [FieldMixin, FieldConditionMixin, FieldValidationMixin],
  components: {
    VDynamicHtmlTag,
  },
};
</script>

<style lang="scss" scoped>
.copy__content {
  @include text-intro;
  text-align: center;
  padding: $unit-base;
  margin-top: $unit-base;
  margin-bottom: $unit-base;

  :deep(a) {
    text-decoration: underline;
  }
}
</style>
