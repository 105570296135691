<template>
  <div class="nolte-kitchen-talk-article-header">
    <div v-if="imageHasSource(headerImage)" class="header-image-container">
      <EditorialImage
        class="header-image"
        :media="headerImage"
        :sources="{
          '(min-width: 769px)': '21:9',
        }"
        default-aspect-ratio="16:10"
        default-sizes="100vw"
      />
    </div>

    <NolteMoodProductVariants
      v-if="productVariants && productVariants.length"
      class="nolte-kitchen-talk-article__product-variants"
      :product-variants="productVariants"
    />

    <section class="introduction">
      <div class="introduction__header">
        <ContentLink
          v-if="parentRef.url"
          :editable="false"
          class="nolte-kitchen-talk-article__breadcrumb"
          :field="{ value: { text: parentRef.name, href: parentRef.url, linktype: 'internal' } }"
        />

        <NolteTags :tags="tags" />
        <EditorialHeadline class="headline" :fields="{ headline: headline }" headline-level="h1" />
        <sc-text
          v-if="isEditing || textHasValue(subheadline)"
          class="subheadline"
          :field="subheadline"
          tag="h2"
        />
      </div>
    </section>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import { Text } from '@sitecore-jss/sitecore-jss-vue';
import EditorialHeadline from './EditorialHeadline';
import EditorialImage from '@/components/EditorialImage';
import NolteMoodProductVariants from '../nolte-ui/NolteMoodProductVariants';
import ContentLink from '@/components/ContentLink';
import NolteTags from './NolteTags';
import { textHasValue, imageHasSource } from './helper.js';

const mapField = (field, mapFunction) => {
  if (!field || !field.length || typeof mapFunction !== 'function') {
    return [];
  }
  return field.map(mapFunction);
};

export default {
  name: 'EditorialKitchenTalkArticleHeader',

  components: {
    ScText: Text,
    EditorialHeadline,
    EditorialImage,
    NolteMoodProductVariants,
    ContentLink,
    NolteTags,
  },

  computed: {
    ...mapGetters('jss', ['isEditing']),

    parentRef() {
      const parent = this.fields?.data?.route?.parent;
      return {
        name: parent?.title?.value,
        url: parent?.url,
      };
    },

    headerImage() {
      return this.fields?.data?.route?.headerImage?.jss;
    },

    productVariants() {
      return mapField(this.fields?.data?.productVariants?.variants, variant => ({
        url: variant?.url,
        variantLabel: variant?.label,
        internalTag: variant?.internalTags,
        productLabel: variant?.productLabel,
        variantImage: variant?.previewImage.jss?.value,
        showProductInformation: variant?.showProductInformation === 'true',
        categoryLabel: variant?.categoryLabel,
      }));
    },

    headline() {
      return this.fields?.data?.route?.headline?.jss;
    },

    subheadline() {
      return this.fields?.data?.route?.subheadline?.jss;
    },

    tags() {
      return this.fields?.data?.route?.sxaTags?.targetItems?.map(tag => {
        return tag.title?.jss?.value;
      });
    },
  },

  methods: {
    textHasValue,
    imageHasSource,
  },
};
</script>

<style lang="scss" scoped>
.header-image-container {
  position: relative;
  @include helper__aspect-ratio(16 / 10);

  @include helper__greater($bp-768) {
    @include helper__aspect-ratio(21 / 9);
  }
}

.header-image {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
}

.introduction {
  @include spacing;
}

.introduction__header {
  margin-bottom: $unit-half * 3;

  @include helper__greater($bp-768) {
    margin-bottom: $unit-base;
  }
}

.nolte-kitchen-talk-article__breadcrumb {
  @include breadcrumb;
  margin: 0 0 $unit-double;
}

.headline {
  @include text-h2;
  @include reset-spacing;
  margin-top: $unit-base;
}

.subheadline {
  @include text-h3;
  @include reset-spacing;
}

.nolte-kitchen-talk-article__product-variants {
  :deep(.viewport) {
    width: 100%;
  }
}
</style>
