import smoothscroll from 'smoothscroll-polyfill';
import eventBus from '@/lib/eventBus';

export default {
  data() {
    return {
      validationComponents: {},
    };
  },

  mounted() {
    smoothscroll.polyfill();
  },

  created() {
    // all field components send their instance if they are mounted so we have an overview of all field components
    eventBus.$on(this.formId + ':validation:register-component', (fieldKey, component) => {
      this.validationComponents[fieldKey] = component;
    });

    eventBus.$on(this.formId + ':form-submitted-failure', ({ result }) => {
      this.processValidation(result);
    });
  },

  methods: {
    processValidation(result) {
      process.env.NODE_ENV === 'development' ? console.log('processValidation error', result) : '';

      // the submitForm Promise can throw an error because of a e.g. HTTP 500 response

      // Case 1: if result is not defined, then the session (Anti-Forgery-Token) is invalid
      if (result === undefined) {
        eventBus.$emit(this.formId + ':invalid-session');
        return;
      }

      // Case 2: if we have an error we cannot use result.validationErrors so we catch the case in front
      if (result instanceof Error) {
        for (const id in this.validationComponents) {
          const $component = this.validationComponents[id];

          // submit field
          if ($component.field.model.templateId === '{94A46D66-B1B8-405D-AAE4-7B5A9CD61C5E}') {
            $component.errors = [this.$t('form-global-error')];
            break;
          }
        }
        return;
      }

      let $formSubmitContextErrors = '';
      result?.errors?.forEach(function (errorMsg) {
        if (errorMsg !== '') {
          $formSubmitContextErrors += errorMsg + '\n';
        }
      });

      let $firstErrorElement;

      for (let id in this.validationComponents) {
        const $component = this.validationComponents[id];
        if (
          $component.field.model.templateId === '{94A46D66-B1B8-405D-AAE4-7B5A9CD61C5E}' && // submit field
          result?.errors?.length
        ) {
          if ($formSubmitContextErrors !== '') {
            $component.errors = [$formSubmitContextErrors];
          } else {
            $component.errors = [this.$t('form-global-error')];
          }
        } else {
          // special handling for the email comparison
          if ($component.field.model.templateId === '{D4F20409-34EA-41B0-B6C9-8337BB3C9C6C}') {
            const checkId = id.replace('.Value', '.ConfirmEmail');
            if (result?.validationErrors[checkId]) {
              id = checkId;
            }
            $component.errors = result?.validationErrors[id] || [];
            if (result?.validationErrors[id] && !$firstErrorElement) {
              $firstErrorElement = $component.$el;
            }
          }
          // special handling for the password comparison field as we have to check not only
          // ".Value" but ".ConfirmPassword" too (".ConfirmPassword" is preferred)
          else {
            const checkId = id.replace('.Value', '.ConfirmPassword');
            // console.log('checkId', checkId);
            // console.log('result?.validationErrors[checkId]', result?.validationErrors[checkId]);
            if (result?.validationErrors[checkId]) {
              id = checkId;
            }
            // console.log(' $component.errors', $component.errors);
            // console.log('$firstErrorElement1', $firstErrorElement);
            $component.errors = result?.validationErrors[id] || [];
            if (result?.validationErrors[id] && !$firstErrorElement) {
              $firstErrorElement = $component.$el;
              //console.log('$firstErrorElement2', $firstErrorElement);
            }
          }
        }
      }

      if (typeof window !== 'undefined' && $firstErrorElement) {
        setTimeout(() => {
          window.scroll({
            top:
              $firstErrorElement.closest('.form-field').offsetTop -
              document.body.scrollTop -
              document.querySelector('header').getBoundingClientRect().height - // header height
              20, // just some whitespace
            behavior: 'smooth',
          });
        }, 400);
      }
    },
  },
};
