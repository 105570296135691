<template>
  <div class="editorial-neo-quote-wrapper">
    <ContentAnchor
      :fields="{
        anchorTitle: fields['Anchor Title'],
        anchorName: fields['Anchor Name'],
      }"
    />
    <blockquote>
      <ScText :field="text" tag="div" />
    </blockquote>
  </div>
</template>

<script>
import { Text } from '@sitecore-jss/sitecore-jss-vue';
import { mapGetters } from 'vuex';
import ContentAnchor from '@/components/ContentAnchor';

export default {
  name: 'EditorialNeoLightQuote',
  components: {
    ScText: Text,
    ContentAnchor,
  },
  computed: {
    ...mapGetters('jss', ['isEditing']),

    text() {
      return this.fields?.Text;
    },

    showInAnchorNavigation() {
      return this.fields && this.fields['Show Anchor in Navigation']?.value ? true : false;
    },
  },
};
</script>

<style lang="scss" scoped>
.editorial-neo-quote-wrapper {
  @include spacing;
  margin: $unit-double * 2 auto;

  @include helper__greater($bp-768) {
    margin: $unit-double * 4 auto;
  }

  > .editorial-anchor {
    margin-bottom: $unit-double * 2 !important;
  }
}

blockquote {
  font-family: $font-futura-pt-book;
  font-size: 1.5rem;
  letter-spacing: 0px;
  word-spacing: 0.2rem;

  position: relative;
  color: $color-ironside-gray;
  opacity: 1;
  margin: 0;
  padding: 0 3.25rem 0 3.25rem;
  white-space: pre-line;

  @include helper__greater($bp-768) {
    font-size: 34px;
    line-height: (38 / 34);
    padding: 0 4.5rem 0 6.5rem;
  }

  &:before,
  &:after {
    position: absolute;
    content: url('../../assets/images/quote.svg');
    background-size: 35px 43px;
    width: 35px;
    height: 43px;

    @include helper__greater($bp-768) {
      background-size: 70px 86px;
      width: 70px;
      height: 86px;
    }
  }

  &:before {
    left: 0;
    top: -1.5rem;

    @include helper__greater($bp-768) {
      top: -3rem;
    }
  }
  &:after {
    right: 0;
    bottom: -1rem;

    @include helper__greater($bp-768) {
      bottom: -2rem;
    }
  }
}
</style>
