<template>
  <div
    class="nolte-catalog-menu-categories"
    :class="{
      'nolte-catalog-menu-categories--active': hasActiveOption,
    }"
    v-click-outside="close"
  >
    <input
      type="checkbox"
      class="nolte-catalog-menu-categories__switch"
      :id="switchId"
      :checked="value"
      @change="$emit('update:modelValue', $event.target.checked)"
    />
    <label
      :for="switchId"
      class="nolte-catalog-menu-categories__open"
      :title="$t('cataloghub-open-products')"
      v-touch-hover.mobileFlag
    >
      <div class="nolte-catalog-menu-categories__indicator">
        {{ $t('cataloghub-products') }}
      </div>
    </label>

    <div class="nolte-catalog-menu-categories__menu">
      <label
        :for="switchId"
        class="nolte-catalog-menu-categories__close"
        :title="$t('cataloghub-close-products')"
      >
        <IconArrowLeft />
      </label>
      <!-- Add key to NolteScroller to fix issue (by init again) with wrong initialization when element is hidden -->
      <NolteScroller
        :key="value"
        scroller-el="ul"
        class="nolte-catalog-menu-categories__scroller"
        :bounce-edges="true"
      >
        <NolteCatalogMenuCategoryPill
          class="swiper-slide"
          v-for="{ id, label, url, title } in categories"
          :key="`category-pill-${id}`"
          :label="label"
          :url="id === activeOptionId ? catalogUrl : url"
          :title="title"
          :active="id === activeOptionId"
          @click="id === activeOptionId && close(CLOSE_DELAY)"
        />
      </NolteScroller>
    </div>
  </div>
</template>

<script>
import vClickOutside from 'click-outside-vue3';

import NolteScroller from '../NolteScroller';
import NolteCatalogMenuCategoryPill from './NolteCatalogMenuCategoryPill';
import IconArrowLeft from '../../assets/icons/IconArrowLeft.svg?inline';
import { timingCatalogMenu } from '../../styles/variables.scss';
import { getRandomString } from '../helper';
import { touchHover } from '../directives';

const CLOSE_DELAY = parseInt(timingCatalogMenu, 10);

export default {
  name: 'NolteCatalogMenuCategories',

  components: {
    NolteScroller,
    NolteCatalogMenuCategoryPill,
    IconArrowLeft,
  },

  directives: {
    clickOutside: vClickOutside.directive,
    touchHover,
  },

  props: {
    categories: {
      type: Array,
      required: true,
    },
    activeOptionId: {
      type: String,
      default: undefined,
    },
    // catalogUrl is the basic url (e.g. for the catalog)
    // which will be used when unselecting a filter option
    catalogUrl: {
      type: String,
      default: '../',
    },
    value: {
      type: Boolean,
      default: false,
    },
  },
  emits: ['update:modelValue'],
  data() {
    return {
      CLOSE_DELAY,
      categoriesTimeout: null,
    };
  },

  computed: {
    hasActiveOption() {
      return !!this.categories.find(({ id }) => id === this.activeOptionId);
    },

    switchId() {
      return `nolte-catalog-menu-categories__switch-${getRandomString()}`;
    },
  },

  methods: {
    close(delay = undefined) {
      if (this.categoriesTimeout) {
        clearTimeout(this.categoriesTimeout);
      }

      if (typeof delay === 'number') {
        this.categoriesTimeout = setTimeout(() => {
          // Skip closing when there is no option selected anymore
          if (this.hasActiveOption) {
            this.$emit('update:modelValue', false);
          }
        }, delay);

        return;
      }

      this.$emit('update:modelValue', false);
    },
  },
};
</script>

<style lang="scss" scoped>
.nolte-catalog-menu-categories {
  max-width: 100%;
  background-color: $color-white;
  border-radius: $radius-default;
  position: relative;

  &::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    box-shadow: 0 25px 25px 0 rgba($color-black, 0.1);
    z-index: -1;
    border-radius: $radius-default;
  }
}

.nolte-catalog-menu-categories__switch {
  @include helper__visually-hidden;

  &:checked ~ .nolte-catalog-menu-categories__open {
    display: none;
  }

  &:not(:checked) ~ .nolte-catalog-menu-categories__menu {
    display: none;
  }
}

.nolte-catalog-menu-categories__menu {
  display: flex;
  border-radius: $radius-default;
}

.nolte-catalog-menu-categories__open {
  @include helper__transition(color);
  @include text-h5;
  display: block;
  padding: $unit-base + $unit-half $unit-base * 2;
  cursor: pointer;
  user-select: none;

  /**
   * Directive touchHover has the modifier "mobileFlag" which adds the event touchstart
   * to the element. After this event gets triggered, the data-attribute data-touch-hover--mobile
   * will set. This helps in combination with :not to prevent :hover from staying active after
   * the element got touched.
   */
  &:not([data-touch-hover--mobile]):hover,
  &[data-touch-hover] {
    color: $color-delta;
  }
}

.nolte-catalog-menu-categories__indicator {
  position: relative;

  &::before {
    @include helper__transition(transform);
    content: '';
    position: absolute;
    background-color: $color-delta;
    height: $unit-half;
    width: 100%;
    left: 0;
    bottom: $unit-base * -1.5;
    // rotate(0.01deg) avoids jitter in Chrome
    // https://greensock.com/forums/topic/16385-chrome-bug-when-i-scale-an-element-with-background-image-the-image-flickers/
    transform: scaleY(0) rotate(0.01deg);
    // Fix for Chrome breaks Firefox. Need another solution to avoid jitter in Firefox
    @include helper__composited-layer;
    transform-origin: bottom;
  }
}

.nolte-catalog-menu-categories--active .nolte-catalog-menu-categories__indicator::before {
  transform: scaleY(1);
  background-color: $color-ironside-gray;
}

.nolte-catalog-menu-categories__close {
  @include helper__transition(color);
  display: block;
  padding: $unit-base;
  z-index: 1;
  cursor: pointer;

  &:hover {
    color: $color-delta;
  }
}

.nolte-catalog-menu-categories__scroller::before {
  background: none;
  box-shadow: inset 5px 0px 10px -7px $color-delta;
}
</style>
