<template>
  <div class="editorial-headline-wrapper">
    <NolteHeadline
      v-if="!isEditing && textHasValue(fields.headline)"
      :level="headlineLevelComputed"
      :design="headlineDesignComputed"
      :class="{
        'headline-category': isCategoryPage,
      }"
    >
      {{ fields.headline.value }}
    </NolteHeadline>

    <sc-text
      v-else-if="isEditing"
      class="editorial-headline"
      :class="{
        [`headline--${headlineClass}`]: true,
        'headline-category': isCategoryPage,
      }"
      :field="fields.headline"
      :tag="headlineLevelComputed"
    />
  </div>
</template>

<script>
import { mapGetters, mapState } from 'vuex';
import { Text } from '@sitecore-jss/sitecore-jss-vue';
import { textHasValue } from './helper';

import NolteHeadline from '../nolte-ui/NolteHeadline';

export default {
  name: 'EditorialHeadline',

  components: {
    ScText: Text,
    NolteHeadline,
  },

  props: {
    headlineLevel: {
      type: String,
      default: undefined,
    },
    headlineStyle: {
      type: String,
      default: undefined,
    },
    headlineDesign: {
      type: String,
      default: undefined,
    },
  },

  computed: {
    ...mapGetters('jss', ['isEditing']),
    ...mapState('jss', ['routeData']),

    headlineLevelComputed() {
      return (
        this.headlineLevel ||
        this.fields?._extendedParams?.HeadlineLevel?.fields?.Level?.value ||
        'h3'
      );
    },

    headlineDesignComputed() {
      return (
        this.headlineDesign ||
        this.fields?._extendedParams?.HeadlineDesign?.fields?.Design?.value ||
        'default'
      );
    },

    headlineClass() {
      if (this.headlineDesignComputed !== 'default') {
        return this.headlineDesignComputed;
      } else {
        return 'level' + this.headlineLevelComputed.replace(/[^\d.]/g, '');
      }
    },

    isCategoryPage() {
      // If templateId is CategoryDetail (eedf6...)
      return this.routeData?.templateId == 'eedf68e4-274a-54c2-a495-a0208cd5e430';
    },
  },

  methods: {
    textHasValue,
  },
};
</script>

<style lang="scss" scoped>
.editorial-headline-wrapper {
  @include spacing;

  @include helper__greater($bp-768) {
    margin: $unit-double * 2 auto $unit-double;
  }
}

.editorial-headline {
  box-sizing: content-box;
  max-width: 700px;
}

.headline--level1 {
  @include text-h1;
}

.headline--level2 {
  @include text-h2;
}

.headline--level3 {
  @include text-h3;
}

.headline--level4 {
  @include text-h4;
}

.headline--centered {
  // we ignore the size defined by the level
  @include text-h2;
}

.headline-category {
  margin-bottom: $unit-double;
}

.editorial-headline--centered {
  text-align: center;
  padding-top: $unit-half;
  padding-bottom: $unit-half;
  margin: $unit-double auto;
  position: relative;

  &::before,
  &::after {
    content: '';
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    height: 4px;
    background-color: $color-athens-gray;
    min-width: 250px;
    max-width: 100%;
  }

  &::before {
    top: 0;
  }

  &::after {
    bottom: 0;
  }
}
</style>
