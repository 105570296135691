<template>
  <div class="nolte-grid-cell" :class="`nolte-grid-cell--type-${type}`">
    <slot />
  </div>
</template>

<script>
export const gridAspectRatios = {
  ASPECT_RATIO_3_2: { attribute: '3-2', value: '3:2', size: 'default' },
  ASPECT_RATIO_3_2_LARGE: { attribute: '3-2--large', value: '3:2', size: 'large' },
  ASPECT_RATIO_3_2_LARGE_PERMANENT: {
    attribute: '3-2--large large-permanent',
    value: '3:2',
    size: 'large',
  },
  ASPECT_RATIO_3_4: { attribute: '3-4', value: '3:4', size: 'default' },
};

export default {
  name: 'NolteGridCell',

  props: {
    type: {
      type: String,
      required: true,
      validator(value) {
        return Object.values(gridAspectRatios)
          .map(x => x.attribute)
          .includes(value);
      },
    },
  },
};
</script>

<style lang="scss" scoped>
.nolte-grid-cell {
  position: relative;

  // aspect ratio container
  &::after {
    content: '';
    display: block;
    pointer-events: none;
  }
}

.nolte-grid-cell--type-3-2--large {
  @include helper__greater($bp-1024) {
    grid-row-end: span 2;
    grid-column-end: span 2;
  }
}

.large-permanent {
  grid-row-end: span 2;
  grid-column-end: span 2;
}

.nolte-grid-cell--type-3-4 {
  grid-row-end: span 2;
}

.nolte-grid-cell--type-3-2::after,
.nolte-grid-cell--type-3-2--large::after {
  padding-bottom: calc(100% / (3 / 2));
}

.nolte-grid-cell--type-3-4::after {
  padding-bottom: calc(100% / (3 / 4));
}

// position content absolute inside aspect ratio container
.nolte-grid-cell :deep() > * {
  // since some child may have 'absolute' position we need to overwrite
  position: absolute !important;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
}
</style>
