<template>
  <NolteScroller scroller-el="ul" class="nolte-catalog-menu-inspiration-filter-options">
    <NolteCatalogMenuInspirationFilterOption
      v-for="({ id, label, url, title, iconId }, index) in children"
      :key="`inspiration-filter-option-${index}`"
      :label="label"
      :url="isActiveOption(id) ? catalogUrl : url"
      :title="title"
      :icon-id="iconId"
      :active="isActiveOption(id)"
      @click="isActiveOption(id) && $emit('close')"
    />
  </NolteScroller>
</template>

<script>
import NolteCatalogMenuInspirationFilterOption from './NolteCatalogMenuInspirationFilterOption.vue';
import NolteScroller from '../NolteScroller';

export default {
  name: 'NolteCatalogMenuInspirationFilterOptions',

  components: {
    NolteScroller,
    NolteCatalogMenuInspirationFilterOption,
  },

  props: {
    catalogUrl: {
      type: String,
      required: true,
    },
    activeOptionId: {
      type: String,
      default: undefined,
    },
    children: {
      type: Array,
      default: undefined,
    },
  },

  computed: {
    isActiveOption() {
      return id => id === this.activeOptionId;
    },
  },
};
</script>

<style lang="scss" scoped>
.nolte-catalog-menu-inspiration-filter-options {
  display: flex;
  justify-content: center;

  :deep(.viewport) {
    min-width: 100%;
    justify-content: center;
  }
}
</style>
