<template>
  <v-field-template
    name="date"
    :field="field"
    :hidden="hidden"
    :disabled="disabled"
    :errors="errors"
  >
    <template #field>
      <input
        type="date"
        :id="field.valueField.id"
        :name="field.valueField.name"
        :required="field.model.required"
        :min="min"
        :max="max"
        :disabled="disabled"
        v-model="value"
        @blur="fieldBlur"
      />
    </template>
  </v-field-template>
</template>

<script>
import FieldMixin from '../structure/FieldMixin';
import FieldConditionMixin from '../structure/conditions/FieldConditionMixin';
import FieldValidationMixin from '../structure/validation/FieldValidationMixin';

function cleanDate(value) {
  // we get the date as ISO 8601 but need a RFC 3339 date
  return value.split('T')[0];
}

export default {
  name: 'SitecoreFormsFieldsDate',
  mixins: [FieldMixin, FieldConditionMixin, FieldValidationMixin],

  data() {
    return {
      value: cleanDate(this.field.model.value || ''),
      min: cleanDate(this.field.model.min || ''),
      max: cleanDate(this.field.model.max || ''),
    };
  },
};
</script>
