<template>
  <v-field-template
    name="checkboxlist"
    :field="field"
    :hidden="hidden"
    :disabled="disabled"
    :errors="errors"
  >
    <template #field>
      <NolteFormWrapper :errors="errors" #default="{ hasError }">
        <NolteFormCheckbox
          v-for="(item, key) in field.model.items"
          :key="key"
          :id="cleanItemId('id-' + item.itemId)"
          :name="field.valueField.name"
          v-model="value"
          :checked="item.selected"
          :checkboxListItems="field.model.items"
          :required="field.model.required"
          :disabled="disabled"
          :value="item.value"
          @blur="fieldBlur"
          :label="item.text"
          :invalid="hasError"
          class="checkboxlist__nolte-form-checkbox"
        />
      </NolteFormWrapper>
    </template>
  </v-field-template>
</template>

<script>
import eventBus from '@/lib/eventBus';

import FieldMixin from '../structure/FieldMixin';
import FieldConditionMixin from '../structure/conditions/FieldConditionMixin';
import FieldValidationMixin from '../structure/validation/FieldValidationMixin';

import NolteFormCheckbox from '../../nolte-ui/NolteForm/NolteFormCheckbox';
import NolteFormWrapper from '../../nolte-ui/NolteForm/NolteFormWrapper';

import { isProxy, toRaw } from 'vue';

export default {
  name: 'SitecoreFormsFieldsCheckboxlist',

  components: {
    NolteFormCheckbox,
    NolteFormWrapper,
  },

  mixins: [FieldMixin, FieldConditionMixin, FieldValidationMixin],

  data() {
    return {
      value: [],
    };
  },
  created() {
    const checked = [...this.field.model.items];

    checked.forEach(item => {
      if (item.selected) {
        this.value.push(item.value);
      }
    });
    eventBus.$on(this.field?.valueField?.name + ':checkbox-change', component => {
      // Remove existing value from the list
      const index = this.value.findIndex(item => item === component.value);
      if (index > -1) {
        this.value.splice(index, 1);
      }
      // and add value to array when checked
      if (component.checked) {
        this.value.push(component.value);
      }

      if (isProxy(this.value)) {
        let value = toRaw(this.value);
        let valueFieldName = toRaw(this.field?.valueField?.name);

        let result = {
          value: value,
          field: {
            valueField: {
              name: valueFieldName,
            },
          },
        };
        eventBus.$emit(this.formId + ':field-change', result);
      }
    });
  },

  onBeforeUnmount() {
    eventBus.$off(this.field?.valueField?.name + ':checkbox-change');
  },
};
</script>

<style lang="scss" scoped>
.checkboxlist__nolte-form-checkbox {
  display: block;
}
</style>
