<template>
  <div class="nolte-article-teaser">
    <ContentLink
      :editable="false"
      class="nolte-article-teaser__link"
      :field="{ value: { href: linkUrl, linktype: 'internal' } }"
    >
      <EditorialImage
        class="nolte-article-teaser__image"
        :media="image"
        fit="cover"
        :default-sizes="imageDefaultSizes"
        :editable="false"
      />
      <NolteTags v-if="tags" class="nolte-article-teaser__tags" :tags="tags" />
      <h2 v-if="headline" class="nolte-article-teaser__headline">{{ headline }}</h2>
      <p v-if="copy" class="nolte-article-teaser__copy">{{ copy }}</p>
    </ContentLink>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import EditorialImage from '@/components/EditorialImage';
import ContentLink from '@/components/ContentLink';
import NolteTags from './NolteTags';

export default {
  name: 'EditorialArticleTeaser',

  components: {
    EditorialImage,
    ContentLink,
    NolteTags,
  },

  props: {
    image: {
      type: Object,
      required: true,
    },
    imageDefaultSizes: {
      type: String,
      required: true,
    },
    headline: {
      type: String,
      required: true,
    },
    copy: {
      type: String,
      default: undefined,
    },
    linkUrl: {
      type: String,
      required: true,
    },
    tags: {
      type: Array,
      default: undefined,
    },
  },

  computed: {
    ...mapGetters('jss', ['isEditing']),
  },
};
</script>

<style lang="scss" scoped>
.nolte-article-teaser {
  background-color: $color-concret;
  display: flex;
  position: relative;

  @include helper__until($bp-1024) {
    border-bottom: 4px solid #fff;
  }
}

.nolte-article-teaser__link {
  width: 100%;
  padding: $unit-base;
  margin: 0 auto;

  @include helper__greater($bp-768) {
    padding: $unit-double;
  }
}

.nolte-article-teaser__image {
  width: 100%;
}

.nolte-article-teaser__headline {
  @include text-h3;
  margin: $unit-base 0;
}

.nolte-article-teaser__copy {
  @include text-copy;
  margin: $unit-base 0 $unit-double;
}
</style>
