<template>
  <NolteStickyMenu
    :class="{
      'nolte-kitchen-talk-menu-sticky--is-mobile': isMobile,
      'nolte-kitchen-talk-menu-sticky--is-desktop': !isMobile,
    }"
    :is-mobile="isMobile"
    :show-if-possible="showIfPossible"
  >
    <div class="nolte-kitchen-talk-menu-sticky__main">
      <NolteKitchenTalkMenuCategories
        v-model="categoriesOptionIndex"
        :categories="categories"
        class="nolte-kitchen-talk-menu-sticky__categories"
      />
    </div>
  </NolteStickyMenu>
</template>

<script>
import eventBus from '@/lib/eventBus';
import NolteStickyMenu from '../NolteStickyMenu';
import NolteKitchenTalkMenuCategories from './NolteKitchenTalkMenuCategories';

export default {
  name: 'NolteKitchenTalkMenuSticky',

  components: {
    NolteStickyMenu,
    NolteKitchenTalkMenuCategories,
  },

  props: {
    categories: {
      type: Array,
      required: true,
    },
    isMobile: {
      type: Boolean,
      default: false,
    },
    showIfPossible: {
      type: Boolean,
      default: false,
    },
  },

  data() {
    return {
      categoriesOptionIndex: undefined,
    };
  },

  beforeUnmount() {
    eventBus.$emit('NolteKitchenTalkMenuSticky:beforeUnmount');
  },

  mounted() {
    document.addEventListener('scroll', this.handleScroll.bind(this));

    eventBus.$once('NolteKitchenTalkMenuSticky:beforeUnmount', () => {
      document.removeEventListener('scroll', this.handleScroll.bind(this));
    });
  },

  methods: {
    handleScroll() {
      requestAnimationFrame(() => {
        this.categoriesOptionIndex = undefined;
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.nolte-kitchen-talk-menu-sticky__main {
  display: flex;
  justify-content: center;
  align-items: flex-start;
  width: 100%;

  .nolte-kitchen-talk-menu-sticky--is-mobile & {
    padding-left: $unit-base;
    padding-right: $unit-base;
    padding-top: 50px;
  }
}

.nolte-kitchen-talk-menu-sticky__categories {
  @include helper__transition(border-radius);

  .nolte-kitchen-talk-menu-sticky--is-desktop & {
    background: none;

    &::before {
      content: none;
    }
  }

  .nolte-kitchen-talk-menu-sticky--is-desktop & :deep(.nolte-kitchen-talk-menu-categories__menu) {
    border-radius: 0;
  }

  .nolte-kitchen-talk-menu-sticky--is-mobile & {
    position: relative;

    &::before {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      box-shadow: 0 -5px 25px 5px rgba($color-black, 0.1);
      z-index: -1;
      border-radius: $radius-default;
    }
  }
}

.nolte-kitchen-talk-menu-sticky--is-mobile .nolte-kitchen-talk-menu-sticky__categories {
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
}

.nolte-kitchen-talk-menu-sticky--is-mobile {
  margin-right: $unit-half;
}

.nolte-kitchen-talk-menu-sticky__categories {
  box-shadow: inset 0 -11px 0 -10px $color-athens-gray;
  margin-bottom: 0;
}
</style>
