<template>
  <div class="nolte-collapsable">
    <slot name="button" :toggleCollapsable="toggleCollapsable" :open="open" />
    <Transition name="fade"
      ><div v-if="open" :key="`collapsable_${open}`" class="nolte-collapsable__expandable">
        <slot /></div
    ></Transition>
  </div>
</template>

<script>
export default {
  name: 'NolteCollapsable',

  data() {
    return {
      open: false,
    };
  },

  props: {
    initialOpen: {
      type: Boolean,
      default: false,
    },
  },

  created() {
    this.open = this.initialOpen;
  },

  methods: {
    toggleCollapsable() {
      this.open = !this.open;
    },
  },
};
</script>

<style lang="scss" scoped>
.expand-enter-active,
.expand-leave-active {
  @include helper__transition(height);
}

.nolte-collapsable__expandable {
  overflow: hidden;
}

.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.3s ease-in-out;
}

.fade-enter-from,
.fade-leave-to {
  opacity: 0;
}
</style>
