<template>
  <div class="nolte-columns">
    <div class="column" v-for="index of columnCount" :key="index">
      <slot :name="`column_${index}`" />
    </div>
  </div>
</template>

<script>
export default {
  name: 'NolteColumns',

  computed: {
    columnCount() {
      return Object.keys(this.$slots).length;
    },
  },
};
</script>

<style lang="scss" scoped>
.nolte-columns {
  display: flex;

  @include helper__until($bp-768) {
    flex-direction: column;
  }
}

.column {
  flex: 1 auto;
  width: 100%;

  @include helper__greater($bp-768) {
    padding-right: $unit-double * 2;
  }
}
</style>
