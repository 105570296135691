import elementClosest from 'element-closest';

import i18nInit from './i18n';
import { createApp } from './createApp';
import config from './temp/config';

// polyfills
if (typeof window !== 'undefined' && typeof document !== 'undefined') {
  elementClosest(window); // this is used to reference window.Element
}

/*
SSR Data
If we're running in a server-side rendering scenario,
the server will provide the window.__JSS_STATE__ object
for us to acquire the initial state to run with on the client.

This enables us to skip a network request to load up the layout data.

SSR is initiated from /server/server.js.
*/

let initLanguage = config.defaultLanguage;
let __JSS_STATE__ = null;
const ssrRawJson = document.getElementById('__JSS_STATE__');
if (ssrRawJson) {
  __JSS_STATE__ = JSON.parse(ssrRawJson.innerHTML);
}
if (__JSS_STATE__) {
  // set i18n language SSR state language instead of static config default language
  initLanguage = __JSS_STATE__.sitecore.context.language;
}

// initialize the dictionary, then render the app
// note: if not making a multlingual app, the dictionary init can be removed.
i18nInit(initLanguage).then(i18n => {
  /* eslint-disable no-underscore-dangle */
  // HTML element to place the app into
  const rootElement = document.getElementById('root');

  const initialState = __JSS_STATE__ || null;

  const { app } = createApp(initialState, i18n);

  // For Debugging: allows console.logs wihtin Templates
  // https://mokkapps.de/vue-tips/debugging-in-templates
  // Use {{ $log('mesage') }}  within a template.
  app.config.globalProperties.$log = console.log;

  app.mount(rootElement);
});
