<template>
  <div v-if="tags && tags.length" class="nolte-tags">
    <div class="nolte-tag" v-for="(tag, index) in tags" :key="`tag_${index}`">
      {{ tag }}
    </div>
  </div>
</template>

<script>
export default {
  name: 'NolteTags',

  props: {
    tags: {
      type: Array,
      default: () => [],
    },
  },
};
</script>

<style lang="scss" scoped>
.nolte-tags {
  margin: $unit-half 0 $unit-base 0;
}

.nolte-tag {
  @include text-caption--small;
  display: inline-block;
  margin: $unit-half $unit-half 0 0;
  padding: 0 $unit-base;
  border-radius: 13px;

  background-color: $color-ironside-gray;
  color: $color-white;
  white-space: nowrap;
  line-height: 25px;
  text-transform: uppercase;
}
</style>
