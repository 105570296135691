<template>
  <div class="nolte-mood-detail">
    <div class="nolte-mood-detail__header">
      <div class="nolte-mood-detail__header-image-wrapper">
        <EditorialImage
          img-class="nolte-mood-detail__header-image"
          :media="headerImage"
          :sources="{
            '(min-width: 769px)': {
              aspectRatio: '21:9',
              sizes: '(min-width: 1440px) 1440px, 100vw',
            },
          }"
          default-aspect-ratio="16:10"
          default-sizes="100vw"
          fit="cover"
          :editable="false"
        />
      </div>

      <NolteMoodProductVariants
        v-if="productVariants && productVariants.length"
        class="nolte-mood-detail__product-variants"
        :product-variants="productVariants"
      />

      <div class="nolte-mood-detail__introduction">
        <ContentLink
          v-if="parentRef.url"
          :editable="false"
          class="nolte-mood-detail__breadcrumb"
          :field="{ value: { text: parentRef.name, href: parentRef.url, linktype: 'internal' } }"
        />
        <NolteHeadline class="nolte-mood-detail__headline" level="h1">{{ title }}</NolteHeadline>
        <p class="nolte-mood-detail__description">{{ description }}</p>
      </div>
    </div>

    <p v-if="images && images.length" class="nolte-mood-detail__gallery-headline">
      {{ $t('mood-detail-gallery-headline') }}
    </p>

    <!-- Gallery type slider -->
    <div class="nolte-mood-detail__gallery-wrapper" v-if="images && images.length">
      <NolteScroller class="nolte-mood-detail__gallery" :show-arrows="true" :count="images.length">
        <EditorialImage
          v-for="(image, index) in images"
          :key="`image-${index}`"
          class="nolte-mood-detail__gallery-image"
          :media="image"
          :default-sizes="imagesDefaultSizes[index]"
          :editable="false"
        />
      </NolteScroller>
    </div>
    <BackgroundWave class="nolte-mood-detail__background-wave" />

    <div
      class="nolte-mood-detail__secondary-background"
      :class="{
        'without-planning-alternatives': planningAlternatives && planningAlternatives.length === 0,
      }"
    >
      <div
        v-if="planningAlternatives && planningAlternatives.length"
        class="nolte-mood-detail__planning-alternatives-wrapper"
      >
        <NolteHeadline class="nolte-mood-detail__planning-alternatives__headline" level="h2">
          {{ planningAlternativesHeadline }}
        </NolteHeadline>
        <p class="nolte-mood-detail__planning-alternatives__copy">
          {{ planningAlternativesCopy }}
        </p>
      </div>
      <EditorialPlanningAlternativeGroup
        class="nolte-mood-detail__planning-alternatives"
        :rendering="rendering"
        :planning-alternatives="planningAlternatives"
      />

      <div
        v-if="highlights && highlights.length"
        class="nolte-mood-detail__highlight-group-wrapper"
      >
        <NolteHeadline level="h2" class="nolte-mood-detail__highlight-headline">
          {{ highlightGroupHeadline }}
        </NolteHeadline>
        <NolteHighlightGroup>
          <NolteHighlight
            v-for="(highlight, index) in highlights"
            :key="`highlight-${index}`"
            :image="highlight.image"
            :headline="highlight.headline"
            :copy="highlight.copy"
          />
        </NolteHighlightGroup>
      </div>

      <div class="nolte-mood-detail__related-moods" v-if="relatedMoods && relatedMoods.length">
        <EditorialHeadline
          class="nolte-mood-detail__related-moods_headline"
          :fields="{ headline: { value: relatedMoodsHeadline } }"
        />

        <NolteScroller :show-arrows="true" :count="relatedMoods.length">
          <div v-for="(product, index) in relatedMoods" :key="`relatedproduct-${index}`">
            <EditorialTeaser :fields="product" :editable="false" />
          </div>
        </NolteScroller>
      </div>
    </div>
  </div>
</template>

<script>
import NolteHighlight from '../NolteHighlight';
import NolteHighlightGroup from '../NolteHighlightGroup';
import NolteMoodProductVariants from '../NolteMoodProductVariants';
import NolteScroller from '../NolteScroller';
import NolteHeadline from '../NolteHeadline';
import BackgroundWave from '../../assets/background_wave_2.svg?inline';
import ContentLink from '@/components/ContentLink';
import EditorialPlanningAlternativeGroup from '@/components/EditorialPlanningAlternativeGroup';
import EditorialImage from '@/components/EditorialImage';
import EditorialTeaser from '@/components/EditorialTeaser';
import EditorialHeadline from '@/components/EditorialHeadline';

export default {
  name: 'NolteMoodDetail',

  components: {
    NolteHighlight,
    NolteHighlightGroup,
    NolteMoodProductVariants,
    NolteScroller,
    NolteHeadline,
    BackgroundWave,
    ContentLink,
    EditorialPlanningAlternativeGroup,
    EditorialImage,
    EditorialTeaser,
    EditorialHeadline,
  },

  props: {
    headerImage: {
      type: Object,
      required: true,
    },
    title: {
      type: String,
      required: true,
    },
    description: {
      type: String,
      required: true,
    },
    productVariants: {
      type: Array,
      required: true,
    },
    highlights: {
      type: Array,
      default: () => [],
    },
    images: {
      type: Array,
      default: () => [],
    },
    imagesDefaultSizes: {
      type: Array,
      required: true,
    },
    parentRef: {
      type: Object,
      default: undefined,
    },
    planningAlternativesHeadline: {
      type: String,
      default: undefined,
    },
    planningAlternativesCopy: {
      type: String,
      default: undefined,
    },
    highlightGroupHeadline: {
      type: String,
      default: undefined,
    },
    rendering: {
      type: Object,
      default: undefined,
    },
    planningAlternatives: {
      type: Array,
      default: () => [],
    },
    relatedMoodsHeadline: {
      type: String,
      default: undefined,
    },
    relatedMoods: {
      type: Array,
      default: () => [],
    },
  },
};
</script>

<style lang="scss" scoped>
.nolte-mood-detail__header {
  max-width: $grid-width;
  margin-left: auto;
  margin-right: auto;

  // fix issue with overlay under image
  // swiper has a z-index of 1
  position: relative;
  z-index: 10;
}

.nolte-mood-detail__header-image-wrapper {
  @include helper__aspect-ratio(16 / 10);

  @include helper__greater($bp-768) {
    @include helper__aspect-ratio(21 / 9);
  }

  & > * {
    height: 100%;
  }
}

.nolte-mood-detail__introduction {
  @include spacing;
  margin-bottom: $unit-double * 2;

  @include helper__greater($bp-768) {
    margin-bottom: $unit-double * 4;
  }
}

.nolte-mood-detail__headline {
  @include text-hero;
  max-width: $bp-768;
}

.nolte-mood-detail__description {
  @include text-intro;
  max-width: $bp-768;
  margin-top: $unit-base;
}

.nolte-mood-detail__breadcrumb {
  @include breadcrumb;
  max-width: $bp-768;
}

.nolte-mood-detail__gallery-headline {
  @include text-h3;
  @include spacing;
}

.nolte-mood-detail__gallery-wrapper {
  @include helper__greater($bp-1024) {
    // we align the start of the viewport vertically with the main content
    padding-left: calc((100vw - 1200px) / 2);
    padding-right: calc((100vw - 1200px) / 2);
  }
}

// MARKER: A
// TODO: this is a marker for code which is duplicated on several places with just minor changes
//  The complete NolteScroller should get refactored, and the wrong nesting of the scroll items
//  implemented by demodern should be fixed.
//  The type of vertical alignment should be copied from NolteHighlight so we don't have the problem
//  that if we have just one image it is sticked to the left side.
.nolte-mood-detail__gallery-wrapper :deep(.nolte-scroller) {
  height: 225px;

  @include helper__greater($bp-1024) {
    height: 600px;
  }

  padding: 0 0 $unit-base;

  @include helper__greater($bp-600) {
    padding: 0 0 $unit-half;
  }

  .swiper-slide {
    @include helper__until($bp-600) {
      margin: 0 $unit-base 0 $unit-base;
    }
  }

  .editorial-image--fit-cover {
    max-width: none;
    height: auto;
  }

  .editorial-image--fit-cover img {
    object-fit: initial;
    width: initial;
    max-width: none;
    height: 225px;

    @include helper__greater($bp-1024) {
      height: 600px;
    }
  }
}

.nolte-mood-detail__highlight-group-wrapper {
  margin-top: $unit-double;

  @include helper__greater($bp-768) {
    margin-top: $unit-double * 2;
  }
}

.nolte-mood-detail__highlight-headline {
  @include text-hero;
  @include spacing;
  margin-bottom: 0 !important;
}

.nolte-mood-detail__background-wave-wrapper {
  max-width: 100vw;
  overflow: hidden;

  background-color: $color-concret;
}

.nolte-mood-detail__background-wave {
  width: 100%;
  color: $color-concret;
  margin-top: $unit-base * 4;
  height: 24px;

  @include helper__greater($bp-768) {
    height: auto;
  }
}

.nolte-mood-detail__secondary-background {
  &.without-planning-alternatives {
    padding: 1rem;
  }
  background-color: $color-concret;
}

.nolte-mood-detail__planning-alternatives-wrapper {
  max-width: 850px;
  margin-left: auto;
  margin-right: auto;
  padding: $unit-double $unit-base 0;
  box-sizing: content-box;

  @include helper__greater($bp-768) {
    padding: $unit-double * 2 $unit-base 0;
  }
}

.nolte-mood-detail__planning-alternatives {
  margin-bottom: $unit-double * 2;

  @include helper__greater($bp-768) {
    margin-bottom: $unit-double * 4;
  }
}

.nolte-mood-detail__planning-alternatives__copy {
  margin-top: $unit-base;
  margin-bottom: $unit-double;
}

.nolte-mood-detail__highlight-offset {
  margin: 0 !important;
}

.nolte-mood-detail__related-moods {
  padding: 0 0 $unit-base 0;

  @include helper__greater($bp-600) {
    padding: 0 0 $unit-half;
  }

  .editorial-headline {
    @include text-h3;
    @include spacing;
  }
}

// MARKER: A
.nolte-mood-detail__related-moods :deep(.nolte-scroller) {
  height: 225px;

  @include helper__greater($bp-1024) {
    height: 600px;
  }

  .swiper-slide {
    @include helper__until($bp-600) {
      margin: 0 $unit-base 0 $unit-base;
    }
  }

  .editorial-image--fit-cover {
    max-width: none;
    height: auto;
  }

  .editorial-image--fit-cover img {
    object-fit: initial;
    width: initial;
    max-width: none;
    height: 225px;

    @include helper__greater($bp-1024) {
      height: 600px;
    }
  }
}
</style>
